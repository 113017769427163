import { gql } from "@apollo/client";

export const CheckEmpResetPwdStatus = gql`
  query CheckEmpResetPwdStatus(
    $token: String!
    $inst_id: ID!
    $msg_emp_id: ID!
  ) {
    CheckEmpResetPwdStatus(
      token: $token
      inst_id: $inst_id
      msg_emp_id: $msg_emp_id
    ) {
      status
      message
    }
  }
`;
