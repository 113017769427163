import { DropResult } from "react-beautiful-dnd";
import { InitialStateType, initialState } from "../context/context";
import { payloadTypes } from "../context/reducer";
import { Actions } from "../context/types";
import { StudentStatus } from "./Enum.types";
import { YesNoOptionsType, optionsType, responseType } from "./Types";
import { BUTTON, EMPTY_STRING, INPUT, SELECT, TEXTAREA } from "./constants";
import dayjs from "dayjs";

import Pdf from "../images/DownloadPdf.svg";
import Doc from "../images/WordDocument.svg";
import Xls from "../images/Excel.svg";
import Image from "../images/DownloadImage.svg";
import Audio from "../images/DownloadAudio.svg";

export const clearGlobalStates = (
  dispatch: React.Dispatch<Actions>,
  state: InitialStateType
) => {
  dispatch({
    type: payloadTypes.RESET,
    payload: {
      resetState: {
        ...initialState,
        claims: state.claims,
        user: state.user,
      },
    },
  });
};

export const removeMoreSpace = (string: string) => {
  return string?.replace(/ +/g, " ").trim();
};

export const toStandardCase = (string: string) => {
  return string?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );
};

export const handleFormEvent = (event: any) => {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    const elementNotDisabled = Object.entries(form.elements).filter(
      (x: any) =>
        x[1].disabled !== true &&
        (x[1].tagName === INPUT ||
          x[1].tagName === SELECT ||
          x[1].tagName === TEXTAREA ||
          x[1].tagName === BUTTON)
    );
    if (form.elements[index + 1].disabled) {
      const nextNotDisabledElement = elementNotDisabled.find(
        (x: any) => x[0] > index + 1
      );
      form.elements[
        nextNotDisabledElement ? nextNotDisabledElement[0] : 0
      ].focus();
    }
    if (form.elements[index + 1].name === "search") {
      form.elements[index + 2].focus();
    } else form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const handleMUISelectEvent = (event: any) => {
  event.preventDefault();
  if (event.keyCode === 13) {
    const form = event.target.form;
    const notDisabledElements: any[] = Object.values(form.elements).filter(
      (x: any) =>
        x.disabled !== true &&
        (x.tagName === INPUT ||
          x.tagName === SELECT ||
          x.tagName === TEXTAREA ||
          (x.tagName === BUTTON && !x.classList.contains("MuiButtonBase-root")))
    );
    const customIndex = notDisabledElements.indexOf(
      notDisabledElements.find((elem) => elem === event.target)
    );

    notDisabledElements[customIndex + 1].focus();
  }
};

export const getFileName = (url: string): string => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};
export const getModifiedScrollHeight = (scrollHeight: number) => {
  const percentage = 75;
  const hundred = 100;
  return percentage * (scrollHeight / hundred);
};

export const toInputStandardDate = (day: string) => {
  return day ? dayjs(day).format("YYYY-MM-DD") : EMPTY_STRING;
};

export const timeDifference = (startDate: string, endDate: string) => {
  const date = Math.abs(
    new Date(endDate).getTime() - new Date(startDate).getTime()
  );
  const totalDays = Math.ceil(date / (1000 * 60 * 60 * 24));
  return totalDays;
};

export const toIsoDate = (date: string) => dayjs(date).format();
export const convertToDateObject_MMDDYYYYFormat = (dateString: string) => {
  const delimiter = dateString.includes("/") ? "/" : "-";
  const [dayStr, month, year] = dateString.split(delimiter).map(Number);
  const day = dayStr < 10 ? `0${dayStr}` : dayStr;
  return new Date(`${month}/${day}/${year}`).toString();
};
export const getHeaderRowStyle = () => {
  const headerRowStyle = [
    {
      font: { bold: true, size: 22, name: "Century Gothic" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "e5e7eb" },
      },
      alignment: { horizontal: "center", vertical: "middle" },
    },
    {
      font: {
        bold: true,
        size: 13,
        name: "Arial",
        color: { argb: "000000" },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD9D9D9" },
      },
      alignment: { horizontal: "center" },
    },
  ];
  return headerRowStyle;
};
export const toStandardDate = (date: string) => {
  const parsedDate = dayjs(date);
  if (!parsedDate.isValid()) {
    return "Invalid Date";
  }
  return parsedDate.format("DD-MM-YYYY");
};
export const MonthName = (date: string) =>
  new Date(date).toLocaleString("en-us", { month: "long", year: "numeric" });

export function getDatesBetween(startDate: string, endDate: string) {
  var dates = [];

  // Parse the start and end dates
  var start = new Date(startDate);
  var end = new Date(endDate);

  // Iterate through each month
  var currentDate = new Date(start);

  while (currentDate <= end) {
    dates.push({
      label: MonthName(currentDate.toString()),
      value: new Date(MonthName(currentDate.toString())),
    });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
}

export const handleClear = () => window.location.reload();

export const FetchStudentStatusClassName = (row: StudentStatus | string) => {
  switch (row) {
    case StudentStatus.CUR:
      return "std_status--cur";
    case StudentStatus.TC:
      return "std_status";
    case StudentStatus.DET:
      return "std_status--det";
    case StudentStatus.SOA:
      return "std_status--soa";
    case StudentStatus.NXT:
      return "std_status--nxt";
    case StudentStatus.NE:
      return "std_status--ne";

    default:
      break;
  }
};

export const isOptionEqualToValue = (
  optionData: responseType | optionsType | YesNoOptionsType,
  valueData: responseType | optionsType | YesNoOptionsType | null
) => {
  if (valueData) {
    return optionData.value === valueData.value;
  } else {
    return false;
  }
};

export const reOrderProcess = (result: DropResult, temp: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.index === destination.index) return;

  const [movedItem] = temp.splice(source.index, 1);
  temp.splice(destination.index, 0, movedItem);
};

export const getFileIcon = (filename: string) => {
  const extension = filename.split(".").pop()?.toLowerCase();
  switch (extension) {
    case "pdf":
      return `${Pdf}`;
    case "doc":
    case "docx":
      return `${Doc}`;
    case "xls":
    case "xlsx":
      return `${Xls}`;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return `${Image}`;
    case "mp3":
    case "wav":
      return `${Audio}`;
    default:
      return "";
  }
};

export const convertIsoToIst = (isoDate: string) => {
  const date = new Date(isoDate);

  // Get the UTC hours and minutes
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();

  // Calculate total UTC minutes
  const totalUTCMinutes = utcHours * 60 + utcMinutes;

  // Indian Standard Time offset in minutes (UTC+5:30)
  const ISTOffset = 5 * 60 + 30;

  // Calculate total minutes for IST
  const totalISTMinutes = totalUTCMinutes + ISTOffset;

  // Calculate hours and minutes for IST
  const ISTHours = Math.floor(totalISTMinutes / 60);
  const ISTMinutes = totalISTMinutes % 60;

  // Adjust the date to IST
  date.setUTCHours(ISTHours);
  date.setUTCMinutes(ISTMinutes);

  // Format the date in IST
  const ISTDate = date.toISOString().replace("Z", "");
  return {
    istDateTime: ISTDate,
    istDate: ISTDate.split("T")[0],
    istTime: ISTDate.split("T")[1].split(".")[0],
  };
};

export const formatter = new Intl.NumberFormat("en-IN");
