import React, { useState } from "react";

import { useTheme } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import Navbar from "../../../../Layouts/Navbar";
import { licenseTypes } from "../../../../../utils/constants";
import { Title } from "../../../../../stories/Title/Title";
import { TabPanel, a11yProps } from "../../../../../styles/Tabs";
import Allocate from "./Allocate";
import InterChangeSections from "./Interchange";
import Sidebar from "../../../../Layouts/Sidebar";
const SectionAllocation = () => {
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  const handleChangeIndex = (index: number) => setValue(index);
  return (
    <>
      {" "}
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="section-allocation">
        <Sidebar />

        <div className="section-allocation__frame">
          <Title>
            {value === 0 ? "Section Allocation" : "Section Interchange"}
          </Title>
          <div className="section-allocation__tabs">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Allocate" value={0} {...a11yProps(0)} />
              <Tab label="Interchange" value={1} {...a11yProps(1)} />
            </Tabs>
          </div>
          <div className="section-allocation__tabpanel">
            {/* values for Attendance */}
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Allocate />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <InterChangeSections />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionAllocation;
