import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import TotalStudents from "../../../../../images/TotalMale.svg";
import ReadyToImport from "../../../../../images/Present.svg";
import ContainsError from "../../../../../images/Absent.svg";
import DownArrow from "../../../../../images/DownArrow.svg";
import FileAttach from "../../../../../images/BrowseFiles.svg";
import Close from "../../../../../images/Close.svg";
import ExcelJS from "exceljs";
import { Cell } from "exceljs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useToken from "../../../../../customhooks/useToken";
import { AppContext } from "../../../../../context/context";
import useServerDateandTime from "../../../../../customhooks/useServerDateandTimeWithoutToken";
import {
  ImportMessageTableStyles,
  ImportStudentDataTableStyles,
} from "../../../../../styles/StickyTableStyles";
import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import { msgType } from "../../../../../utils/FormTypes";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  E4_CELL,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  EMPTY_STRING,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FILENAME,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_ALIGNMENT_LEFT,
  HEADER_CSS,
  TABLE_HEADER_CSS,
} from "../../../../../utils/constants";
import {
  ChannelQueryType,
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  MsgChannelType,
  Operation,
  SortBy,
  StudentReportType,
} from "../../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";

import useInstDetails from "../../../../../customhooks/useInstDetails";
import LoadingModal from "../../../../Modals/LoadingModal";
import MessageModal from "../../../../../utils/MessageModal";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import { LoadingStyles } from "../../../../styles/ModalStyles";
import {
  getHeaderRowStyle,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import Eduate from "../../../../../images/Eduate_Logo_image.png";
import ReactModal from "react-modal";
import { AddIndividualStdMessageWithTags } from "../../../../../queries/chat/mutations";
import ExcelLoading from "../../ExcelLoading";
import useStudentsbyNewApi from "../../../../../customhooks/useStudentByNodeApi";
import useChannels from "../../CustomHooks/useChannels";
import { GetChannelMessages } from "../../../../../queries/chat/feeds/list";

interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageImport = ({ setModalFlag }: Props) => {
  const { token } = useToken();
  const { entryId, instId } = useParams();
  const { state } = useContext(AppContext);
  const { serverDate } = useServerDateandTime();
  const classes = ImportMessageTableStyles();
  const { InstLogo } = useInstLogoDetails();
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [schema, setSchema] = useState<any>();
  const [importModal, setImportModal] = useState(false);
  const [records, setRecords] = useState<any[]>([]);

  const {
    USE_CLASS_KEY,
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
  } = useInstitutionConfiguration();

  const totalRecords = records.filter(
    (record) => !record.isValidatedAndError
  ).length;
  const [message, setMessage] = useState<msgType>({
    message: EMPTY_STRING,
    flag: false,
    operation: Operation.NONE,
  });

  const { user_details } = useLoggedInUserDetails();

  const [AddMessages, { loading }] = useMutation(
    AddIndividualStdMessageWithTags,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const { data } = useChannels(ChannelQueryType.ENTRYLEVEL);

  const channelId =
    data && data.GetChannels.length ? data.GetChannels[0].id : 0;
  const {
    StudentsData: { data: studentsData, loading: stdLoading },
  } = useStudentsbyNewApi(
    0,
    0,
    0,
    0,
    0,
    EMPTY_STRING,
    null,
    StudentReportType.STUDENTS_BY_CHANNEL,
    channelId,
    []
  );

  const { InstDetails } = useInstDetails();

  const studentData = [
    {
      Headers: "EduateId",
      key: "EduateId",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "entryId",
      key: "entryId",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "channelId",
      key: "channelId",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    {
      Headers: "StudentName",
      key: "StudentName",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Mobile",
      key: "Mobile",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
    {
      Headers: "Message",
      key: "Message",
      width: 50,
      required: false,
      type: String,
      use_in_sheet: true,
    },
  ];
  const student_schema = {
    EduateId: {
      Headers: "EduateId",
      key: "EduateId",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    entryId: {
      Headers: "entryId",
      key: "entryId",
      width: 50,
      required: false,
      use_in_sheet: true,
    },
    channelId: {
      Headers: "channelId",
      key: "channelId",
      width: 50,
      required: false,
      use_in_sheet: true,
    },

    StudentName: {
      Headers: "StudentName",
      key: "StudentName",
      width: 50,
      required: true,
      use_in_sheet: true,
    },
    Mobile: {
      Headers: "Mobile",
      key: "Mobile",
      width: 50,
      required: true,
      use_in_sheet: true,
    },
    Message: {
      Headers: "Message",
      key: "Message",
      width: 50,
      required: true,
      use_in_sheet: true,
    },
  };

  const schemaKeys = Object.keys(student_schema);

  const filteredSchemaKeys = schemaKeys.filter((key) =>
    records.some((record) => {
      const value = record[key];
      const excludedKeys = ["EduateId", "entryId", "channelId"];
      return (
        !excludedKeys.includes(key) &&
        value !== undefined &&
        value !== null &&
        value !== ""
      );
    })
  );

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(ExcelPageHeader.INST_CONFIG_DATA);
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet.views = FROZEN_CELLS;
    const StudentsData =
      studentsData &&
      studentsData.GetStudents.edges.map((data) => ({
        EduateId: data.node.id,
        entryId: entryId!,
        channelId: channelId!,
        StudentName: data.node.std_name,
        Mobile: data.node.std_mobile,
      }));
    const dataRows = StudentsData
      ? StudentsData?.map((item) => [
          item.EduateId,
          item.entryId,
          item.channelId,
          item.StudentName,
          item.Mobile,
        ])
      : [];
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 35;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 23;
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet1.getColumn(2).width = 60;
    worksheet1.getColumn(3).width = 60;
    worksheet1.getColumn(4).width = 60;
    worksheet.getColumn(6).width = 20;
    worksheet1.getColumn(1).hidden = true;
    worksheet1.getColumn(2).hidden = true;
    worksheet1.getColumn(3).hidden = true;

    worksheet.protect("the123", {
      formatCells: false,
      formatColumns: true,
      formatRows: true,
      insertRows: true,
      insertColumns: false,
      deleteRows: true,
      deleteColumns: false,
      sort: true,
      autoFilter: true,
    });

    const getBase64 = (blob: Blob, cb: (a: string) => void) => {
      const file = new File([blob], FILENAME, {
        lastModified: Date.now(),
        type: blob.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });

          worksheet.addImage(imageV, "F1:F3");
          workbook.xlsx.writeBuffer().then(() => {
            fetch(InstLogo.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, 6);

                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstDetails.data?.nodes[0]?.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;

                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value =
                    InstDetails.data?.nodes[0]?.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:F2");

                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstDetails.data?.nodes[0]?.inst_place +
                    "-" +
                    InstDetails.data?.nodes[0]?.inst_pin;

                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:F3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "InstConfigdata";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:D4");
                  const mergedDate: Cell = worksheet.getCell(E4_CELL);
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FIN_YEAR_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("E4:F4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr.fin_yr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");

                  const headerRow = worksheet1.getRow(1);
                  headerRow.height = 25;
                  headerRow.font = ACC_HEADER_FONT;
                  const filteredStudentExcelData = studentData.filter(
                    (column) => column.use_in_sheet
                  );

                  let studentInfoHeader = filteredStudentExcelData
                    .filter((student) => student.use_in_sheet)
                    .map((f) => f.Headers);
                  for (let i = 0; i < filteredStudentExcelData.length; i++) {
                    const cell = headerRow.getCell(i + 1);

                    cell.value = studentInfoHeader[i];
                    cell.fill = TABLE_HEADER_CSS;
                    cell.border = BORDER_DATA;

                    cell.alignment = { horizontal: ExcelAlignment.CENTER };
                    worksheet1.columns.forEach((column) => {
                      column.width = 20;
                    });
                  }

                  dataRows!.forEach((rowData) => {
                    const row = worksheet1.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });
                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "MessageImport");
                    document.body.appendChild(link);
                    link.click();
                  });
                });
              });
          });
        });
      });
  };

  const downloadExcelContainsError = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("StudentInfo");
    worksheet1.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
    if (records.length === 0) {
      console.warn("No data available to create the Excel file.");
      return;
    }

    const errorColumns = ["errors"]; // Add any other error columns you want to include
    const headers = Object.keys(records[0]).filter(
      (header) =>
        !["isChecked", "isValidated", "isValidatedAndError", "id"].includes(
          header
        )
    );

    // Add error columns if they are not present
    errorColumns.forEach((col) => {
      if (!headers.includes(col)) {
        headers.push(col);
      }
    });
    worksheet1.columns = headers.map((header) => ({
      header,
      key: header,
      width: 20,
    }));

    const headerRow = worksheet1.getRow(1);
    headerRow.height = 25;
    headerRow.font = { name: "Arial", size: 12, bold: true };
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFE0B2" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    records
      .filter((record) => record?.errors)
      .forEach((entry, rowIndex) => {
        const row = worksheet1.getRow(rowIndex + 2);
        headers.forEach((header, colIndex) => {
          const cell = row.getCell(colIndex + 1);
          cell.value = entry[header];
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "StudentInfo.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const processExcel = (data: any) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[1];

    var records = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // eslint-disable-next-line
    records.map((record: any) => {
      let errors: any = [];
      schema &&
        // eslint-disable-next-line
        Object.keys(schema).map((key) => {
          // @ts-ignore
          let keySchema = student_schema[key];
          let keyValue = record[key];
          if (
            keySchema.required &&
            (keyValue === null || keyValue === undefined)
          ) {
            let errorObj = {
              column: key,
              error: "Required",
            };
            errors.push(errorObj);
          }

          // if (key === "ContactNumber" && !_isPhone.mask.test(keyValue)) {
          //   let errorObj = {
          //     column: key,
          //     error: "Invalid Phone",
          //   };
          //   errors.push(errorObj);
          // }

          // if (
          //   key === "DateOfBirth" &&
          //   !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          // ) {
          //   let errorObj = {
          //     column: key,
          //     error:
          //       "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
          //   };
          //   errors.push(errorObj);
          // }
          // if (
          //   key === "DateOfAdmission" &&
          //   !/^\d{2}[-/]\d{2}[-/]\d{4}$/.test(keyValue)
          // ) {
          //   let errorObj = {
          //     column: key,
          //     error:
          //       "Invalid D.O.A, the format should be DD/MM/YYYY or DD-MM-YYYY",
          //   };
          //   errors.push(errorObj);
          // }

          // if (key === "Gender" && !_genderRegex.mask.test(keyValue)) {
          //   let errorObj = {
          //     column: key,
          //     error: "Invalid Gender, the format should be male or female",
          //   };
          //   errors.push(errorObj);
          // }
        });
      if (errors.length > 0) {
        record.errors = errors;
      }
    });
    return records;
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setRecords([]);
    }
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };

  const handleAddStudent = async () => {
    setSuccessCount(0);
    setErrorCount(0);
    setImportModal(true);

    for (const record of records.filter(
      (record) => !record.isValidatedAndError
    )) {
      try {
        await AddMessages({
          variables: {
            token,
            input: {
              msg_content: record.Message,
              msg_header: EMPTY_STRING,
            },
            inst_id: instId!,
            std_ids: [record.EduateId],
            user_details,
          },
          refetchQueries: [
            {
              query: GetChannelMessages,
              variables: {
                token,
                inst_id: instId,
                input: {
                  query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                  channel_id: channelId,
                },
                direction: Direction.ASC,
                last: 5,
                after: null,
                sortBy: SortBy.CREATED_AT,
                mediaContent: EMPTY_STRING,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setSuccessCount((prev) => prev + 1);
          }
        });
      } catch (error) {
        console.error(error);
        setErrorCount((prev) => prev + 1);
      }
    }
  };

  const displayFileName = (): void => {
    const inputElement = document.getElementById("input") as HTMLInputElement;
    const fileName = inputElement.value;
    const fileLabel = document.getElementById("file-name") as HTMLSpanElement;
    fileLabel.innerHTML = fileName.split("\\").pop()!;
  };
  useEffect(() => {
    const input = document.getElementById("input") as HTMLInputElement;
    if (input) {
      input.addEventListener("change", () => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const res = processExcel(reader.result);

          setRecords(
            res.map((f: any, index: number) => {
              return {
                ...f,
                id: index + 1,
                isChecked: false,
                isValidated: false,
                isValidatedAndError: false,
              };
            })
          );
        };
        reader.readAsBinaryString(input.files![0]);
      });
    }
  });

  useEffect(() => {
    if (
      USE_CLASS_KEY ||
      USE_DEPARTMENT_KEY ||
      USE_SEMESTER_KEY ||
      USE_CATEGORY_KEY ||
      USE_BRANCH_KEY
    ) {
      setSchema(student_schema);
    } // eslint-disable-next-line
  }, [
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_CATEGORY_KEY,
    USE_BRANCH_KEY,
  ]);

  return (
    <>
      <div className="import-excel-data__marks">
        <div className="import-excel-data__marks--title">
          <Title>Import Student Messages</Title>
          <img src={Close} alt="/" onClick={() => setModalFlag(false)} />
        </div>

        <div className="row g-0 import-excel-data__blocks">
          <div className="col-6">
            <div className="import-excel-data__file-upload">
              <div className="import-excel-data__file-upload--file-name">
                <span id="file-name"></span>
              </div>
              <div className="import-excel-data__file-upload--browse">
                <label htmlFor="input">
                  <img src={FileAttach} alt="/" /> Choose Excel File
                </label>
                <input
                  id="input"
                  type="file"
                  name="file"
                  onChange={() => displayFileName()}
                  className="import-excel-data__file-upload--input"
                />
              </div>
            </div>
          </div>

          <div className="col import-excel-data__blocks--cards">
            <b>
              <img src={TotalStudents} alt="/" />
              Total Students
            </b>
            <span className="import-excel-data__blocks--cards--total-students">
              {records.length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ReadyToImport} alt="/" />
              Ready to Import
            </b>
            <span className="import-excel-data__blocks--cards--ready-to-import">
              {records.filter((record) => !record.isValidatedAndError).length}
            </span>
          </div>
          <div
            className="col import-excel-data__blocks--cards"
            onClick={() => setExpanded(!expanded)}
          >
            <b>
              <img src={ContainsError} alt="/" />
              Contains Error
            </b>
            <span className="import-excel-data__blocks--cards--contains-error">
              {records.filter((record) => record?.errors).length}
            </span>
          </div>
        </div>
        <div className="import-excel-data__datablock">
          {records.length > 0 && schema ? (
            <>
              <div
                className="import-excel-data__datablock--title"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="import-excel-data__datablock--records-count">
                  <span>
                    {
                      records.filter((record) => !record.isValidatedAndError)
                        .length
                    }
                  </span>
                  <b> Records Found to Import</b>
                </div>
                <img src={DownArrow} alt="/" />
              </div>
              <div className="import-excel-data__datablock--tableblock">
                <TableContainer className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.stickyHeaderSl}
                          id="td-center"
                        >
                          Sl
                        </TableCell>
                        {filteredSchemaKeys.map((key, index) => (
                          <TableCell
                            key={index}
                            className={classes.stickyHeaderName}
                            id="td-center"
                          >
                            {key}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {records.map((record, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell
                            className={classes.stickyColumnSl}
                            id="td-center"
                          >
                            {rowIndex + 1}
                          </TableCell>
                          {filteredSchemaKeys.map((key, colIndex) => {
                            // @ts-ignore
                            const fieldSchema = student_schema[key];
                            const value = record[key];
                            return (
                              <TableCell
                                key={colIndex}
                                className={
                                  fieldSchema?.name === "StudentName"
                                    ? classes.stickyColumnName
                                    : classes.stickyColumn
                                }
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          ) : null}
        </div>
        <div className="row g-0">
          <div className="col">
            <Button
              onClick={() => {
                setImportModal(true);
                handleAddStudent();
              }}
              disabled={!records.length ? true : false}
              mode="excel"
            >
              Import Students Messages
            </Button>

            <Button mode="cancel" onClick={() => setModalFlag(false)} />
          </div>
          <div className="col import-excel-data__button">
            <Button mode="excel" onClick={downloadExcel}>
              Generate Template XLS File
            </Button>
          </div>
        </div>
      </div>

      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={LoadingStyles}
        ariaHideApp={false}
      >
        <ExcelLoading
          total={totalRecords}
          success={successCount}
          errors={errorCount}
          loading={loading}
        />
        <div className="modal-flex__image">
          <img
            onClick={() => setImportModal(!importModal)}
            src={Close}
            alt="/"
            className="modal-close-icon"
          />
        </div>
      </ReactModal>
      <LoadingModal flag={stdLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default MessageImport;
