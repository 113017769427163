import React, { useContext, useEffect, useState } from "react";
import Send from "../../../../../images/SendGreenIcon.svg";
import ReactModal from "react-modal";
import {
  MessageModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import ForIndividual from "./ForIndividual/Index";
import Input from "../../../../../stories/Input/Input";
import useStudentsbyNewApi, {
  StudentEdges,
} from "../../../../../customhooks/useStudentByNodeApi";
import {
  ROWS_PER_PAGE,
  emptyMessageType,
  payloadTypes,
} from "../../../../../utils/constants";
import {
  ChannelQueryType,
  StudentReportType,
} from "../../../../../utils/Enum.types";
import useChannels from "../../CustomHooks/useChannels";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import axios from "axios";
import { AppContext } from "../../../../../context/context";
import Modal from "react-modal";

import Avatar from "../../../../../images/Avatar.svg";
import { getModifiedScrollHeight } from "../../../../../utils/UtilFunctions";
import { Button } from "../../../../../stories/Button/Button";
import SendMessage from "./SendMessage";
import { msgType } from "../../../../../utils/Types";
import { Checkbox } from "@mui/material";

interface membersList {
  std_id: number;
  std_adm_no: string;
  std_profile_pic: string;
  extension: string;
}

interface Props {
  individualMsgEnable: boolean;
}
const MembersList = ({ individualMsgEnable }: Props) => {
  const [forIndividualStudent, setForIndividualStudent] = useState(false);
  const { data } = useChannels(ChannelQueryType.ENTRYLEVEL);

  const [searchStudent, setSearchStudent] = useState("");
  const [selectedStudents, setSelectedStudents] = useState<
    { id: number; name: string }[]
  >([]);
  const [downLoadUrl, setDownloadUrl] = useState<membersList[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const { dispatch } = useContext(AppContext);
  const { InstFormData } = useInstDetails();
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const channelId =
    data && data.GetChannels.length ? data.GetChannels[0].id : 0;
  const getDownloadUrl = (messageId: string, mediaContent: string) => {
    const downloadBaseUrl = `https://hnlm1fiypb.execute-api.ap-south-1.amazonaws.com/downloadObjectFromESandeshBucket?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;

    try {
      axios
        .post(downloadBaseUrl, null, {
          headers: {
            "Content-Type":
              "application/x-www-form-urlencoded; charset=UTF-8;application/json",
          },
        })
        .then((response) => {
          if (response && response.data !== null) {
            axios.get(response.data, { responseType: "blob" }).then(() => {
              setDownloadUrl((prevDownloadUrl) =>
                prevDownloadUrl.map((messageData) => {
                  if (messageId === messageData.std_id.toString()) {
                    return { ...messageData, std_profile_pic: response.data };
                  } else return messageData;
                })
              );
            });
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  const {
    StudentsData: { data: studentsData, loading, fetchMore },
  } = useStudentsbyNewApi(
    0,
    0,
    0,
    0,
    0,
    searchStudent,
    ROWS_PER_PAGE,
    StudentReportType.STUDENTS_BY_CHANNEL,
    channelId,
    []
  );
  const handleCheckboxChange = (studentId: number, studentName: string) => {
    setSelectedStudents((prevSelectedStudents) => {
      const isSelected = prevSelectedStudents.some(
        (student) => student.id === studentId
      );
      if (isSelected) {
        // Unselect the student if already selected
        return prevSelectedStudents.filter(
          (student) => student.id !== studentId
        );
      } else {
        // Select the student if not already selected
        return [...prevSelectedStudents, { id: studentId, name: studentName }];
      }
    });
  };
  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudents.edges;
            const pageInfo = fetchMoreResult.GetStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudents: {
                edges: [...students, ...newEdges],
                pageInfo,
                totalCount: studentsData
                  ? studentsData.GetStudents.totalCount!
                  : 0,
              },
            };
          },
        });
      }
    }
  };
  useEffect(() => {
    if (studentsData) {
      const messageDataArray: membersList[] = studentsData.GetStudents.edges
        .filter((mess) => mess.node.std_profile_filename !== "")
        .flatMap((message) => {
          const extension =
            message.node.std_profile_filename.split(".").length > 1
              ? message.node.std_profile_filename.split(".")[
                  message.node.std_profile_filename.split(".").length - 1
                ]
              : "";
          return {
            std_id: message.node.id,
            std_adm_no: message.node.std_adm_no,
            std_profile_pic: "",
            extension,
          };
        });

      setDownloadUrl(messageDataArray);
      const fetchDownloadUrls = () => {
        messageDataArray.forEach((message) => {
          getDownloadUrl(
            message.std_id.toString(),
            `${InstFormData.inst_name}/students/${message.std_id}/std_profile_pic/std_profile_pic.${message.extension}`
          );
        });
      };
      fetchDownloadUrls();
    }

    // if (messageDataArray.some((message) => message.mediaContent !== ""))
  }, [studentsData, InstFormData.inst_name]);

  useEffect(() => {
    if (studentsData && !loading) {
      const newData = studentsData.GetStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = students.find(
            (row) => row.node.id === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(studentsData.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [studentsData, loading]);

  return (
    <>
      <div className="channel-members-list">
        <div className="channel-members-list--students">
          <Input
            id="search"
            placeholder="Search"
            onChange={(e) => setSearchStudent(e.target.value)}
          />
          <ul
            className={
              !enableCheckbox
                ? "channel-members-list--students--list"
                : "channel-members-list--students--list--ul"
            }
            onScroll={handleScroll}
          >
            {/* {studentsData
              ? students.map((data, index) => {
                  const foundPic: membersList | undefined = downLoadUrl.find(
                    ({ std_id }) => std_id === data.node.id
                  );

                  const downloadedPic: string = foundPic
                    ? foundPic.std_profile_pic
                    : Avatar;

                  return (
                    <li
                      key={index}
                      onClick={() => {
                        if (individualMsgEnable) {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: { studentId: data.node.id },
                          });
                          setForIndividualStudent(!forIndividualStudent);
                        }
                      }}
                    >
                      <div className="channel-members-list--students--list--flex">
                        <img
                          src={downloadedPic ? downloadedPic : Avatar}
                          alt=""
                        />

                        <b>{data.node.std_name}</b>
                      </div>

                      <img
                        src={Send}
                        alt=""
                        className="channel-members-list--students--list--send"
                      />
                    </li>
                  );
                })
              : null} */}
            {studentsData
              ? students.map((data, index) => {
                  const foundPic: membersList | undefined = downLoadUrl.find(
                    ({ std_id }) => std_id === data.node.id
                  );
                  const isChecked = selectedStudents.some(
                    (student) => student.id === data.node.id
                  );
                  const downloadedPic: string = foundPic
                    ? foundPic.std_profile_pic
                    : Avatar;

                  return (
                    <li
                    key={index}
                    onClick={() => {
                      if (!enableCheckbox) {
                        if (individualMsgEnable) {
                          dispatch({
                            type: payloadTypes.SET_STUDENT_ID,
                            payload: { studentId: data.node.id },
                          });
                          setForIndividualStudent(!forIndividualStudent);
                        }
                      }
                    }}
                  >
                      <div className="channel-members-list--students--list--flex">
                        {enableCheckbox ? (
                          <Checkbox
                            checked={isChecked}
                            onChange={() =>
                              handleCheckboxChange(
                                data.node.id,
                                data.node.std_name
                              )
                            }
                          />
                        ) : null}
                        <img
                          src={downloadedPic ? downloadedPic : Avatar}
                          alt=""
                        />

                        <b>{data.node.std_name}</b>
                      </div>
                      {!enableCheckbox && (
                        <img
                          src={Send}
                          alt=""
                          className="channel-members-list--students--list--send"
                 
                        />
                      )}
                    </li>
                  );
                })
              : null}
          </ul>
          {studentsData ? (
            !enableCheckbox ? (
              <Button
                mode="selected"
                onClick={() => setEnableCheckbox(!enableCheckbox)}
              />
            ) : (
              <>
                <div className="channel-members-list--students--list--button">
                  <Button
                    mode="message"
                    onClick={() => setSendMessage(!sendMessage)}
                  />
               
                  <Button
                    mode="cancel"
                    onClick={() => {
                      setSelectedStudents([]);
                      setEnableCheckbox(!enableCheckbox);
                    }}
                  />
                </div>
              </>
            )
          ) : null}
        </div>
      </div>

      <ReactModal
        isOpen={forIndividualStudent}
        style={MessageModalStyles}
        ariaHideApp={false}
      >
        <ForIndividual setModal={setForIndividualStudent} />
      </ReactModal>
      <Modal
        isOpen={sendMessage}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <SendMessage
          setModal={setSendMessage}
          Channel={ChannelQueryType.MULTIPLE}
          setMessage={setMessage}
          selectedStudents={selectedStudents!}
          setSelectedStudents={setSelectedStudents}
        />
      </Modal>
    </>
  );
};

export default MembersList;
