import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import { useMutation } from "@apollo/client";

import useToken from "../../../../../customhooks/useToken";
import { msgType } from "../../../../../utils/Types";
import { Direction, Operation, SortBy } from "../../../../../utils/Enum.types";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../../utils/constants";
import { UpdateEmpLoginStatus } from "../../../../../queries/userrights/mutations";
import useEmployee, {
  PayRollEmpEdges,
} from "../../../../../customhooks/useEmployee";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../../../utils/UtilFunctions";
import { Title } from "../../../../../stories/Title/Title";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import LoadingModal from "../../../../Modals/LoadingModal";
import MessageModal from "../../../../../utils/MessageModal";
import { AntSwitch } from "../../../../../pages/Switch";
import useMasterTableJson from "../../../Masters/json/useTableJson";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import { GetMsgEmp } from "../../../../../queries/userrights";

const EnableUserLogin = () => {
  const { token } = useToken();
  const { instId } = useParams();
  const { EnableUserLogin } = useMasterTableJson();

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [searchEmp, setSearchEmp] = useState("");
  // eslint-disable-next-line
  const [empId, setEmpId] = useState(0);
  const classes = useDataGridStyles();

  const dynamicHeaders = EnableUserLogin.Table_Headers.map((header) => ({
    headerName: header.headerName,
    className: header.cellClassName,
    field: header.field,
    headerAlign: header.headerAlign as GridAlignment,
    align: header.align as GridAlignment,
    flex: header.flex,
  }));
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "user_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <AntSwitch
            checked={params.row.is_login_created}
            onClick={() => {
              handleSubmit(params.row.empId);
              setEmpId(params.row.empId);
              // setMobileNo(params.row.node.emp_mobile);
            }}
          />
        );
      },
    },
  ];

  const [emailModal, setEmailModal] = useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const { user_details } = useLoggedInUserDetails();
  const {
    empDetails: { data, loading: EmpLoading, fetchMore },
  } = useEmployee(ROWS_PER_PAGE, searchEmp);

  const [EnableLogin, { loading: EnableLoading }] = useMutation(
    UpdateEmpLoginStatus,
    {
      onError: (e) => {
        setEmailModal(!emailModal);
      },
    }
  );

  const clear = () => {
    setSearchEmp("");
  };

  const handleSubmit = (pr_emp_id: number) => {
    const flagData =
      data &&
      data.GetMsgEmp.edges.find((data) => data.node.id === pr_emp_id)?.node;

    if (flagData === undefined) {
      alert("Employee not found");
      return;
    }
    EnableLogin({
      variables: {
        token,
        msg_emp_id: pr_emp_id,
        inst_id: instId!,
        user_details,
        login_status: !flagData?.is_login_created,
      },
      refetchQueries: [
        {
          query: GetMsgEmp,
          variables: {
            after: null,
            direction: Direction.ASC,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            sortBy: SortBy.EMP_NAME,
            inst_id: instId,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.EMP_NAME,
            },
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: !flagData?.is_login_created
            ? "User Login Enabled Successfully"
            : "User Login Disabled Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !EmpLoading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetMsgEmp.edges;
                const pageInfo = fetchMoreResult.GetMsgEmp.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetMsgEmp.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetMsgEmp: {
                    edges: [...employees, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetMsgEmp.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (data && !EmpLoading) {
      const newData = data.GetMsgEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.empId && row.empId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            emp_name: node.emp_name,
            emp_mobile: node.emp_mobile,
            emp_email: node.emp_email,
            empId: node.id,
            is_login_created: node.is_login_created,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            emp_name: node.emp_name,
            emp_mobile: node.emp_mobile,
            emp_email: node.emp_email,
            empId: node.id,
            is_login_created: node.is_login_created,
          }))
        );
      }
      setEndCursor(data.GetMsgEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, EmpLoading]);

  return (
    <>
      <Title variant="subtitle1">User Login Status</Title>
      <div className="enable-user-login">
        <form className="row g-0 enable-user-login__options">
          <div className="col-1 ">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchEmp(e.target.value)
              }
              value={searchEmp}
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col"></div>

          <div className="col-1">
            <Button
              mode="clear"
              onClick={(e: React.FormEvent) => {
                e.preventDefault();
                clear();
              }}
              className="enable-user-login__options--button"
            />
          </div>
        </form>
        <div className={`enable-user-login__tableblock ${classes.root}`}>
          <DataGridPro
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>
        <div className="flex-end">
          <div className="student-total-count">
            Total Users<b>{data && data.GetMsgEmp.totalCount}</b>
          </div>
        </div>
      </div>

      <LoadingModal flag={EmpLoading || EnableLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default EnableUserLogin;
