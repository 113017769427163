import { gql } from "@apollo/client";

export const GetServerDateAndTime = gql`
  query GetServerDateAndTime($token: String!) {
    GetServerDateAndTime(token: $token)
  }
`;

export const GetServerDateAndTimeWithoutToken = gql`
  query GetServerDateAndTimeWithoutToken($inst_id: ID!) {
    GetServerDateAndTimeWithoutToken(inst_id: $inst_id)
  }
`;

export const EnableStudentLoginStatusWithNewEmail = gql`
  mutation EnableStudentLoginStatusWithNewEmail(
    $token: String!
    $student_id: ID!
    $login_data: StdLoginData!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    EnableStudentLoginStatusWithNewEmail(
      token: $token
      student_id: $student_id
      login_data: $login_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const GetTimeZone = gql`
  query GetTimeZone(
    $token: String!
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $orderBy: MsgSwTimeZoneOrder
    $name: String
  ) {
    GetTimeZone(
      token: $token
      after: $after
      first: $first
      before: $before
      last: $last
      orderBy: $orderBy
      where: { or: [{ timezoneContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          timezone
        }
      }
    }
  }
`;
