import React, { useState } from "react";
import Navbar from "../../../../Layouts/Navbar";
import {
  EMPTY_STRING,
  emptyMessageType,
  licenseTypes,
} from "../../../../../utils/constants";
import { Title } from "../../../../../stories/Title/Title";
import InstDetails from "./common/InstDetails";
import Input from "../../../../../stories/Input/Input";
import { TextField, Tooltip } from "@mui/material";
import Compare from "../../../../../images/Compare.svg";
import ReactModal from "react-modal";
import {
  MessageModalStyles,
  StudentModalStyles,
} from "../../../../styles/ModalStyles";
import SendMessage from "./SendMessage";
import { Button } from "../../../../../stories/Button/Button";
import { useStyles } from "../../../../styles/TooltipStyles";
import MembersList from "./MembersList";
import MessageList from "./MessageList";
import Sidebar from "../../../../Layouts/Sidebar";
import { ChannelQueryType, Operation } from "../../../../../utils/Enum.types";
import useChannels from "../../CustomHooks/useChannels";
import MemberCount from "./MemberCount";
import MessageModal from "../../../../../utils/MessageModal";
import { msgType } from "../../../../../utils/Types";
import MessageImport from "./MessageImport";
// import MessageImport from "./MessageImport";
const Index = () => {
  const [date, setDate] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const tooltipClasses = useStyles();
  const [searchMessage, setSearchMessage] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [importModal, setImportModal] = useState(false);

  const [isMembersListChecked, setIsMembersListChecked] = useState(false);

  const handleMembersListChange = () => {
    setIsMembersListChecked(!isMembersListChecked);
  };
  const { data } = useChannels(ChannelQueryType.ENTRYLEVEL);

  const channelName =
    data && data.GetChannels.length
      ? data.GetChannels[0].channel_name
      : EMPTY_STRING;
  return (
    <>
      <input
        type="checkbox"
        id="members-list"
        name=""
        checked={isMembersListChecked}
        onChange={handleMembersListChange}
      />
      <Navbar navType={licenseTypes.CHANNEL} />

      <div className="chat-channel">
        <Sidebar />
        <div className="chat-channel__frame">
          <Title>Information Center</Title>
          <InstDetails />
          <div className="chat-channel__data-block">
            <div className="chat-channel__data-block--title">
              <Title variant="subtitle1"># {channelName}</Title>
              <div className="chat-channel__data-block--flex">
                <Input
                  id="search"
                  placeholder="Search..."
                  onChange={(e) => setSearchMessage(e.target.value)}
                />

                {date ? (
                  <>
                    <TextField
                      className="chat-channel__data-block--textfield"
                      label="Date"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                    />
                    <Tooltip
                      title="Filter By Start & End Date"
                      arrow
                      placement="top"
                      classes={{
                        tooltip: tooltipClasses.customTooltipGrey,
                        arrow: tooltipClasses.customArrowGrey,
                      }}
                    >
                      <img
                        src={Compare}
                        alt=""
                        className="chat-channel__data-block--compare"
                        onClick={() => setDate(!date)}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <TextField
                      className="chat-channel__data-block--textfield"
                      label="Start Date"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                      type="date"
                    />
                    <Tooltip
                      title="Filter By Date"
                      arrow
                      placement="top"
                      classes={{
                        tooltip: tooltipClasses.customTooltipGrey,
                        arrow: tooltipClasses.customArrowGrey,
                      }}
                    >
                      <img
                        src={Compare}
                        alt=""
                        className="chat-channel__data-block--compare"
                        onClick={() => setDate(!date)}
                      />
                    </Tooltip>

                    <TextField
                      className="chat-channel__data-block--textfield"
                      label="End Date"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      type="date"
                    />
                  </>
                )}
                <MemberCount isMembersListChecked={isMembersListChecked} />
              </div>
            </div>
            <div className="chat-channel__data-block--frame">
              <div
                className={`chat-channel__data-block--left ${
                  isMembersListChecked ? "shift-left" : ""
                }`}
              >
                <MessageList
                  searchMessage={searchMessage}
                  endDate={endDate}
                  startDate={startDate}
                  channel={ChannelQueryType.ENTRYLEVEL}
                />
              </div>
              <div
                className={`chat-channel__data-block--right ${
                  isMembersListChecked ? "expand-right" : ""
                }`}
              >
                <MembersList individualMsgEnable />
              </div>
            </div>
          </div>
          <Button
            onClick={() => setMessageModal(!messageModal)}
            mode="add-message"
          />
          <Button
            mode="excel"
            type="button"
            onClick={() => setImportModal(!importModal)}
          >
            Import Messages
          </Button>
        </div>
      </div>
      <ReactModal
        isOpen={messageModal}
        ariaHideApp={false}
        style={MessageModalStyles}
      >
        <SendMessage
          setModal={setMessageModal}
          Channel={ChannelQueryType.ENTRYLEVEL}
          setMessage={setMessage}
        />
      </ReactModal>
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={importModal}
        style={StudentModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <MessageImport setModalFlag={setImportModal} />
          </div>
        </div>
      </ReactModal>
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.CREATE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;
