import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Avatar from "../../../../../images/Avatar.svg";

import { useMutation } from "@apollo/client";

import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {
  listAutoCompleteStyles,
  listAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";

import { AppContext } from "../../../../../context/context";
import { msgType, responseType } from "../../../../../utils/Types";
import {
  InstitutionConfigurationTypes,
  Operation,
  PageFor,
} from "../../../../../utils/Enum.types";
import useToken from "../../../../../customhooks/useToken";

import { Button } from "../../../../../stories/Button/Button";
import LoadingModal from "../../../../Modals/LoadingModal";
import MessageModal from "../../../../../utils/MessageModal";
import useSwConfigData from "../../../../../customhooks/useSwConfighData";
import { ResetEmpPassword } from "../../../../../queries/userrights/mutations";
import { Title } from "../../../../../stories/Title/Title";
import useEmpDetailsById from "../../../../../customhooks/useEmpDetailsById";
import useEmployee from "../../../../../customhooks/useEmployee";
import { ROWS_PER_PAGE } from "../../../../../utils/constants";
import { payloadTypes } from "../../../../../context/reducer";
import { Keys } from "../../../../../utils/Enum.keys";
import DataFetch from "../../../../../images/Edit.svg";
import ReactModal from "react-modal";
import { StudentModalStyles } from "../../../../styles/ModalStyles";
import LoginUsers from "../LoginUsers/Index";

const UserResetPassword = () => {
  const listClasses = listAutoCompleteStyles();
  const listTextClasses = listAutoCompleteTextStyles();
  const { instId } = useParams();
  const { user_details } = useLoggedInUserDetails();

  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState("");
  const [searchEmp, setSearchEmp] = useState("");
  const [empListModal, setEmpListModal] = useState(false);
  const { dispatch, state } = useContext(AppContext);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { empDetails } = useEmployee(ROWS_PER_PAGE, searchEmp);

  const { token } = useToken();

  const { employeeFormData } = useEmpDetailsById(false);

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.DEFAULT_PASSWORD
  );

  const [passwordReset, { loading: updationLoading }] =
    useMutation(ResetEmpPassword);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const resetForm = async () => {
    if (state.employeeId === 0) {
      alert("Employee not found");
      return;
    }
    await passwordReset({
      variables: {
        token,
        msg_emp_id: state.employeeId,
        inst_id: instId,
        user_details,
        password: resetPassword,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Password reset Successfull",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      //   handleClear(clearType.CLEAR_ALL);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    if (configData.data && !configData.loading) {
      setResetPassword(
        configData.data?.GetSwConfigVariables[0].config_string_value
      );
    }
  }, [configData.data, configData.loading]);

  return (
    <>
      <div className="reset-password">
        <Title variant="subtitle1">Reset User Password</Title>

        <div className="row g-0 reset-password__details">
          <div className="col">
            <div className="reset-password__details--autocomplete">
              <Autocomplete
                classes={listClasses}
                options={empDetails.responseType}
                openOnFocus
                value={
                  empDetails.responseType.find(
                    (emp) => emp.value === state.employeeId
                  ) ?? null
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: (newValue as responseType).value },
                    });
                  } else {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.BACKSPACE) {
                    dispatch({
                      type: payloadTypes.SET_EMPLOYEE_ID,
                      payload: { employeeId: 0 },
                    });
                  }
                }}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee"
                    autoFocus
                    autoComplete="false"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchEmp(e.target.value)
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "false",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    classes={{ root: listTextClasses.formControlRoot }}
                  />
                )}
              />
              <img
                className="data-fetch-icon"
                src={DataFetch}
                alt="/"
                onClick={() => setEmpListModal(!empListModal)}
              />
            </div>

            <TextField
              label="Mobile"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_mobile}
              className="reset-password__details--textfield"
            />
          </div>
          <div className="col">
            <TextField
              label="Name"
              InputLabelProps={{ shrink: true }}
              disabled
              value={employeeFormData?.emp_name}
              className="reset-password__details--textfield"
            />
          </div>
          <div className="col">
            <TextField
              className="reset-password__details--textfield"
              label="Email"
              value={employeeFormData?.emp_email}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col-1 h-100 reset-password__details--image">
            <img src={Avatar} alt="/" />
          </div>
        </div>
        <div className="reset-password__form row g-0">
          <div className="col-4">
            <TextField
              className="reset-password__form--pwd"
              label="Password"
              value={resetPassword}
              onChange={(e) => setResetPassword(e.target.value)}
              InputLabelProps={{ shrink: true }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Button
          mode="save"
          onClick={resetForm}
          disabled={state.employeeId === 0}
        />
      </div>
      <LoadingModal flag={updationLoading} />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <ReactModal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={empListModal}
        style={StudentModalStyles}
      >
        <LoginUsers pageType={PageFor.MODAL} setModalFlag={setEmpListModal} />
      </ReactModal>
    </>
  );
};

export default UserResetPassword;
