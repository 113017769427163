import "./button.scss";
import Save from "../../images/Save.svg";
import Update from "../../images/Update.svg";
import Add from "../../images/Add.svg";
import DeleteButton from "../../images/DeleteButton.svg";
import View from "../../images/View.svg";
import Previous from "../../images/Previous.svg";
import Print from "../../images/Print.svg";
import Clear from "../../images/Clear.svg";
import Cancel from "../../images/CancelButton.svg";
import Active from "../../images/Active.svg";
import ReOrder from "../../images/ReOrder.svg";
import Send from "../../images/Send.svg";
import Excel from "../../images/DownloadExcel.svg";
import DownloadPdf from "../../images/DownloadPdf.svg";
import UserRights from "../../images/User_Rights.svg";
import Transfer from "../../images/TransferReceipt.svg";
import Proceed from "../../images/Proceed_to_Pay.svg";
import Yes from "../../images/Yes.svg";
import MoveTo from "../../images/Transfer.svg";
import Selected from "../../images/Collected.svg";
import Message from "../../images/Chat.svg";

import PayFee from "../../images/ProceedToPay.svg";
import Fetch from "../../images/Fetch.svg";
interface ButtonProps {
  autoFocus?: boolean;
  primary?: boolean;
  secondary?: boolean;

  backgroundColor?: string;

  mode?:
    | "default"
    | "addnew"
    | "cancel"
    | "back"
    | "edit"
    | "view"
    | "clear"
    | "print"
    | "save"
    | "delete"
    | "update"
    | "re-order"
    | "save-continue"
    | "update-continue"
    | "previous"
    | "sign-in"
    | "otp"
    | "pay-fee"
    | "finish"
    | "active"
    | "send-message"
    | "pdf"
    | "excel"
    | "download-excel"
    | "user-rights"
    | "sign-out"
    | "add-message"
    | "change-number"
    | "proceed-to-pay"
    | "okay"
    | "next"
    | "move-to"
    | "okay"
    | "fetch"
    | "close"
    | "docs-delete"
    | "send"
    | "selected"
    | "message";

  /**
   * Button contents
   */
  label?: string;
  /**
   * Optional click handler
   */
  onClick?: any;

  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  children?: any;
  onKeyDown?: any;
  className?: String;
  buttonref?: React.LegacyRef<HTMLButtonElement> | undefined;
}

export const Button = ({
  mode = "default",
  onClick,
  children,
  onKeyDown,
  autoFocus,
  type,
  disabled,
  className,
  buttonref,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={type}
      autoFocus={autoFocus}
      onClick={onClick}
      ref={buttonref!}
      className={[
        "storybook-button",
        `storybook-button--${mode}`,
        className,
      ].join(" ")}
      onKeyDown={onKeyDown}
    >
      {mode && mode === "save" ? (
        <>
          <img src={Save} alt="/" />
          Save
        </>
      ) : mode === "edit" ? (
        <>
          <img src={Update} alt="/" />
          Edit
        </>
      ) : mode === "addnew" ? (
        <>
          <img src={Add} alt="/" />
          Add
        </>
      ) : mode === "fetch" ? (
        <>
          <img src={Fetch} alt="/" />
          Fetch Details
        </>
      ) : mode === "add-message" ? (
        <>
          <img src={Add} alt="/" />
          Add Message
        </>
      ) : mode === "message" ? (
        <>
          <img src={Message} alt="/" />
          Message
        </>
      ) : mode && mode === "pdf" ? (
        <>
          <img src={DownloadPdf} alt="/" />
          Download
        </>
      ) : mode === "pay-fee" ? (
        <>
          <img src={PayFee} alt="/" />
          Pay Fee
        </>
      ) : mode === "excel" ? (
        <>
          <img src={Excel} alt="/" />
        </>
      ) : mode === "change-number" ? (
        <>
          <img src={Transfer} alt="/" />
          Change Number
        </>
      ) : mode === "download-excel" ? (
        <>
          <img src={Excel} alt="/" />
          Download Excel
        </>
      ) : mode === "clear" ? (
        <>
          <img src={Clear} alt="/" />
          Clear
        </>
      ) : mode === "okay" ? (
        <>
          <img src={Yes} alt="/" />
          Okay
        </>
      ) : mode === "re-order" ? (
        <>
          <img src={ReOrder} alt="/" />
          ReOrder
        </>
      ) : mode === "print" ? (
        <>
          <img src={Print} alt="/" />
          Print
        </>
      ) : mode && mode === "user-rights" ? (
        <>
          <img src={UserRights} alt="/" />
          Assign Rights
        </>
      ) : mode === "back" ? (
        <>
          <img src={Previous} alt="/" />
          Back
        </>
      ) : mode === "cancel" ? (
        <>
          <img src={Cancel} alt="/" />
          Cancel
        </>
      ) : mode === "delete" ? (
        <>
          <img src={DeleteButton} alt="/" />
          Delete
        </>
      ) : mode === "view" ? (
        <>
          <img src={View} alt="/" />
          View
        </>
      ) : mode === "update-continue" ? (
        <>
          <img src={Update} alt="/" />
          Update & Continue
        </>
      ) : mode === "update" ? (
        <>
          <img src={Update} alt="/" />
          Update
        </>
      ) : mode === "save-continue" ? (
        <>
          <img src={Save} alt="/" />
          Save & Continue
        </>
      ) : mode === "send-message" ? (
        <>
          <img src={Send} alt="/" />
          Send Message
        </>
      ) : mode === "selected" ? (
        <>
          <img src={Selected} alt="/" />
          Select Multiple Students
        </>
      ) : mode === "finish" ? (
        <>
          <img src={View} alt="/" /> Finish
        </>
      ) : mode && mode === "previous" ? (
        <>
          <img src={Previous} alt="/" />
          Previous
        </>
      ) : mode === "sign-in" ? (
        <>Login </>
      ) : mode === "otp" ? (
        <>
          <img src={Add} alt="/" />
          Request OTP
        </>
      ) : mode === "proceed-to-pay" ? (
        <>
          <img src={Proceed} alt="/" />
          Proceed to Pay
        </>
      ) : mode === "active" ? (
        <>
          <img src={Active} alt="/" />
        </>
      ) : mode === "sign-out" ? (
        <>Log Out</>
      ) : mode === "next" ? (
        <>
          <img src={MoveTo} alt="/" />
          Next
        </>
      ) : mode === "move-to" ? (
        <>
          <img src={MoveTo} alt="/" />
          Next
        </>
      ) : mode === "close" ? (
        <>
          <img src={Cancel} alt="/" />
          Close
        </>
      ) : null}

      {children}
    </button>
  );
};
