import React, { useState } from "react";
import useInstDetails from "../../../../customhooks/useInstDetails";
import Logo from "../../../../images/EduateLogo.svg";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import Fetch from "../../../../images/Fetch.svg";
import { useNavigate, useParams } from "react-router-dom";
import useInstLogoDetails from "../../../../customhooks/useInstLogoDetails";
import { useLazyQuery } from "@apollo/client";
import { GetEncodedValue } from "../../../../queries/payments/query";
import useToken from "../../../../customhooks/useToken";
import useMstInstLogoWithoutToken from "../../../../customhooks/useMstInstLogoWithoutToken";
import useInstDetailsWithoutToken from "../../../../customhooks/useInstDetailsWithoutToken";

export interface GetEncodedValueData {
  GetEncodedValue: string;
}
export interface GetEncodedValueVars {
  inst_id: string;
  str_to_encode: string;
}

const FetchDetails = () => {
  const { instId } = useParams();
  const { InstFormData } = useInstDetailsWithoutToken();
  const { InstLogo } = useMstInstLogoWithoutToken();

  const [encodeValue, setEncodeValue] = useState("");
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setEncodeValue("");
    setValue(newValue);
  };

  const [GetEncode] = useLazyQuery<GetEncodedValueData, GetEncodedValueVars>(
    GetEncodedValue
  );
  return (
    <div className="fee-fetch-details">
      <div className="fee-fetch-details__block">
        <div className="fee-fetch-details__block--inst-details">
          {InstLogo.defaultLogo ? (
            <img src={InstLogo.defaultLogo} alt="/" />
          ) : null}{" "}
          <span>{InstFormData.cust_name}</span>
          <b>{InstFormData.inst_name}</b>
          <span>{`${InstFormData.inst_address} ${InstFormData.inst_place} ${InstFormData.inst_state} ${InstFormData.inst_pin}`}</span>
        </div>
        <div className="fee-fetch-details__block--frame">
          <Title variant="subtitle1">Fetch Data Through</Title>
          <div className="fee-fetch-details__block--frame--tabs">
            <Tabs value={value} onChange={handleTabChange}>
              <Tab label="Mobile No" value={0} {...a11yProps(0)} />

              <Tab label="Student Id" value={1} {...a11yProps(1)} />
              <Tab label="Admission No." value={2} {...a11yProps(2)} />
            </Tabs>
          </div>
          <div className="fee-fetch-details__block--frame--tab-panel">
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Label>Mobile</Label>
              <Input
                onChange={(e) => {
                  if (
                    Number(e.target.value) >= 0 &&
                    e.target.value.length <= 10
                  ) {
                    setEncodeValue(e.target.value);
                  }
                }}
                type="number"
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Label>Student Id</Label>
              <Input
                onChange={(e) => {
                  setEncodeValue(e.target.value);
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Label>Roll No.</Label>
              <Input
                onChange={(e) => {
                  setEncodeValue(e.target.value);
                }}
              />
            </TabPanel>
          </div>
          <br />
          <a
            onClick={() => {
              GetEncode({
                variables: {
                  inst_id: instId!,
                  str_to_encode: encodeValue,
                },
              }).then(({ data }) => {
                if (data && data.GetEncodedValue.length) {
                  switch (value) {
                    case 0:
                      navigate(
                        `/${instId}/chat/fee/fetchdetails/${data.GetEncodedValue}/stdlist/mobile`
                      );
                      break;
                    case 1:
                      navigate(
                        `/${instId}/chat/fee/fetchdetails/${data.GetEncodedValue}/stdlist/studentid`
                      );
                      break;
                    case 2:
                      navigate(
                        `/${instId}/chat/fee/fetchdetails/${data.GetEncodedValue}/stdlist/rollno`
                      );
                      break;
                    default:
                      break;
                  }
                }
              });
            }}
            target="blank"
            className="fee-fetch-details__block--fetch-details"
          >
            <img src={Fetch} alt="" />
            Fetch Details
          </a>
        </div>
        <div className="fee-fetch-details__block--footer">
          <div className="fee-fetch-details__block--footer--data">
            <span className="fee-fetch-details__block--footer--text">
              Powered by
            </span>
            <img src={Logo} alt="" />
            <b>My-Eduate</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FetchDetails;
