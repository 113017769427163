import React, { useEffect, useState } from "react";
import TotalFee from "../../../../images/DemandCollected.svg";
import TotalPaid from "../../../../images/TotalPaid.svg";
import FeeBalance from "../../../../images/Fee_Balance.svg";
import { Button } from "../../../../stories/Button/Button";

import { useNavigate, useParams } from "react-router-dom";
import Arrow from "../../../../images/ArrowRight.svg";
import useStdDetailsWithoutTokenByDemanId from "../../../../customhooks/useStdDetailsWithoutTokenByDemanId";
import useServerDateandTimeWithoutToken from "../../../../customhooks/useServerDateandTime";
import { EMPTY_STRING, emptyMessageType } from "../../../../utils/constants";
import { Operation, Result } from "../../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import {
  AcctMakeIciciOnlinePaymentData,
  AcctMakeIciciOnlinePaymentVars,
} from "../../Chat/FeeDetails/Payment";
import { AcctMakeIciciOnlinePayment } from "../../../../queries/payments/mutations/new";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import Payment from "../../../../images/Payment_Process.svg";
import Loading from "../../../../images/Loading.gif";
import MessageModal from "../../../../utils/MessageModal";
interface Props {
  PaymentResult: Result;
}

const PayFee = ({ PaymentResult }: Props) => {
  const navigate = useNavigate();
  const { instId, stdDemandId } = useParams();

  const { studentFormData } = useStdDetailsWithoutTokenByDemanId();
  const [amount, setAmount] = useState("");
  const { serverDate } = useServerDateandTimeWithoutToken();
  const [message, setMessage] = useState(emptyMessageType);
  const [proceeding, setProceeding] = useState(false);

  const [MakePayment] = useMutation<
    AcctMakeIciciOnlinePaymentData,
    AcctMakeIciciOnlinePaymentVars
  >(AcctMakeIciciOnlinePayment);
  useEffect(() => {
    if (studentFormData.fee_balance) {
      setAmount(studentFormData.fee_balance.toString());
    }
  }, [studentFormData]);

  const handlePayment = () => {
    if (serverDate === EMPTY_STRING) {
      setMessage({
        flag: true,
        message: "Date does not exist",
        operation: Operation.NONE,
      });
      return;
    }
    if (serverDate)
      MakePayment({
        variables: {
          acct_std_demand_id: stdDemandId!,
          fee_paid: amount,
          inst_id: instId!,
          payment_date: toIsoDate(serverDate),
          payment_mode: "9",
        },
      }).then(({ data }) => {
        if (data && data.AcctMakeIciciOnlinePayment) {
          const {
            encrypted_mandatory_fields,
            encrypted_payment_mode,
            encrypted_reference_no,
            encrypted_return_url,
            encrypted_sub_merchant_id,
            encrypted_transaction_amount,
          } = data.AcctMakeIciciOnlinePayment;

          if (
            encrypted_mandatory_fields &&
            encrypted_payment_mode &&
            encrypted_reference_no &&
            encrypted_return_url &&
            encrypted_sub_merchant_id &&
            encrypted_transaction_amount
          ) {
            setProceeding(!proceeding);
            window.location.href = `https://eazypayuat.icicibank.com/EazyPG?merchantid=600343&mandatory%20fields=${encrypted_mandatory_fields}&optional%20fields=&returnurl=${encrypted_return_url}&Reference%20No=${encrypted_reference_no}&submerchantid=${encrypted_sub_merchant_id}&transaction%20amount=${encrypted_transaction_amount}&paymode=${encrypted_payment_mode}`;
          }
        }
      });
  };

  return (
    <div className="mobile-login__pay-fee">
      <div className="mobile-login__pay-fee--back">
        <a
          href={
            PaymentResult === Result.SUCCESS
              ? `/${instId}/mobileapp/fee/fetchdetails/paymentprocess`
              : PaymentResult === Result.FAILURE
              ? `/${instId}/mobileapp/fee/fetchdetails/paymentprocess`
              : `/${instId}/mobileapp/login`
          }
        >
          <img src={Arrow} alt="" /> Go Back
        </a>
      </div>
      <div className="mobile-login__pay-fee--frame">
        <h4 className="mobile-login__pay-fee--title">Pay Fee</h4>
        <div className="mobile-login__pay-fee--cards">
          <div className="mobile-login__pay-fee--card">
            <img src={TotalFee} alt="" />
            <span>Total Fee</span>
            <b className="font-purple">{studentFormData.fee_total_demand}</b>
          </div>
          <div className="mobile-login__pay-fee--card">
            <img src={TotalPaid} alt="" />
            <span>Total Paid</span>
            <b className="font-green">{studentFormData.fee_total_paid}</b>
          </div>
          <div className="mobile-login__pay-fee--card">
            <img src={FeeBalance} alt="" />
            <span>Fee Balance</span>
            <b className="font-red">{studentFormData.fee_balance}</b>
          </div>
        </div>
        <div className="mobile-login__pay-fee--block">
          {!proceeding ? (
            <>
              <Label>Enter Amount to Pay</Label>
              <Input
                value={amount}
                onChange={(e) => {
                  if (Number(e.target.value) >= 0) {
                    setAmount(e.target.value);
                  }
                }}
              />
            </>
          ) : (
            <>
              <div className="std-fee-details__std-details--proceed">
                <h4 className="mobile-login__proceed--title">
                  Proceeding for Payment
                </h4>
                <Label>Total Amount Paying is</Label>
                <b>{amount}</b>
                <img src={Payment} alt="" />
                <br />
                <img
                  src={Loading}
                  alt="loading"
                  className="mobile-login__proceed--gif"
                />{" "}
              </div>
            </>
          )}
        </div>
        <div className="mobile-login__pay-fee--buttons">
          <Button mode="proceed-to-pay" onClick={handlePayment} />
          <Button
            mode="cancel"
            onClick={() => {
              navigate(
                PaymentResult === Result.SUCCESS ||
                  PaymentResult === Result.FAILURE
                  ? `/${instId}/mobileapp/fee/fetchdetails/paymentprocess`
                  : `/${instId}/mobileapp/fee/fetchdetails`
              );
            }}
          />
        </div>
      </div>
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </div>
  );
};

export default PayFee;
