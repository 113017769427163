import React from "react";
import { BasicDetailsProps } from "./BasicDetails";
import { Button } from "../../../../../stories/Button/Button";
import EduateGlobalConfig from "../../Eduate/EduateGlobalConfig";
import { Operation, PageFor } from "../../../../../utils/Enum.types";

const Configuration = ({ setModal }: BasicDetailsProps) => {
  return (
    <>
      <div className="inst-details__config">
        <EduateGlobalConfig
          pageType={PageFor.MODAL}
          setModal={setModal}
          operation={Operation.CREATE}
        />
        <Button mode="finish" onClick={() => setModal(false)} />
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default Configuration;
