import { gql } from "@apollo/client";

export const AddMsgInstTemplate = gql`
  mutation AddMsgInstTemplate(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: [CreateMsgMstInstTemplatesInput!]!
  ) {
    AddMsgInstTemplate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;

export const DeleteMsgInstTemplate = gql`
  mutation DeleteMsgInstTemplate(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteMsgInstTemplate(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateMsgInstTemplate = gql`
  mutation UpdateMsgInstTemplate(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMsgMstInstTemplatesInput!
  ) {
    UpdateMsgInstTemplate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      id: $id
      input: $input
    )
  }
`;

export const AddSwMsgTag = gql`
  mutation AddSwMsgTag(
    $token: String!
    $user_details: SubscribedUser!
    $input: CreateMsgSwMsgTagsInput!
  ) {
    AddSwMsgTag(token: $token, user_details: $user_details, input: $input)
  }
`;

export const DeleteSwMsgTag = gql`
  mutation DeleteSwMsgTag(
    $token: String!
    $user_details: SubscribedUser!
    $id: ID!
  ) {
    DeleteSwMsgTag(token: $token, id: $id, user_details: $user_details)
  }
`;

export const UpdateSwMsgTag = gql`
  mutation UpdateSwMsgTag(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMsgSwMsgTagsInput!
  ) {
    UpdateSwMsgTag(
      token: $token
      id: $id
      user_details: $user_details
      input: $input
    )
  }
`;
