import React from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import EduateLogo from "../../../../../images/EduateLogo.svg";
import { TextField } from "@mui/material";
import Modules from "../../../../../images/Modules.svg";
import AcademicYear from "../../../../../images/AcademicYear.svg";
import ActiveLicense from "../../../../../images/ActiveLicense.svg";
import LicenseStatus from "../../../../../images/LicenceStatus.svg";
import Compare from "../../../../../images/Compare.svg";
import { Button } from "../../../../../stories/Button/Button";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ setModal }: Props) => {
  const InstitutionDetails = [
    {
      label: "Name",
      value: "AIT",
    },
    {
      label: "Short Name",
      value: "AIT",
    },
    {
      label: "Contact Person",
      value: "Arun",
    },
    {
      label: "Phone",
      value: 89632514789,
    },
    {
      label: "Mobile",
      value: 89632514789,
    },
    {
      label: "Email",
      value: "abc@g.com",
    },
    {
      label: "Address",
      value: "Bangalore",
    },
    {
      label: "Place",
      value: "Bangalore",
    },
    {
      label: "PinCode",
      value: "560043",
    },
    {
      label: "State",
      value: "Karnataka",
    },
  ];
  return (
    <>
      <div className="inst-preview">
        <div className="inst-preview__title">
          <Title>Institution Details</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <div className="inst-preview__frame">
          <div className="inst-preview__frame--basic">
            <Title variant="subtitle1">Institution Basic Details</Title>
            <div className="inst-preview__frame--basic--logo">
              <img src={EduateLogo} alt="" />
            </div>
            <div className="inst-preview__frame--basic--data">
              {InstitutionDetails.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <TextField
                      className="inst-preview__frame--basic--data--textfield"
                      label={data.label}
                      value={data.value}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="inst-preview__frame--license">
            <div className="inst-preview__frame--license--active-acd-yr">
              <div className="inst-preview__frame--license--active-acd-yr--title">
                <Title variant="subtitle1">Academic Year</Title>
              </div>
              <img
                src={AcademicYear}
                alt=""
                className="inst-preview__frame--license--image"
              />
              <div className="inst-preview__frame--license--active-acd-yr--footer">
                2024-12-12
              </div>
            </div>
            <div className="inst-preview__frame--license--status">
              <Title variant="subtitle1">License Status</Title>
              <img
                src={LicenseStatus}
                alt=""
                className="inst-preview__frame--license--image"
              />
              <div className="inst-preview__frame--license--status--textfields">
                <TextField
                  className="inst-preview__frame--license--status--start"
                  disabled
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  value="2023-12-12"
                />
                <img
                  src={Compare}
                  alt=""
                  className="inst-preview__frame--license--status--compare"
                />
                <TextField
                  className="inst-preview__frame--license--status--end"
                  disabled
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  value="2023-12-12"
                />
              </div>

              <div className="inst-preview__frame--license--status--days">
                <b>85</b> Days Left to Expire
              </div>
              <div className="inst-preview__frame--license--status--active">
                <img src={ActiveLicense} alt="" />
                License Active
              </div>
            </div>
          </div>
          <div className="inst-preview__frame--modules">
            <Title variant="subtitle1">Availabel Modules</Title>
            <img src={Modules} alt="" />
            <ul className="inst-preview__frame--modules--list">
              <li>1 &nbsp;Academics</li>
            </ul>
          </div>
        </div>
        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>
    </>
  );
};

export default Index;
