import { gql } from "@apollo/client";
export const GetAcdYrActiveByInstId = gql`
  query GetAcdYrActiveByInstId($token: String!, $inst_id: ID!) {
    GetAcdYrActiveByInstId(token: $token, inst_id: $inst_id) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      acd_is_curr_yr
    }
  }
`;
