import { gql } from "@apollo/client";

export const InstModuleById = gql`
  query InstModuleById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstModuleMaster {
        id
        lic_type
        lic_active
        lic_exp_grace_period
        lic_enabled_date
        lic_start_date
        lic_end_date
        inst_module_details {
          id
          is_module_enabled
          eduate_module_details {
            Name
          }
        }
      }
    }
  }
`;
