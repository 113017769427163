import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { GetServerDateAndTimeWithoutToken } from "../queries/byTime";
import { useParams } from "react-router-dom";
interface ServerDateTimeData {
  GetServerDateAndTimeWithoutToken: string;
}
interface ServerDateTimeVars {
  inst_id: string;
}

const useServerDateandTime = () => {
  const { instId } = useParams();
  const [serverDate, setServerDate] = useState("");
  const [GetDate, { data, loading }] = useLazyQuery<
    ServerDateTimeData,
    ServerDateTimeVars
  >(GetServerDateAndTimeWithoutToken, {
    variables: {
      inst_id: instId!,
    },
  });
  useEffect(() => {
    if (instId) GetDate();
  }, [instId, GetDate]);
  useEffect(() => {
    if (data && !loading) {
      setServerDate(data.GetServerDateAndTimeWithoutToken);
    }
  }, [data, loading]);
  return {
    serverDate,
    data,
    loading,
  };
};
export default useServerDateandTime;
