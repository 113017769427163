import { gql } from "@apollo/client";

export const AcdYrDetailsByNode = gql`
  query AcdYrDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstAcdYr {
        acd_yr
        acd_st_date
        acd_end_date
        inst_id
        id
      }
    }
  }
`;
