import { gql } from "@apollo/client";

export const GetStudentDetailsByAuthId = gql`
  query GetStudentDetailsByAuthId($token: String!, $auth_id: ID!) {
    GetStudentDetailsByAuthId(token: $token, auth_id: $auth_id) {
      id
      std_name
      inst_id
    }
  }
`;
export const StudentPassoutById = gql`
  query StudentPassoutById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MstStudentPassout {
        id
        std_name
        std_passout_yr
        std_mobile
        std_email
        inst_id
        branch_id
        class_id
        branch_details {
          branch_desc
        }
        class_details {
          class_desc
        }
      }
    }
  }
`;

export const GetStudentDetails = gql`
  query GetStudentDetails($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MsgMstStudent {
        id
        std_name
        parent_name
        std_status
        std_sex
        std_adm_no
        std_dob
        std_email
        std_mobile
        is_login_created
        std_profile_filename
        inst_id
        dept_id
        branch_id
        class_id
        semester_id
        section_id
        acd_yr_id
        class {
          class_desc
          id
        }

        branch {
          branch_desc
          id
        }
        semester {
          sem_desc
          id
        }
        section {
          id
          semester_id
          section_desc
        }

        dept {
          dept_desc
          id
        }
        category {
          cat_desc
          id
        }
        acd_yr {
          acd_yr
          id
        }
        fin_yr {
          fin_yr
          id
        }
      }
    }
  }
`;
