import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { AppContext } from "../context/context";
import useToken from "./useToken";
import { InstDetailsByNodeId } from "../queries/institution/queries/byId";
import { InstitutionNode, NodeVarsMultiple } from "../utils/Query.Types";
import { EMPTY_STRING } from "../utils/constants";
interface InstFormData {
  inst_name: string;
  inst_short_name: string;
  inst_address: string;
  inst_pin: string;
  inst_place: string;
  inst_phone: string;
  inst_state: string;
  inst_email: string;
  inst_mobile: string;
  cust_name: string;
}
interface InstNodeData {
  nodes: InstitutionNode[];
}

const useInstDetails = () => {
  const { instId } = useParams();
  const { state } = useContext(AppContext);

  const { token } = useToken();

  const [InstFormData, setInstFormData] = useState<InstFormData>({
    inst_name: "",
    inst_short_name: "",
    inst_address: "",
    inst_pin: "",
    inst_place: "",
    inst_phone: "",
    inst_state: "",
    inst_email: "",
    inst_mobile: "",
    cust_name: "",
  });

  const [GetInstDetails, { data, loading, error }] = useLazyQuery<
    InstNodeData,
    NodeVarsMultiple
  >(InstDetailsByNodeId, {
    variables: {
      ids: [instId ? Number(instId) : Number(state.InstId)],
      token,
    },
  });
  useEffect(() => {
    if (token && (instId || state.InstId)) {
      GetInstDetails().then(({ data }) => {
        if (data && data.nodes.length) {
          const { nodes } = data;
          setInstFormData({
            inst_name: nodes[0].inst_name,
            inst_short_name: nodes[0].inst_short_name,
            inst_address: nodes[0].inst_address,
            inst_pin: nodes[0].inst_pin,
            inst_place: nodes[0].inst_place,
            inst_phone: nodes[0].inst_phone,
            inst_state: nodes[0].inst_state,
            inst_email: nodes[0].inst_email,
            inst_mobile: nodes[0].inst_mobile,
            cust_name: EMPTY_STRING,
          });
        }
      });
    }
  }, [token, instId, state.InstId, GetInstDetails]);

  return {
    InstDetails: { data, error, loading },
    InstFormData,
  };
};

export default useInstDetails;
