import { gql } from "@apollo/client";

export const GetSwConfigVariables = gql`
  query GetSwConfigVariables(
    $token: String!
    $inst_id: ID!
    $input: ConfigQuery!
  ) {
    GetSwConfigVariables(token: $token, inst_id: $inst_id, input: $input) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
      config_secondary_list_caption
    }
  }
`;

export const GetNotAllocatedIds = gql`
  query GetNotAllocatedIds(
    $token: String!
    $inst_id: ID!
    $table: TableType!
    $table_id: ID!
  ) {
    GetNotAllocatedIds(
      token: $token
      inst_id: $inst_id
      table: $table
      table_id: $table_id
    ) {
      dept_id
      branch_id
      class_id
      semester_id
      section_id
    }
  }
`;
