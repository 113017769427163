import { gql } from "@apollo/client";

export const AddInstDept = gql`
  mutation AddInstDept(
    $input: CreateMsgMstInstDeptInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstDept(token: $token, input: $input, user_details: $user_details)
  }
`;
