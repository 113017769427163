import React, { useState } from "react";
// import useMstInstTree from "../../../customhooks/useMstInstTree";
import DepartmentImage from "../../../../images/department.svg";
import BranchImage from "../../../../images/branch.svg";
import ClassImage from "../../../../images/class.svg";
import SemesterImage from "../../../../images/Semester.svg";
import SectionImage from "../../../../images/Section.svg";
import Collapse from "../../../../images/Collapse.svg";
import UnCollapse from "../../../../images/UnCollapse.svg";
import { Title } from "../../../../stories/Title/Title";
import useMstInstTree from "../../../../customhooks/useMstInstTree";
import useInstLabels from "../../../../customhooks/useInstLabels";

const TreeView = () => {
  const { data } = useMstInstTree(0, 0, 0, 0, 0);

  const { branchLabel, classLabel } = useInstLabels();
  const [collapseFlag1, setCollapseFlag1] = useState<number[]>([]);
  const [collapseFlag2, setCollapseFlag2] = useState<number[]>([]);
  const [collapseFlag3, setCollapseFlag3] = useState<number[]>([]);
  const [collapseFlag4, setCollapseFlag4] = useState<number[]>([]);

  const handleCollapse = (level: number, index: number) => {
    if (level === 1) {
      if (collapseFlag1.includes(index)) {
        setCollapseFlag1((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag1((prevState) => [...prevState, index]);
      }
    } else if (level === 2) {
      if (collapseFlag2.includes(index)) {
        setCollapseFlag2((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag2((prevState) => [...prevState, index]);
      }
    } else if (level === 3) {
      if (collapseFlag3.includes(index)) {
        setCollapseFlag3((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag3((prevState) => [...prevState, index]);
      }
    } else if (level === 4) {
      if (collapseFlag4.includes(index)) {
        setCollapseFlag4((prevState) =>
          prevState.filter((item) => item !== index)
        );
      } else {
        setCollapseFlag4((prevState) => [...prevState, index]);
      }
    }
  };

  return (
    <>
      <Title variant="subtitle1">
        {branchLabel},{classLabel}
        Overview
      </Title>
      <div className="masterconfig__tree-view">
        <ol>
          {data
            ? data.GetMstInstTree.map((level1) => {
                return (
                  <React.Fragment key={level1.level_1_id}>
                    <li
                      className="masterconfig__tree-view--li"
                      onClick={() => {
                        if (level1.level_2_details !== null)
                          handleCollapse(1, level1.level_1_id);
                      }}
                    >
                      {collapseFlag1.includes(level1.level_1_id) ? (
                        <img
                          src={Collapse}
                          alt="/"
                          className="masterconfig__tree-view--icons"
                        />
                      ) : (
                        <img
                          src={UnCollapse}
                          alt="/"
                          className="masterconfig__tree-view--icons"
                        />
                      )}

                      <img src={DepartmentImage} alt="" />
                      {level1.level_1_desc}
                    </li>
                    {collapseFlag1.includes(level1.level_1_id) && (
                      <ol className="masterconfig__tree-view--ol">
                        {level1.level_2_details.map((level2) => {
                          return (
                            <React.Fragment key={level2.level_2_id}>
                              <li
                                className="masterconfig__tree-view--li"
                                onClick={() => {
                                  if (level2.level_3_details !== null)
                                    handleCollapse(2, level2.level_2_id);
                                }}
                              >
                                {collapseFlag2.includes(level2.level_2_id) ? (
                                  <img
                                    src={Collapse}
                                    alt="/"
                                    className="masterconfig__tree-view--icons"
                                  />
                                ) : (
                                  <img
                                    src={UnCollapse}
                                    alt="/"
                                    className="masterconfig__tree-view--icons"
                                  />
                                )}
                                <img src={BranchImage} alt="" />
                                {level2.level_2_desc}
                              </li>
                              {collapseFlag2.includes(level2.level_2_id) && (
                                <ol className="masterconfig__tree-view--ol">
                                  {level2.level_3_details.map((level3) => {
                                    return (
                                      <React.Fragment key={level3.level_3_id}>
                                        <li
                                          className="masterconfig__tree-view--li"
                                          onClick={() => {
                                            if (level3.level_4_details !== null)
                                              handleCollapse(
                                                3,
                                                level3.level_3_id
                                              );
                                          }}
                                        >
                                          {collapseFlag3.includes(
                                            level3.level_3_id
                                          ) ? (
                                            <img
                                              src={Collapse}
                                              alt="/"
                                              className="masterconfig__tree-view--icons"
                                            />
                                          ) : (
                                            <img
                                              src={UnCollapse}
                                              alt="/"
                                              className="masterconfig__tree-view--icons"
                                            />
                                          )}
                                          <img src={ClassImage} alt="" />
                                          {level3.level_3_desc}
                                        </li>
                                        {collapseFlag3.includes(
                                          level3.level_3_id
                                        ) && (
                                          <ol className="masterconfig__tree-view--ol">
                                            {level3.level_4_details.map(
                                              (level4) => {
                                                return (
                                                  <React.Fragment
                                                    key={level4.level_4_id}
                                                  >
                                                    <li
                                                      className="masterconfig__tree-view--li"
                                                      onClick={() => {
                                                        if (
                                                          level4.level_5_details !==
                                                          null
                                                        )
                                                          handleCollapse(
                                                            4,
                                                            level4.level_4_id
                                                          );
                                                      }}
                                                    >
                                                      {collapseFlag4.includes(
                                                        level4.level_4_id
                                                      ) ? (
                                                        <img
                                                          src={Collapse}
                                                          alt="/"
                                                          className="masterconfig__tree-view--icons"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={UnCollapse}
                                                          alt="/"
                                                          className="masterconfig__tree-view--icons"
                                                        />
                                                      )}
                                                      <img
                                                        src={SemesterImage}
                                                        alt=""
                                                      />
                                                      {level4.level_4_desc}
                                                    </li>
                                                    {collapseFlag4.includes(
                                                      level4.level_4_id
                                                    ) && (
                                                      <ol className="masterconfig__tree-view--ol">
                                                        {level4.level_5_details.map(
                                                          (level5) => {
                                                            return (
                                                              <React.Fragment
                                                                key={
                                                                  level5.level_5_id
                                                                }
                                                              >
                                                                <li className="masterconfig__tree-view--li">
                                                                  <img
                                                                    src={
                                                                      SectionImage
                                                                    }
                                                                    alt=""
                                                                  />
                                                                  {
                                                                    level5.level_5_desc
                                                                  }
                                                                </li>
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        )}
                                                      </ol>
                                                    )}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </ol>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </ol>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </ol>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </ol>
      </div>
    </>
  );
};

export default TreeView;
