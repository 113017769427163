import React, { useState } from "react";
import { Title } from "../../../../../../stories/Title/Title";
import Close from "../../../../../../images/Close.svg";
import { Button } from "../../../../../../stories/Button/Button";
import ReactModal from "react-modal";
import { MessageModalStyles } from "../../../../../styles/ModalStyles";
import StudentDetails from "../common/StudentDetails";
import SendMessage from "../SendMessage";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Operation,
} from "../../../../../../utils/Enum.types";
import MessageList from "../MessageList";
import {
  EMPTY_STRING,
  emptyMessageType,
} from "../../../../../../utils/constants";
import MessageModal from "../../../../../../utils/MessageModal";
import { msgType } from "../../../../../../utils/Types";
import useChannelConfigs from "../../../CustomHooks/useChannelConfigs";
import Send from "../../../../../../images/Send.svg";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ForIndividual = ({ setModal }: Props) => {
  const [sendMessage, setSendMessage] = useState(false);

  const { enableIndividualChannels } = useChannelConfigs();

  const [message, setMessage] = useState<msgType>(emptyMessageType);

  // const messageListRef = useRef<HTMLUListElement>(null);

  return (
    <>
      <div className="message-for-individual">
        <div className="message-for-individual__title">
          <Title>Individual Message</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
        <StudentDetails />
        {enableIndividualChannels ? (
          <>
            <div className="message-for-individual__message-list">
              <MessageList
                endDate={DEFAULT_TIME}
                startDate={DEFAULT_TIME}
                searchMessage={EMPTY_STRING}
                channel={ChannelQueryType.INDIVIDUAL}
              />
            </div>
            <Button onClick={() => setSendMessage(!sendMessage)}>
              <img src={Send} alt="/" />
              Compose Message
            </Button>
          </>
        ) : null}

        <Button mode="cancel" onClick={() => setModal(false)} />
      </div>

      <ReactModal
        isOpen={sendMessage}
        ariaHideApp={false}
        style={MessageModalStyles}
      >
        <SendMessage
          setModal={setSendMessage}
          Channel={ChannelQueryType.INDIVIDUAL}
          setMessage={setMessage}
        />
      </ReactModal>

      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.CREATE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default ForIndividual;
