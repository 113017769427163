import React from "react";
import Navbar from "../../../Layouts/Navbar";
import { licenseTypes } from "../../../../utils/constants";

import { Title } from "../../../../stories/Title/Title";
import Sidebar from "../../../Layouts/Sidebar";
import MasterDetailsDropDown from "../../Masters/Components/MasterDropDown/MasterDropDown";
import { AppProvider } from "../../Masters/Components/MasterDropDown/context";
import TreeView from "./TreeView";
export interface TreeType {
  level1_name: string;
  level1_rowspan: number;
  level1_count: number;

  level2_name: string;
  level2_rowspan: number;
  level2_count: number;

  level3_name: string;
  level3_span: number;
  level3_count: number;

  level4_name: string;
  level4_span: number;
  level4_count: number;

  level5_name: string;
  level5_count: number;

  level1_id: number;
  level2_id: number;
  level3_id: number;
  level4_id: number;
  level5_id: number;
  filterThis: boolean;
}
const Index = () => {
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="chat-home">
        <Sidebar />

        <div className="chat-home__frame">
          <Title>Information Center</Title>
          <div className="chat-home__frame--data">
            <AppProvider>
              <MasterDetailsDropDown>
                <TreeView />
              </MasterDetailsDropDown>
            </AppProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
