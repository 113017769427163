import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GetChannels } from "../../../../queries/chat/queries";
import { GetChannelsData, GetChannelsVars } from "../Types";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { ChannelQueryType } from "../../../../utils/Enum.types";
import { AppContext } from "../../../../context/context";
import useStudentDatabyId from "../../../../customhooks/useStudentDataById";

const useChannels = (channel_type: ChannelQueryType) => {
  const { token } = useToken();

  const { entryId, instId } = useParams();
  const { state } = useContext(AppContext);

  const [entry_id, setEntryId] = useState("0");
  const { entry_level } = useInstitutionConfiguration();
  const { studentEntryId } = useStudentDatabyId();
  const [GetChannel, { data, loading, error }] = useLazyQuery<
    GetChannelsData,
    GetChannelsVars
  >(GetChannels, {
    variables: {
      channel_type,
      entry_id,
      entry_level,
      inst_id: instId!,
      token,
    },
  });
  useEffect(() => {
    if (state.claims && state.claims.STUDENT) {
      setEntryId(studentEntryId.toString());
    } else if (entryId) {
      setEntryId(entryId);
    }
  }, [state.claims, entryId, studentEntryId]);
  useEffect(() => {
    if (token && entry_level) {
      GetChannel();
    }
  }, [token, entry_level, GetChannel, instId, entry_id, channel_type]);
  return { data, loading, error };
};

export default useChannels;
