import { gql } from "@apollo/client";

export const SemesterByNode = gql`
  query SemesterDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstSemester {
        id
        inst_id
        sem_desc
        sem_short_desc
      }
    }
  }
`;
