import React from "react";
import "./input.scss";
// import { Requiredfield } from "./Input.stories";

export type Props = {
  // If true, will pass native `event` to the `onChange` callback
  rawOnChange?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  // CSS class name that will be added to the component.
  className?: string;
  name?: string;
  // Auto-focus input
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  // Component label.
  label?: string | any;
  id?: string;

  // Is input disabled?
  disabled?: boolean;

  // Description beneath the input.

  // Placeholder is used with `fullwidth` prop instead of a `label`. `label` and `placeholder` are always mutually exclusive.
  placeholder?: string;

  // Type of input ()
  type?: string;
  // size of input field
  size?: string;
  // A ref for the native input.
  inputRef?: React.Ref<any>;
  required?: boolean;
  // A callback that is executed when input focus is lost.
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement>) => void;

  // A callback that is executed when key is pressed / held.
  onKeyDown?: (e: React.KeyboardEvent) => any;

  // // A callback that is executed when key is pressed / held.
  // onKeyPress?: (e: SyntheticInputEvent<HTMLInputElement>) => any;

  // // A callback that is executed when key is released.
  // onKeyUp?: (e: SyntheticInputEvent<HTMLInputElement>) => any;

  // // A callback that is executed when input is focused.
  value?: any;
  min?: any;
  max?: any;
  defaultValue?: any;
  maxLength?: any;
  error?: any;
  pattern?: string;
  checked?: boolean;
};

/**
 * Generic input component.
 */
// eslint-disable-next-line
export function Input({
  onBlur,
  placeholder,
  disabled,
  required,
  type,
  min,
  name,
  max,
  onChange,
  autoFocus,
  size,
  defaultValue,
  inputRef,
  checked,
  maxLength,
  onKeyDown,
  onFocus,
  value,
  error,
  id,
  className,
  pattern,
}: Props) {
  // eslint-disable-next-line
  error ? (placeholder = error) : (placeholder = placeholder);

  return (
    <>
      <input
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete="off"
        onChange={onChange}
        type={type}
        ref={inputRef!}
        name={name}
        required={required}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        min={min}
        checked={checked}
        max={max}
        pattern={pattern}
        id={id}
        onBlur={onBlur}
        className={[
          `storybook-input  ${error ? "field_error" : ""} ${
            required ? "required_error" : ""
          } ${size} ${className}`,
        ].join(" ")}
      />
    </>
  );
}
export default Input;
