import { StudentStatus } from "../../../../../utils/Enum.types";

export const FetchStudentStatusClassName = (row: StudentStatus | string) => {
  switch (row) {
    case StudentStatus.CUR:
      return "std_status--cur";
    case StudentStatus.TC:
      return "std_status";
    case StudentStatus.DET:
      return "std_status--det";
    case StudentStatus.SOA:
      return "std_status--soa";
    case StudentStatus.NXT:
      return "std_status--nxt";
    case StudentStatus.NE:
      return "std_status--ne";

    default:
      break;
  }
};
