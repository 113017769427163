import { TEXT_FONT_FAMILY } from "../../utils/constants";
import { makeStyles } from "@mui/styles";

export interface FormAutoCompleteTextStyles {
  formControlRoot: {
    fontFamily: string;
    color: string;
    borderRadius: string;
    position: string;
    marginTop: string;
    "&.Mui-focused .MuiInputBase-formControl": {
      border: string;
    };
    "&.hover": {
      border: string;
    };
    "& label.Mui-focused": {
      color: string;
    };
    "& .MuiInputBase-input": {
      height: string;
      fontSize: number;
      fontFamily: string;
      padding: string;
    };
    "& .MuiAutocomplete-endAdornment": {
      top: string;
    };
    " & .MuiAutocomplete-endAdornment ": {
      backgroundColor: string;
    };
  };
  inputLabelRoot: {
    color: string;
    fontFamily: string;
    "&.focused": {
      color: string;
      filter: string;
      border: string;
    };
  };
}

export const instAutoCompleteStyles = makeStyles<FormAutoCompleteTextStyles>(
  () => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 16,
      padding: "0px !important",

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "0px solid var(--level-5)",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px solid var(--border-color) !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "0px solid var(--level-3) !important",
      },
      "& .MuiOutlinedInput-root": {
        padding: 6,
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        margin: -1,
        fontSize: 14,
      },
      "& .MuiSvgIcon-root ": {
        height: 18,
        width: 18,
      },

      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
      "& .MuiAutocomplete-root ": {
        padding: "7px !important",
      },
    },
  })
);

export const instAutoCompleteTextStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    formControlRoot: {
      fontFamily: TEXT_FONT_FAMILY,
      color: "var(--text-color)",
      borderRadius: "6px",
      position: "relative",

      "&.Mui-focused .MuiInputBase-formControl": {
        border: "1px solid var(--level-5)",
      },
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)",
      },

      "& label.Mui-focused": {
        color: "var(--text-color)",
      },
      "& .MuiInputBase-input": {
        fontSize: 16,
        fontFamily: TEXT_FONT_FAMILY,
        padding: " 2px !important",
        fontWeight: "700 !important",
      },

      "& .MuiAutocomplete-endAdornment": {
        top: "calc(50% - 10px)",
      },
    },
    inputLabelRoot: {
      color: "black",
      fontFamily: TEXT_FONT_FAMILY,

      "&.focused": {
        color: "#ffffff",
        filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
        border: "0px solid var(--level-5)",
      },
    },
  }));
export const formAutoCompleteStyles = makeStyles<FormAutoCompleteTextStyles>(
  () => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 14,
      padding: "0px !important",

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        background: "var(--disabled-color) !important",
        cursor: "not-allowed",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--border-color) !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--level-3) !important",
      },
      "& .MuiOutlinedInput-root": {
        padding: 6,
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        margin: -1,
        fontSize: 12,
      },
      "& .MuiSvgIcon-root ": {
        height: 18,
        width: 18,
      },

      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
      "& .MuiAutocomplete-root ": {
        padding: "7px !important",
      },
    },
  })
);

export const formAutoCompleteTextStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    formControlRoot: {
      fontFamily: TEXT_FONT_FAMILY,
      color: "var(--text-color)",
      borderRadius: "6px",
      position: "relative",
      marginTop: "10px !important",

      "&.Mui-focused .MuiInputBase-formControl": {
        border: "1px solid var(--level-5)",
      },
      " &.hover": {
        border: "1px solid var(--level-5)",
      },

      "& label.Mui-focused": {
        color: "var(--text-color)",
      },
      "& .MuiInputBase-input": {
        height: "30px !important",
        fontSize: 14,
        fontFamily: TEXT_FONT_FAMILY,
        padding: "0px 5px !important",
      },

      "& .MuiAutocomplete-endAdornment": {
        top: "calc(50% - 12px)",
      },
      " & .MuiAutocomplete-endAdornment ": {
        backgroundColor: "var(--bg-color)",
      },
    },
    inputLabelRoot: {
      color: "black",
      fontFamily: TEXT_FONT_FAMILY,
      "&.focused": {
        color: "#ffffff",
        filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
        border: "1px solid var(--level-5)",
      },
    },
  }));
export const listAutoCompleteStyles = makeStyles<FormAutoCompleteTextStyles>(
  () => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 14,
      padding: "6px !important",
      "& .MuiAutocomplete-root": {
        width: "100%",
      },

      "&:Mui-hover .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        background: "var(--disabled-color)",
        cursor: "not-allowed",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--border-color) !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--level-3) !important",
      },

      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        fontSize: 12,
      },

      "& .MuiSvgIcon-root ": {
        height: 16,
        width: 16,
      },
      " & .MuiAutocomplete-endAdornment ": {
        backgroundColor: "var(--bg-color)",
      },
      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
    },
  })
);

export const listAutoCompleteTextStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    formControlRoot: {
      fontFamily: TEXT_FONT_FAMILY,
      color: "var(--text-color) !important",
      borderRadius: "6px",
      position: "relative",
      fontSize: 12,
      marginTop: "10px !important",

      "&.Mui-focused .MuiInputBase-formControl": {
        border: "1px solid var(--level-5)",
      },
      " &.hover": {
        border: "1px solid var(--level-5) !important",
      },

      "& label.Mui-focused": {
        color: "var(--text-color)",
      },
      "& .MuiInputBase-input": {
        height: "3px !important",
      },
      "& .MuiAutocomplete-endAdornment": {
        top: "calc(50% - 11px)",
      },
      "& .MuiAutocomplete-root    .MuiOutlinedInput-root   .MuiAutocomplete-input":
        {
          padding: 2,
        },
    },
    inputLabelRoot: {
      color: "var(--text-color) !important",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 12,
      "&.focused": {
        color: "#ffffff",

        border: "1px solid var(--level-5)",
      },
      "&.hover": {
        color: "#ffffff",

        border: "1px solid var(--level-5)",
      },
    },
  }));
export const requiredListAutoCompleteStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 14,
      padding: "6px !important",

      "&:Mui-hover .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        background: "var(--disabled-color)",
        cursor: "not-allowed",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--border-color) !important",
        borderRight: "3px solid red !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--level-3) !important",
      },

      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        fontSize: 12,
      },

      "& .MuiSvgIcon-root ": {
        height: 16,
        width: 16,
      },
      " & .MuiAutocomplete-endAdornment ": {
        backgroundColor: "var(--bg-color)",
      },
      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: "12px !important",
        fontFamily: TEXT_FONT_FAMILY,
      },
    },
  }));

export const requiredAutoCompleteStyles =
  makeStyles<FormAutoCompleteTextStyles>(() => ({
    inputRoot: {
      color: "var(--text-color)",
      fontFamily: TEXT_FONT_FAMILY,
      fontSize: 14,
      boxShadow: " 3px 0px 0px red",
      width: "calc(100% - 3px) !important",
      padding: "0px !important",

      "&.Mui-focused .MuiAutocomplete-root": {
        border: "1px solid var(--level-5)",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--border-color) !important",
      },

      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid var(--level-3) !important",
      },
      "& .MuiOutlinedInput-root": {
        padding: 6,
      },
      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
        fontSize: 12,
      },
      "& .MuiSvgIcon-root ": {
        height: 18,
        width: 18,
      },

      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
        fontSize: 12,
        fontFamily: TEXT_FONT_FAMILY,
      },
      "& .MuiAutocomplete-root ": {
        padding: "7px !important",
      },
    },
  }));
