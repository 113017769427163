import { makeStyles } from "@mui/styles";
import { TEXT_FONT_FAMILY } from "../../utils/constants";

export const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "var(--level-5) !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },
  customTooltipCalendar: {
    backgroundColor: "white !important",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color) !important",
  },
  customTooltipOrganization: {
    backgroundColor: "white !important",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
    color: "var(--text-color) !important",
  },

  customArrow: {
    color: "var(--level-5) !important",
  },
  customTooltipError: {
    backgroundColor: "var(--text-color) !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowError: {
    color: "var(--text-color) !important",
  },
  customTooltipTeal: {
    backgroundColor: "#4fd1c5 !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowTeal: {
    color: "#4fd1c5 !important",
  },
  customTooltipSky: {
    backgroundColor: "#38bdf8 !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowSky: {
    color: "#38bdf8 !important",
  },
  customTooltipPurple: {
    backgroundColor: "#c084fc !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowPurple: {
    color: "#c084fc !important",
  },
  customTooltipPink: {
    backgroundColor: "#f472b6 !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowPink: {
    color: "#f472b6 !important",
  },
  customTooltipLime: {
    backgroundColor: "#a3e635 !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowLime: {
    color: "#a3e635 !important",
  },
  customTooltipGreen: {
    backgroundColor: "#22c55e !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowGreen: {
    color: "#22c55e !important",
  },
  customTooltipFuchsia: {
    backgroundColor: "#e879f9 !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowFuchsia: {
    color: "#e879f9 !important",
  },
  customTooltipGrey: {
    backgroundColor: "#78716c !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowGrey: {
    color: "#78716c !important",
  },

  customTooltipBlue: {
    backgroundColor: "#3b82f6 !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowBlue: {
    color: "#3b82f6 !important",
  },

  customTooltipAmber: {
    backgroundColor: "var(--level-5) !important",
    fontSize: 14,
    fontFamily: TEXT_FONT_FAMILY,
  },

  customArrowAmber: {
    color: "var(--level-5) !important",
  },
  studentOptions: {
    background: "white !important",
    color: "black !important",
    fontFamily: TEXT_FONT_FAMILY,
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    fontSize: 16,
    fontWeight: 500,
    width: "fit-content",
    marginTop: 0,
  },
}));
