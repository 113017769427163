import { getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

const useToken = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdTokenResult(user).then((IdTokenResult) => {
          setToken(IdTokenResult.token);
        });
      }
    });
  }, []);

  return { token };
};

export default useToken;
