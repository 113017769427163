import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GetNotAllocatedIdsData, TableType } from "../utils/Query.Types";
import { useParams } from "react-router-dom";
import { GetNotAllocatedIds } from "../queries/institution/common";
import { GetNotAllocatedIdsVars } from "./useMasterDetailsDropDown";
import useToken from "./useToken";

const useNotAllocatedIds = () => {
  const { instId } = useParams();
  const { token } = useToken();
  const [GetNotAllocated, { data }] = useLazyQuery<
    GetNotAllocatedIdsData,
    GetNotAllocatedIdsVars
  >(GetNotAllocatedIds, {
    variables: {
      inst_id: instId!,
      table: TableType.TABLE_NONE,
      table_id: 0,
      token,
    },
  });

  useEffect(() => {
    if (token && instId) {
      GetNotAllocated();
    }
  }, [token, instId, GetNotAllocated]);
  return {
    data,
    notAllocatedDeptId: data ? data.GetNotAllocatedIds.dept_id : 0,
    notAllocatedBranchId: data ? data.GetNotAllocatedIds.branch_id : 0,
    notAllocatedClassId: data ? data.GetNotAllocatedIds.class_id : 0,
    notAllocatedSemId: data ? data.GetNotAllocatedIds.semester_id : 0,
    notAllocatedSectionId: data ? data.GetNotAllocatedIds.section_id : 0,
  };
};

export default useNotAllocatedIds;
