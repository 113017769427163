import { makeStyles } from "@mui/styles";

export const attendanceOverViewStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    bottom: "0px !important",
    width: "40%",
    borderRadius: "6px 0px",
    padding: 10,
    height: "100% !important",
  },
}));
export const disclaimerStyles = makeStyles(() => ({
  drawer: {
    width: "100%",
  },
  drawerPaper: {
    bottom: "0px !important",
    width: "100%",
    borderRadius: "6px 0px",
    padding: 10,
    height: "70% !important",
  },
}));
export const allocateTeachers = makeStyles(() => ({
  drawer: {
    width: "50%",
  },
  drawerPaper: {
    top: "auto !important",
    bottom: " 0px !important",
    height: "calc(100% - 110px) !important",
    width: "50%",
    borderRadius: "6px 0px",
    padding: 10,
  },
}));
