import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
const stepHeader = () => {
  return [
    "Basic Details",
    "Financial Year",
    "Academic Year",
    "Module Configuration",
    "Basic Configuration",
  ];
};

interface Props {
  step: number;
}
const Steps = ({ step }: Props) => {
  const stepsHeader = stepHeader();
  return (
    <div className="inststeppers">
      <Stepper alternativeLabel activeStep={step - 1}>
        {stepsHeader.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default Steps;
