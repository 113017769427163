import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Enlarge from "../../images/Enlarge.svg";
import { useStyles } from "../styles/TooltipStyles";
import Skins from "./Skins";
import Username from "../../images/Username.svg";
import ESandeshLogo from "../../images/E-Sandesh.svg";
import Close from "../../images/DownArrow.svg";

import Modal from "react-modal";
import { LogOutModalStyles, WaiveOffModalStyles } from "../styles/ModalStyles";
import { Title } from "../../stories/Title/Title";
import { auth } from "../../firebase";
import { Label } from "../../stories/Label/Label";
import { Button } from "../../stories/Button/Button";
import { AppContext } from "../../context/context";
import { handleSignOut } from "./Sidebar";
import useToken from "../../customhooks/useToken";
import { LogoutUser } from "../../queries/DetailsByToken";
import { useMutation } from "@apollo/client";
import useInstDetails from "../../customhooks/useInstDetails";
import Captcha from "../../pages/Captcha";
import InstLogoUpload from "../Modules/Masters/Student/Components/Modals/InstLogoUpload";
import { emptyMessageType } from "../../utils/constants";
import { msgType } from "../../utils/Types";
import { getDownloadUrl } from "../../utils/DownloadFile";
import useEmpDetailsById from "../../customhooks/useEmpDetailsById";
const Header = () => {
  const tooltipClasses = useStyles();
  const { InstFormData, InstDetails } = useInstDetails();
  const [userLogout, setUserLogout] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [instLogoModal, setInstLogoModal] = useState(false);
  const [imageString, setImageString] = useState("");
  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const { token } = useToken();

  const { state } = useContext(AppContext);
  const [DeleteDeviceId] = useMutation(LogoutUser);
  const { employeeFormData } = useEmpDetailsById(true);

  const toggleFullSceen = (): void => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handleDeleteUserDeviceId = () => {
    if (state.claims?.EDUATE || state.claims?.OWNER) {
      handleSignOut();
    } else
      DeleteDeviceId({
        variables: {
          token,
          device_token: "",
          input: {
            id: state?.claims!.EMPLOYEE
              ? state.empLoginId
              : state?.claims!.PARENT
              ? state.parentId
              : state.studentId,
            user_type: state.claims!.EMPLOYEE
              ? "EMPLOYEE"
              : state.claims!.PARENT
              ? "PARENT"
              : "STUDENT",
          },
        },
      }).then(({ data }) => {
        if (data) {
          handleSignOut();
        }
      });
  };
  useEffect(() => {
    if (
      InstDetails.data &&
      InstDetails.data.nodes.length &&
      InstDetails.data.nodes[0].inst_logo_filename !== ""
    ) {
      getDownloadUrl(
        InstDetails.data.nodes[0].inst_logo_filename,
        setImageString,
        false
      );
    }
  }, [InstDetails.data, message.flag]);
  return (
    <>
      <div className="header">
        <div className="row g-0">
          <div className="col-5 header__inst-details ">
            <span className="header--myeduate">
              <img src={ESandeshLogo} alt="/" className="logo" />
              Sandesh
            </span>
            {state.claims &&
            state.claims.EMPLOYEE &&
            employeeFormData.is_global_user ? (
              <Tooltip
                title="Click here to upload logo."
                arrow
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
              >
                <img
                  src={imageString ? imageString : ESandeshLogo}
                  alt="/"
                  className="logo"
                  onClick={() => setInstLogoModal(!instLogoModal)}
                />
              </Tooltip>
            ) : (
              <img
                src={imageString ? imageString : ESandeshLogo}
                alt="/"
                className="logo"
              />
            )}
            <b className="header--inst-name">{InstFormData.inst_name}</b>
          </div>
          <div className="col"></div>
          <div className="col-5">
            <ul className="header__icon">
              <li>
                <Tooltip
                  title="Full Screen"
                  arrow
                  placement="top"
                  classes={{
                    tooltip: tooltipClasses.customTooltipGrey,
                    arrow: tooltipClasses.customArrowGrey,
                  }}
                >
                  <img
                    src={Enlarge}
                    alt="/"
                    onClick={() => toggleFullSceen()}
                  />
                </Tooltip>
              </li>
              <li>
                <Skins />
              </li>

              <li>
                <Tooltip
                  title="SignOut"
                  arrow
                  placement="top"
                  className="tooltip-hide"
                >
                  <img
                    src={Username}
                    alt="/"
                    onClick={() => setUserLogout(!userLogout)}
                  />
                </Tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={userLogout}
        ariaHideApp={false}
        style={LogOutModalStyles}
        onRequestClose={() => setUserLogout(!userLogout)}
      >
        <div className="logout-modal">
          <img src={Username} alt="/" />
          <Title variant="subtitle1">UserName</Title>
          <Title variant="subtitle2">{auth.currentUser?.email}</Title>

          <Label
            variant="LabelPrimary"
            onClick={() => {
              setPasswordModal(!passwordModal);
              setUserLogout(!userLogout);
            }}
          >
            Change Password
          </Label>

          <Button
            onClick={() => {
              // handleSignOut();
              handleDeleteUserDeviceId();
              // window.location.href = "/";
              setUserLogout(!userLogout);
            }}
            mode="sign-out"
          />
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={passwordModal}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <div className="col">
              <Captcha setPasswordModal={setPasswordModal} />
            </div>
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              onClick={() => setPasswordModal(!passwordModal)}
            />
          </div>
        </div>
      </Modal>
      <InstLogoUpload
        handleClose={() => {
          setInstLogoModal(!instLogoModal);
        }}
        modal={instLogoModal}
        setMessage={setMessage}
        setModal={setInstLogoModal}
        message={message}
      />
    </>
  );
};

export default Header;
