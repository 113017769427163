import React, { useState, Key, useEffect, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";

import { useParams } from "react-router-dom";
import Close from "../../../../../images/Close.svg";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { ModalType } from "../Index";

import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";

import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import Input from "../../../../../stories/Input/Input";
import {
  CommonNodeVars,
  EmpData,
  GlobalPageConfigData,
  LoginUser,
} from "../Types";
import useToken from "../../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { msgType } from "../../../../../utils/Types";
import {
  ChatConfigKeys,
  Direction,
  InstitutionConfigurationTypes,
  Operation,
  SortBy,
} from "../../../../../utils/Enum.types";

import {
  EMPTY_STRING,
  EmpStatusOptions,
  GenderOptions,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import { GetMsgEmp, MsgEmpMaster } from "../../../../../queries/userrights";
import {
  AddMsgEmpMaster,
  UpdateMsgEmpMaster,
} from "../../../../../queries/userrights/mutations";
import ReactModal from "react-modal";
import { EditModalCustomStyles } from "../../../../styles/ModalStyles";
import { Title } from "../../../../../stories/Title/Title";
import { loginUserValidation } from "../../../../../utils/validationRules";
import { formLabelProps } from "../../../Masters/Customer/Add";
import { Label } from "../../../../../stories/Label/Label";
import { Keys } from "../../../../../utils/Enum.keys";
import {
  handleFormEvent,
  handleMUISelectEvent,
  removeMoreSpace,
  toInputStandardDate,
  toIsoDate,
} from "../../../../../utils/UtilFunctions";
import { Button } from "../../../../../stories/Button/Button";
import LoadingModal from "../../../../Modals/LoadingModal";
import MessageModal from "../../../../../utils/MessageModal";
import { AntSwitch } from "../../../../../pages/Switch";
import DownArrow from "../../../../../images/DownArrow.svg";
import useServerDateandTime from "../../../../../customhooks/useServerDateandTime";
import useSwConfigData from "../../../../../customhooks/useSwConfighData";

const { User_Rights } = require("../../Json/form.json");
interface Props {
  openModal: ModalType;
  setOpenModal: React.Dispatch<React.SetStateAction<ModalType>>;
}

const AddLoginUser = ({ openModal, setOpenModal }: Props) => {
  const { id, operation } = openModal;
  const { token } = useToken();
  const { instId } = useParams();
  const nameRef = document.getElementsByName(
    "emp_first_name"
  )[0] as HTMLInputElement;
  const genderRef = useRef<HTMLSelectElement>(null);

  const { user_details } = useLoggedInUserDetails();
  const textClasses = formAutoCompleteTextStyles();
  const classes = formAutoCompleteStyles();
  const { serverDate } = useServerDateandTime();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [gender, setGender] = useState("");
  const [emp_status, setEmpStatus] = useState(EmpStatusOptions[0]);

  const [formData, setFormData] = useState<LoginUser>({
    emp_dob: "",
    emp_email: "",
    emp_mobile: "",
    emp_name: "",
    can_send_announcement: false,
    can_send_feeds: false,
    can_send_bd_msg: false,
  });

  const { configData } = useSwConfigData([
    ChatConfigKeys.ENABLE_ANNOUNCEMENTS,
    ChatConfigKeys.ENABLE_MY_CAMPUS_FEEDS,
    ChatConfigKeys.ENABLE_TO_SEND_BIRTHDAY_MESSAGES,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let enableAnnouncements = false;
    let enableFeeds = false;
    let enableBirthdayWishes = false;

    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case ChatConfigKeys.ENABLE_ANNOUNCEMENTS:
            enableAnnouncements = item.config_boolean_value;
            break;
          case ChatConfigKeys.ENABLE_MY_CAMPUS_FEEDS:
            enableFeeds = item.config_boolean_value;
            break;
          case ChatConfigKeys.ENABLE_TO_SEND_BIRTHDAY_MESSAGES:
            enableBirthdayWishes = item.config_boolean_value;
            break;
          default:
            break;
        }
      });
    }
    return {
      enableAnnouncements,
      enableFeeds,
      enableBirthdayWishes,
    };
  };

  const { enableAnnouncements, enableFeeds, enableBirthdayWishes } =
    filterDataByConfigKey(
      configData.data ? configData.data.GetSwConfigVariables : []
    );

  const [GetLoginEmp, { data }] = useLazyQuery<EmpData, CommonNodeVars>(
    MsgEmpMaster,
    {
      variables: {
        id: openModal.id,
        token,
      },
    }
  );

  const [AddLoginUser, { loading: creationLoading }] = useMutation(
    AddMsgEmpMaster,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [UpdateLoginUser, { loading: updationLoading }] = useMutation(
    UpdateMsgEmpMaster,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues: LoginUser) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleNumberChange = (mobileNo: string) => {
    setFormData((prevValues) => ({
      ...prevValues,
      emp_mobile: mobileNo,
    }));
  };

  const HandleUserCreation = () => {
    if (openModal.operation === Operation.CREATE) {
      AddLoginUser({
        variables: {
          token,
          inst_id: instId,
          user_details,
          input: {
            emp_name: removeMoreSpace(formData.emp_name),
            emp_sex: gender,
            emp_dob: toIsoDate(formData.emp_dob),
            emp_mobile: formData.emp_mobile.length
              ? `+${formData.emp_mobile}`
              : "",
            emp_email: formData.emp_email,
            emp_status: "ROLE",
            can_send_feeds: formData.can_send_feeds,
            can_send_announcement: formData.can_send_announcement,
            can_send_bd_msg: formData.can_send_bd_msg,
          },
        },
        refetchQueries: [
          {
            query: GetMsgEmp,
            variables: {
              after: null,
              first: ROWS_PER_PAGE,
              inst_id: instId,
              name: EMPTY_STRING,
              orderBy: {
                field: SortBy.EMP_NAME,
                direction: Direction.ASC,
              },
              token,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Saved",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateLoginUser({
        variables: {
          token,
          id: openModal.id,
          inst_id: instId,
          user_details,
          input: {
            emp_name: removeMoreSpace(formData.emp_name),
            emp_sex: gender,
            emp_dob: toIsoDate(formData.emp_dob),
            emp_mobile: `+${formData.emp_mobile}`,
            emp_email: formData.emp_email,
            emp_status: emp_status,
            can_send_feeds: formData.can_send_feeds,
            can_send_announcement: formData.can_send_announcement,
            can_send_bd_msg: formData.can_send_bd_msg,
          },
        },
        refetchQueries: [
          {
            query: GetMsgEmp,
            variables: {
              after: null,
              first: ROWS_PER_PAGE,
              inst_id: instId,
              name: EMPTY_STRING,
              orderBy: {
                field: SortBy.EMP_NAME,
                direction: Direction.ASC,
              },
              token,
            },
            fetchPolicy: "network-only",
          },
          {
            query: MsgEmpMaster,
            variables: {
              id: openModal.id,
              token,
            },
            fetchPolicy: "network-only",
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Successfully Saved",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };

  const handleClear = () => {
    setFormData({
      emp_dob: "",
      emp_email: "",
      emp_mobile: "",
      emp_name: "",
      can_send_announcement: false,
      can_send_feeds: false,
      can_send_bd_msg: false,
    });
    if (nameRef) nameRef.focus();
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
      setOpenModal({
        flag: false,
        id: 0,
        operation: Operation.NONE,
      });
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (id && operation === Operation.UPDATE && token) {
      GetLoginEmp().then(({ data }) => {
        if (data && data.node) {
          const empMobileWithoutPlus = data.node.emp_mobile.replaceAll("+", "");
          setFormData({
            emp_email: data.node.emp_email,
            emp_name: data.node.emp_name,
            emp_dob: toInputStandardDate(data.node.emp_dob),
            emp_mobile: empMobileWithoutPlus,
            can_send_announcement: data.node.can_send_announcement,
            can_send_feeds: data.node.can_send_feeds,
            can_send_bd_msg: data.node.can_send_bd_msg,
          });
          setGender(data.node.emp_sex);
          setEmpStatus(data.node.emp_status);
        }
      });
    }
  }, [id, operation, data, GetLoginEmp, token]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={openModal.flag}
        style={EditModalCustomStyles}
        ariaHideApp={false}
      >
        <div className="user-rights__add">
          <div className="user-rights__add--title">
            <Title>
              {openModal.operation === Operation.CREATE
                ? "Add Staff"
                : "Update Staff"}
            </Title>
            <img
              src={Close}
              alt="/"
              onClick={() => {
                setOpenModal({
                  operation: Operation.NONE,
                  flag: false,
                  id: 0,
                });
                handleClear();
              }}
            />
          </div>
          <Formik
            initialValues={formData}
            validationSchema={loginUserValidation}
            onSubmit={HandleUserCreation}
            enableReinitialize
          >
            {(meta) => {
              return (
                <Form>
                  <div className="row g-0">
                    {User_Rights.AddLoginUser.map(
                      (label: formLabelProps, index: Key) => {
                        return (
                          <React.Fragment key={index}>
                            <div className="label-grid">
                              <Label>{label.labelName}</Label>
                              <Input
                                name={label.inputName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleValueChange(e);
                                  meta.handleChange(e);
                                }}
                                autoFocus={label.autoFocus}
                                value={formData[label.inputName]}
                                type={label.dataType}
                                required={label.required}
                                maxLength={label.maxLength}
                                max={toInputStandardDate(serverDate)}
                                onKeyDown={handleFormEvent}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                    )}
                    <div className="label-grid">
                      <Label>Mobile</Label>
                      <PhoneInput
                        country={"in"}
                        value={formData.emp_mobile}
                        onChange={handleNumberChange}
                        inputProps={{
                          required: true,
                        }}
                        placeholder={
                          meta.values.emp_mobile ? meta.errors.emp_mobile : ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();

                            if (genderRef.current)
                              (
                                genderRef.current?.childNodes[0].childNodes[0]
                                  .childNodes[0] as HTMLInputElement
                              )?.focus();
                          }
                        }}
                      />
                    </div>

                    <div className="label-grid">
                      <Label>Gender</Label>
                      <Autocomplete
                        classes={classes}
                        options={GenderOptions}
                        value={gender}
                        isOptionEqualToValue={(option) => {
                          return option === gender || gender === "";
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setGender(newValue);
                          } else {
                            setGender("");
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        ref={genderRef}
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="label-grid">
                      <Label>Status</Label>
                      <Autocomplete
                        classes={classes}
                        options={EmpStatusOptions}
                        value={emp_status}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            setEmpStatus(newValue);
                          }
                        }}
                        disableClearable
                        onKeyDown={(e) => {
                          if (e.key === Keys.ENTER) {
                            handleMUISelectEvent(e);
                          }
                        }}
                        openOnFocus
                        freeSolo
                        popupIcon={<img src={DownArrow} alt="/" />}
                        forcePopupIcon
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            classes={{
                              root: textClasses.formControlRoot,
                            }}
                          />
                        )}
                      />
                    </div>
                    {enableAnnouncements ? (
                      <div className="label-grid">
                        <Label>Enable Announcements</Label>
                        <FormGroup>
                          <FormControlLabel
                            label=""
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={formData.can_send_announcement}
                                onClick={() => {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    can_send_announcement:
                                      !prevValues.can_send_announcement,
                                  }));
                                }}
                                onKeyDown={handleMUISelectEvent}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    ) : null}
                    {enableFeeds ? (
                      <div className="label-grid">
                        <Label>Enable Feeds</Label>
                        <FormGroup>
                          <FormControlLabel
                            label=""
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={formData.can_send_feeds}
                                onClick={() => {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    can_send_feeds: !prevValues.can_send_feeds,
                                  }));
                                }}
                                onKeyDown={handleMUISelectEvent}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    ) : null}
                    {enableBirthdayWishes ? (
                      <div className="label-grid">
                        <Label>Enable Birthday Wishes</Label>
                        <FormGroup>
                          <FormControlLabel
                            label=""
                            labelPlacement="start"
                            control={
                              <AntSwitch
                                checked={formData.can_send_bd_msg}
                                onClick={() => {
                                  setFormData((prevValues) => ({
                                    ...prevValues,
                                    can_send_bd_msg:
                                      !prevValues.can_send_bd_msg,
                                  }));
                                }}
                                onKeyDown={handleMUISelectEvent}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    ) : null}
                  </div>
                  <Button mode="save" type="submit" />
                  <Button mode="clear" type="button" onClick={handleClear} />
                  <Button
                    mode="cancel"
                    type="button"
                    onClick={() => {
                      handleClear();
                      setOpenModal({
                        operation: Operation.NONE,
                        flag: false,
                        id: 0,
                      });
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </ReactModal>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default AddLoginUser;
