import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useAcademicYear from "./useActiveAcademicYear";
import {
  BranchesList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../utils/Types";
import {
  Direction,
  InstitutionConfigurationTypes,
  SortBy,
  StudentAcdType,
  StudentReportType,
} from "../utils/Enum.types";
import { MstStudentMiscData, StudentNode } from "./useStudentByNodeApi";
import useToken from "./useToken";
import { AppContext } from "../context/context";
import useSwConfigData from "./useSwConfighData";
import { GetAcdStudents } from "../queries/students/list";
export interface StudentAcdNode {
  std_name: string;
  id: number;
  std_adm_no: string;
  std_email: string;
  parent_name: string;
  std_fresher: boolean;
  std_mobile: string;
  std_mother_name: string;
  std_reg_no: string;
  std_status: string;
  std_doa: string;
  std_dob: string;
  is_login_created: boolean;
  acd_dept: DepartmentList;
  acd_branch: BranchesList;
  acd_class: ClassList;
  acd_semester: SemesterList;
  acd_section: SectionList;
  misc_data: MstStudentMiscData;
  // acd_indivual_marks: GetAcdStdMarksByIdDetails[];
  isChecked: boolean;
  ext_marks?: number;
  int_marks?: number;
  scored_grade?: string;
  teacher_comments?: string;
  is_present?: boolean;
  att_status?: string;
  comments?: string;
  approved_by?: string;
  std_roll_no: string;
  std_sex?: string;
}
export interface StudentPageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}
export interface StudentEdges {
  node: StudentNode;
}
export interface GetAcdStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
interface input {
  std_query_type: string;
  ids: number[];
  str_data: string[];
}
export interface GetStudentAcdData {
  GetAcdStudents: GetAcdStudentsList;
}
export interface AcdStudentsvars {
  name: string;
  after: string | null;
  orderBy?: {
    direction: string;
    field: string;
  }[];
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}

export interface AcdStudentsvarsForCount {
  after: string | null;
  orderBy?: {
    direction: string;
    field: string;
  }[];
  first: number | null;
  token: string;
  acd_yr_id: number;
  input: input;
}
const useAcdStudentsData = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  categoryId: number,
  rowsPerPage: number | null,
  allotedId: number,
  name: string,
  queryType: StudentReportType | string,
  subjectId?: number,
  testConductId?: number,
  instIdForCampus?: number
) => {
  const { token } = useToken();
  const { instId } = useParams();
  const { state } = useContext(AppContext);
  const [ids, setIds] = useState<number[]>([]);
  const [str_data, setstr_data] = useState<string[]>([]);
  const [std_query_type, setstd_query_type] = useState("");
  const { activeAcademicYearData } = useAcademicYear();
  const acdYearID = activeAcademicYearData.data
    ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    : 0;

  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.SUBJECT_ALLOCATION_LEVEL
  );

  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStudentAcdData,
    AcdStudentsvars
  >(GetAcdStudents, {
    variables: {
      after: null,
      acd_yr_id: acdYearID,
      first: rowsPerPage,
      name,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.STD_NAME,
        },
      ],
      input: {
        ids,
        std_query_type,
        str_data,
      },
    },
  });

  useEffect(() => {
    switch (queryType) {
      case StudentReportType.GENERAL:
        if (sectionId) {
          setIds([sectionId]);
          setstd_query_type(StudentAcdType.BY_SECTION_ID);
        } else if (semesterId) {
          setIds([semesterId]);
          setstd_query_type(StudentAcdType.BY_SEMESTER_ID);
        } else if (classId) {
          setIds([classId]);
          setstd_query_type(StudentAcdType.BY_CLASS_ID);
        } else if (branchId) {
          setIds([branchId]);
          setstd_query_type(StudentAcdType.BY_BRANCH_ID);
        } else if (departmentId) {
          setIds([departmentId]);
          setstd_query_type(StudentAcdType.BY_DEPT_ID);
        } else {
          setIds([Number(instId)]);
          setstd_query_type(StudentAcdType.BY_INST_ID);
        }
        break;
      default:
        // setIds([Number(instId)]);

        // setstd_query_type(StudentAcdType.BY_ACD_INST_ID);
        break;
    }
  }, [
    sectionId,
    semesterId,
    classId,
    branchId,
    departmentId,
    categoryId,
    instId,
    queryType,
    allotedId,
    configData.data,
    configData.loading,
    testConductId,
    subjectId,
    instIdForCampus,
  ]);

  useEffect(() => {
    if (
      !activeAcademicYearData.loading &&
      activeAcademicYearData.data &&
      std_query_type &&
      ids.length
    ) {
      GetStudentsData();
    }
  }, [
    activeAcademicYearData.data,
    activeAcademicYearData.loading,
    queryType,
    GetStudentsData,
    str_data,
    std_query_type,
    ids.length,
  ]);
  return {
    AcademicsStudentData: { data, loading, error, fetchMore },
  };
};

export default useAcdStudentsData;
