import { TextField } from "@mui/material";
import React from "react";
import useInstLabels from "../../../../../../customhooks/useInstLabels";
import useInstConfigByEntryId from "../../../../../../customhooks/useInstConfigByEntryId";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "../../../../../../customhooks/useInstitutionConfiguration";
import { EMPTY_STRING } from "../../../../../../utils/constants";

const InstDetails = () => {
  const { entryId } = useParams();

  const {
    entry_level,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const { InstConfigDetails } = useInstConfigByEntryId(entryId!, entry_level);
  return (
    <>
      <div className="row g-0 chat-channel__select">
        {USE_DEPARTMENT_KEY ? (
          <div className="col-2">
            <TextField
              className="chat-channel__select--textfield"
              label={departmentLabel}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                InstConfigDetails.data
                  ? InstConfigDetails.data.GetInstConfigNames.dept_desc
                  : EMPTY_STRING
              }
            />
          </div>
        ) : null}
        <div className="col-2">
          {USE_BRANCH_KEY ? (
            <TextField
              className="chat-channel__select--textfield"
              label={branchLabel}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                InstConfigDetails.data
                  ? InstConfigDetails.data.GetInstConfigNames.branch_desc
                  : EMPTY_STRING
              }
            />
          ) : null}
        </div>
        <div className="col-2">
          {USE_CLASS_KEY ? (
            <TextField
              className="chat-channel__select--textfield"
              label={classLabel}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                InstConfigDetails.data
                  ? InstConfigDetails.data.GetInstConfigNames.class_desc
                  : EMPTY_STRING
              }
            />
          ) : null}
        </div>
        <div className="col-2">
          {USE_SEMESTER_KEY ? (
            <TextField
              className="chat-channel__select--textfield"
              label={semesterLabel}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                InstConfigDetails.data
                  ? InstConfigDetails.data.GetInstConfigNames.semester_desc
                  : EMPTY_STRING
              }
            />
          ) : null}
        </div>
        <div className="col-2">
          {USE_SECTION_KEY ? (
            <TextField
              className="chat-channel__select--textfield"
              label={sectionLabel}
              InputLabelProps={{ shrink: true }}
              disabled
              value={
                InstConfigDetails.data
                  ? InstConfigDetails.data.GetInstConfigNames.section_desc
                  : EMPTY_STRING
              }
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InstDetails;
