import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

import useToken from "./useToken";
import { responseType } from "../utils/Types";
import { GetAcdYrsByInstId } from "../queries/institution/queries/byId";
import {
  AcademicYearListData,
  AcademicYearListVars,
} from "../utils/Query.Types";
import { AppContext } from "../context/context";

const useAcademicYears = () => {
  const { token } = useToken();

  const { state } = useContext(AppContext);
  const [responseType, setResponseType] = useState<responseType[]>([]);
  const [GetAcdYears, { data, loading, error }] = useLazyQuery<
    AcademicYearListData,
    AcademicYearListVars
  >(GetAcdYrsByInstId, {
    variables: { inst_id: state.InstId!, token },
  });
  useEffect(() => {
    if (token && state.InstId) {
      GetAcdYears().then(({ data }) => {
        if (data) {
          setResponseType(
            data.GetAcdYrsByInstId.map((acdYear) => ({
              label: acdYear.acd_yr,
              value: acdYear.id,
              isChecked: false,
            }))
          );
        }
      });
    }
  }, [token, state.InstId, GetAcdYears]);
  return { academicYearResponse: { data, loading, error, responseType } };
};

export default useAcademicYears;
