import { gql } from "@apollo/client";

export const GetMstInstDepts = gql`
  query GetMstInstDepts(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $name: String
    $orderBy: MsgMstInstDeptOrder
  ) {
    GetMstInstDepts(
      token: $token
      inst_id: $inst_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: { or: [{ deptDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          dept_desc
          dept_short_desc
          dept_idx
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const GetDeptIdByName = gql`
  query GetDeptIdByName($dept_name: String!, $inst_id: ID!, $token: String!) {
    GetDeptIdByName(dept_name: $dept_name, inst_id: $inst_id, token: $token)
  }
`;
