import { gql } from "@apollo/client";

export const UpdateInst = gql`
  mutation UpdateInst(
    $id: ID!
    $token: String!
    $input: UpdateMsgMstInstInput!
    $user_details: SubscribedUser!
  ) {
    UpdateInst(
      id: $id
      token: $token
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeleteInstById = gql`
  mutation DeleteInstById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstById(inst_id: $id, token: $token, user_details: $user_details)
  }
`;

export const ReCalculateSummaryCounts = gql`
  mutation ReCalculateSummaryCounts(
    $token: String!
    $inst_id: ID!
    $acd_yr_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReCalculateSummaryCounts(
      token: $token
      inst_id: $inst_id
      acd_yr_id: $acd_yr_id
      user_details: $user_details
    )
  }
`;

export const UpdateInstTimeZoneAndBdayMsgTime = gql`
  mutation UpdateInstTimeZoneAndBdayMsgTime(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: TimeZoneAndBdMsgTime!
  ) {
    UpdateInstTimeZoneAndBdayMsgTime(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    )
  }
`;
