import { TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Avatar from "../../../../../images/Avatar.svg";
import useStudentDatabyId from "../../../../../customhooks/useStudentDataById";
import { EMPTY_STRING, NOTALLOCATED } from "../../../../../utils/constants";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { toStandardDate } from "../../../../../utils/UtilFunctions";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import { AppContext } from "../../../../../context/context";

const StudentAcademicDetails = () => {
  const { studentFormData, studentData } = useStudentDatabyId();
  const { state } = useContext(AppContext);
  const [studentDetails, setStudentDetails] = useState({
    std_adm_no: "",
    std_reg_no: "",
    std_dob: "",
    std_name: "",
    std_parent_name: "",
    branch_desc: "",
    class_desc: "",
    acd_yr: "",
    std_profile_filename: "",
  });
  const [imageString, setImageString] = useState("");

  const { InstDetails } = useInstDetails();

  useEffect(() => {
    if (studentFormData) {
      setStudentDetails({
        branch_desc: studentFormData.branch,
        class_desc: studentFormData.class,
        std_name: studentFormData.std_name,
        std_adm_no: studentFormData.std_adm_no,
        std_dob: studentFormData.std_dob,
        std_parent_name: studentFormData.parent_name,
        std_reg_no: studentFormData.reg_number,
        acd_yr: studentFormData.acd_yr,
        std_profile_filename: studentFormData.std_profile_filename,
      });
    }
  }, [studentFormData, studentData.data]);

  useEffect(() => {
    if (
      studentDetails.std_profile_filename !== EMPTY_STRING &&
      InstDetails.data
    ) {
      const studentProfilePic = `${InstDetails.data?.nodes[0]?.inst_name}/students/${state.studentId}/std_profile_pic/std_profile_pic`;

      // eslint-disable-next-line
      const ers = getDownloadUrl(studentProfilePic, setImageString, false);
    }
  }, [
    studentDetails.std_profile_filename,
    studentFormData,
    studentData.data,
    InstDetails.data,
    state.studentId,
  ]);

  const { branchLabel, classLabel } = useInstLabels();
  return (
    <>
      <div className="student-preview__details row g-0">
        <div className="col">
          <TextField
            className="student-preview__details--textfield"
            label="Admission Number"
            InputLabelProps={{ shrink: true }}
            value={studentDetails.std_adm_no}
            disabled
          />
          {studentDetails.branch_desc !== NOTALLOCATED && (
            <TextField
              className="student-preview__details--textfield"
              label={branchLabel}
              InputLabelProps={{ shrink: true }}
              value={studentDetails.branch_desc}
              disabled
            />
          )}
        </div>
        <div className="col">
          <TextField
            className="student-preview__details--textfield"
            label="Name"
            InputLabelProps={{ shrink: true }}
            value={studentDetails.std_name}
            disabled
          />
          <TextField
            className="student-preview__details--textfield"
            label="Father Name"
            InputLabelProps={{ shrink: true }}
            value={studentDetails.std_parent_name}
            disabled
          />
          {studentDetails.class_desc !== NOTALLOCATED && (
            <TextField
              className="student-preview__details--textfield"
              label={classLabel}
              InputLabelProps={{ shrink: true }}
              value={studentDetails.class_desc}
              disabled
            />
          )}
        </div>
        <div className="col">
          <TextField
            className="student-preview__details--date"
            label="DOB"
            InputLabelProps={{ shrink: true }}
            value={toStandardDate(studentDetails.std_dob!)}
            disabled
          />
          <TextField
            className="student-preview__details--textfield"
            label="Academic Year"
            InputLabelProps={{ shrink: true }}
            value={studentDetails.acd_yr}
            disabled
          />
        </div>
        <div className="col-1 student-preview__image ">
          <img src={imageString ? imageString : Avatar} alt="/" />
        </div>
      </div>
    </>
  );
};

export default StudentAcademicDetails;
