import React, { useContext, useEffect, useRef, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "../../../../../../stories/Button/Button";
import { Title } from "../../../../../../stories/Title/Title";
import { Label } from "../../../../../../stories/Label/Label";
import { Autocomplete, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import {
  formAutoCompleteStyles,
  formAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteStyles";
import Input from "../../../../../../stories/Input/Input";
import { BasicDetailsProps } from "../BasicDetails";
import List from "./List";
import ReactModal from "react-modal";
import { ActiveAcdYrStyles } from "../../../../../styles/ModalStyles";
import Close from "../../../../../../images/Close.svg";
import useLoggedInUserDetails from "../../../../../../customhooks/useLoggedInUserDetails";
import { AppContext } from "../../../../../../context/context";
import { Operation, PageFor } from "../../../../../../utils/Enum.types";
import { academicYear } from "../../../../../../utils/FormTypes";
import { academic_validation } from "../../../../../../utils/validationRules";
import { updateInstAcademicYear } from "../../../../../../queries/institution/academicyear/mutations/update";
import { msgType, responseType } from "../../../../../../utils/Types";
import {
  EMPTY_STRING,
  emptyMessageType,
} from "../../../../../../utils/constants";
import useToken from "../../../../../../customhooks/useToken";
import { GetAcdYrsByInstId } from "../../../../../../queries/institution/queries/byId";
import { AcdYrDetailsByNode } from "../../../../../../queries/institution/academicyear/queries/byId";
import { AddInstAcdYr } from "../../../../../../queries/institution/academicyear/mutations/new";
import {
  isOptionEqualToValue,
  toInputStandardDate,
  toIsoDate,
} from "../../../../../../utils/UtilFunctions";
import dayjs from "dayjs";
import { Keys } from "../../../../../../utils/Enum.keys";
import MessageModal from "../../../../../../utils/MessageModal";
import LoadingModal from "../../../../../Modals/LoadingModal";
import {
  AcademicYearListData,
  AcademicYearListVars,
  AcademicYearNode,
  NodeVars,
} from "../../../../../../utils/Query.Types";
import { GetAcdYrActiveByInstId } from "../../../../../../queries/institution/academicyear/queries/index";
import useActiveAcademicYear from "../../../../../../customhooks/useActiveAcademicYear";
const AcademicYear = ({
  operation,
  onNext,
  onBack,
  setModal,
}: BasicDetailsProps) => {
  const classes = formAutoCompleteStyles();
  const textClasses = formAutoCompleteTextStyles();
  const [activeAcdYr, setActiveAcdYr] = useState(false);
  const { user_details } = useLoggedInUserDetails();
  const { state } = useContext(AppContext);
  const saveModalRef = useRef<HTMLButtonElement>(null);

  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [academicYearId, setAcademicYrId] = useState(0);
  const [selectedActiveAcademicYear, setSelectedActiveAcademicYear] =
    useState<responseType | null>(null);
  const [insideOperation, setInsideOperation] = useState(Operation.CREATE);
  const [formData, setFormData] = useState<academicYear>({
    AcdYear: "",
    startDate: "",
    endDate: "",
  });
  const startDateRef = useRef<HTMLInputElement>(null);
  const endDateRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);

  const acdYrRef = useRef<HTMLSelectElement>(null);
  const acdYrInputRef = acdYrRef?.current?.childNodes[0]?.childNodes[0]
    ?.childNodes[0] as HTMLInputElement;
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();

  const [GetAcdYears, { data: AcademicYearResponse }] = useLazyQuery<
    AcademicYearListData,
    AcademicYearListVars
  >(GetAcdYrsByInstId, {
    variables: { inst_id: state.InstId!, token },
  });
  const [GetAcdYear] = useLazyQuery<AcademicYearNode, NodeVars>(
    AcdYrDetailsByNode
  );
  const AcademicYearList1 = AcademicYearResponse
    ? AcademicYearResponse.GetAcdYrsByInstId
    : [];

  const upcommingAcademicYears = () => {
    var currentYear = new Date().getFullYear();

    return [...new Array(1).keys()].map((i) => {
      const start = dayjs(`4-1-${currentYear}`).add(i, "year").format();
      const end = dayjs(`03-31-${currentYear + 1}`)
        .add(i, "year")
        .format();

      return {
        label: `${dayjs(start).format("YYYY")}-${dayjs(end).format("YYYY")}`,
        value: {
          startDate: start,
          endDate: end,
        },
      };
    });
  };

  const years = [...upcommingAcademicYears()];
  const [updateAcademicYear, { loading: updationLoading }] = useMutation(
    updateInstAcademicYear,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [newAcademicYear, { loading: creationLoading }] = useMutation(
    AddInstAcdYr,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  useEffect(() => {
    if (formData.AcdYear) {
      const filteredAcademicYear = years.filter(
        ({ label }) => label === formData.AcdYear
      );
      setFormData({
        AcdYear: filteredAcademicYear[0]?.label,
        endDate: toInputStandardDate(filteredAcademicYear[0]?.value.endDate),
        startDate: toInputStandardDate(
          filteredAcademicYear[0]?.value.startDate
        ),
      });
    } // eslint-disable-next-line
  }, [formData.AcdYear]);

  const HandleRegister = async () => {
    if (
      activeAcademicYearData.data &&
      activeAcademicYearData.data.GetAcdYrActiveByInstId.id
    ) {
      onNext();
    } else {
      await newAcademicYear({
        variables: {
          token,
          user_details,
          input: {
            inst_id: state.InstId,
            acd_yr: formData.AcdYear,
            acd_st_date: toIsoDate(formData.startDate),
            acd_end_date: toIsoDate(formData.endDate),
          },
        },
        refetchQueries: [
          {
            query: GetAcdYrsByInstId,
            variables: { inst_id: state.InstId, token },
          },
        ],
      }).then(({ data }) => {
        if (data && data.AddInstAcdYr) {
          setMessage({
            flag: true,
            message: "Academic year added successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
    // backend api call
  };
  const HandleUpdate = async () => {
    const AyStartDate = new Date(formData.startDate).toISOString();
    const AyEndDate = new Date(formData.endDate).toISOString();
    await updateAcademicYear({
      variables: {
        id: academicYearId,
        token,
        acd_yr: formData.AcdYear,
        acd_st_date: AyStartDate,
        acd_end_date: AyEndDate,
        acd_is_curr_yr: false,
        user_details,
      },
      refetchQueries: [
        {
          query: GetAcdYrsByInstId,
          variables: { inst_id: state.InstId, token },
        },
        {
          query: AcdYrDetailsByNode,
          variables: { token, id: academicYearId },
        },
      ],
    }).then(({ data }) => {
      if (data)
        setMessage({
          message: "Successfully Updated  Academic year",
          flag: true,
          operation: Operation.UPDATE,
        });
    });
  };
  const handleClear = () => {
    setFormData({
      AcdYear: "",
      startDate: "",
      endDate: "",
    });
    setAcademicYrId(0);
  };
  const ActiveDropdown: responseType[] = AcademicYearList1.map((res) => ({
    label: res.acd_yr,
    value: res.id,
  }));
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    if (message.operation === Operation.CREATE) {
      onNext();
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
    if (acdYrInputRef) acdYrInputRef.focus();
  };

  useEffect(() => {
    if (token) {
      GetAcdYears();
    }
  }, [token, message, GetAcdYears]);
  const HandleActiveYearUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    const findRowData = AcademicYearList1?.find(
      ({ id }) =>
        selectedActiveAcademicYear &&
        Number(id) === Number(selectedActiveAcademicYear.value)
    );

    if (selectedActiveAcademicYear) {
      if (activeAcademicYearData.data) {
        await updateAcademicYear({
          variables: {
            token,
            inst_id: state.InstId,
            id: activeAcademicYearData.data.GetAcdYrActiveByInstId?.id,
            acd_is_curr_yr: false,
            acd_yr: activeAcademicYearData.data.GetAcdYrActiveByInstId?.acd_yr,
            acd_st_date:
              activeAcademicYearData.data.GetAcdYrActiveByInstId?.acd_st_date,
            acd_end_date:
              activeAcademicYearData.data.GetAcdYrActiveByInstId?.acd_end_date,
            user_details,
          },
          refetchQueries: [
            {
              query: GetAcdYrsByInstId,
              variables: { inst_id: state.InstId, token },
            },
            {
              query: AcdYrDetailsByNode,
              variables: {
                token,
                id: activeAcademicYearData.data.GetAcdYrActiveByInstId?.id,
              },
            },

            {
              query: GetAcdYrActiveByInstId,
              variables: {
                token,
                inst_id: state.InstId,
              },
            },
          ],
        }).then(async ({ data }) => {
          if (data) {
            await updateAcademicYear({
              variables: {
                token,
                inst_id: state.InstId!,
                id: selectedActiveAcademicYear?.value,
                acd_is_curr_yr: true,
                acd_yr: findRowData?.acd_yr,
                acd_st_date: toIsoDate(findRowData?.acd_st_date!),
                acd_end_date: toIsoDate(findRowData?.acd_end_date!),
                user_details,
              },
              refetchQueries: [
                {
                  query: GetAcdYrsByInstId,
                  variables: { inst_id: state.InstId, token },
                },
                {
                  query: AcdYrDetailsByNode,
                  variables: { token, id: selectedActiveAcademicYear.value },
                },
                {
                  query: GetAcdYrActiveByInstId,
                  variables: {
                    token,
                    inst_id: state.InstId,
                  },
                },
              ],
            });
          }
        });
      } else {
        updateAcademicYear({
          variables: {
            token,
            inst_id: state.InstId!,
            id: selectedActiveAcademicYear?.value,
            acd_is_curr_yr: true,
            acd_yr: findRowData?.acd_yr,
            acd_st_date: toIsoDate(findRowData?.acd_st_date!),
            acd_end_date: toIsoDate(findRowData?.acd_end_date!),
            user_details,
          },
          refetchQueries: [
            {
              query: GetAcdYrsByInstId,
              variables: { inst_id: state.InstId, token },
            },
            {
              query: AcdYrDetailsByNode,
              variables: { token, id: selectedActiveAcademicYear?.value },
            },
            {
              query: GetAcdYrActiveByInstId,
              variables: {
                token,
                inst_id: state.InstId,
              },
            },
          ],
        }).then(({ data }) => {
          if (data)
            setMessage({
              message: "Successfully Updated Active Academic year",
              flag: true,
              operation: Operation.UPDATE,
            });
        });
      }
    }
    setActiveAcdYr(!activeAcdYr);
  };

  const fetchDataForEit = (id: number) => {
    GetAcdYear({
      variables: {
        id,
        token,
      },
    }).then(({ data, error }) => {
      if (data && data.node) {
        setAcademicYrId(id);
        setFormData({
          AcdYear: data.node.acd_yr,
          endDate: toInputStandardDate(data.node.acd_end_date),
          startDate: toInputStandardDate(data.node.acd_st_date),
        });
      }
    });
    setInsideOperation(Operation.UPDATE);
  };
  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={academic_validation}
        onSubmit={
          insideOperation === Operation.UPDATE ? HandleUpdate : HandleRegister
        }
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="inst-details">
              <Title variant="subtitle1">Academic Year</Title>
              <div className="inst-details__form row g-0">
                <div className="col  inst-details__form--frame-left">
                  <Title variant="subtitle1">
                    {operation === Operation.CREATE
                      ? " Add Academic Year"
                      : "Update Academic Year"}
                  </Title>
                  <div className="inst-details__academics--add">
                    <div className="label-grid">
                      {insideOperation === Operation.CREATE ? (
                        <>
                          <Label>Choose Academic Year</Label>
                          <Autocomplete
                            classes={classes}
                            ref={acdYrRef!}
                            onKeyDown={(e: any) => {
                              if (e.key === Keys.ENTER) {
                                e.preventDefault();
                                if (formData.AcdYear) {
                                  startDateRef?.current?.focus();
                                }
                              }
                              if (e.key === Keys.BACKSPACE) {
                                setFormData((prev) => ({
                                  ...prev,
                                  AcdYear: EMPTY_STRING,
                                }));
                              }
                            }}
                            options={years}
                            openOnFocus
                            forcePopupIcon
                            value={
                              years?.find(
                                ({ label }) => label === formData.AcdYear
                              )! ?? null
                            }
                            onChange={(e, newValue) => {
                              if (newValue) {
                                setFormData((prev) => ({
                                  ...prev,
                                  AcdYear: newValue.label,
                                }));
                              } else {
                                setFormData((prev) => ({
                                  ...prev,
                                  AcdYear: EMPTY_STRING,
                                }));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                required={!formData?.AcdYear}
                                {...params}
                                autoFocus
                                fullWidth
                                classes={{
                                  root: textClasses.formControlRoot,
                                }}
                              />
                            )}
                          />
                        </>
                      ) : (
                        <Input
                          name="AcdYear"
                          type="text"
                          value={formData.AcdYear}
                          disabled
                        />
                      )}

                      <Label>Start Date</Label>
                      <Input
                        type="date"
                        value={formData.startDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          setFormData((prev) => ({
                            ...prev,
                            startDate: e.target.value,
                          }));
                        }}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            endDateRef?.current?.focus();
                          }
                        }}
                      />
                      <Label>End Date</Label>
                      <Input
                        type="date"
                        value={formData.endDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          meta.handleChange(e);
                          setFormData((prev) => ({
                            ...prev,
                            endDate: e.target.value,
                          }));
                        }}
                        inputRef={endDateRef}
                        onKeyDown={(e: React.KeyboardEvent) => {
                          if (e.key === Keys.ENTER) {
                            e.preventDefault();
                            if (saveRef.current) saveRef.current.focus();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col inst-details__form--frame-right">
                  <Title variant="subtitle1">List of Academic Year</Title>
                  <List
                    handleEdit={fetchDataForEit}
                    onlyTable={false}
                    setModal={setModal}
                    pageType={PageFor.GENERAL}
                  />
                </div>
              </div>
              {activeAcademicYearData.data &&
              activeAcademicYearData.data.GetAcdYrActiveByInstId.id ? (
                <Button
                  mode="save"
                  buttonref={saveRef}
                  type="button"
                  onClick={() => onNext()}
                />
              ) : (
                <>
                  <Button mode="save" buttonref={saveRef} type="submit" />
                  <Button mode="next" onClick={onNext} />
                </>
              )}
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="previous"
                type="button"
                onClick={() => {
                  handleClear();
                  onBack?.();
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <LoadingModal flag={creationLoading || updationLoading} />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default AcademicYear;
