import { gql } from "@apollo/client";

export const GetMstInstSections = gql`
  query GetMstInstSections(
    $token: String!
    $id: ID!
    $after: Cursor
    $query_type: SectionQueryType!
    $first: Int
    $name: String
    $orderBy: MsgMstInstSectionOrder
  ) {
    GetMstInstSections(
      token: $token
      id: $id
      first: $first
      after: $after
      query_type: $query_type
      orderBy: $orderBy
      where: { or: [{ sectionDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          section_desc
          section_short_desc
          section_idx
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const SectionIdByName = gql`
  query SectionIdByName(
    $token: String!
    $section_name: String!
    $semester_id: ID!
    $inst_id: ID!
  ) {
    GetSectionIdByName(
      token: $token
      section_name: $section_name
      semester_id: $semester_id
      inst_id: $inst_id
    )
  }
`;
