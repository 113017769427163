import React, { useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import { ActiveAcdYrStyles } from "../../../../styles/ModalStyles";
import Add from "./Add";
import TreeView from "../Treeview";
import List from "./List";
import { Breadcrumbs } from "@mui/material";
import { Direction, Operation } from "../../../../../utils/Enum.types";
import useToken from "../../../../../customhooks/useToken";
import {
  BranchListData,
  BranchListEdges,
  BranchListVars,
  BranchQueryType,
  MsgMstInstBranchOrderField,
} from "../../../../../utils/Query.Types";
import { EMPTY_STRING, emptyMessageType } from "../../../../../utils/constants";
import { msgType } from "../../../../../utils/Types";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { GetMstInstBranches } from "../../../../../queries/institution/branch/query";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DeleteInstBranchById,
  ReOrderInstBranch,
} from "../../../../../queries/institution/branch/mutations/update";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import DeleteModal from "../../../../Modals/DeleteModal";
import useInstLabels from "../../../../../customhooks/useInstLabels";

const Branch = () => {
  const navigate = useNavigate();

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reOrder, setReOrder] = useState(false);

  const [branchId, setBranch] = useState(0);

  const [operation, setOperation] = useState(Operation.CREATE);
  const [localItems, setLocalItems] = useState<BranchListEdges[]>([]);

  const { instId, deptId } = useParams();
  const { token } = useToken();

  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const { user_details } = useLoggedInUserDetails();

  const [GetBranches] = useLazyQuery<BranchListData, BranchListVars>(
    GetMstInstBranches
  );

  const { branchLabel } = useInstLabels();
  const [ReOrderBranch, { loading: reorderLoading }] = useMutation(
    ReOrderInstBranch,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [DeleteBranch, { loading: DeleteLoading }] = useMutation(
    DeleteInstBranchById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const hanldeEdit = (id: number) => {
    setBranch(id);

    if (id > 0) {
      setOperation(Operation.UPDATE);
    } else {
      setOperation(Operation.CREATE);
    }
    setAddModal(!addModal);
  };

  const hanldeDelete = (id: number) => {
    setBranch(id);
    setDeleteModal(!deleteModal);
  };

  const HandleDelete = async (branch_id: number) => {
    setDeleteModal(!deleteModal);
    await DeleteBranch({
      variables: {
        token,
        branch_id,
        inst_id: instId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstBranches,
          variables: {
            token,
            query_type: BranchQueryType.BRANCH_BY_DEPT_ID,
            id: deptId,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstBranchOrderField.BRANCH_IDX,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `${branchLabel} Deleted successfully`,
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
  };
  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    ReOrderBranch({
      variables: {
        token,
        input: localItems?.map(({ node: res }, index) => ({
          id: res.id,
          branch_idx: index + 1,
        })),
        inst_id: instId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstBranches,
          variables: {
            token,
            query_type: BranchQueryType.BRANCH_BY_DEPT_ID,
            id: deptId,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstBranchOrderField.BRANCH_IDX,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.ReOrderInstBranch) {
        setReOrder(false);
        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="inst-config__masters">
        <Title>{branchLabel}</Title>
        <Breadcrumbs aria-label="breadcrumb" className="inst-breadcrumbs">
          <NavLink to="/"></NavLink>
        </Breadcrumbs>

        <div className="inst-config__masters--datablock row g-0">
          <div className="col inst-config__masters--datablock--left">
            <List
              handleEdit={hanldeEdit}
              handleDelete={hanldeDelete}
              reOrder={reOrder}
              localItemsState={{ localItems, setLocalItems }}
            />
          </div>
          <div className="col inst-config__masters--datablock--right">
            <TreeView />
          </div>
        </div>

        {reOrder ? (
          <Button mode="save" onClick={HandleSaveDragAndDrop} />
        ) : (
          <>
            <Button
              mode="addnew"
              onClick={() => {
                setBranch(0);
                setOperation(Operation.CREATE);
                setAddModal(!addModal);
              }}
            />
            <Button mode="re-order" onClick={() => setReOrder(!reOrder)} />
          </>
        )}

        <Button
          mode="back"
          onClick={() => {
            if (reOrder) {
              GetBranches({
                variables: {
                  token,
                  query_type: BranchQueryType.BRANCH_BY_DEPT_ID,
                  id: deptId!,
                  after: null,
                  first: null,
                  name: EMPTY_STRING,
                  orderBy: {
                    direction: Direction.ASC,
                    field: MsgMstInstBranchOrderField.BRANCH_IDX,
                  },
                },
              }).then(({ data }) => {
                if (data && data.GetMstInstBranches) {
                  setLocalItems(
                    data.GetMstInstBranches.edges.filter(
                      ({ node }) => node != null
                    )
                  );
                }
              });
              setReOrder(false);
            } else navigate(-1);
          }}
        />
      </div>
      <ReactModal
        isOpen={addModal}
        ariaHideApp={false}
        style={ActiveAcdYrStyles}
      >
        <Add
          setModal={setAddModal}
          operation={operation}
          id={branchId}
          handleEdit={hanldeEdit}
        />
      </ReactModal>

      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={branchId}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={reorderLoading || DeleteLoading} />
    </>
  );
};

export default Branch;
