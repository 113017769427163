import { gql } from "@apollo/client";

export const AddInstSemester = gql`
  mutation AddInstSemester(
    $input: CreateMsgMstInstSemesterInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstSemester(token: $token, input: $input, user_details: $user_details)
  }
`;
