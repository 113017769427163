import React, { useEffect, useState } from "react";
import Avatar from "../../../../../../images/Avatar.svg";
import { TextField } from "@mui/material";
import useStudentDatabyId from "../../../../../../customhooks/useStudentDataById";
import { EMPTY_STRING } from "../../../../../../utils/constants";
import { getDownloadUrl } from "../../../../../../utils/DownloadFile";

const StudentDetails = () => {
  const [imageString, setImageString] = useState("");
  const studentDetails = [
    {
      label: "Adm No.",
      value: "std_adm_no",
    },
    {
      label: "Name",
      value: "std_name",
    },
    {
      label: "Mobile",
      value: "std_mobile",
    },

    {
      label: "Parent Name",
      value: "parent_name",
    },
    {
      label: "Branch",
      value: "branch",
    },
  ];

  const { studentFormData } = useStudentDatabyId();

  useEffect(() => {
    if (studentFormData.std_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        studentFormData.std_profile_filename,
        setImageString,
        false
      );
    }
  }, [studentFormData.std_profile_filename, studentFormData]);

  return (
    <>
      <div className="row g-0 channel-student-data">
        <div className="col channel-student-data__flex">
          {studentDetails.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <TextField
                  label={data.label}
                  value={studentFormData[data.value]}
                  className="channel-student-data__textfield"
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </React.Fragment>
            );
          })}
        </div>
        <div className="col-2 channel-student-data__profile">
          {imageString ? (
            <img src={imageString} alt="" />
          ) : (
            <img src={Avatar} alt="/" />
          )}
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
