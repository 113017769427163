import { gql } from "@apollo/client";

export const GetAcctOnlineICICIPayments = gql`
  query GetAcctOnlineICICIPayments(
    $token: String!
    $inst_id: ID!
    $acct_std_demand_id: ID!
    $query_type: AcctOnlinePaymentQueryType!
    $orderBy: MsgAcctOnlinePaymentsOrder
    $after: Cursor
    $first: Int
    # $responseCodeNEQ: String
    $transactionDateContainsFold: String
    $searchString: String
  ) {
    GetAcctOnlineICICIPayments(
      token: $token
      inst_id: $inst_id
      acct_std_demand_id: $acct_std_demand_id
      query_type: $query_type
      orderBy: $orderBy
      after: $after
      first: $first
      where: {
        or: [
          # {
          #   responseCode: $responseCode
          #   transactionDateContainsFold: $transactionDateContainsFold
          #   voucherNoContainsFold: $searchString
          # }
          # { responseCodeNEQ: $responseCodeNEQ }
          {
            uniqueRefNumberContainsFold: $searchString
            transactionDateContainsFold: $transactionDateContainsFold
          }
          { transactionDateContainsFold: $transactionDateContainsFold }
        ]
      }
    ) {
      edges {
        node {
          id
          uuid
          transaction_status
          response_code
          response_code_str
          unique_ref_number
          service_tax_amount
          processing_fee_amount
          total_amount
          transaction_amount
          transaction_date
          payment_mode
          sub_merchant_id
          reference_no
          merchant_eazypay_id
          mandatory_fields
          optional_fields
          fee_total_demand
          fee_paid
          created_at
          inst_id
          acct_std_demand_id
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const GetEncodedValue = gql`
  query GetEncodedValue($inst_id: ID!, $str_to_encode: String!) {
    GetEncodedValue(inst_id: $inst_id, str_to_encode: $str_to_encode)
  }
`;

export const OnlineTransactionById = gql`
  query OnlineTransactionById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MsgAcctOnlinePayments {
        uuid
        transaction_status
        response_code
        response_code_str
        unique_ref_number
        service_tax_amount
        processing_fee_amount
        total_amount
        transaction_amount
        transaction_date
        payment_mode
        sub_merchant_id
        reference_no
        merchant_eazypay_id
        mandatory_fields
        optional_fields
        fee_total_demand
        fee_paid
        created_at
        inst_id
        acct_std_demand_id
        std_demand_details {
          id
          std_name
          std_id
          std_roll_no
          std_father_name
          std_mother_name
          std_place
          class_desc
          mobile_no
          fee_total_demand
          fee_total_paid
          fee_balance
          inst_id
        }
      }
    }
  }
`;
