import { gql } from "@apollo/client";
export const UpdateInstClass = gql`
  mutation UpdateInstClass(
    $id: ID!
    $token: String!
    $inst_id: ID!
    $input: UpdateMsgMstInstClassInput!
    $user_details: SubscribedUser!
  ) {
    UpdateInstClass(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstClassById = gql`
  mutation DeleteInstClassById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstClassById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ReOrderInstClass = gql`
  mutation ReOrderInstClass(
    $token: String!
    $input: [ReOrderMsgMstInstClassInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstClass(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
