import { gql } from "@apollo/client";

export const SectionByNode = gql`
  query SectionDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstSection {
        id
        inst_id
        section_desc
        section_short_desc
      }
    }
  }
`;
