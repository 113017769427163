import { gql } from "@apollo/client";

export const AcctMakeIciciOnlinePayment = gql`
  mutation AcctMakeIciciOnlinePayment(
    $inst_id: ID!
    $acct_std_demand_id: ID!
    $fee_paid: String!
    $payment_date: Time!
    $payment_mode: String!
  ) {
    AcctMakeIciciOnlinePayment(
      inst_id: $inst_id
      acct_std_demand_id: $acct_std_demand_id
      fee_paid: $fee_paid
      payment_date: $payment_date
      payment_mode: $payment_mode
    ) {
      encrypted_mandatory_fields
      encrypted_optional_fields
      encrypted_return_url
      encrypted_sub_merchant_id
      encrypted_transaction_amount
      encrypted_payment_mode
      encrypted_reference_no
    }
  }
`;
