import Modal from "react-modal";
import Close from "../../images/Close.svg";
import Warning from "../../images/Warning.svg";
import { Button } from "../../stories/Button/Button";
import { ErrorModalStyles, WaiveOffModalStyles } from "../styles/ModalStyles";
import { useState } from "react";
import Input from "../../stories/Input/Input";
import { DELETE } from "../../utils/constants";
import { Title } from "../../stories/Title/Title";
interface Props {
  modalFlag: boolean;
  setModalFlag: (modalFlag: boolean) => void;
  id: number | number[];
  handleDelete?: (id: number) => void;
  handleMultipleDelete?: (ids: number[]) => void;

  children?: any;
}
const DeleteModal = ({
  modalFlag,
  setModalFlag,
  handleDelete,
  handleMultipleDelete,
  id,
  children,
}: Props) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={modalFlag}
        style={WaiveOffModalStyles}
        ariaHideApp={false}
      >
        <div className="delete-modal">
          <div className="delete-modal__title">
            <Title>
              <img src={Warning} alt="/" /> Are you sure?
            </Title>
            <img
              className="close-icon"
              src={Close}
              alt="/"
              onClick={() => {
                setInputValue("");
                setModalFlag(!modalFlag);
              }}
            />
          </div>
          <div className="delete-modal__body">
            <span>Do you really want to delete these records?</span>

            {/* Shiva: to be removed after css */}
            <br />
            <span>
              Please enter <b className="nodata">'DELETE' </b>to confirm the
              deletion.
            </span>
            <Input
              value={inputValue}
              autoFocus
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
          </div>
          <Button
            disabled={inputValue !== DELETE}
            onClick={() => {
              setInputValue("");
              Array.isArray(id)
                ? handleMultipleDelete?.(id)
                : handleDelete?.(id);
            }}
            mode="delete"
          />
          <Button
            onClick={() => {
              setInputValue("");
              setModalFlag(!modalFlag);
            }}
            mode="cancel"
          />
        </div>
      </Modal>
      {children!}
    </>
  );
};

export default DeleteModal;
