import { gql } from "@apollo/client";

export const GetMstInstSemesters = gql`
  query GetMstInstSemesters(
    $token: String!
    $id: ID!
    $after: Cursor
    $query_type: SemesterQueryType!
    $first: Int
    $name: String
    $orderBy: MsgMstInstSemesterOrder
  ) {
    GetMstInstSemesters(
      token: $token
      id: $id
      first: $first
      after: $after
      query_type: $query_type
      orderBy: $orderBy
      where: { or: [{ semDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          sem_desc
          sem_short_desc
          sem_idx
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const SemIdByName = gql`
  query GetSemesterIdByName(
    $token: String!
    $inst_id: ID!
    $class_id: ID!
    $semester_name: String!
  ) {
    GetSemesterIdByName(
      token: $token
      inst_id: $inst_id
      class_id: $class_id
      semester_name: $semester_name
    )
  }
`;
