import { ApolloClient, InMemoryCache } from "@apollo/client";

import { query } from "../utils/constants";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "0ec9c4fbef6920cbf42d680afcc17ab9Tz04NjUzMyxFPTE3NDIxMDEyMTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export const client = new ApolloClient({
  uri: process.env.React_App_Backend_End_Point + query,
  cache: new InMemoryCache({}),

  // defaultOptions: { query: { fetchPolicy: "network-only" } },
});
