import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// this is for production build, NEVER USE THIS
// const firebaseConfig = {
//   apiKey: "AIzaSyBaQyABwqS8GF5dQXK7xGWkd9_ysyFiWQE",
//   authDomain: "esandesh-45d2e.firebaseapp.com",
//   projectId: "esandesh-45d2e",
//   storageBucket: "esandesh-45d2e.appspot.com",
//   messagingSenderId: "468007647710",
//   appId: "1:468007647710:web:acdf60da2c8baef804854f",
//   measurementId: "G-8DMCV6WWEE",
// };

// dev Branch firebase config
export const firebaseConfig = {
  apiKey: "AIzaSyCPpOMlza_ZclZAPiMt7hEC1H8_xeU3eRg",
  authDomain: "myeduate-48fee.firebaseapp.com",
  projectId: "myeduate-48fee",
  storageBucket: "myeduate-48fee.appspot.com",
  messagingSenderId: "512000334631",
  appId: "1:512000334631:web:7b4b3eb5e6e6467603a702",
  measurementId: "G-XD4WRX9H8E",
};

// my personal
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBXPLL49Bih6pK-z5BqBtW_pvAcnh7slpU",
//   authDomain: "eduate2.firebaseapp.com",
//   projectId: "eduate2",
//   storageBucket: "eduate2.appspot.com",
//   messagingSenderId: "735882090232",
//   appId: "1:735882090232:web:01b4872ff4e1757c7e0d0d",
//   measurementId: "G-1FPBB30H9C",
// };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db };
export { storage as default };
