import { gql } from "@apollo/client";

export const GetBranchNamesByDeptIds = gql`
  query GetBranchNamesByDeptIds($dept_id: [ID!]!, $token: String!) {
    GetBranchNamesByDeptIds(token: $token, dept_id: $dept_id) {
      id
      branch_desc
      dept_id
    }
  }
`;
