export enum AcctOnlinePaymentQueryType {
  ON_PY_RECORDS = "ON_PY_RECORDS",
  ON_PY_SUCCESS_RECORDS = "ON_PY_SUCCESS_RECORDS",
  ON_PY_FAILED_RECORDS = "ON_PY_FAILED_RECORDS",
  ON_PY_IN_COMPLETE_RECORDS = "ON_PY_IN_COMPLETE_RECORDS",
}

export enum StdDemandQueryType {
  ALL_STD_DEMANDS = "ALL_STD_DEMANDS",
  BY_MOBILE_NO = "BY_MOBILE_NO",
  BY_STD_ID = "BY_STD_ID",
  BY_STD_ROLL_NO = "BY_STD_ROLL_NO",
}
