import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";

import { Autocomplete, Checkbox, Drawer, TextField } from "@mui/material";
import Modal from "react-modal";

import { useNavigate, useParams } from "react-router-dom";
import { StudentModalStyles } from "../../../../styles/ModalStyles";

import Close from "../../../../../images/Close.svg";
import { sectionAllocationStyles } from "../../../../styles/DrawerStyles";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import { AppContext } from "../../../../../context/context";
import { msgType, responseType } from "../../../../../utils/Types";
import {
  Direction,
  InstitutionDetails,
  Operation,
  StudentReportType,
  TableHeaders,
  studentQueryTypes,
} from "../../../../../utils/Enum.types";
import useStudentsbyNewApi, {
  StudentEdges,
} from "../../../../../customhooks/useStudentByNodeApi";
import useToken from "../../../../../customhooks/useToken";
import useNotAlloactedIdFromInst from "../../../../../customhooks/useNotAlloactedIdFromInst";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useDropdownData from "../../../../../customhooks/useDropDown";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../../utils/constants";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../../utils/UtilFunctions";
import { GetStudents } from "../../../../../queries/students/list";
import { Keys, SortBy } from "../../../../../utils/Enum.keys";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import Input from "../../../../../stories/Input/Input";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import StudentTotalCount from "../Components/StudentsTotalCount";
import { Label } from "../../../../../stories/Label/Label";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import { UpdateStdSectionByIds } from "../../../../../queries/institution/sections/mutations/update";
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const InterChangeSections = () => {
  const navigate = useNavigate();
  //Styles
  const classes = ListAutoCompleteStyles();
  const { state } = useContext(AppContext);
  const textClasses = ListAutoCompleteTextStyles();
  //usestates
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [sectionStudents, setSectionStudents] = useState<StudentEdges[]>([]);
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {USE_SECTION_KEY &&
            sectionDropDown.length > 0 &&
            interchangeSection === false &&
            leftSectionSelected && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            )}
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
          {USE_BRANCH_KEY ? (
            <TableCell className="studentlist__table--status">
              {branchLabel}
            </TableCell>
          ) : null}
          {USE_CLASS_KEY ? (
            <TableCell className="studentlist__table--status">
              {classLabel}
            </TableCell>
          ) : null}
          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };
  const EnhancedTableHeadForDrawer = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TableHeaders.SLNO}</TableCell>
          <TableCell>{TableHeaders.ADMISSION_NUMBER}</TableCell>
          <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>

          {USE_SECTION_KEY ? (
            <TableCell className="studentlist__table--status">
              {sectionLabel}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };

  //useStates
  const { token } = useToken();
  const { instId } = useParams();
  const drawerClasses = sectionAllocationStyles();
  const [interchangeSection, setInterchangeSection] = useState(false);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [sectionHasNextPage, setSectionHasNextPage] = useState<boolean>(true);
  const [sectionEndCursor, setSectionEndCursor] = useState<string | null>(null);
  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );

  const [leftSectionSelected, setLeftSectionSelected] =
    useState<responseType | null>(null);
  const [rightSectionSelected, setRightSectionSelected] =
    useState<responseType | null>(null);

  const [searchData, setSearchData] = useState("");
  const [viewStudentModal, setViewStudentModal] = useState(false);
  //useRefs

  const { NotAllocatedDeptId } = useNotAlloactedIdFromInst();
  const { user_details } = useLoggedInUserDetails();
  //useMutations
  const [UpdateStdSection, { loading: updationLoading }] = useMutation(
    UpdateStdSectionByIds,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
    masterIds,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  //Left table
  const { StudentsData: LeftStudentsData } = useStudentsbyNewApi(
    USE_DEPARTMENT_KEY
      ? departmentSelected
        ? departmentSelected.value
        : 0
      : NotAllocatedDeptId,
    masterIds.branchId,
    masterIds.classId,
    masterIds.semesterId,
    leftSectionSelected ? leftSectionSelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentReportType.ELIGIBLE,
    0,
    []
  );
  //pagination for leftSection

  // Right table
  const { StudentsData: RightStudentsData } = useStudentsbyNewApi(
    0,
    0,
    0,
    0,
    rightSectionSelected ? rightSectionSelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentReportType.ELIGIBLE,
    0,
    []
  );
  //pagination for modalview
  const righttotalCount = RightStudentsData.data?.GetStudents?.totalCount;

  useEffect(() => {
    if (LeftStudentsData.data && !LeftStudentsData.loading && token) {
      const newData = LeftStudentsData.data.GetStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: false, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = students.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setStudents(updatedNewData);
      } else {
        setStudents(newData);
      }
      setEndCursor(LeftStudentsData.data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [LeftStudentsData.data, LeftStudentsData.loading, token]);
  useEffect(() => {
    if (RightStudentsData.data && !RightStudentsData.loading && token) {
      const newData = RightStudentsData.data.GetStudents.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: false, // set default value of isChecked to true
        },
      }));

      if (sectionEndCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        const filteredStudents = sectionStudents.filter(
          (student) => !student.node.isChecked
        );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = filteredStudents.find(
            (student) => student.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                isChecked: false,
              },
            };
          }
          return newStudent;
        });
        setSectionStudents(updatedNewData);
      } else {
        setSectionStudents(newData);
      }
      setSectionEndCursor(
        RightStudentsData.data.GetStudents.pageInfo.endCursor
      );
    } // eslint-disable-next-line
  }, [RightStudentsData.data, RightStudentsData.loading, token]);

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setStudents([]);
      setInterchangeSection(!interchangeSection);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !LeftStudentsData.loading) {
        LeftStudentsData.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudents.edges;
            const pageInfo = fetchMoreResult.GetStudents.pageInfo;
            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudents: {
                edges: [...students!, ...newEdges],
                pageInfo,
                totalCount: LeftStudentsData.data?.GetStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  const rightHandleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight + 2 >= getModifiedScrollHeight(scrollHeight)) {
      if (sectionHasNextPage && !RightStudentsData.loading) {
        RightStudentsData.fetchMore({
          variables: {
            first: ROWS_PER_PAGE,
            after: sectionEndCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetStudents.edges;
            const pageInfo = fetchMoreResult.GetStudents.pageInfo;
            setSectionEndCursor(pageInfo.endCursor);
            setSectionHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck = prevResult.GetStudents.edges.filter(
              ({ node: { id } }) =>
                newEdges.findIndex(
                  ({ node: { id: newId } }) => newId === id
                ) !== -1
            );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetStudents: {
                edges: [...sectionStudents!, ...newEdges],
                pageInfo,
                totalCount: RightStudentsData.data?.GetStudents.totalCount!,
              },
            };
          },
        });
      }
    }
  };

  const handleClick = (
    data: StudentEdges,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const mappedDataLeft = students?.map((row) => {
      if (row.node.id === data.node.id) {
        return {
          ...row,
          node: {
            ...row.node,
            isChecked: (e.target as HTMLInputElement).checked ? true : false,
          },
        };
      } else {
        return row;
      }
    });

    setStudents(mappedDataLeft);
  };
  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setLeftSectionSelected(null);
    setRightSectionSelected(null);
    setStudents([]);
    setSearchData("");
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = students?.map((edge) => ({
      ...edge,
      node: {
        ...edge.node,
        isChecked: event.target.checked,
      },
    }));
    setStudents(newSelecteds);
  };

  const handleAllocationOfStudentsToSection = () => {
    UpdateStdSection({
      variables: {
        token,
        inst_id: instId!,
        user_details,
        student_ids: students
          .filter((student) => student.node.isChecked)
          ?.map(({ node }) => node.id),
        section_id: rightSectionSelected?.value,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.STD_NAME,
            input: {
              ids: [rightSectionSelected?.value],
              std_query_type: studentQueryTypes.STATUS_EQ_ACTIVE_BY_SECTION_ID,
            },
          },
        },
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.STD_NAME,
            input: {
              ids: [leftSectionSelected?.value],
              std_query_type: studentQueryTypes.STATUS_EQ_ACTIVE_BY_SECTION_ID,
            },
          },
        },
        {
          query: GetStudents,
          variables: {
            admNo: EMPTY_STRING,
            after: null,
            fin_yr_id: state.ActiveFinYr ? state.ActiveFinYr.id : 0,
            first: ROWS_PER_PAGE,
            name: EMPTY_STRING,
            token,
            direction: Direction.ASC,
            sortBy: SortBy.STD_NAME,
            input: {
              ids: [instId],
              std_query_type: studentQueryTypes.BY_SECTION_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Students Section Interchanged Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
        clear();
      }
    });
  };
  const clearRestInstDetails = (type: InstitutionDetails) => {
    switch (type) {
      case InstitutionDetails.DEPARTMENT:
        setBranchSelected(null);
        setClassSelected(null);
        setSemesterSelected(null);
        setLeftSectionSelected(null);
        break;
      case InstitutionDetails.BRANCH:
        setClassSelected(null);
        setSemesterSelected(null);
        setLeftSectionSelected(null);
        break;
      case InstitutionDetails.CLASS:
        setSemesterSelected(null);
        setLeftSectionSelected(null);
        break;
      case InstitutionDetails.SEMESTER:
        setLeftSectionSelected(null);
        break;
      default:
        break;
    }
  };
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  return (
    <>
      <form className="row g-0 section-allocation__options">
        <div className="col-1 studentlist__search">
          <Input
            id="search"
            type="text"
            placeholder="Search..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchData(e.target.value);
            }}
            value={searchData}
            onKeyDown={handleFormEvent}
          />
        </div>

        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={departmentDropDown!}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, departmentSelected)
              }
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && departmentSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setDepartmentSelected(null);
                }
              }}
              openOnFocus
              value={departmentSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setDepartmentSelected(newValue);
                } else {
                  setDepartmentSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.DEPARTMENT);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={departmentLabel}
                  id="outlined Departments"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, branchSelected)
              }
              options={branchDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && branchSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setBranchSelected(null);
                }
              }}
              openOnFocus
              value={branchSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setBranchSelected(newValue);
                } else {
                  setBranchSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.BRANCH);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={branchLabel}
                  id="outlined Branches"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_CLASS_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, classSelected)
              }
              options={classDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setClassSelected(null);
                }
              }}
              openOnFocus
              value={classSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClassSelected(newValue);
                } else {
                  setClassSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.CLASS);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={classLabel}
                  id="outlined Classes"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, semesterSelected)
              }
              options={semesterDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && semesterSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setSemesterSelected(null);
                }
              }}
              openOnFocus
              value={semesterSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setSemesterSelected(newValue);
                } else {
                  setSemesterSelected(null);
                }
                clearRestInstDetails(InstitutionDetails.SEMESTER);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={semesterLabel}
                  id="outlined Semesters"
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          </div>
        ) : null}
        <div className="col-1">
          {USE_SECTION_KEY ? (
            <Autocomplete
              classes={classes}
              isOptionEqualToValue={(option) =>
                isOptionEqualToValue(option, leftSectionSelected)
              }
              options={sectionDropDown}
              onKeyDown={(e) => {
                if (e.key === Keys.ENTER && classSelected) {
                  handleMUISelectEvent(e);
                }
                if (e.key === Keys.BACKSPACE) {
                  setLeftSectionSelected(null);
                }
              }}
              openOnFocus
              value={leftSectionSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setLeftSectionSelected(newValue);
                } else {
                  setLeftSectionSelected(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label={`From ${sectionLabel}`}
                  classes={{ root: textClasses.formControlRoot }}
                />
              )}
            />
          ) : null}
        </div>
      </form>
      <div className="section-allocation__tableblock">
        {!students?.length ? (
          <b className="nodata">Sorry no students found </b>
        ) : (
          <>
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHead
                  numSelected={
                    students?.filter((data) => data.node.isChecked).length
                  }
                  onSelectAllClick={(event) => handleSelectAllClick(event)}
                  rowCount={students?.length}
                />
                <TableBody>
                  {students?.map((response, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={students[index]?.node.isChecked}
                        tabIndex={-1}
                        key={response.node.id}
                        selected={students[index]?.node.isChecked}
                      >
                        {USE_SECTION_KEY &&
                          sectionDropDown.length > 0 &&
                          leftSectionSelected && (
                            <TableCell
                              padding="checkbox"
                              id="td-center"
                              className="section-allocation__table--slno"
                            >
                              <Checkbox
                                checked={
                                  students[index]?.node.isChecked ?? false
                                }
                                onClick={(event) =>
                                  handleClick(response, event)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          )}
                        <TableCell
                          id="td-center"
                          className="section-allocation__table--slno"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="section-allocation__table--admno">
                          {response.node.std_adm_no}
                        </TableCell>

                        <TableCell id={labelId}>
                          {response.node.std_name}
                        </TableCell>
                        {USE_BRANCH_KEY ? (
                          <TableCell className=" section-allocation__table--desc ">
                            {response.node.branch.branch_desc}
                          </TableCell>
                        ) : null}
                        {USE_CLASS_KEY ? (
                          <TableCell className=" section-allocation__table--desc ">
                            {response.node.class.class_desc}
                          </TableCell>
                        ) : null}
                        {USE_SECTION_KEY ? (
                          <TableCell className="section-allocation__table--desc">
                            {response.node.section.section_desc}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <div className="row g-0">
        <div className="col">
          <Button
            onClick={() => setInterchangeSection(!interchangeSection)}
            disabled={
              !(students?.filter((data) => data.node.isChecked).length > 0)
            }
            mode="move-to"
          />

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>
        <div className="col-2 section-allocation__total">
          <StudentTotalCount
            totalCount={LeftStudentsData.data?.GetStudents?.totalCount!}
          />
        </div>
      </div>

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={interchangeSection}
        onClose={() => setInterchangeSection(!interchangeSection)}
      >
        <div className="section-allocation__move-to">
          <Title>Interchange Section</Title>

          <div className="row g-0 section-allocation__move-to--select">
            {USE_SECTION_KEY ? (
              <div className="col-4">
                <Autocomplete
                  classes={classes}
                  options={
                    sectionDropDown?.filter(
                      (data) => data.value !== leftSectionSelected?.value
                    )!
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setRightSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={
                    sectionDropDown?.find(
                      ({ value }) =>
                        Number(value) === rightSectionSelected?.value
                    )! ?? null
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setRightSectionSelected(newValue);
                    } else {
                      setRightSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label={`Move to ${sectionLabel}`}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
          </div>
          <div
            className="section-allocation__move-to--count"
            onClick={() => {
              rightSectionSelected && setViewStudentModal(!viewStudentModal);
            }}
          >
            <b>{rightSectionSelected && righttotalCount}</b>
            <Label>Students :</Label>
            <Label>{sectionLabel}</Label>
          </div>
          <div className="section-allocation__move-to--tableblock">
            <TableContainer
              className="section-allocation__table"
              onScroll={handleScroll}
            >
              <Table stickyHeader>
                <EnhancedTableHeadForDrawer />
                <TableBody>
                  {students &&
                    students
                      .filter((data) => data.node.isChecked)
                      .map((response, index) => {
                        return (
                          <TableRow
                            key={response.node.id}
                            hover
                            aria-checked={response.node.isChecked}
                            tabIndex={-1}
                            selected={response.node.isChecked}
                          >
                            <TableCell
                              id="td-center"
                              className="section-allocation__table--slno"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell className="section-allocation__table--admno">
                              {response.node.std_adm_no}
                            </TableCell>

                            <TableCell>{response.node.std_name}</TableCell>

                            {USE_SECTION_KEY ? (
                              <TableCell className="section-allocation__table--desc">
                                {
                                  sectionDropDown?.find(
                                    ({ value }) =>
                                      Number(value) ===
                                      rightSectionSelected?.value
                                  )?.label
                                }
                              </TableCell>
                            ) : null}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button mode="save" onClick={handleAllocationOfStudentsToSection} />
          <Button
            mode="cancel"
            onClick={() => {
              clear();
              setInterchangeSection(!interchangeSection);
            }}
          />
          <MessageModal
            modalFlag={message.flag!}
            value={message.message!}
            handleClose={handleClose}
            operation={message.operation!}
          />
          <LoadingModal flag={updationLoading} />
        </div>
      </Drawer>
      <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={viewStudentModal}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Title>List of Students In Section</Title>
            <TableContainer
              className="section-allocation__table"
              onClick={rightHandleScroll}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="section-allocation__table--th-slno">
                      {TableHeaders.SLNO}
                    </TableCell>
                    <TableCell className="section-allocation__table--th-admno">
                      {TableHeaders.ADMISSION_NUMBER}
                    </TableCell>
                    <TableCell className="section-allocation__table--th-admno">
                      {TableHeaders.REGISTER_NUMBER}
                    </TableCell>
                    <TableCell>{TableHeaders.STUDENT_NAME}</TableCell>
                    {USE_DEPARTMENT_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {departmentLabel}
                      </TableCell>
                    ) : null}
                    {USE_BRANCH_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {branchLabel}
                      </TableCell>
                    ) : null}

                    {USE_CLASS_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {classLabel}
                      </TableCell>
                    ) : null}

                    {USE_SEMESTER_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {semesterLabel}
                      </TableCell>
                    ) : null}
                    {USE_SECTION_KEY ? (
                      <TableCell className="section-allocation__table--desc">
                        {sectionLabel}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rightSectionSelected &&
                    sectionStudents?.map((response, index: number) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell
                            id="td-center"
                            className="section-allocation__table--slno"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className="section-allocation__table--admno">
                            {response.node.std_adm_no}
                          </TableCell>

                          <TableCell id={labelId}>
                            {response.node.std_name}
                          </TableCell>
                          {USE_DEPARTMENT_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.dept.dept_desc}
                            </TableCell>
                          ) : null}
                          {USE_BRANCH_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.branch.branch_desc}
                            </TableCell>
                          ) : null}

                          {USE_CLASS_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.class.class_desc}
                            </TableCell>
                          ) : null}

                          {USE_SEMESTER_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.semester.sem_desc}
                            </TableCell>
                          ) : null}
                          {USE_SECTION_KEY ? (
                            <TableCell className="section-allocation__table--desc">
                              {response.node.section.section_desc}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setViewStudentModal(!viewStudentModal)}
            />
          </div>
        </div>
      </Modal>
      <LoadingModal flag={LeftStudentsData?.loading} />
    </>
  );
};

export default InterChangeSections;
