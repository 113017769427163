import { gql } from "@apollo/client";

export const GetAuthDetailsByToken = gql`
  query GetAuthDetailsByToken($token: String!) {
    GetAuthDetailsByToken(token: $token) {
      id
      auth_user_id
      auth_group_type
      auth_is_active
      auth_is_blocked
      firebase_id
      inst_id
    }
  }
`;

export const LogoutUser = gql`
  mutation LogoutUser($token: String!, $input: SubscribedUser!) {
    LogoutUser(token: $token, input: $input)
  }
`;
export const ValidateAuthUser = gql`
  query ValidateAuthUser(
    $token: String!
    $inst_id: ID!
    $input: SubscribedUser!
  ) {
    ValidateAuthUser(token: $token, inst_id: $inst_id, input: $input)
  }
`;
