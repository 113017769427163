import React, { useState } from "react";
import useToken from "../../../../customhooks/useToken";
import { msgType } from "../../../../utils/FormTypes";
import { Operation } from "../../../../utils/Enum.types";
import { useMutation } from "@apollo/client";
import { EMPTY_STRING, licenseTypes } from "../../../../utils/constants";
import { SendFcmMsg } from "../../../../queries/chat/mutations";
import { Label } from "../../../../stories/Label/Label";
import { TextField } from "@mui/material";
import { Button } from "../../../../stories/Button/Button";
import LoadingModal from "../../../Modals/LoadingModal";
import MessageModal from "../../../../utils/MessageModal";
import Navbar from "../../../Layouts/Navbar";
import Sidebar from "../../../Layouts/Sidebar";

const Fcm = () => {
  const { token } = useToken();
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [fcmMessage, setFcmMessage] = useState("");
  const [SendFcmMessage, { loading: deleteTemplateLoading }] = useMutation(
    SendFcmMsg,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleClose = () => {
    setMessage({
      flag: false,
      message: EMPTY_STRING,
      operation: Operation.NONE,
    });
  };
  const HandleSendMessage = () => {
    SendFcmMessage({
      variables: {
        token,
        msg: fcmMessage,
      },
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Message Sent Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  return (
    <>
      <Navbar navType={licenseTypes.CUSTOMER} />
      <div className="cust-list">
        <Sidebar />
        <div className="cust-list__frame">
          <Label>Enter Message</Label>
          <TextField
            value={fcmMessage}
            onChange={(e) => setFcmMessage(e.target.value)}
          />
          <Button mode="save" onClick={HandleSendMessage} />
        </div>
      </div>

      <LoadingModal flag={deleteTemplateLoading} />
      <MessageModal
        modalFlag={message.flag}
        value={message.message}
        handleClose={handleClose}
        operation={message.operation}
      />
    </>
  );
};

export default Fcm;
