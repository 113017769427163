import { reauthenticateWithCredential, updatePassword } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { EmailAuthProvider } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import UpdatedRefresh from "../images/UpdatedRefresh.svg";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { msgType } from "../utils/Types";
import { Operation } from "../utils/Enum.types";
import { auth } from "../firebase";
import { EMPTY_STRING } from "../utils/constants";
import { Title } from "../stories/Title/Title";
import { Label } from "../stories/Label/Label";
import { Button } from "../stories/Button/Button";
import MessageModal from "../utils/MessageModal";
interface Props {
  setPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Captcha = ({ setPasswordModal }: Props) => {
  const [captchaText, setCaptchaText] = useState<string>("");
  const [userInput, setUserInput] = useState<string>("");

  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaText(captcha);
    setUserInput("");
  };
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const updatePasswordWithReauthentication = () => {
    const currentUser = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      currentUser?.email!,
      oldPassword
    );
    if (newPassword !== confirmPassword) {
      setMessage({
        flag: true,
        message: "Passwords do not match",
        operation: Operation.NONE,
      });
    } else if (oldPassword === newPassword) {
      setMessage({
        flag: true,
        message: "New password must be different from the current password",
        operation: Operation.NONE,
      });
    } else if (captchaText !== userInput) {
      setMessage({
        flag: true,
        message: "Captcha do not match , Please Try Again",
        operation: Operation.NONE,
      });
    } else if (currentUser) {
      reauthenticateWithCredential(currentUser, credential)
        .then((user) => {
          updatePassword(currentUser, newPassword)
            .then(() => {
              setMessage({
                flag: true,
                message: "Password updated successfully",
                operation: Operation.UPDATE,
              });
            })
            .catch((e: FirebaseError) => {
              setMessage({
                flag: true,
                message: e.message,
                operation: Operation.NONE,
              });
            });
        })
        .catch((e: FirebaseError) => {
          setMessage({
            flag: true,
            message: e.message,
            operation: Operation.NONE,
          });
        });
    }
  };
  const handleClickShowPassword = (field: string) => {
    switch (field) {
      case "current":
        setShowCurrentPassword((show) => !show);
        break;
      case "new":
        setShowNewPassword((show) => !show);
        break;
      case "confirm":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };
  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClear = () => {
    setOldPassword(EMPTY_STRING);
    setNewPassword(EMPTY_STRING);
    setConfirmPassword(EMPTY_STRING);
    setUserInput(EMPTY_STRING);
  };
  const handleBack = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setPasswordModal(false);
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  useEffect(() => {
    generateCaptcha();
  }, []);
  return (
    <>
      <Title>Change Password</Title>
      <div className="change-password">
        <TextField
          required
          value={oldPassword}
          className="change-password__textfield"
          name="password"
          type={showCurrentPassword ? "text" : "password"}
          onChange={(e) => setOldPassword(e.target.value)}
          label="Current Password"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("current")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          value={newPassword}
          className="change-password__textfield"
          name="password"
          type={showNewPassword ? "text" : "password"}
          onChange={(e) => setNewPassword(e.target.value)}
          label="New Password"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("new")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          value={confirmPassword}
          className="change-password__textfield"
          name="password"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("confirm")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Label>Captcha code</Label>
        <div className="change-password__textfield--captcha">
          <div className="change-password__textfield--captcha--text">
            <span>{captchaText}</span>
          </div>

          <img src={UpdatedRefresh} alt="/" onClick={generateCaptcha} />
        </div>

        {/* <TextField
          InputLabelProps={{ shrink: true }}
          label="Captcha Code"
          value={captchaText}
          disabled
          InputProps={{
            endAdornment: (
              <img src={UpdatedRefresh} alt="/" onClick={generateCaptcha} />
            ),
          }}
          className="change-password__textfield--captcha"
        /> */}

        <TextField
          InputLabelProps={{ shrink: true }}
          label="Enter Captcha Code"
          className="change-password__textfield"
          type="text"
          value={userInput}
          onChange={handleUserInput}
        />
      </div>
      <Button
        type="submit"
        mode="save"
        onClick={updatePasswordWithReauthentication}
      />
      <Button type="button" mode="clear" onClick={handleClear} />

      <Button
        mode="cancel"
        type="button"
        onClick={() => setPasswordModal(false)}
      />
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleBack}
        operation={message.operation!}
      />
    </>
  );
};

export default Captcha;
