import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { Label } from "../../../../../stories/Label/Label";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import Close from "../../../../../images/Close.svg";
import { AddInstDept } from "../../../../../queries/institution/department/mutations/new";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Direction, Operation } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Types";
import { EMPTY_STRING, emptyMessageType } from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  handleFormEvent,
  removeMoreSpace,
} from "../../../../../utils/UtilFunctions";
import {
  branchType,
  classType,
  departmentType,
  sectionType,
} from "../../../../../utils/FormTypes";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { GetMstInstDepts } from "../../../../../queries/institution/department/query";
import { Form, Formik } from "formik";
import { UpdateInstDept } from "../../../../../queries/institution/department/mutations/update";
import { DepartmentByNode } from "../../../../../queries/institution/department/query/byNode";
import { sectionValidation } from "../../../../../utils/validationRules";
import LoadingModal from "../../../../Modals/LoadingModal";
import {
  AddInstBranchData,
  AddInstBranchVars,
  AddInstClassData,
  AddInstClassVars,
  AddInstSectionData,
  AddInstSectionVars,
  BranchList,
  BranchQueryType,
  ClassList,
  ClassQueryType,
  MsgMstInstBranchOrderField,
  MsgMstInstClassOrderField,
  MsgMstInstDeptOrderField,
  MsgMstInstSectionOrderField,
  NodeVars,
  SectionList,
  SectionQueryType,
  UpdateInstBranchData,
  UpdateInstBranchVars,
  UpdateInstClassData,
  UpdateInstClassVars,
  UpdateInstSectionData,
  UpdateInstSectionVars,
} from "../../../../../utils/Query.Types";
import MessageModal from "../../../../../utils/MessageModal";
import { AddInstBranch } from "../../../../../queries/institution/branch/mutations/new";
import { UpdateInstBranch } from "../../../../../queries/institution/branch/mutations/update";
import { BranchByNode } from "../../../../../queries/institution/branch/query/byNode";
import { GetMstInstBranches } from "../../../../../queries/institution/branch/query";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { ClassByNode } from "../../../../../queries/institution/class/query/byNode";
import { UpdateInstClass } from "../../../../../queries/institution/class/mutations/update";
import { AddInstClass } from "../../../../../queries/institution/class/mutations/new";
import { GetMstInstClasses } from "../../../../../queries/institution/class/query";
import { SectionByNode } from "../../../../../queries/institution/sections/query/byNode";
import { AddInstSection } from "../../../../../queries/institution/sections/mutations/new";
import { UpdateInstSection } from "../../../../../queries/institution/sections/mutations/update";
import { GetMstInstSections } from "../../../../../queries/institution/sections/query";
interface ModalProps {
  operation: Operation;
  id: number;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleEdit: (id: number) => void;
}
const Add = ({ id, operation, setModal, handleEdit }: ModalProps) => {
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [formData, setFormData] = useState<sectionType>({
    section_desc: "",
    section_short_desc: "",
  });
  const { token } = useToken();

  const { semId, instId } = useParams();

  const { user_details } = useLoggedInUserDetails();

  const { sectionLabel } = useInstLabels();
  const [GetSectionsByNode] = useLazyQuery<
    {
      node: SectionList;
    },
    NodeVars
  >(SectionByNode);
  const [updateSection, { loading: updationLoading }] = useMutation<
    UpdateInstSectionData,
    UpdateInstSectionVars
  >(UpdateInstSection, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [NewSection, { loading: creationLoading }] = useMutation<
    AddInstSectionData,
    AddInstSectionVars
  >(AddInstSection, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  useEffect(() => {
    if (id && operation === Operation.UPDATE && token) {
      GetSectionsByNode({
        variables: {
          id,
          token,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setFormData({
            section_desc: data.node.section_desc,
            section_short_desc: data.node.section_short_desc,
          });
        }
      });
    } else {
      setFormData({
        section_desc: "",
        section_short_desc: "",
      });
    }
  }, [id, GetSectionsByNode, token, operation]);
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const HandleSubmit = async () => {
    await NewSection({
      variables: {
        token,
        input: {
          inst_id: instId!,
          section_desc: removeMoreSpace(formData.section_desc),
          section_short_desc: removeMoreSpace(formData.section_short_desc),
          semester_id: semId!,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstSections,
          variables: {
            id: semId,
            token,
            query_type: SectionQueryType.SECTION_BY_SEMESTER_ID,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstSectionOrderField.SECTION_IDX,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `${sectionLabel} Added successfully`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClear = () => {
    setFormData({
      section_desc: "",
      section_short_desc: "",
    });
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      if (message.operation === Operation.UPDATE) {
        handleEdit(0);
      }
      setModal(false);
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const HandleEdit = async () => {
    await updateSection({
      variables: {
        token,
        id: id,
        inst_id: instId!,
        input: {
          section_desc: removeMoreSpace(formData.section_desc),
          section_short_desc: removeMoreSpace(formData.section_short_desc),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstSections,
          variables: {
            id: semId,
            token,
            query_type: SectionQueryType.SECTION_BY_SEMESTER_ID,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstSectionOrderField.SECTION_IDX,
            },
          },
        },
        {
          query: SectionByNode,
          variables: { id: id, token },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `${sectionLabel} Updated successfully`,
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  return (
    <>
      <div className="inst-config__masters--title">
        <Title>
          {operation === Operation.CREATE ? "Add" : "Update"} {sectionLabel}
        </Title>
        <img src={Close} alt="" onClick={() => setModal(false)} />
      </div>
      <Formik
        initialValues={formData}
        validationSchema={sectionValidation}
        onSubmit={operation === Operation.UPDATE ? HandleEdit : HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form>
              <div className="label-grid">
                <Label>Description</Label>
                <Input
                  name="section_desc"
                  value={formData.section_desc}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    meta.handleChange(e);
                    handleValueChange(e);
                  }}
                  onKeyDown={handleFormEvent}
                  required
                  autoFocus
                  error={meta.errors.section_desc}
                />
                <Label>Short Description</Label>
                <Input
                  name="section_short_desc"
                  value={formData.section_short_desc}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    meta.handleChange(e);
                    handleValueChange(e);
                  }}
                  onKeyDown={handleFormEvent}
                  required
                />
              </div>
              <Button mode="save" type="submit" />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  handleClear();
                  setModal(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={creationLoading || updationLoading} />
    </>
  );
};

export default Add;
