import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentDashboard from "../../Student/Dashboard/Index";
const StudentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<StudentDashboard />} />
    </Routes>
  );
};

export default StudentRoutes;
