import { gql } from "@apollo/client";

export const CheckStdResetPwdStatus = gql`
  query CheckStdResetPwdStatus(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
  ) {
    CheckStdResetPwdStatus(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
    ) {
      status
      message
    }
  }
`;
export const GetStudents = gql`
  query GetStudents(
    $name: String
    $first: Int
    $acd_yr_id: ID!
    $after: Cursor
    $orderBy: [MsgMstStudentOrder!]
    $token: String!
    $input: StdQueryData!
  ) {
    GetStudents(
      token: $token
      first: $first
      acd_yr_id: $acd_yr_id
      after: $after
      orderBy: $orderBy
      where: { and: [{ or: [{ stdNameContainsFold: $name }] }] }
      input: $input
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          std_name
          parent_name
          std_status
          std_sex
          std_adm_no
          std_dob
          std_email
          std_mobile
          is_login_created
          std_profile_filename
          inst_id
          dept_id
          branch_id
          class_id
          semester_id
          section_id
          acd_yr_id
          misc_data {
            mobile_app_count
          }
          dept {
            id
            dept_desc
          }
          branch {
            id
            branch_desc
          }
          class {
            id
            class_desc
          }
          semester {
            id
            sem_desc
          }
          section {
            id
            section_desc
          }
        }
      }
    }
  }
`;
export const GetMobileDevicesDetails = gql`
  query GetMobileDevicesDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    GetMobileDevicesDetails(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
    ) {
      device_id
      brand
      model
      last_login_date
    }
  }
`;
export const GetStudentDemandDetails = gql`
  query GetStudentDemandDetails($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgAcctStdDemand {
        id
        std_name
        std_id
        std_roll_no
        std_father_name
        std_mother_name
        std_place
        class_desc
        mobile_no
        fee_total_demand
        fee_total_paid
        fee_balance
      }
    }
  }
`;
export const GetStudentDetails = gql`
  query GetStudentDetails($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      ... on MsgMstStudent {
        id
        std_name
        std_adm_no
        parent_name
        std_email
        std_mobile
        std_sex
        std_dob
        std_status
        std_profile_filename
        branch {
          id
          branch_desc
        }
        class {
          id
          class_desc
        }
        semester {
          id
          sem_desc
        }
        section {
          id
          section_desc
        }
        dept {
          id
          dept_desc
        }
        acd_yr {
          id
          acd_yr
        }
      }
    }
  }
`;

export const GetStdDemand = gql`
  query GetStdDemand(
    $token: String!
    $inst_id: ID!
    $after: Cursor
    $first: Int
    $orderBy: MsgAcctStdDemandOrder
    $name: String
  ) {
    GetStdDemand(
      token: $token
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
      where: { and: [{ or: [{ stdNameContainsFold: $name }] }] }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          std_name
          std_id
          std_roll_no
          std_father_name
          std_mother_name
          std_place
          class_desc
          mobile_no
          fee_total_demand
          fee_total_paid
          fee_balance
          inst_id
        }
      }
    }
  }
`;

export const GetStdDemandForPayment = gql`
  query GetStdDemandForPayment(
    $inst_id: ID!
    $after: Cursor
    $encoded_str: String!
    $query_type: StdDemandQueryType!
    $first: Int
    $orderBy: MsgAcctStdDemandOrder
    $name: String
  ) {
    GetStdDemandForPayment(
      inst_id: $inst_id
      after: $after
      first: $first
      orderBy: $orderBy
      encoded_str: $encoded_str
      query_type: $query_type
      where: { and: [{ or: [{ stdNameContainsFold: $name }] }] }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          std_name
          std_id
          std_roll_no
          std_father_name
          std_mother_name
          std_place
          class_desc
          mobile_no
          fee_total_demand
          fee_total_paid
          fee_balance
          inst_id
        }
      }
    }
  }
`;

export const GetStdDemandDetails = gql`
  query GetStdDemandDetails($std_demand_id: ID!) {
    GetStdDemandDetails(std_demand_id: $std_demand_id) {
      id
      std_name
      std_id
      std_roll_no
      std_father_name
      std_mother_name
      std_place
      class_desc
      mobile_no
      fee_total_demand
      fee_total_paid
      fee_balance
      inst_id
    }
  }
`;

export const GetAcdStudents = gql`
  query GetAcdStudents(
    $name: String
    $first: Int
    $after: Cursor
    $orderBy: [MsgMstStudentOrder!]
    $token: String!
    $acd_yr_id: ID!
    $input: StdQueryData!
  ) {
    GetAcdStudents(
      token: $token
      acd_yr_id: $acd_yr_id
      first: $first
      after: $after
      orderBy: $orderBy
      where: {
        or: [{ stdNameContainsFold: $name }, { stdAdmNoContainsFold: $name }]
      }
      input: $input
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          std_name
          parent_name
          std_status
          std_sex
          std_adm_no
          std_dob
          std_email
          std_mobile
          is_login_created
          std_profile_filename
          inst_id
          dept_id
          branch_id
          class_id
          semester_id
          section_id
          acd_yr_id
          branch {
            id
            branch_desc
          }
          class {
            id
            class_desc
          }
          dept {
            id
            dept_desc
          }
          semester {
            id
            sem_desc
          }
          section {
            id
            section_desc
          }
        }
      }
    }
  }
`;
