import React from "react";
import Close from "../../../../images/Close.svg";
import Warning from "../../../../images/Warning.svg";
import { useNavigate, useParams } from "react-router-dom";
import Accept from "../../../../images/Accept.svg";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  stdDemandId: number;

}

const DisClaimer = ({ setModalFlag ,stdDemandId}: Props) => {
  const navigate = useNavigate();
  const { instId } = useParams();
  return (
    <>
      <div className="disclaimer">
        <div className="disclaimer__header">
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="disclaimer__data">
          <div className="disclaimer__data--image">
            <img src={Warning} alt="/" />
          </div>
          <h2 className="disclaimer__data--title">Disclaimer</h2>
          <div className="disclaimer__data--text">
            <ul>
              <li>
                <p>
                  We hereby declare that we are not liable or responsible for
                  any financial loss or inconvenience resulting from errors,
                  inaccuracies, or failures in the payment transaction process.
                </p>
              </li>
              <li>
                <b>User Error </b>
                <p>
                  Users are responsible for ensuring the accuracy of payment
                  details provided during the transaction process. Any errors or
                  discrepancies in payment information entered by the user,
                  including but not limited to account numbers, billing
                  addresses, or transaction amounts, are the sole responsibility
                  of the user.
                </p>
              </li>
              <li>
                <b>Third-Party Services</b>
                <p>
                  Some payment methods may involve third-party services or
                  platforms. While we strive to partner with reputable
                  providers, we are not responsible for the actions, policies,
                  or performance of third-party service providers.
                </p>
              </li>
              <li>
                <b>Summarizes the key point</b>
                <p>
                  By proceeding with the payment transaction, users acknowledge
                  and accept that they bear sole responsibility for the
                  accuracy, and integrity of their payment information and
                  transactions.
                </p>
                <p>
                  By proceeding with the payment process, you acknowledge that
                  you have read, understood, and agree to abide by the terms and
                  conditions outlined above.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="disclaimer__footer"
          onClick={() => {
            navigate(
              `/${instId}/mobileapp/fee/fetchdetails/${stdDemandId}/paymentprocess`
            );
          }}
        >
          <img src={Accept} alt="" />
          <span> Accept and Proceed for Payment</span>
        </div>
      </div>
    </>
  );
};

export default DisClaimer;
