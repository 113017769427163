import React, { useState, useRef, useEffect } from "react";
import { Drawer } from "@mui/material";

import Edit from "../../../images/EditProfile.svg";
import Delete from "../../../images/Delete.svg";
import { useLazyQuery, useMutation } from "@apollo/client";

import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import Close from "../../../images/Close.svg";
import {
  DeleteLedgerModalStyles,
  templateViewStyles,
} from "../../styles/ModalStyles";

import { Title } from "../../../stories/Title/Title";
import Input from "../../../stories/Input/Input";
import { Button } from "../../../stories/Button/Button";
import { Direction, EduateModule, Operation } from "../../../utils/Enum.types";
import LoadingModal from "../../Modals/LoadingModal";
import MessageModal from "../../../utils/MessageModal";
import { msgType } from "../../../utils/Types";
import useLoggedInUserDetails from "../../../customhooks/useLoggedInUserDetails";
import useToken from "../../../customhooks/useToken";
import { Label } from "../../../stories/Label/Label";
import TextArea from "../../../stories/TextArea/TextArea";
import DeleteModal from "../../Modals/DeleteModal";
import {
  AddMsgInstTemplate,
  DeleteMsgInstTemplate,
  UpdateMsgInstTemplate,
} from "../../../queries/Templates/mutations/new";
import {
  GetInstTemplateById,
  GetMsgInstTemplates,
  GetSwMsgTags,
} from "../../../queries/Templates/query";
import {
  GetInstMsgTemplatesVars,
  GetInstTemplateByIdData,
  GetMsgInstTemplatesData,
  GetSwMsgTagsData,
  GetSwMsgTagsVars,
  GetTemplateByIdVars,
} from "../../../utils/Query.Types";
import TagsListImport from "./TagsImport";
import { licenseTypes } from "../../../utils/constants";
import Navbar from "../../Layouts/Navbar";
import Sidebar from "../../Layouts/Sidebar";

interface HighlightedTextProps {
  text: string;
}
export const HighlightedText: React.FC<HighlightedTextProps> = ({ text }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(".highlight-text");

    elements.forEach((element) => {
      const html = element.innerHTML;
      const updatedHtml = html.replace(
        /<([\w-]+)>/g,
        '<span class="highlight">&lt; $1 &gt;</span>'
      );
      element.innerHTML = updatedHtml;
    });
  }, []);

  return (
    <span
      className="highlight-text"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

const Index = () => {
  const drawerClasses = templateViewStyles();

  const { user_details } = useLoggedInUserDetails();

  const { token } = useToken();
  const { instId } = useParams();
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");
  const [operation, setOperation] = useState(Operation.CREATE);
  const [search, setSearch] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [acctId, setAcctId] = useState(0);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });
  const [tagsModal, setTagsModal] = useState(false);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);

  const [GetTemplates, { data: templatesList }] = useLazyQuery<
    GetMsgInstTemplatesData,
    GetInstMsgTemplatesVars
  >(GetMsgInstTemplates);

  const [GetTemplatesByNode] = useLazyQuery<
    GetInstTemplateByIdData,
    GetTemplateByIdVars
  >(GetInstTemplateById);

  const [DeleteTemplate, { loading: deleteTemplateLoading }] = useMutation(
    DeleteMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [CreateTemplate, { loading: creationLoading }] = useMutation(
    AddMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [UpdateTemplate, { loading: updationLoading }] = useMutation(
    UpdateMsgInstTemplate,
    {
      onError: (e) =>
        setMessage({
          message: e.message,
          operation: Operation.NONE,
          flag: true,
        }),
    }
  );
  const [GetTags, { data }] = useLazyQuery<GetSwMsgTagsData, GetSwMsgTagsVars>(
    GetSwMsgTags
  );
  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      setTemplateMessage("");
      setIsDrawerOpen(false);
      setDeleteModal(false);
    }
    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };

  const HandleDelete = (id: number) => {
    DeleteTemplate({
      variables: {
        token,
        id,
        inst_id: instId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMsgInstTemplates,
          variables: {
            name: search,
            token,
            after: null,
            first: null,
            inst_id: instId!,
            orderBy: {
              direction: Direction.ASC,
              field: "MSG_TPL_DESC",
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          flag: true,
          message: "Voucher Deleted Sucessfully",
          operation: Operation.DELETE,
        });
      }
    });
  };
  const handleTemplateClick = (template: string) => {
    const selectedText = window.getSelection()?.toString();
    const updatedMessage =
      selectedText && selectedText.length > 0
        ? templateMessage.replace(selectedText, template)
        : templateMessage + " " + template;
    setTemplateMessage(updatedMessage);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTemplateMessage(e.target.value);
  };

  const handleTextAreaSelect = () => {
    const selectedText = window.getSelection()?.toString();
    if (selectedText && selectedText.length > 0) {
      messageRef.current?.focus();
    }
  };

  const handleAddTemplate = () => {
    if (operation === Operation.CREATE) {
      CreateTemplate({
        variables: {
          token,
          inst_id: instId,
          user_details,
          input: {
            msg_tpl_idx: 0,
            msg_tpl_desc: templateMessage,
            inst_module: EduateModule.MESSAGEAPP,
            inst_type: "S",
          },
        },
        refetchQueries: [
          {
            query: GetMsgInstTemplates,
            variables: {
              name: search,
              token,
              after: null,
              first: null,
              inst_id: instId!,
              orderBy: {
                direction: Direction.ASC,
                field: "MSG_TPL_DESC",
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Template Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateTemplate({
        variables: {
          token,
          inst_id: instId,
          id: acctId,
          user_details,
          input: {
            msg_tpl_idx: 0,
            msg_tpl_desc: templateMessage,
            inst_module: EduateModule.MESSAGEAPP,
            inst_type: "S",
          },
        },
        refetchQueries: [
          {
            query: GetMsgInstTemplates,
            variables: {
              name: search,
              token,
              after: null,
              first: null,
              inst_id: instId!,
              orderBy: {
                direction: Direction.ASC,
                field: "MSG_TPL_DESC",
              },
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Template Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const filteredTemplates =
    templatesList && templatesList.GetMsgInstTemplates.edges;
  useEffect(() => {
    if (token && instId) {
      GetTemplates({
        variables: {
          name: search,
          token,
          after: null,
          first: null,
          inst_id: instId!,
          orderBy: {
            direction: Direction.ASC,
            field: "MSG_TPL_DESC",
          },
        },
      });
    }
  }, [GetTemplates, token, search, instId]);
  useEffect(() => {
    if (token) {
      GetTags({
        variables: {
          token,
        },
      });
    }
  }, [token, GetTags]);
  useEffect(() => {
    if (operation === Operation.UPDATE && acctId && token) {
      GetTemplatesByNode({
        variables: {
          id: acctId,
          token,
        },
      }).then(({ data }) => {
        if (data) {
          setTemplateMessage(data.node.msg_tpl_desc);
        }
      });
    }
  }, [token, GetTemplatesByNode, acctId, operation]);
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="message-template">
        <Sidebar />
        <div className="message-template__frame">
          <Title>Templates for Message</Title>

          <div className="message-template__filters row g-0">
            <div className="col-2">
              <Input
                id="search"
                placeholder="Search Message"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>

            <div className="col"></div>
            <div className="col-2">
              <Button
                mode="addnew"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                className="message-template__filters--button"
              />
            </div>
          </div>
          <div className="message-template__datablock">
            <ul>
              {filteredTemplates &&
                filteredTemplates.map((template, index) => {
                  return (
                    <li
                      className="message-template__datablock--li"
                      key={template.node.id}
                    >
                      <div className="message-template__datablock--li--message">
                        {`${index + 1})`}&nbsp;
                        <HighlightedText text={template.node.msg_tpl_desc} />
                      </div>
                      <div className="message-template__datablock--li--module">
                        {template.node.inst_module}
                      </div>
                      <div className="message-template__datablock--li--image">
                        <img
                          src={Edit}
                          alt=""
                          onClick={() => {
                            setIsDrawerOpen(!isDrawerOpen);
                            setOperation(Operation.UPDATE);
                            setAcctId(template.node.id);
                          }}
                        />
                        <img
                          src={Delete}
                          alt=""
                          onClick={() => {
                            setAcctId(template.node.id);
                            setDeleteModal(!deleteModal);
                          }}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          <Button mode="back" onClick={() => navigate(-1)} />
        </div>

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(!isDrawerOpen)}
          className={drawerClasses.drawer}
          classes={{
            paper: drawerClasses.drawerPaper,
          }}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <Title>
                {operation === Operation.CREATE
                  ? " Add New Template"
                  : "Update Template"}
              </Title>

              <div className="row g-0">
                <div className="col">
                  <div className="label-grid">
                    <Label>Description</Label>
                    <TextArea
                      textAreaRef={messageRef}
                      rows={3}
                      onChange={handleTextAreaChange}
                      onSelect={handleTextAreaSelect}
                      value={templateMessage}
                    />
                  </div>
                </div>
              </div>

              <div className="message-template__predefined-words">
                {data &&
                  data.GetSwMsgTags.map((res) => {
                    return (
                      <button onClick={() => handleTemplateClick(res.tag_name)}>
                        {res.tag_name}
                      </button>
                    );
                  })}
              </div>
              <Button
                mode="save"
                onClick={handleAddTemplate}
                disabled={!templateMessage}
              />
              <Button
                mode="cancel"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
            </div>
          </div>
        </Drawer>
        <Modal
          shouldCloseOnOverlayClick={true}
          isOpen={tagsModal}
          style={DeleteLedgerModalStyles}
          ariaHideApp={false}
        >
          <div className="modal-flex h-100">
            <div className="modal-flex__data h-100">
              <TagsListImport setModalFlag={setTagsModal} />
            </div>
            <div className="modal-flex__image">
              <img
                src={Close}
                alt="/"
                onClick={() => setTagsModal(!tagsModal)}
              />
            </div>
          </div>
        </Modal>
        <LoadingModal
          flag={creationLoading || deleteTemplateLoading || updationLoading}
        />
        <MessageModal
          modalFlag={message.flag}
          value={message.message}
          handleClose={handleClose}
          operation={message.operation}
        />
        <DeleteModal
          modalFlag={deleteModal}
          setModalFlag={setDeleteModal}
          handleDelete={HandleDelete}
          id={acctId}
        />
      </div>
    </>
  );
};

export default Index;
