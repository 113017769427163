import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { OnlineTransactionById } from "../queries/payments/query";
import { AcctOnlinePaymentsNode } from "../components/Modules/Masters/Student/Dashboard/TransactionTable";
import { NodeVars } from "../utils/Query.Types";
import { useParams } from "react-router-dom";
import useToken from "./useToken";
interface OnlineTransactionByIdData {
  node: AcctOnlinePaymentsNode;
}
export const useTransactionDetailsById = () => {
  const { transactionId } = useParams();
  const { token } = useToken();
  const [GetTransaction, { data, loading }] = useLazyQuery<
    OnlineTransactionByIdData,
    NodeVars
  >(OnlineTransactionById, {
    variables: {
      id: Number(transactionId)!,
      token,
    },
  });
  useEffect(() => {
    if (token && Number(transactionId)) GetTransaction();
  }, [transactionId, GetTransaction, token]);
  return { data, loading };
};
