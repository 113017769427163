import React, { useContext, useEffect, useState } from "react";
import Input from "../../../../../stories/Input/Input";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import useMasterTableJson from "../../../Masters/json/useTableJson";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { PayRollEmpEdges } from "../Types";
import { ModalType } from "../Index";
import { Operation, PageFor } from "../../../../../utils/Enum.types";
import useEmployee from "../../../../../customhooks/useEmployee";
import { ROWS_PER_PAGE, payloadTypes } from "../../../../../utils/constants";
import {
  getModifiedScrollHeight,
  handleFormEvent,
} from "../../../../../utils/UtilFunctions";
import { TableHeaderProps } from "../../../../../utils/Types";
import AddLoginUser from "./Add";
import Edit from "../../../../../images/EditProfile.svg";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import { AppContext } from "../../../../../context/context";
import Close from "../../../../../images/Close.svg";
interface Props {
  pageType: PageFor;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}
const Index = ({ pageType, setModalFlag }: Props) => {
  const classes = useDataGridStyles();

  const { LoginUsers } = useMasterTableJson();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [employees, setEmployees] = useState<PayRollEmpEdges[]>([]);
  const { dispatch } = useContext(AppContext);
  const [openModal, setOpenModal] = useState<ModalType>({
    operation: Operation.NONE,
    flag: false,
    id: 0,
  });
  const [searchData, setSearchData] = useState("");
  const {
    empDetails: { data, loading, fetchMore },
  } = useEmployee(ROWS_PER_PAGE, searchData);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetMsgEmp.edges;
                const pageInfo = fetchMoreResult.GetMsgEmp.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetMsgEmp.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetMsgEmp: {
                    edges: [...employees, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetMsgEmp.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },

    // eslint-disable-next-line
    [rows]
  );

  const dynamicHeaders: TableHeaderProps[] = LoginUsers.Table_Headers.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "user_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <img
            src={Edit}
            alt=""
            onClick={() => {
              setOpenModal({
                operation: Operation.UPDATE,
                flag: true,
                id: params.row.empId,
              });
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetMsgEmp.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.empId && row.empId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setEmployees(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            empId: node.id,
            user_name: node.emp_name,
            user_mobile: node.emp_mobile,
            user_email: node.emp_email,
          }))
        );
      } else {
        setEmployees(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            empId: node.id,
            user_name: node.emp_name,
            user_mobile: node.emp_mobile,
            user_email: node.emp_email,
          }))
        );
      }
      setEndCursor(data.GetMsgEmp.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const handleClick = (params: GridCellParams) => {
    if (params.field === "user_name" && pageType === PageFor.MODAL) {
      const empId = params.row.empId;
      dispatch({
        type: payloadTypes.SET_EMPLOYEE_ID,
        payload: { employeeId: empId },
      });
      setModalFlag(false);
    }
  };
  return (
    <>
      <div className="user-rights__login-user">
        <div className="user-rights__login-user--title">
          <Title variant="subtitle1">
            {pageType === PageFor.GENERAL ? "Create Login User" : "User List"}
          </Title>
          {pageType === PageFor.MODAL && (
            <img src={Close} alt="" onClick={() => setModalFlag(false)} />
          )}
        </div>

        <div className="row g-0 ">
          <div className="col-2 ">
            <Input
              id="search"
              type="text"
              placeholder="Search ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchData(e.target.value)
              }
              onKeyDown={handleFormEvent}
            />
          </div>
          <div className="col"></div>

          {pageType === PageFor.GENERAL ? (
            <div className="col-2">
              <Button
                mode="addnew"
                className="user-rights__login-user--button"
                autoFocus
                onClick={() =>
                  setOpenModal({
                    operation: Operation.CREATE,
                    flag: true,
                    id: 0,
                  })
                }
              />
            </div>
          ) : null}
        </div>
        <div className={`user-rights__login-user--tableblock ${classes.root}`}>
          <DataGridPro
            columns={columns.filter(
              ({ field }) =>
                (field !== "user_actions" && pageType === PageFor.MODAL) ||
                pageType === PageFor.GENERAL
            )}
            rows={rows}
            disableRowSelectionOnClick
            onCellClick={handleClick}
            disableChildrenSorting
            rowHeight={TABLE_ROW_HEIGHT}
            hideFooter
          />
        </div>
        <div className="flex-end">
          <div className="student-total-count">
            Total Users :<b>{data && data.GetMsgEmp.totalCount}</b>
          </div>
        </div>
      </div>

      {pageType === PageFor.GENERAL ? (
        <AddLoginUser openModal={openModal} setOpenModal={setOpenModal} />
      ) : null}
    </>
  );
};

export default Index;
