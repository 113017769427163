import React, { useContext, useEffect, useState } from "react";

import Close from "../../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";

import { useParams } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Direction,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import { AppContext } from "../../../../context/context";
import { ChannelDataByIdVars, MessageDataByIdData } from "../Feeds/Types";
import {
  GetChannelMessages,
  GetMessageById,
} from "../../../../queries/chat/feeds/list";
import { msgType } from "../../../../utils/Types";
import {
  AddChannelmessage,
  UpdateChannelMessage,
} from "../../../../queries/chat/mutations";
import {
  EMPTY_STRING,
  formatOptions,
  formattingToolbarOptions,
} from "../../../../utils/constants";
import { Title } from "../../../../stories/Title/Title";
import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import MessageModal from "../../../../utils/MessageModal";
import LoadingModal from "../../../Modals/LoadingModal";
import useChannels from "../CustomHooks/useChannels";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import useEmpDetailsById from "../../../../customhooks/useEmpDetailsById";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  operation: Operation;
  messageId: number;
}
const CreateAnnouncements = ({ setModalFlag, operation, messageId }: Props) => {
  const { token } = useToken();

  const [channelMessage, setChannelMessage] = useState("");
  const { user_details } = useLoggedInUserDetails();
  const { instId } = useParams();

  const { employeeFormData } = useEmpDetailsById(true);

  const { state } = useContext(AppContext);
  const { data } = useChannels(ChannelQueryType.ANNOUNCEMENTS);
  const channelId =
    data && data.GetChannels.length ? data.GetChannels[0].id : 0;

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const [GetChannelMessage] = useLazyQuery<
    MessageDataByIdData,
    ChannelDataByIdVars
  >(GetMessageById);

  const [AddChannelMsg, { loading }] = useMutation(AddChannelmessage, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateChannelMsg, { loading: UpdateLoading }] = useMutation(
    UpdateChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleSubmit = () => {
    if (operation === Operation.CREATE) {
      AddChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: EMPTY_STRING,
            msg_channel_id: channelId,
            inst_id: instId!,
            msg_header: EMPTY_STRING,
          },
          user_details,
        },
        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: instId ? instId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.ANNOUNCEMENTS,
                channel_id: channelId,
                start_date: toIsoDate(DEFAULT_TIME),
                end_date: toIsoDate(DEFAULT_TIME),
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              messageContent: EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Announcement Created Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    } else {
      UpdateChannelMsg({
        variables: {
          token,
          input: {
            msg_content: channelMessage,
            msg_media_content: EMPTY_STRING,
            msg_header: EMPTY_STRING,
          },
          user_details,
          msg_id: messageId,
        },
        refetchQueries: [
          {
            query: GetChannelMessages,
            variables: {
              token,
              inst_id: instId ? instId : state.InstId.toString(),
              input: {
                query_type: MsgChannelType.ANNOUNCEMENTS,
                channel_id: channelId,
                start_date: toIsoDate(DEFAULT_TIME),
                end_date: toIsoDate(DEFAULT_TIME),
              },
              direction: Direction.ASC,
              last: 10,
              after: null,
              sortBy: SortBy.CREATED_AT,
              messageContent: EMPTY_STRING,
            },
          },
        ],
      }).then(({ data }) => {
        if (data) {
          setMessage({
            flag: true,
            message: "Announcement Updated Successfully",
            operation: Operation.CREATE,
          });
        }
      });
    }
  };
  const handleClear = () => {
    setChannelMessage(EMPTY_STRING);
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      setModalFlag(false);
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  useEffect(() => {
    if (messageId && operation === Operation.UPDATE && token) {
      GetChannelMessage({
        variables: {
          token,
          id: messageId,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setChannelMessage(data.node.msg_content);
        }
      });
    }
  }, [messageId, operation, GetChannelMessage, token]);
  return (
    <>
      <div className="create-feeds">
        <div className="create-feeds--title">
          <Title>Create Announcements</Title>
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="create-feeds--data">
          <div className="create-feeds--selected"></div>
          <div className="label-grid">
            <Label>Description</Label>
            <ReactQuill
              value={channelMessage}
              onChange={setChannelMessage}
              modules={formattingToolbarOptions}
              formats={formatOptions}
            />
          </div>
        </div>
        <Button
          mode="save"
          onClick={handleSubmit}
          disabled={
            channelMessage === EMPTY_STRING ||
            employeeFormData.can_send_announcement === false
          }
        />
        <Button mode="clear" onClick={handleClear} />
        <Button mode="cancel" onClick={() => setModalFlag(false)} />
      </div>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={loading || UpdateLoading} />
    </>
  );
};

export default CreateAnnouncements;
