import React, { useEffect, useRef, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import useMasterTableJson, {
  TableColumn,
} from "../../../Masters/json/useTableJson";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import Edit from "../../../../../images/EditProfile.svg";
import Delete from "../../../../../images/Delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import { ActiveAcdYrStyles } from "../../../../styles/ModalStyles";
import Add from "./Add";
import { useLazyQuery } from "@apollo/client";
import { GetMstInstDepts } from "../../../../../queries/institution/department/query";
import {
  BranchListData,
  BranchListVars,
  BranchQueryType,
  ClassListData,
  ClassListVars,
  ClassQueryType,
  DepartmentList,
  DepartmentListDataByInstId,
  DepartmentListEdges,
  DepartmentListVarsByInstId,
  GetNotAllocatedIdsData,
  GetNotAllocatedIdsVars,
  MsgMstInstBranchOrderField,
  MsgMstInstClassOrderField,
  MsgMstInstDeptOrderField,
  TableType,
} from "../../../../../utils/Query.Types";
import useToken from "../../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { Direction, Operation } from "../../../../../utils/Enum.types";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { TableHeaderProps } from "../../../../../utils/Types";
import { reOrderProcess } from "../../../../../utils/UtilFunctions";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import DeleteModal from "../../../../Modals/DeleteModal";
import { GetMstInstBranches } from "../../../../../queries/institution/branch/query";
import { GetMstInstClasses } from "../../../../../queries/institution/class/query";
import { GetNotAllocatedIds } from "../../../../../queries/institution/configuration/query/SoftwareConfig";
import { GetBranchNamesByDeptIds } from "../../../../../queries/institution/branch/query/byId";
import { GetClassNamesByBranchIds } from "../../../../../queries/institution/class/query/byId";
import { GetSemesterNamesByClassIds } from "../../../../../queries/institution/semester/query/byId";
import { extend } from "dayjs";
export interface InstDetailsProps {
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  reOrder: boolean;
}

interface DeptListProps extends InstDetailsProps {
  localItemsState: {
    localItems: DepartmentListEdges[];
    setLocalItems: React.Dispatch<React.SetStateAction<DepartmentListEdges[]>>;
  };
}
const List = ({
  handleEdit,
  handleDelete,
  reOrder,
  localItemsState,
}: DeptListProps) => {
  const { Institutions } = useMasterTableJson();
  const navigate = useNavigate();
  const classes = useDataGridStyles();

  const { instId } = useParams();
  const { token } = useToken();
  const { localItems, setLocalItems } = localItemsState;
  // eslint-disable-next-line
  const [items, setItems] = useState<DepartmentListEdges[]>([]);
  const { USE_BRANCH_KEY, USE_CLASS_KEY, USE_SECTION_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();
  const { departmentLabel } = useInstLabels();
  const [GetDepartments, { data, loading }] = useLazyQuery<
    DepartmentListDataByInstId,
    DepartmentListVarsByInstId
  >(GetMstInstDepts, {
    variables: {
      inst_id: instId!,
      token,
      after: null,
      first: null,
      name: EMPTY_STRING,
      orderBy: {
        direction: Direction.ASC,
        field: MsgMstInstDeptOrderField.DEPT_IDX,
      },
    },
  });

  const [BranchData] = useLazyQuery(GetBranchNamesByDeptIds);
  const [ClassData] = useLazyQuery(GetClassNamesByBranchIds);
  const [SemesterData] = useLazyQuery(GetSemesterNamesByClassIds);

  useEffect(() => {
    if (token) {
      GetDepartments();
    }
  }, [token, GetDepartments]);

  useEffect(() => {
    if (data && !loading)
      setLocalItems(
        data.GetMstInstDepts.edges.filter(({ node: dept }) => dept != null)
      );
    // eslint-disable-next-line
  }, [data, loading]);

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: DepartmentListEdges[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setItems(temp);
      return temp;
    });
  };

  return (
    <>
      <Title variant="subtitle1">List of {departmentLabel}</Title>
      <div className="inst-config__masters--tableblock">
        <TableContainer className="inst-config__masters--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {reOrder ? <TableCell></TableCell> : null}
                {Institutions.InstDetails.filter(
                  ({ headerName }: TableColumn) =>
                    !(headerName === "Actions" && reOrder)
                ).map((th: TableColumn, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TableCell className={th.cellClassName}>
                        {th.headerName}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </TableHead>

            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {localItems?.map(({ node: response }, index) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                        isDragDisabled={!reOrder}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <TableRow
                              key={index}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                            >
                              {reOrder ? (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  id="td-center"
                                  className="inst-config__masters--table--slno"
                                >
                                  =
                                </TableCell>
                              ) : null}
                              <TableCell
                                {...draggableProvided.dragHandleProps}
                                id="td-center"
                                className="inst-config__masters--table--slno"
                              >
                                {index + 1}
                              </TableCell>
                              {USE_BRANCH_KEY ? (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  onClick={() =>
                                    navigate(`${response.id}/branch`)
                                  }
                                  className="inst-config__masters--table--name"
                                >
                                  {response.dept_desc}
                                </TableCell>
                              ) : USE_CLASS_KEY ? (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  onClick={async () => {
                                    const branchResult = await BranchData({
                                      variables: {
                                        dept_id: response.id,
                                        token,
                                      },
                                    });
                                    if (branchResult.data) {
                                      navigate(
                                        `${response.id}/branch/${branchResult.data.GetMstInstBranches.edges[0]?.node.id}/class`
                                      );
                                    }
                                  }}
                                >
                                  {response.dept_desc}
                                </TableCell>
                              ) : USE_SEMESTER_KEY ? (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  onClick={async () => {
                                    const branchResult = await BranchData({
                                      variables: {
                                        dept_id: response.id,
                                        token,
                                      },
                                    });

                                    if (branchResult.data) {
                                      const classResult = await ClassData({
                                        variables: {
                                          branch_id: [
                                            branchResult.data
                                              .GetBranchNamesByDeptIds[0].id,
                                          ],
                                          token,
                                        },
                                      });
                                      navigate(
                                        `${response.id}/branch/${branchResult.data.GetBranchNamesByDeptIds[0].id}/class/${classResult.data.GetClassNamesByBranchIds[0]?.id}/semester`
                                      );
                                    }
                                  }}
                                >
                                  {response.dept_desc}
                                </TableCell>
                              ) : USE_SECTION_KEY ? (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  onClick={async () => {
                                    const branchResult = await BranchData({
                                      variables: {
                                        dept_id: [response.id!],
                                        token,
                                      },
                                    });
                                    if (branchResult.data) {
                                      const classResult = await ClassData({
                                        variables: {
                                          branch_id: [
                                            branchResult.data
                                              .GetBranchNamesByDeptIds[0].id!,
                                          ],
                                          token,
                                        },
                                      });
                                      if (classResult.data) {
                                        const semesterResult =
                                          await SemesterData({
                                            variables: {
                                              token,
                                              class_id: [
                                                classResult.data
                                                  .GetClassNamesByBranchIds[0]
                                                  ?.id,
                                              ],
                                            },
                                          });
                                        if (semesterResult.data)
                                          navigate(
                                            `${response.id}/branch/${branchResult.data.GetBranchNamesByDeptIds[0].id}/class/${classResult.data.GetClassNamesByBranchIds[0].id}/semester/${semesterResult.data.GetSemesterNamesByClassIds[0].id}/section`
                                          );
                                      }
                                    }
                                  }}
                                >
                                  {response.dept_desc}
                                </TableCell>
                              ) : (
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                >
                                  {response.dept_desc}
                                </TableCell>
                              )}
                              {reOrder === false ? (
                                <TableCell
                                  id="td-center"
                                  {...draggableProvided.dragHandleProps}
                                  className="inst-config__masters--table--actions"
                                >
                                  <>
                                    <img
                                      src={Edit}
                                      alt="/"
                                      onClick={() => {
                                        handleEdit(response.id);
                                      }}
                                    />

                                    <img
                                      src={Delete}
                                      alt="/"
                                      onClick={() => {
                                        handleDelete(response.id);
                                      }}
                                    />
                                  </>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default List;
