import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { licenseTypes } from "../../utils/constants";
import Header from "./Header";
import BreadCrumb from "./BreadCrumb";

interface MenuProps {
  title: string;
  url: string;
}

interface NavProps {
  navType: licenseTypes;
}
interface DropDownProp {
  submenus: MenuProps[];
  dropdown: boolean;
  depthLevel: number;
  url: string;
}
interface MenuItemsProps {
  menu?: string | number;
  url?: string;
  title: string;
  submenu: string[];
  showNavBar: boolean;
}

const Dropdown = ({ submenus, dropdown, depthLevel, url }: DropDownProp) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu: MenuProps, index: React.Key) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          url={url}
        />
      ))}
    </ul>
  );
};

const MenuItems = ({ items, depthLevel }: any) => {
  const [dropdown, setDropdown] = useState(false);
  // const { dispatch, state } = useContext(AppContext);
  const nullRef = useRef<HTMLLIElement>(null);
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        dropdown &&
        nullRef &&
        nullRef.current &&
        !nullRef.current.contains(event.target as Element)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    // Cleanup the event listener
    return () => document.removeEventListener("mousedown", handler);
  }, [dropdown]);

  return (
    <li className="menu-items" ref={nullRef}>
      {items?.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
            className={
              depthLevel > 0 ? "menu-items__submenu" : "menu-items__mainmenu"
            }
          >
            {items.title}

            {depthLevel > 0 ? (
              <span className="menu-items__rightarrow">
                <KeyboardArrowRight />
              </span>
            ) : (
              <span className="arrow" />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            url={items.url}
          />
        </>
      ) : (
        items && (
          <NavLink
            to={items?.url}
            // onClick={() => {
            //   clearGlobalStates(dispatch, state);
            // }}
          >
            {items?.title}
          </NavLink>
        )
      )}
    </li>
  );
};

const Navbar = ({ navType }: NavProps) => {
  // const { state } = useContext(AppContext);

  const Customer = [
    {
      title: "Customers",
      submenu: [
        {
          title: "List",
          url: `/customers`,
        },
      ],
    },
    {
      title: "Tags",
      submenu: [
        {
          title: "Create Tag",
          url: `/tags`,
        },
      ],
    },
  ];
  const Institution = [
    {
      title: "Institutions",
      submenu: [
        {
          title: "List",
          url: "/institutions",
        },
      ],
    },
  ];

  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (navType === licenseTypes.CUSTOMER) {
      setItems(Customer);
    }
    if (navType === licenseTypes.INSTITUTION) {
      setItems(Institution);
    }

    // eslint-disable-next-line
  }, [navType]);
  return (
    <>
      <div className="row g-0">
        <Header />
      </div>
      <div className="row g-0">
        <BreadCrumb />
      </div>

      <nav>
        <ul className="menus">
          {items
            .filter((menu: MenuItemsProps) => menu)
            .map((menu: MenuItemsProps, index: React.Key) => {
              const depthLevel = 0;

              return (
                <MenuItems
                  items={menu}
                  key={index}
                  depthLevel={depthLevel}
                  url={menu.url}
                />
              );
            })}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
