import React from "react";
import { AppProvider } from "./context/context";
import EduateChat from "./EduateChat";

const Eduate = () => {
  return (
    <AppProvider>
      <EduateChat />
    </AppProvider>
  );
};

export default Eduate;
