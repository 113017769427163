import React, { useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { Autocomplete, TextField } from "@mui/material";
import {
  listAutoCompleteStyles,
  listAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { transactionStatusOptions } from "../../../../../utils/constants";
import { optionsType } from "../../../../../utils/Types";
import { All } from "./StudentTransactionIndex";
import TransactionTable from "./TransactionTable";
import { Button } from "../../../../../stories/Button/Button";
import Close from "../../../../../images/Close.svg"
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const AllTransactionsIndex = ({ setModal }: Props) => {
  const classes = listAutoCompleteStyles();
  const textClasses = listAutoCompleteTextStyles();

  const [searchString, setSearchString] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [count, setCount] = useState(0);
  const [queryType, setQueryType] = useState<optionsType>({
    ...All,
    value: All.generalValue,
  });
  const [transactionDate, setTransactionDate] = useState("");
  const handleClear = () => {
    setSearchString("");
    setQueryType(All);
    setStatusCode("");
    setTransactionDate("");
  };
  return (
    <>    <div className="online-transaction__title">
      <Title>All Transactions Details</Title>
      <img src={Close} alt="" />
      </div>
      <div className="all-transaction">
        <div className="all-transaction__select row g-0">
          <div className="col-2">
            <TextField
              className="online-transaction__details--textfield"
              label="Unique Reference No / Voucher No"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={transactionStatusOptions}
              openOnFocus
              forcePopupIcon
              disableClearable
              onChange={(e, newValue) => {
                if (newValue) {
                  setQueryType({
                    label: newValue.label,
                    value: newValue.value,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                  label="Transaction Status"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <TextField
              className="online-transaction__filters--textfield"
              label="Date"
              type="date"
              onChange={(e) => {
                setTransactionDate(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <TransactionTable
          showLogDataBtn={false}
          queryType={queryType}
          statusCode={statusCode}
          transactionDateContainsFold={transactionDate}
          searchString={searchString}
          setCount={setCount}
        />
        <div className="row g-0">
          <div className="col">
            <Button mode="clear" onClick={handleClear} />
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          <div className="col-4">
            <div className="online-transaction__total-count">
              Total Transactions Done : <b>{count}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTransactionsIndex;
