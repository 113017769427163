import React, { useContext, useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import Navbar from "../../../Layouts/Navbar";
import {
  EMPTY_STRING,
  ROWS_PER_PAGE,
  emptyMessageType,
  licenseTypes,
  payloadTypes,
} from "../../../../utils/constants";
import Input from "../../../../stories/Input/Input";
import { Autocomplete, Checkbox, TextField, Tooltip } from "@mui/material";
import {
  listAutoCompleteStyles,
  listAutoCompleteTextStyles,
} from "../../../styles/AutocompleteStyles";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import useMasterTableJson, { TableColumn } from "../json/useTableJson";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DatagridStyles";
import useStudentsbyNewApi, {
  StudentEdges,
} from "../../../../customhooks/useStudentByNodeApi";
import {
  TableHeaderProps,
  msgType,
  responseType,
} from "../../../../utils/Types";
import {
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
} from "../../../../utils/UtilFunctions";
import {
  ColumnVisibilityFor,
  Direction,
  Operation,
  SortBy,
  StudentReportType,
  studentQueryTypes,
} from "../../../../utils/Enum.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import { Keys } from "../../../../utils/Enum.keys";
import useDropdownData from "../../../../customhooks/useDropDown";
import useInstLabels from "../../../../customhooks/useInstLabels";
import More from "../../../../images/More.svg";
import Close from "../../../../images/Close.svg";

import { useStyles } from "../../../styles/TooltipStyles";
import Message from "../../../../images/Message.svg";
import EditBlue from "../../../../images/EditBlue.svg";

import ReactModal from "react-modal";
import {
  DeleteLedgerModalStyles,
  MessageModalStyles,
  StudentPreviewModalStyle,
} from "../../../styles/ModalStyles";
import ForIndividual from "../../Chat/InformationCenter/Channel/ForIndividual/Index";
import Sidebar from "../../../Layouts/Sidebar";
import { AppContext as GlobalAppContext } from "../../../../context/context";

import { payloadTypes as globalPayLoad } from "../../../../utils/constants";

import MessageModal from "../../../../utils/MessageModal";
import ProfilePicUpload from "./Components/Modals/ProfilePicUpload";
import useChannelConfigs from "../../Chat/CustomHooks/useChannelConfigs";
import StudentAcademicDetails from "./Components/StudentPreviewDetails";
import { useMutation } from "@apollo/client";
import { DeleteStudents } from "../../../../queries/students/mutations";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import DeleteModal from "../../../Modals/DeleteModal";
import { GetStudents } from "../../../../queries/students/list";
import useActiveAcademicYear from "../../../../customhooks/useActiveAcademicYear";
import StudentTotalCount from "./Components/StudentsTotalCount";
import LoadingModal from "../../../Modals/LoadingModal";
import Registration from "./StudentRegistration";
import useStudentTableJson from "../../../../customhooks/useStudentTableJson";

const Index = () => {
  const tableClasses = useDataGridStyles();
  const tooltipClasses = useStyles();
  const classes = listAutoCompleteStyles();
  const textClasses = listAutoCompleteTextStyles();

  const { Students } = useMasterTableJson();
  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [sendMessage, setSendMessage] = useState(false);
  const [rowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [uploadPic, setUploadPic] = useState(false);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [selectedIds, setSelecetdIds] = useState<Set<number>>(new Set());
  const [selected, setSelected] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [addStudent, setAddStudent] = useState(false);
  const [studentPreviewModal, setStudentPreviewModal] =
    useState<boolean>(false);

  const { dispatch: globalDispatch } = useContext(GlobalAppContext);
  const { token } = useToken();
  const { instId } = useParams();

  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );

  const [updateStudentDetails, setUpdateStdudentDetails] = useState(false);

  const { enableIndividualChannels } = useChannelConfigs();
  const { user_details } = useLoggedInUserDetails();

  const { activeAcademicYearData } = useActiveAcademicYear();

  const [DeleteStudent, { loading: deleteLoding }] = useMutation(
    DeleteStudents,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const handleClear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setSearchData("");
  };
  const {
    StudentsData: { data, loading, fetchMore },
  } = useStudentsbyNewApi(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    searchData,
    rowsPerPage,
    StudentReportType.GENERAL,
    0,
    []
  );

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const enabledMasters = (ele: TableColumn) => {
    if (ele.field === "dept_desc") return USE_DEPARTMENT_KEY;
    else if (ele.field === "branch_desc") return USE_BRANCH_KEY;
    else if (ele.field === "class_desc") {
      return USE_CLASS_KEY;
    } else if (ele.field === "sem_desc") return USE_SEMESTER_KEY;
    else if (ele.field === "section_desc") return USE_SECTION_KEY;
    else return true;
  };

  const handleSelectAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelecetdIds(new Set(rows.map((row) => row.stdId as number)));
    } else {
      setSelecetdIds(new Set());
    }
    setSelected(!selected);
  };

  const hanldeStudentClick = (id: number, checked: boolean) => {
    const newSet = selectedIds;

    if (checked) {
      newSet.add(id);
      setSelecetdIds(newSet);
    } else {
      newSet.delete(id);
      setSelecetdIds(newSet);
    }

    setSelected(!selected);
  };
  const dynamicHeaders: TableHeaderProps[] = Students.Table_Headers.filter(
    enabledMasters
  ).map((header) => ({
    headerName: header.headerName,
    className: header.cellClassName,
    field: header.field,
    headerAlign: header.headerAlign as GridAlignment,
    align: header.align as GridAlignment,
    flex: header.flex,
  }));
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
      renderHeader: (params) => {
        return (
          <>
            <Checkbox
              checked={selectedIds.size === rows.length}
              onChange={handleSelectAll}
            />
            Sl
          </>
        );
      },
      renderCell: (params) => {
        const stdId = params.row?.stdId || "";

        return (
          <>
            <Checkbox
              checked={
                selectedIds.size === rows.length || selectedIds.has(stdId)
              }
              onChange={(e, checked) =>
                hanldeStudentClick(params.row.stdId, checked)
              }
            />
            {params.row.id}
          </>
        );
      },
    },
    ...dynamicHeaders,
    {
      field: "std_status",
      headerName: "Status",
      cellClassName: "td-status",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,

    },
    {
      field: "actions",
      headerName: "Actions",
      cellClassName: "td-status",
      headerAlign: HEADER_TEXT_ALIGN,
      align:SLNO_TEXT_ALIGN,
      renderCell: (params) => (
        <>
          <Tooltip
            title={
              <ul>
                {enableIndividualChannels ? (
                  <li
                    className="student-list__li"
                    onClick={() => {
                      globalDispatch({
                        type: globalPayLoad.SET_STUDENT_ID,
                        payload: { studentId: params.row.stdId },
                      });
                      setSendMessage(!sendMessage);
                    }}
                  >
                    <img src={Message} alt="" />
                    Message
                  </li>
                ) : null}
                <li
                  className="student-list__li"
                  onClick={() => {
                    globalDispatch({
                      type: globalPayLoad.SET_STUDENT_ID,
                      payload: { studentId: params.row.stdId },
                    });
                    setUploadPic(true);
                  }}
                >
                  <img src={EditBlue} alt="" />
                  Upload Profile Pic
                </li>
              </ul>
            }
            arrow
            placement="top"
            classes={{
              tooltip: tooltipClasses.customTooltipGrey,
              arrow: tooltipClasses.customArrowGrey,
            }}
          >
            <img src={More} alt="" />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleCloseForUploadPic = () => {
    if (studentPreviewModal === false)
      globalDispatch({
        type: globalPayLoad.SET_STUDENT_ID,
        payload: { studentId: 0 },
      });
    setUploadPic(false);
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleCloseForUploadPic();
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleCloseStudentPreviewModal = () => {
    globalDispatch({
      type: globalPayLoad.SET_STUDENT_ID,
      payload: { studentId: 0 },
    });
    setStudentPreviewModal(!studentPreviewModal);
  };

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStudents.edges;
                const pageInfo = fetchMoreResult.GetStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },

    // eslint-disable-next-line
    [rows]
  );
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);

        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_name: node.std_name,
            parent_name: node.parent_name,
            dept_desc: node.dept.dept_desc,
            branch_desc: node.branch.branch_desc,
            class_desc: node.class.class_desc,
            sem_desc: node.semester.sem_desc,
            section_desc: node.section.section_desc,
            std_mobile: node.std_mobile,
            std_email: node.std_email,
            std_status: node.std_status,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_name: node.std_name,
            parent_name: node.parent_name,
            dept_desc: node.dept.dept_desc,
            branch_desc: node.branch.branch_desc,
            class_desc: node.class.class_desc,
            sem_desc: node.semester.sem_desc,
            section_desc: node.section.section_desc,
            std_mobile: node.std_mobile,
            std_email: node.std_email,
            std_status: node.std_status,
          }))
        );
      }
      setEndCursor(data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const handleClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      globalDispatch({
        type: payloadTypes.SET_STUDENT_ID,
        payload: { studentId: params.row.stdId },
      });
      setStudentPreviewModal(!studentPreviewModal);
    }
  };

  const handleDelete = (ids: number[]) => {
    setDeleteModal(!deleteModal);
    DeleteStudent({
      variables: {
        token,
        inst_id: instId,
        user_details,
        student_ids: ids,
      },
      refetchQueries: [
        {
          query: GetStudents,
          variables: {
            after: null,
            first: rowsPerPage === null ? null : rowsPerPage,
            acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
            name: EMPTY_STRING,
            token,
            orderBy: [
              {
                direction: Direction.ASC,
                field: SortBy.STD_NAME,
              },
            ],
            input: {
              ids: [Number(instId)],
              std_query_type: studentQueryTypes.BY_INST_ID,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteStudents) {
        setMessage({
          flag: true,
          message: `Successfully deleted ${
            ids.length > 1 ? "students" : "student"
          }`,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,

      is_login_created: false,
      std_roll_no: false,
      dept: false,
      branch: false,
      class: false,
      sem: false,
      sec: false,
    });
    useEffect(() => {
      const savedVisibilityModel = localStorage.getItem(
        ColumnVisibilityFor.STUDENT_LIST
      );
      if (savedVisibilityModel) {
        setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
      }
    }, []);
  
    useEffect(() => {
      localStorage.setItem(
        ColumnVisibilityFor.STUDENT_LIST,
        JSON.stringify(columnVisibilityModel)
      );
    }, [columnVisibilityModel]);
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="student-list">
        <Sidebar />
        <div className="student-list__frame">
          <Title>Student List</Title>

          <div className="student-list__filters row g-0">
            <div className="col-1">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchData(e.target.value);
                }}
                value={searchData}
                onKeyDown={handleFormEvent}
              />{" "}
            </div>
            {USE_DEPARTMENT_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={departmentDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, departmentSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && departmentSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setDepartmentSelected(null);
                    }
                  }}
                  openOnFocus
                  value={departmentSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setDepartmentSelected(newValue);
                      setHasNextPage(true);
                    } else {
                      setDepartmentSelected(null);
                    }
                    setBranchSelected(null);
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_BRANCH_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={branchDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, branchSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && branchSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setBranchSelected(null);
                    }
                  }}
                  openOnFocus
                  value={branchSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setBranchSelected(newValue);
                      setHasNextPage(true);
                    } else {
                      setBranchSelected(null);
                    }
                    setClassSelected(null);
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={branchLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_CLASS_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={classDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, classSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && classSelected) {
                      handleMUISelectEvent(e);
                    }

                    if (e.key === Keys.BACKSPACE) {
                      setClassSelected(null);
                    }
                  }}
                  openOnFocus
                  value={classSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setClassSelected(newValue);
                      setHasNextPage(true);
                    } else {
                      setClassSelected(null);
                    }
                    setSemesterSelected(null);
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={classLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                      fullWidth
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SEMESTER_KEY ? (
              <div className="col-1">
                <Autocomplete
                  classes={classes}
                  options={semesterDropDown}
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, semesterSelected)
                  }
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && semesterSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSemesterSelected(null);
                    }
                  }}
                  openOnFocus
                  value={semesterSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSemesterSelected(newValue);
                      setHasNextPage(true);
                    } else {
                      setSemesterSelected(null);
                    }
                    setSectionSelected(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={semesterLabel}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            {USE_SECTION_KEY ? (
              <div className="col-1">
                <Autocomplete
                  isOptionEqualToValue={(option) =>
                    isOptionEqualToValue(option, sectionSelected)
                  }
                  classes={classes}
                  options={sectionDropDown}
                  onKeyDown={(e) => {
                    if (e.key === Keys.ENTER && sectionSelected) {
                      handleMUISelectEvent(e);
                    }
                    if (e.key === Keys.BACKSPACE) {
                      setSectionSelected(null);
                    }
                  }}
                  openOnFocus
                  value={sectionSelected}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSectionSelected(newValue);
                      setHasNextPage(true);
                    } else {
                      setSectionSelected(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={sectionLabel}
                      InputLabelProps={{ shrink: true }}
                      classes={{ root: textClasses.formControlRoot }}
                    />
                  )}
                />
              </div>
            ) : null}
            <div className="col"></div>
            <div className="col-3 flex-end">
              <Button mode="clear" onClick={handleClear} />
              <Button
                mode="addnew"
                onClick={() => {
                  setAddStudent(true);
                }}
              />
            </div>
          </div>

          <div className={`student-list__tableblock ${tableClasses.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              onCellClick={handleClick}
              disableRowSelectionOnClick
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </div>
          {/* <Button mode="pdf" />
          <Button mode="download-excel" /> */}
          <div className="row g-0">
            <div className="col-3">
              <Button
                mode="delete"
                onClick={() => setDeleteModal(!deleteModal)}
              />
            </div>
            <div className="col"></div>
            <div className="col-5 flex-end">
              <StudentTotalCount
                totalCount={data ? data.GetStudents.totalCount : 0}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={studentPreviewModal}
        ariaHideApp={false}
        style={StudentPreviewModalStyle}
      >
        <div className="student-preview">
          <div className="student-preview__title">
            <Title>Student Details</Title>
            <img src={Close} alt="" onClick={handleCloseStudentPreviewModal} />
          </div>
          <StudentAcademicDetails />
          <Button
            mode="update"
            onClick={() => setUpdateStdudentDetails(!updateStudentDetails)}
          >
            {" "}
            Student Details
          </Button>
          <Button mode="update" onClick={() => setUploadPic(!uploadPic)}>
            profile pic
          </Button>
          <Button mode="cancel" onClick={handleCloseStudentPreviewModal} />
        </div>
      </ReactModal>
      <ReactModal
        isOpen={sendMessage}
        ariaHideApp={false}
        style={MessageModalStyles}
      >
        <ForIndividual setModal={setSendMessage} />
      </ReactModal>
      <ReactModal
        isOpen={addStudent}
        ariaHideApp={false}
        style={DeleteLedgerModalStyles}
      >
        <Registration operation={Operation.CREATE} setModal={setAddStudent} />
      </ReactModal>

      <ReactModal
        isOpen={updateStudentDetails}
        ariaHideApp={false}
        style={DeleteLedgerModalStyles}
      >
        <Registration
          operation={Operation.UPDATE}
          setModal={setUpdateStdudentDetails}
        />
      </ReactModal>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <ProfilePicUpload
        handleClose={handleCloseForUploadPic}
        modal={uploadPic}
        setMessage={setMessage}
        setModal={setUploadPic}
      />
      <DeleteModal
        id={[...selectedIds]}
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleMultipleDelete={handleDelete}
      />

      <LoadingModal flag={deleteLoding} />
    </>
  );
};

export default Index;
