export enum ReceiptTypes {
  STUDENT_RECEIPT = "S",
  PYMT = "P",
  RCPT = "R",
  CONTRA = "C",
  JOURNAL = "J",
  NONE = "NONE",
}
export enum InstitutionDetails {
  INSTITUTION = "INSTITUTION",
  DEPARTMENT = "DEPARTMENT",
  BRANCH = "BRANCH",
  CLASS = "CLASS",
  SEMESTER = "SEMESTER",
  SECTION = "SECTION",
  CATEGORY = "CATEGORY",
  FINANCIAL_YEAR = "FINANCIAL_YEAR",
  TABLE_NONE = "TABLE_NONE",
  COMPLETEDETAILS = "COMPLETEDETAILS",
}
export enum InstitutionConfigurationTypes {
  INSTITUTION_USE_DEPARTMENT = "INSTITUTION_USE_DEPARTMENT",
  INSTITUTION_USE_BRANCH = "INSTITUTION_USE_BRANCH",
  INSTITUTION_USE_CLASS = "INSTITUTION_USE_CLASS",
  INSTITUTION_USE_SEMESTER = "INSTITUTION_USE_SEMESTER",
  INSTITUTION_USE_SECTION = "INSTITUTION_USE_SECTION",
  INSTITUTION_USE_CATEGORY = "INSTITUTION_USE_CATEGORY",
  INSTITUTION_USE_ADM_NUMBER = "STD_ADMISSION_NUMBER",
  ENABLE_STUDENT_LOGIN = "ENABLE_STUDENT_LOGIN",
  ENABLE_PARENT_LOGIN = "ENABLE_PARENT_LOGIN",
  ENABLE_EMPLOYEE_LOGIN = "ENABLE_EMPLOYEE_LOGIN",
  STD_ADM_NUMBER_EDIT = "STD_ADM_NUMBER_EDIT",
  USE_EDUATE_LOGO_IN_FILES = "USE_EDUATE_LOGO_IN_FILES",
  DEFAULT_PASSWORD = "DEFAULT_PASSWORD",
  ENABLE_MIDDLE_NAME = "ENABLE_MIDDLE_NAME",
  ENABLE_LAST_NAME = "ENABLE_LAST_NAME",
  ATTACHMENT_FILE_SIZE = "ATTACHMENT_FILE_SIZE",
  ATTACHMENT_MEDIA_SIZE = "ATTACHMENT_MEDIA_SIZE",
  INST_BANNER_1 = "INST_BANNER_1",
  INST_BANNER_2 = "INST_BANNER_2",
  STD_REG_NUMBER_AUTO_INCREMENT = "STD_REG_NUMBER_AUTO_INCREMENT",
  EDIT_STD_BRANCH_DETAILS = "EDIT_STD_BRANCH_DETAILS",
  INACTIVITY_TIMEOUT = "INACTIVITY_TIMEOUT",
  CREATE_ANONYMOUS_STUDENT = "CREATE_ANONYMOUS_STUDENT",
  ENABLE_SCHOLARSHIP = "ENABLE_SCHOLARSHIP",
  PAYMENT_GATEWAY = "PAYMENT_GATEWAY",
  ENABLE_PER_SESSION_ATTENDANCE = "ENABLE_PER_SESSION_ATTENDANCE",
  TOAST_MSG_TIMEOUT = "TOAST_MSG_TIMEOUT",
  CAN_STUDENT_UPDATE_PROFILE_PHOTO = "CAN_STUDENT_UPDATE_PROFILE_PHOTO",
  SUBJECT_ALLOCATION_LEVEL = "SUBJECT_ALLOCATION_LEVEL",
}
export enum ChatConfigKeys {
  ENABLE_MY_CAMPUS_FEEDS = "ENABLE_MY_CAMPUS_FEEDS",
  ENABLE_ANNOUNCEMENTS = "ENABLE_ANNOUNCEMENTS",
  ENABLE_CLASS_LEVEL_CHANNELS = "ENABLE_CLASS_LEVEL_CHANNELS",
  ENABLE_INDIVIDUAL_CHANNELS = "ENABLE_INDIVIDUAL_CHANNELS",
  ENABLE_TO_SEND_BIRTHDAY_MESSAGES = "ENABLE_TO_SEND_BIRTHDAY_MESSAGES",
}
export enum SwConfigQueryType {
  BY_CONFIG_KEY = "BY_CONFIG_KEY",
  PAYROLL_CONFIG_FLAGS = "PAYROLL_CONFIG_FLAGS",
  INST_COMPLETE_CONFIG = "INST_COMPLETE_CONFIG",
  EDUATE_COMPLETE_CONFIG = "EDUATE_COMPLETE_CONFIG",
  INST_BY_MODULE = "INST_BY_MODULE",
  EDUATE_BY_MODULE = "EDUATE_BY_MODULE",
}

export const enum InstDetails {
  DEFAULT_DEPT_LABEL = "DEFAULT_DEPT_LABEL",
  DEFAULT_BRANCH_LABEL = "DEFAULT_BRANCH_LABEL",
  DEFAULT_CLASS_LABEL = "DEFAULT_CLASS_LABEL",
  DEFAULT_SEMESTER_LABEL = "DEFAULT_SEMESTER_LABEL",
  DEFAULT_SECTION_LABEL = "DEFAULT_SECTION_LABEL",
}
interface ConfigQueryType {
  config_query_type: SwConfigQueryType;
  str_value: string[] | string;
  int_value: number;
}

export interface GetSwConfigVariablesVars {
  token: string;
  inst_id: string | number;
  input: ConfigQueryType;
}

export enum UserType {
  USER = "USER",
  CASHIER = "CASHIER",
  EDUATE = "EDUATE",
  PARENT = "PARENT",
  EMPLOYEE = "EMPLOYEE",
  OWNER = "OWNER",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum Operation {
  UPDATE = "UPDATE",
  CREATE = "CREATE",
  DELETE = "DELETE",
  VIEW = "VIEW",
  NONE = "NONE",
  CLEAR = "CLEAR",
  CLOSE = "CLOSE",
  PRIMARY = "PRIMARY",
}

export enum SortBy {
  CUST_NAME = "CUST_NAME",
  INST_NAME = "INST_NAME",
  FIRST_NAME = "FIRST_NAME",
  EMP_NAME = "EMP_NAME",
  CREATED_AT = "CREATED_AT",
  STD_NAME = "STD_NAME",
  MSG_TPL_DESC = "MSG_TPL_DESC",
  STD_ADM_NO = "STD_ADM_NO",
  TIME_ZONE = "TIME_ZONE",
}

export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PredefinedDataTypes {
  STATE = "STATE",
  CITY = "CITY",
  PLACE = "PLACE",
  BLOODGROUP = "BLOOD_GROUP",
  DISTRICT = "DISTRICT",
  RELIGION = "RELIGION",
  CASTE = "CASTE",
  SUBCASTE = "SUBCASTE",
  CASTE_GROUP = "CASTE_GROUP",
  SC_ST = "SC_ST",
  YESNO = "YESNO",
  RURAL_URBAN = "RURAL_URBAN",
  MOTHER_TONGUE = "MOTHER_TONGUE",
  NATIONALITY = "NATIONALITY",
  FREEPAYMENTSEAT = "FREE_PAYMENT",
  STATEORINTERSTATE = "PUR_STATE",
  SEATGOTTHROUGH = "SEAT_GOT_THROUGH",
  CATEGORY = "CATEGORY",
  MARTIAL_STATUS = "MARTIAL_STATUS",
  STUDENT_STATUS = "STUDENT_STATUS",
  STUDENT_FEE_TYPE = "STUDENT_FEE_TYPE",
  PARENT_TYPE = "PARENT_TYPE",
  STAFF_TYPE = "STAFF_TYPE",
  GENDER = "GENDER",
  CR_DB = "CR_DB",
  BANK_COLLECTION = "BANK_COLLECTION",
  BOOK_STATUS = "BOOK_STATUS",
  OCCUPATION = "OCCUPATION",
  LIB_DONOR_TYPE = "LIB_DONOR_TYPE",
  EARNING_DEDUCTION = "EARNING_DEDUCTION",
  SAL_TYPE = "SAL_TYPE",
  DEDUCTION_LEDGER = "DEDUCTION_LEDGER",
  TEST_TYPE = "TEST_TYPE",
  HOLIDAY_TYPE = "HOLIDAY_TYPE",
  HOLIDAY_FULL_HALF_DAY = "HOLIDAY_FULL_HALF_DAY",
  CERTIFICATE = "CERTIFICATE",
  LEAVE_TYPE = "LEAVE_TYPE",
  ENQ_FOLLOW_UP_STATUS = "ENQ_FOLLOW_UP_STATUS",
  ENQ_REFERENACE_FROM = "ENQ_REFERENACE_FROM",
}

export enum ReturnType {
  RESPONSE_TYPE = "RESPONSE_TYPE",
  QUERY_TYPE = "QUERY_TYPE",
  BOTH = "BOTH",
  VALUE_SAME_AS_LABEL = "VALUE_SAME_AS_LABEL",
  WITH_ID = "WITH_ID",
}

export enum PageFor {
  MODAL = "MODAL",
  GENERAL = "GENERAL",
}

export enum LicenseTypes {
  ACCOUNTS = "ACCOUNTS",
  ACADEMIC = "ACADEMIC",
  LIBRARY = "LIBRARY",
  TRANSPORT = "TRANSPORT",
  ENQUIRY = "ENQUIRY",
  PAYROLL = "PAYROLL",
  EDUATE_CONFIGURATION = "EDUATE_CONFIGURATION",
  USER_RIGHTS = "USER_RIGHTS",
  INSTITUTION_CONFIG = "INSTITUTION_CONFIG",
  MASTERS = "MASTERS",
  EDUATE_GENERAL = "EDUATE_GENERAL",
  EDUATE_CUSTOMER = "EDUATE_CUSTOMER",
  ADMISSION = "ADMISSION",
}
export enum ExcelAlignment {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center",
  MIDDLE = "middle",
}
export enum ExcelPageHeader {
  YEAR = "  Fin-Year: ",
  DATE = "As on Date :",
  INST_CONFIG_DATA = "InstConfigData",
}

export enum InstTreeQueryType {
  TREE_BY_INST_ID = "TREE_BY_INST_ID",
  TREE_BY_DEPT_ID = "TREE_BY_DEPT_ID",
  TREE_BY_BRANCH_ID = "TREE_BY_BRANCH_ID",
  TREE_BY_CLASS_ID = "TREE_BY_CLASS_ID",
  TREE_BY_SEMESTER_ID = "TREE_BY_SEMESTER_ID",
  TREE_BY_SECTION_ID = "TREE_BY_SECTION_ID",
}
export enum AcdSubjectAllocationData {
  CLASS = "CLASS",
  SEMESTER = "SEM",
  SECTION = "SEC",
  DEPT = "DEPT",
  BRANCH = "BRANCH",
}
export enum StudentStatus {
  CUR = "CUR",
  TC = "TC",
  DET = "DET",
  NE = "NE",
  NXT = "NXT",
  SOA = "SOA",
}

export enum studentQueryTypes {
  //general student details
  BY_INST_ID = "BY_INST_ID",
  BY_DEPT_ID = "BY_DEPT_ID",
  BY_BRANCH_ID = "BY_BRANCH_ID",
  BY_CLASS_ID = "BY_CLASS_ID",
  BY_SEMESTER_ID = "BY_SEMESTER_ID",
  BY_SECTION_ID = "BY_SECTION_ID",
  BY_CATEGORY_ID = "BY_CATEGORY_ID",
  BY_DEPT_AND_CATEGORY_ID = "BY_DEPT_AND_CATEGORY_ID",
  BY_BRANCH_AND_CATEGORY_ID = "BY_BRANCH_AND_CATEGORY_ID",
  BY_CLASS_AND_CATEGORY_ID = "BY_CLASS_AND_CATEGORY_ID",
  BY_SEMESTER_AND_CATEGORY_ID = "BY_SEMESTER_AND_CATEGORY_ID",
  BY_SECTION_AND_CATEGORY_ID = "BY_SECTION_AND_CATEGORY_ID",
  //students by not allocated section

  SECTION_NOT_ALLOC_BY_INST_ID = "SECTION_NOT_ALLOC_BY_INST_ID",
  SECTION_NOT_ALLOC_BY_DEPT_ID = "SECTION_NOT_ALLOC_BY_DEPT_ID",
  SECTION_NOT_ALLOC_BY_BRANCH_ID = "SECTION_NOT_ALLOC_BY_BRANCH_ID",
  SECTION_NOT_ALLOC_BY_CLASS_ID = "SECTION_NOT_ALLOC_BY_CLASS_ID",
  SECTION_NOT_ALLOC_BY_SEMESTER_ID = "SECTION_NOT_ALLOC_BY_SEMESTER_ID",
  SECTION_NOT_ALLOC_BY_CATEGORY_ID = "SECTION_NOT_ALLOC_BY_CATEGORY_ID",

  //eligible students
  STATUS_EQ_ACTIVE_BY_INST_ID = "STATUS_EQ_ACTIVE_BY_INST_ID",
  STATUS_EQ_ACTIVE_BY_DEPT_ID = "STATUS_EQ_ACTIVE_BY_DEPT_ID",
  STATUS_EQ_ACTIVE_BY_BRANCH_ID = "STATUS_EQ_ACTIVE_BY_BRANCH_ID",
  STATUS_EQ_ACTIVE_BY_CLASS_ID = "STATUS_EQ_ACTIVE_BY_CLASS_ID",
  STATUS_EQ_ACTIVE_BY_SEMESTER_ID = "STATUS_EQ_ACTIVE_BY_SEMESTER_ID",
  STATUS_EQ_ACTIVE_BY_SECTION_ID = "STATUS_EQ_ACTIVE_BY_SECTION_ID",

  //not eligible
  STATUS_NEQ_CUR_BY_INST_ID = "STATUS_NEQ_CUR_BY_INST_ID",
  STATUS_NEQ_CUR_BY_DEPT_ID = "STATUS_NEQ_CUR_BY_DEPT_ID",
  STATUS_NEQ_CUR_BY_BRANCH_ID = "STATUS_NEQ_CUR_BY_BRANCH_ID",
  STATUS_NEQ_CUR_BY_CLASS_ID = "STATUS_NEQ_CUR_BY_CLASS_ID",
  STATUS_NEQ_CUR_BY_SEMESTER_ID = "STATUS_NEQ_CUR_BY_SEMESTER_ID",
  STATUS_NEQ_CUR_BY_SECTION_ID = "STATUS_NEQ_CUR_BY_SECTION_ID",
  STATUS_NEQ_CUR_BY_CATEGORY_ID = "STATUS_NEQ_CUR_BY_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_DEPT_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_DEPT_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_BRANCH_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_BRANCH_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_CLASS_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_CLASS_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_SEMESTER_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_SEMESTER_AND_CATEGORY_ID",
  STATUS_NEQ_CUR_BY_SECTION_AND_CATEGORY_ID = "STATUS_NEQ_CUR_BY_SECTION_AND_CATEGORY_ID",
  //Students not Registered
  NOT_REGISTERED_BY_INST_ID = "NOT_REGISTERED_BY_INST_ID",
  NOT_REGISTERED_BY_DEPT_ID = "NOT_REGISTERED_BY_DEPT_ID",
  NOT_REGISTERED_BY_BRANCH_ID = "NOT_REGISTERED_BY_BRANCH_ID",
  NOT_REGISTERED_BY_CLASS_ID = "NOT_REGISTERED_BY_CLASS_ID",
  NOT_REGISTERED_BY_SEMESTER_ID = "NOT_REGISTERED_BY_SEMESTER_ID",
  NOT_REGISTERED_BY_SECTION_ID = "NOT_REGISTERED_BY_SECTION_ID",

  //Library Book Clearance List
  LIB_BOOK_CLEARANCE_BY_INST_ID = "LIB_BOOK_CLEARANCE_BY_INST_ID",
  LIB_BOOK_CLEARANCE_BY_DEPT_ID = "LIB_BOOK_CLEARANCE_BY_DEPT_ID",
  LIB_BOOK_CLEARANCE_BY_BRANCH_ID = "LIB_BOOK_CLEARANCE_BY_BRANCH_ID",
  LIB_BOOK_CLEARANCE_BY_CLASS_ID = "LIB_BOOK_CLEARANCE_BY_CLASS_ID",
  LIB_BOOK_CLEARANCE_BY_SEMESTER_ID = "LIB_BOOK_CLEARANCE_BY_SEMESTER_ID",
  LIB_BOOK_CLEARANCE_BY_SECTION_ID = "LIB_BOOK_CLEARANCE_BY_SECTION_ID",
  //students by channelId
  BY_MSG_CHANNEL_ID = "BY_MSG_CHANNEL_ID",
  //student Reservation
  STATUS_EQ_NXT_BY_INST_ID = "STATUS_EQ_NXT_BY_INST_ID",
  STATUS_EQ_NXT_BY_DEPT_ID = "STATUS_EQ_NXT_BY_DEPT_ID",
  STATUS_EQ_NXT_BY_BRANCH_ID = "STATUS_EQ_NXT_BY_BRANCH_ID",
  STATUS_EQ_NXT_BY_CLASS_ID = "STATUS_EQ_NXT_BY_CLASS_ID",
  STATUS_EQ_NXT_BY_SEMESTER_ID = "STATUS_EQ_NXT_BY_SEMESTER_ID",
  STATUS_EQ_NXT_BY_SECTION_ID = "STATUS_EQ_NXT_BY_SECTION_ID",
  STATUS_EQ_NXT_BY_CATEGORY_ID = "STATUS_EQ_NXT_BY_CATEGORY_ID",
  STATUS_EQ_NXT_BY_DEPT_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_DEPT_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_BRANCH_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_BRANCH_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_CLASS_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_CLASS_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_SEMESTER_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_SEMESTER_AND_CATEGORY_ID",
  STATUS_EQ_NXT_BY_SECTION_AND_CATEGORY_ID = "STATUS_EQ_NXT_BY_SECTION_AND_CATEGORY_ID",

  //new Admission students
  NEW_ADMISSIONS_BY_INST_ID = "NEW_ADMISSIONS_BY_INST_ID",
  NEW_ADMISSIONS_BY_DEPT_ID = "NEW_ADMISSIONS_BY_DEPT_ID",
  NEW_ADMISSIONS_BY_BRANCH_ID = "NEW_ADMISSIONS_BY_BRANCH_ID",
  NEW_ADMISSIONS_BY_CLASS_ID = "NEW_ADMISSIONS_BY_CLASS_ID",
  NEW_ADMISSIONS_BY_SEMESTER_ID = "NEW_ADMISSIONS_BY_SEMESTER_ID",
  NEW_ADMISSIONS_BY_SECTION_ID = "NEW_ADMISSIONS_BY_SECTION_ID",
  NEW_ADMISSIONS_BY_CATEGORY_ID = "NEW_ADMISSIONS_BY_CATEGORY_ID",
  NEW_ADMISSIONS_BY_DEPT_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_DEPT_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_BRANCH_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_BRANCH_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_CLASS_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_CLASS_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_SEMESTER_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_SEMESTER_AND_CATEGORY_ID",
  NEW_ADMISSIONS_BY_SECTION_AND_CATEGORY_ID = "NEW_ADMISSIONS_BY_SECTION_AND_CATEGORY_ID",

  //By Religion
  BY_RELIGION_AND_INST_ID = "BY_RELIGION_AND_INST_ID",
  BY_RELIGION_AND_DEPT_ID = "BY_RELIGION_AND_DEPT_ID",
  BY_RELIGION_AND_BRANCH_ID = "BY_RELIGION_AND_BRANCH_ID",
  BY_RELIGION_AND_CLASS_ID = "BY_RELIGION_AND_CLASS_ID",
  BY_RELIGION_AND_SEMESTER_ID = "BY_RELIGION_AND_SEMESTER_ID",
  BY_RELIGION_AND_SECTION_ID = "BY_RELIGION_AND_SECTION_ID",
  BY_RELIGION_AND_CATEGORY_ID = "BY_RELIGION_AND_CATEGORY_ID",
  BY_RELIGION_AND_DEPT_AND_CATEGORY_ID = "BY_RELIGION_AND_DEPT_AND_CATEGORY_ID",
  BY_RELIGION_AND_BRANCH_AND_CATEGORY_ID = "BY_RELIGION_AND_BRANCH_AND_CATEGORY_ID",
  BY_RELIGION_AND_CLASS_AND_CATEGORY_ID = "BY_RELIGION_AND_CLASS_AND_CATEGORY_ID",
  BY_RELIGION_AND_SEMESTER_AND_CATEGORY_ID = "BY_RELIGION_AND_SEMESTER_AND_CATEGORY_ID",
  BY_RELIGION_AND_SECTION_AND_CATEGORY_ID = "BY_RELIGION_AND_SECTION_AND_CATEGORY_ID",

  ANONYMOUS_STUDENT = "ANONYMOUS_STUDENT",
  SOCIAL_WELFARE_STUDENT = "SOCIAL_WELFARE_STUDENT",
  AGENT_STUDENT = "AGENT_STUDENT",
  ALL_ANONYMOUS_STUDENTS = "ALL_ANONYMOUS_STUDENTS",
  STDS_BY_ADMISSION_NUMS = "STDS_BY_ADMISSION_NUMS",

  //By Age
  BY_AGE_AND_INST_ID = "BY_AGE_AND_INST_ID",
  BY_AGE_AND_DEPT_ID = "BY_AGE_AND_DEPT_ID",
  BY_AGE_AND_BRANCH_ID = "BY_AGE_AND_BRANCH_ID",
  BY_AGE_AND_CLASS_ID = "BY_AGE_AND_CLASS_ID",
  BY_AGE_AND_SEMESTER_ID = "BY_AGE_AND_SEMESTER_ID",
  BY_AGE_AND_SECTION_ID = "BY_AGE_AND_SECTION_ID",
  BY_AGE_AND_CATEGORY_ID = "BY_AGE_AND_CATEGORY_ID",
  BY_AGE_AND_DEPT_AND_CATEGORY_ID = "BY_AGE_AND_DEPT_AND_CATEGORY_ID",
  BY_AGE_AND_BRANCH_AND_CATEGORY_ID = "BY_AGE_AND_BRANCH_AND_CATEGORY_ID",
  BY_AGE_AND_CLASS_AND_CATEGORY_ID = "BY_AGE_AND_CLASS_AND_CATEGORY_ID",
  BY_AGE_AND_SEMESTER_AND_CATEGORY_ID = "BY_AGE_AND_SEMESTER_AND_CATEGORY_ID",
  BY_AGE_AND_SECTION_AND_CATEGORY_ID = "BY_AGE_AND_SECTION_AND_CATEGORY_ID",
  //Transport Service
  AVAILED_TRANSPORT_BY_INST_ID = "AVAILED_TRANSPORT_BY_INST_ID",
  AVAILED_TRANSPORT_BY_DEPT_ID = "AVAILED_TRANSPORT_BY_DEPT_ID",
  AVAILED_TRANSPORT_BY_BRANCH_ID = "AVAILED_TRANSPORT_BY_BRANCH_ID",
  AVAILED_TRANSPORT_BY_CLASS_ID = "AVAILED_TRANSPORT_BY_CLASS_ID",
  AVAILED_TRANSPORT_BY_SEMESTER_ID = "AVAILED_TRANSPORT_BY_SEMESTER_ID",
  AVAILED_TRANSPORT_BY_SECTION_ID = "AVAILED_TRANSPORT_BY_SECTION_ID",

  NOT_AVAILED_TRANSPORT_BY_INST_ID = "NOT_AVAILED_TRANSPORT_BY_INST_ID",
  NOT_AVAILED_TRANSPORT_BY_DEPT_ID = "NOT_AVAILED_TRANSPORT_BY_DEPT_ID",
  NOT_AVAILED_TRANSPORT_BY_BRANCH_ID = "NOT_AVAILED_TRANSPORT_BY_BRANCH_ID",
  NOT_AVAILED_TRANSPORT_BY_CLASS_ID = "NOT_AVAILED_TRANSPORT_BY_CLASS_ID",
  NOT_AVAILED_TRANSPORT_BY_SEMESTER_ID = "NOT_AVAILED_TRANSPORT_BY_SEMESTER_ID",
  NOT_AVAILED_TRANSPORT_BY_SECTION_ID = "NOT_AVAILED_TRANSPORT_BY_SECTION_ID",

  ROUTE_NOT_ASSIGNED_BY_INST_ID = "ROUTE_NOT_ASSIGNED_BY_INST_ID",
  ROUTE_NOT_ASSIGNED_BY_DEPT_ID = "ROUTE_NOT_ASSIGNED_BY_DEPT_ID",
  ROUTE_NOT_ASSIGNED_BY_BRANCH_ID = "ROUTE_NOT_ASSIGNED_BY_BRANCH_ID",
  ROUTE_NOT_ASSIGNED_BY_CLASS_ID = "ROUTE_NOT_ASSIGNED_BY_CLASS_ID",
  ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID = "ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID",
  ROUTE_NOT_ASSIGNED_BY_SECTION_ID = "ROUTE_NOT_ASSIGNED_BY_SECTION_ID",

  BY_CHANNEL_ID = "BY_CHANNEL_ID",
}

export enum StudentReportType {
  GENERAL = "GENERAL",
  STUDENTS_BY_CHANNEL = "STUDENTS_BY_CHANNEL",
  SECTION_ALLOCATION = "SECTION_ALLOCATION",
  ELIGIBLE = "ELIGIBLE",
  PASSED_OUT = "PASSED_OUT",
  BY_ALLOTTED_LEVEL = "BY_ALLOTTED_LEVEL",
  BY_MARKS = "BY_MARKS",
  LIB_BOOK_CLEARANCE = "LIB_BOOK_CLEARANCE",
  TRANSPORT_NOT_ASSIGNED = "TRANSPORT_NOT_ASSIGNED",
  TRANSPORT_ASSIGNED = "TRANSPORT_ASSIGNED",
  NXT_STUDENTS = "NXT_STUDENTS",
  CURR_STUDENTS = "CURR_STUDENTS",
  BY_COMBO_ID_AT_ALLOTED_LEVEL = "BY_COMBO_ID_AT_ALLOTED_LEVEL",
  STDS_BY_ADMISSION_NUMS = "STDS_BY_ADMISSION_NUMS",
  COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL = "COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL",
}
export enum YesNo {
  YES = "Yes",
  NO = "No",
}

export enum ChannelQueryTypeByInstId {
  MYCAMPUSFEED = "MY_CAMPUS_FEEDS",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  MSGS_BY_CHANNEL_ID = "MSGS_BY_CHANNEL_ID",
  ALL_CHANNELS = "ALL_CHANNELS",
}
export const enum MsgChannelType {
  MSGS_BY_CHANNEL_ID = "MSGS_BY_CHANNEL_ID",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  MY_CAMPUS_FEEDS = "MY_CAMPUS_FEEDS",
}
export enum InstDetailType {
  CLASS = "CLASS",
  SEMESTER = "SEM",
  SECTION = "SEC",
  DEPT = "DEPT",
  BRANCH = "BRANCH",
}

export enum PayRollMasterConfigKey {
  PR_USE_EMP_CATEGORY = "PR_USE_EMP_CATEGORY",
  PR_USE_EMP_JOB_TYPE = "PR_USE_EMP_JOB_TYPE",
  PR_USE_EMP_DEPT = "PR_USE_EMP_DEPT",
  PR_USE_EMP_GRADE = "PR_USE_EMP_GRADE",
  PR_USE_EMP_DESIGNATION = "PR_USE_EMP_DESIGNATION",
}
export const DEFAULT_TIME = "1970-01-01";
export enum EduateModule {
  ACCOUNTS = "ACCOUNTS",
  ADMISSION = "ADMISSION",
  LIBRARY = "LIBRARY",
  TRANSPORT = "TRANSPORT",
  PAYROLL = "PAYROLL",
  ACADEMICS = "ACADEMICS",
  ENQUIRY = "ENQUIRY",
  ACADEMICSAPP = "ACADEMICS-APP",
  MESSAGEAPP = "MESSAGE-APP",
}
export enum UserRightsTypes {
  SYSADMIN = "1",
  ADMIN = "2",
  BASIC = "3",
  VIEW = "4",
}

export enum TableHeaders {
  SLNO = "SLNO",
  ADMISSION_NUMBER = "ADMISSION_NUMBER",
  REGISTER_NUMBER = "REGISTER_NUMBER",
  STUDENT_NAME = "STUDENT_NAME",
  ACTION = "Action",
  STATUS = "STATUS",
}

export enum ChannelQueryType {
  MYCAMPUSFEED = "MY-CAMPUS-FEED",
  ANNOUNCEMENTS = "ANNOUNCEMENTS",
  ASSIGNMENTS = "ASSIGNMENTS",
  ENTRYLEVEL = "ENTRY-LEVEL",
  CUSTOM = "CUSTOM",
  INDIVIDUAL = "INDIVIDUAL",
  MULTIPLE = "MULTIPLE",
}

export enum imageFileType {
  png = "png",
  jpg = "jpg",
  jpeg = "jpeg",
}

export enum DebitOrCredit {
  DEBIT = "Dr",
  CREDIT = "Cr",
}

export enum Result {
  PENDING = "P",
  FAILURE = "F",
  SUCCESS = "S",
  NOT_STARTED = "NS",
}

export const enum ChannelType {
  INDIVIDUAL = "INDIVIDUAL",
  INSTITUTION = "INSTITUTION",
  SECTION = "SECTION",
  SEMESTER = "SEMESTER",
  CLASS = "CLASS",
  BRANCH = "BRANCH",
  DEPARTMENT = "DEPARTMENT",
}
export const enum PayFeeTypes {
  CONSOLIDATED = "CONSOLIDATED",
  ALL_LEDGERS = "ALL LEDGERS",
}
export const enum PaymentProcess {
  PROCEEDING = "PROCEEDING",
  DONE = "DONE",
  FAILED = "FAILED",
}

export const enum GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export const enum ColumnVisibilityFor {
  FOR_ACCOUNTS = "FOR_ACCOUNTS",
  FOR_ACADEMICS = "FOR_ACADEMICS",
  FOR_ADMISSIONS = "FOR_ADMISSIONS",
  FOR_LIBRARY = "FOR_LIBRARY",
  FOR_PASS_ACD = "FOR_PASS_ACD",
  FOR_PASS_ACC = "FOR_PASS_ACC",
  FOR_LOGIN_STATUS = "FOR_LOGIN_STATUS",
  FOR_ALUMINI = "FOR_ALUMINI",
  RESERVATION = "RESERVATION",
  FOR_DELETE = "FOR_DELETE",
  FOR_PRINT = "FOR_PRINT",
  DEVICE_DETAILS = "DEVICE_DETAILS",
  STUDENT_LIST="STUDENT_LIST",
  BULK_PHOTO_UPLOAD="BULK_PHOTO_UPLOAD",
  FEE_DETAILS="FEE_DETAILS",
  USER_LOGIN="USER_LOGIN"
}
export enum DeviceType {
  Installed = "Installed",
  Not_Installed = "Not_Installed",
}
export enum StudentAcdType {
  BY_INST_ID = "BY_INST_ID",
  BY_DEPT_ID = "BY_DEPT_ID",
  BY_BRANCH_ID = "BY_BRANCH_ID",
  BY_CLASS_ID = "BY_CLASS_ID",
  BY_SEMESTER_ID = "BY_SEMESTER_ID",
  BY_SECTION_ID = "BY_SECTION_ID",
  BY_ALLOTTED_LEVEL = "BY_ALLOTTED_LEVEL",
  ACD_STD_MARKS_BY_SUBJECT = "ACD_STD_MARKS_BY_SUBJECT",
  LIB_BOOK_CLEARANCE_BY_INST_ID = "LIB_BOOK_CLEARANCE_BY_INST_ID",
  LIB_BOOK_CLEARANCE_BY_DEPT_ID = "LIB_BOOK_CLEARANCE_BY_DEPT_ID",
  LIB_BOOK_CLEARANCE_BY_BRANCH_ID = "LIB_BOOK_CLEARANCE_BY_BRANCH_ID",
  LIB_BOOK_CLEARANCE_BY_CLASS_ID = "LIB_BOOK_CLEARANCE_BY_CLASS_ID",
  LIB_BOOK_CLEARANCE_BY_SEMESTER_ID = "LIB_BOOK_CLEARANCE_BY_SEMESTER_ID",
  LIB_BOOK_CLEARANCE_BY_SECTION_ID = "LIB_BOOK_CLEARANCE_BY_SECTION_ID",
  AVAILED_TRANSPORT_BY_ACD_INST_ID = "AVAILED_TRANSPORT_BY_ACD_INST_ID",
  AVAILED_TRANSPORT_BY_ACD_DEPT_ID = "AVAILED_TRANSPORT_BY_ACD_DEPT_ID",
  AVAILED_TRANSPORT_BY_ACD_BRANCH_ID = "AVAILED_TRANSPORT_BY_ACD_BRANCH_ID",
  AVAILED_TRANSPORT_BY_ACD_CLASS_ID = "AVAILED_TRANSPORT_BY_ACD_CLASS_ID",
  AVAILED_TRANSPORT_BY_ACD_SEMESTER_ID = "AVAILED_TRANSPORT_BY_ACD_SEMESTER_ID",
  AVAILED_TRANSPORT_BY_ACD_SECTION_ID = "AVAILED_TRANSPORT_BY_ACD_SECTION_ID",
  ROUTE_NOT_ASSIGNED_BY_INST_ID = "ROUTE_NOT_ASSIGNED_BY_INST_ID",
  ROUTE_NOT_ASSIGNED_BY_DEPT_ID = "ROUTE_NOT_ASSIGNED_BY_DEPT_ID",
  ROUTE_NOT_ASSIGNED_BY_BRANCH_ID = "ROUTE_NOT_ASSIGNED_BY_BRANCH_ID",
  ROUTE_NOT_ASSIGNED_BY_CLASS_ID = "ROUTE_NOT_ASSIGNED_BY_CLASS_ID",
  ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID = "ROUTE_NOT_ASSIGNED_BY_SEMESTER_ID",
  ROUTE_NOT_ASSIGNED_BY_SECTION_ID = "ROUTE_NOT_ASSIGNED_BY_SECTION_ID",
  ROUTE_ASSIGNED_BY_ACD_INST_ID = "ROUTE_ASSIGNED_BY_ACD_INST_ID",
  ROUTE_ASSIGNED_BY_ACD_DEPT_ID = "ROUTE_ASSIGNED_BY_ACD_DEPT_ID",
  ROUTE_ASSIGNED_BY_ACD_BRANCH_ID = "ROUTE_ASSIGNED_BY_ACD_BRANCH_ID",
  ROUTE_ASSIGNED_BY_ACD_CLASS_ID = "ROUTE_ASSIGNED_BY_ACD_CLASS_ID",
  ROUTE_ASSIGNED_BY_ACD_SEMESTER_ID = "ROUTE_ASSIGNED_BY_ACD_SEMESTER_ID",
  ROUTE_ASSIGNED_BY_ACD_SECTION_ID = "ROUTE_ASSIGNED_BY_ACD_SECTION_ID",
  NXT_STUDENTS_BY_ACD_INST_ID = "NXT_STUDENTS_BY_ACD_INST_ID",
  NXT_STUDENTS_BY_ACD_DEPT_ID = "NXT_STUDENTS_BY_ACD_DEPT_ID",
  NXT_STUDENTS_BY_ACD_BRANCH_ID = "NXT_STUDENTS_BY_ACD_BRANCH_ID",
  NXT_STUDENTS_BY_ACD_CLASS_ID = "NXT_STUDENTS_BY_ACD_CLASS_ID",
  NXT_STUDENTS_BY_ACD_SEMESTER_ID = "NXT_STUDENTS_BY_ACD_SEMESTER_ID",
  NXT_STUDENTS_BY_ACD_SECTION_ID = "NXT_STUDENTS_BY_ACD_SECTION_ID",
  NXT_STUDENTS_BY_CATEGORY_ID = "NXT_STUDENTS_BY_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_DEPT_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_DEPT_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_BRANCH_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_BRANCH_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_CLASS_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_CLASS_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_SEMESTER_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_SEMESTER_ID_AND_CATEGORY_ID",
  NXT_STUDENTS_BY_ACD_SECTION_ID_AND_CATEGORY_ID = "NXT_STUDENTS_BY_ACD_SECTION_ID_AND_CATEGORY_ID",
  CUR_BY_ACD_INST_ID = "CUR_BY_ACD_INST_ID",
  CUR_BY_ACD_DEPT_ID = "CUR_BY_ACD_DEPT_ID",
  CUR_BY_ACD_BRANCH_ID = "CUR_BY_ACD_BRANCH_ID",
  CUR_BY_ACD_CLASS_ID = "CUR_BY_ACD_CLASS_ID",
  CUR_BY_ACD_SEMESTER_ID = "CUR_BY_ACD_SEMESTER_ID",
  CUR_BY_ACD_SECTION_ID = "CUR_BY_ACD_SECTION_ID",
  CUR_BY_ACD_CATEGORY_ID = "CUR_BY_ACD_CATEGORY_ID",
  CUR_BY_ACD_DEPT_AND_CATEGORY_ID = "CUR_BY_ACD_DEPT_AND_CATEGORY_ID",
  CUR_BY_ACD_BRANCH_AND_CATEGORY_ID = "CUR_BY_ACD_BRANCH_AND_CATEGORY_ID",
  CUR_BY_ACD_CLASS_AND_CATEGORY_ID = "CUR_BY_ACD_CLASS_AND_CATEGORY_ID",
  CUR_BY_ACD_SEMESTER_AND_CATEGORY_ID = "CUR_BY_ACD_SEMESTER_AND_CATEGORY_ID",
  CUR_BY_ACD_SECTION_AND_CATEGORY_ID = "CUR_BY_ACD_SECTION_AND_CATEGORY_ID",
  BY_ENTRY_LEVEL_FOR_MAIN_ELECTIVE_SUBJ = "BY_ENTRY_LEVEL_FOR_MAIN_ELECTIVE_SUBJ",
  BY_ENTRY_LEVEL_AND_BY_SUBJ_ID = "BY_ENTRY_LEVEL_AND_BY_SUBJ_ID",
  ACD_PASSED_STDS_BY_ACD_INST_ID = "ACD_PASSED_STDS_BY_ACD_INST_ID",
  ACD_PASSED_STDS_BY_ACD_DEPT_ID = "ACD_PASSED_STDS_BY_ACD_DEPT_ID",
  ACD_PASSED_STDS_BY_ACD_BRANCH_ID = "ACD_PASSED_STDS_BY_ACD_BRANCH_ID",

  BY_COMBO_ID_AT_ALLOTED_LEVEL = "BY_COMBO_ID_AT_ALLOTED_LEVEL",
  COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL = "COMBO_UNASSIGNED_STDS_AT_ALLOTED_LEVEL",
  PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL = "PER_STUDENT_SUBJECT_ALLOCATION_AT_ENTRY_LEVEL",
  CUR_STD_HAS_DOB_TODAY = "CUR_STD_HAS_DOB_TODAY",
  CUR_STD_HAS_DOB_IN_WEEK = "CUR_STD_HAS_DOB_IN_WEEK",
  CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL = "CUR_STD_HAS_DOB_TODAY_AT_ENTRY_LEVEL",
  CUR_STD_HAS_DOB_IN_WEEK_AT_ENTRY_LEVEL = "CUR_STD_HAS_DOB_IN_WEEK_AT_ENTRY_LEVEL",
}

export enum HRS_MINS {
  HRS = "HRS",
  MINS = "MINS",
}
