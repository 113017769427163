export enum Keys {
  ENTER = "Enter",
  BACKSPACE = "Backspace",
  v = "v",
}

export enum SortBy {
  CUST_NAME = "CUST_NAME",
  STD_NAME = "STD_NAME",
}
