import { InitialStateType } from "./context";
import { Actions } from "./types";
export enum payloadTypes {
  SET_SEARCH_DATA = "SET_SEARCH_DATA",
  SET_DEPT_ID = "SET_DEPT_ID",
  SET_BRANCH_ID = "SET_BRANCH_ID",
  SET_CLASS_ID = "SET_CLASS_ID",
  SET_SEMESTER_ID = "SET_SEMESTER_ID",
  SET_SECTION_ID = "SET_SECTION_ID",
}
export const reducer = (state: InitialStateType, action: Actions) => {
  switch (action.type) {
    case payloadTypes.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload.searchData,
      };
    case payloadTypes.SET_DEPT_ID:
      return { ...state, deptId: action.payload.deptId };

    case payloadTypes.SET_BRANCH_ID:
      return {
        ...state,
        branchId: action.payload.branchId,
      };
    case payloadTypes.SET_CLASS_ID:
      return { ...state, classId: action.payload.classId };
    case payloadTypes.SET_SEMESTER_ID:
      return { ...state, semId: action.payload.semId };
    case payloadTypes.SET_SECTION_ID:
      return { ...state, sectionId: action.payload.sectionId };

    default:
      return state;
  }
};
