import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "./useToken";
import { responseType } from "../utils/Types";
import { PageInfo } from "../components/Modules/Chat/Feeds/Types";
import { EmpDetails } from "../components/Modules/Chat/UserRights/Types";
import { Direction, SortBy } from "../utils/Enum.types";
import { GetMsgEmp } from "../queries/userrights";

export interface PayRollEmpEdges {
  node: EmpDetails;
}
export interface PayRoleEmpList {
  edges: PayRollEmpEdges[];
  pageInfo: PageInfo;
  totalCount: number;
}
export interface PayRoleEmpvars {
  name: string;
  inst_id: string;
  after: string | null;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
  first: number;
  token: string;
}
export interface PayRollEmpData {
  GetMsgEmp: PayRoleEmpList;
}

const useEmployee = (rowsPerPage: number, name: string) => {
  const { token } = useToken();
  const { instId } = useParams();

  const [responseType, setResponseType] = useState<responseType[]>([]);

  const [GetEmployees, { data, loading, error, fetchMore }] = useLazyQuery<
    PayRollEmpData,
    PayRoleEmpvars
  >(GetMsgEmp, {
    variables: {
      after: null,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.EMP_NAME,
      },
      first: rowsPerPage,
      name,
      token,
      inst_id: instId!,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token) {
      GetEmployees().then(({ data }) => {
        if (data && data.GetMsgEmp) {
          setResponseType(
            data.GetMsgEmp.edges.map((edge) => ({
              label: edge.node.emp_name,
              value: edge.node.id,
            }))
          );
        }
      });
    }
  }, [GetEmployees, name, token]);
  return { empDetails: { data, loading, error, fetchMore, responseType } };
};

export default useEmployee;
