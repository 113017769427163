import { gql } from "@apollo/client";

export const GetMstInstClasses = gql`
  query GetMstInstClasses(
    $token: String!
    $id: ID!
    $after: Cursor
    $query_type: ClassQueryType!
    $first: Int
    $name: String
    $orderBy: MsgMstInstClassOrder
  ) {
    GetMstInstClasses(
      token: $token
      id: $id
      first: $first
      after: $after
      query_type: $query_type
      orderBy: $orderBy
      where: { or: [{ classDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          class_desc
          class_short_desc
          class_idx
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
