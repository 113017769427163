import { licenseTypes } from "../../../../../utils/constants";
import Navbar from "../../../../Layouts/Navbar";

interface DashBoardProps {
  DashBoardRequired: boolean;
  NavType: licenseTypes.INSTITUTION | licenseTypes.CUSTOMER;
}
const Index = ({ DashBoardRequired, NavType }: DashBoardProps) => {
  return (
    <>
      <Navbar navType={NavType} />
      {DashBoardRequired && <p>Home Page</p>}
    </>
  );
};

export default Index;
