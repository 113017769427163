import { gql } from "@apollo/client";
export const UpdateInstSemester = gql`
  mutation UpdateInstSemester(
    $input: UpdateMsgMstInstSemesterInput!
    $id: ID!
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstSemester(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstSemesterById = gql`
  mutation DeleteInstSemesterById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstSemesterById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ReOrderInstSemester = gql`
  mutation ReOrderInstSemester(
    $token: String!
    $input: [ReOrderMsgMstInstSemesterInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstSemester(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
