import { InstModuleDetails } from "../customhooks/useInstModules";
import {
  ChannelQueryTypeByInstId,
  Direction,
  InstTreeQueryType,
  MsgChannelType,
} from "./Enum.types";

interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
export interface CutomerListNode {
  id: number;
  cust_name: string;
  cust_place: string;
  cust_contact_person: string;
  cust_mobile: string;
}
export interface CutomerListEdge {
  node: CutomerListNode;
  cursor: string;
}
interface CustomerListPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
interface GetCustomers {
  totalCount: number;
  pageInfo: CustomerListPageInfo;
  edges: CutomerListEdge[];
}
export interface GetCustomersData {
  GetCustomers: GetCustomers;
}
export interface GetCustomersVars {
  first: number;
  after: string | null;
  sortBy: string;
  direction: string;
  token: string;
  name: string;
}

export interface userDetails {
  user_type: string;
  id: number;
}
export interface GlobalPageConfigData {
  id: number;
  config_key: string;
  config_form_label_caption: string;
  config_key_used_for_module: string;
  config_key_data_storage_type: string;
  config_depends_on_primary_list_key: boolean;
  config_depends_on_secondary_list_key: boolean;
  config_primary_list_key: string;
  config_secondary_list_key: string;
  config_boolean_value: boolean;
  config_integer_value: number;
  config_double_value: number;
  config_string_value: string;
  config_string_value_2: string;
  config_secondary_list_caption: string;
  config_key_show_index: number;
  inst_id: number;
  isChecked: boolean;
}

export interface GetSwConfigVariablesData {
  GetSwConfigVariables: GlobalPageConfigData[];
}

//GetAuthDetailsByToken
export interface GetAuthDetailsByTokenVars {
  token: string;
}

export interface GetAuthDetailsByTokenData {
  GetAuthDetailsByToken: {
    id: number;
    auth_user_id: string;
    auth_group_type: string;
    auth_is_active: boolean;
    auth_is_blocked: boolean;
    firebase_id: string;
    inst_id: number;
  };
}

export interface GetStudentDetailsByAuthIdVars {
  token: string;
  auth_id: number;
}

export interface GetStudentDetailsByAuthIdData {
  GetStudentDetailsByAuthId: {
    id: number;
    first_name: string;
    fin_yr_id: number;
    inst_id: number;
  };
}

export interface GetEmployeeByAuthIdData {
  GetEmployeeByAuthId: {
    id: number;
    emp_first_name: string;
    inst_id: number;
  };
}
export interface GetEmployeeByAuthIdVars {
  token: string;
  auth_id: number;
}

export interface CheckEmpResetPwdStatusVars {
  token: string;
  inst_id: string;
  msg_emp_id: number;
}

export interface CheckResetPwdStatusData {
  status: boolean;
  message: string;
}

export interface GetEduateDetailsByAuthIdVars {
  token: string;
  auth_id: number;
}

export interface GetEduateDetailsByAuthIdData {
  GetEduateDetailsByAuthId: {
    id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
  };
}

export interface CheckStdResetPwdStatusVars {
  token: string;
  inst_id: string;
  student_id: number;
}

export interface CheckResetStdPwdStatusData {
  CheckStdResetPwdStatus: CheckResetPwdStatusData;
}

export interface CheckResetEmpPwdStatusData {
  CheckEmpResetPwdStatus: CheckResetPwdStatusData;
}

export interface AddCustomerData {
  AddCustomer: boolean;
}

export interface AddCustomerVars {
  input: {
    cust_name: string;
    cust_address: string;
    cust_place: string;
    cust_state: string;
    cust_pin: string;
    cust_contact_person: string;
    cust_phone: string;
    cust_email: string;
    cust_mobile: string;
    cust_banner_1: string;
    cust_banner_2: string;
    cust_url: string;
    cust_logo_filename: string;
  };
  token: string;
  user_details: userDetails;
}

export interface UpdateCustomerData {
  UpdateCustomer: boolean;
}

export interface UpdateCustomerVars {
  input: {
    cust_name: string;
    cust_address: string;
    cust_place: string;
    cust_state: string;
    cust_pin: string;
    cust_contact_person: string;
    cust_phone: string;
    cust_email: string;
    cust_mobile: string;
    cust_url: string;
    cust_banner_1: string;
    cust_banner_2: string;
    cust_logo_filename: string;
  };
  token: string;
  id: number;
  user_details: userDetails;
}

export interface PredefinedDataByTypeListNode {
  id: number;
  index: number;
  type: string;
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  edit: string;
}

export interface PredefinedDataByTypeEdge {
  node: PredefinedDataByTypeListNode;
  cursor: string;
  value: number;
}
export interface PredefinedDataByTypePageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
export interface PredefinedDataByTypeList {
  totalCount: number;
  pageInfo: PredefinedDataByTypePageInfo;
  edges: PredefinedDataByTypeEdge[];
}
export interface PredefinedDataByTypeData {
  GetPredefinedDataByType: PredefinedDataByTypeList;
}

export interface PredefinedDataByTypevars {
  token: string;
  type: string;
  Lname?: string;
  first?: number | null;
  after?: string | null;
  sortBy?: string;
  direction?: string;
}

export interface GetCustomerByNodeData {
  node: {
    cust_name: string;
    cust_address: string;
    cust_place: string;
    cust_state: string;
    cust_pin: string;
    cust_contact_person: string;
    cust_phone: string;
    cust_email: string;
    cust_mobile: string;
    cust_url: string;
    cust_banner_1: string;
    cust_banner_2: string;
    cust_logo_filename: string;
  };
}

export interface NodeVars {
  token: string;
  id: number;
}
export interface nodeVars {
  token: string;
  ids: number[];
}
export interface NodeVars {
  token: string;
  id: number;
}

export interface NodeVarsMultiple {
  token: string;
  ids: number[];
}

export interface InstitutionNode {
  id: number;
  inst_code: string;
  inst_name: string;
  inst_short_name: string;
  inst_address: string;
  inst_place: string;
  inst_state: string;
  inst_pin: string;
  inst_contact_person: string;
  inst_phone: string;
  inst_email: string;
  inst_mobile: string;
  inst_url: string;
  inst_logo_filename: string;
  inst_latitude: number;
  inst_longitude: number;
  cust_name: string;
  inst_time_zone: string;
  bd_msg_hours: number;
  bd_msg_mins: number;
}
export interface InstitutionEdges {
  node: InstitutionNode;
  cursor: string;
}
interface InstitutionListPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}
interface GetInstitutions {
  totalCount: number;
  pageInfo: InstitutionListPageInfo;
  edges: InstitutionEdges[];
}

export interface GetInstitutionsByCustIdData {
  GetInstsByCustId: GetInstitutions;
}

export interface GetInstitutionsVars {
  customer_id?: number;
  name: string;
  first: number | null;
  after: string | null;
  orderBy: { field: string; direction: string };
  token: string;
}

export interface EduateModuleDetails {
  id: number;
  Name: string;
}
export interface GetMstInstActiveModulesData {
  id: number;
  is_module_enabled: boolean;
  eduate_module_details: EduateModuleDetails;
}
export interface GetMstInstActiveModulesDetails {
  GetMstInstActiveModules: GetMstInstActiveModulesData[];
}
export interface GetMstInstActiveModulesVars {
  token: string;
  inst_id: string;
}

export interface UpdateMstInstModuleDetailsData {
  UpdateMstInstModuleDetails: boolean;
}

export interface UpdateMstInstModuleDetailsVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  input: {
    module_master_id: number;
    update_module_master: {
      lic_type: string;
      lic_active: boolean;
      lic_exp_grace_period: number;
      lic_enabled_date: string;
      lic_start_date: string;
      lic_end_date: string;
    };
    module_details: { is_module_enabled: boolean; eduate_module_id: number }[];
  };
}

export interface GetMstInstModulesVars {
  token: string;
  inst_id: string;
  query_type: string;
}

export interface GetMstInstModulesData {
  GetMstInstModules: {
    id: number;
    lic_type: string;
    lic_active: boolean;
    lic_exp_grace_period: number;
    lic_enabled_date: string;
    lic_start_date: string;
    lic_end_date: string;
    inst_module_details: InstModuleDetails[];
  };
}

export interface AcademicYearList {
  id: number;
  acd_yr: string;
  acd_st_date: string;
  acd_end_date: string;
  acd_is_curr_yr: Boolean;
}
export interface AcademicYearListData {
  GetAcdYrsByInstId: AcademicYearList[];
}

export interface AcademicYearListVars {
  inst_id: string | number;
  token: string;
}

export interface AcademicYearNode {
  node: AcademicYearList;
}

//Departments
export interface AddInstDeptData {
  AddInstDept: boolean;
}
export interface AddInstDeptVars {
  input: {
    dept_desc: string;
    dept_short_desc: string;
    inst_id: string;
  };
  token: string;
  user_details: userDetails;
}
export interface DepartmentList {
  id: number;
  dept_desc: string;
  idx: string;
  dept_short_desc: string;
}
export interface DepartmentListEdges {
  node: DepartmentList;
  cursor: string;
}
export interface DepartmentListDataByInstId {
  GetMstInstDepts: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: DepartmentListEdges[];
  };
}
export enum MsgMstInstDeptOrderField {
  DEPT_DESC = "DEPT_DESC",
  DEPT_IDX = "DEPT_IDX",
}
export interface DepartmentListVarsByInstId {
  inst_id: string | number;
  token: string;
  after: string | null;
  first: number | null;
  name: string;
  orderBy: {
    direction: Direction;
    field: MsgMstInstDeptOrderField;
  };
}

export interface UpdateInstDeptData {
  UpdateInstDept: boolean;
}

export interface UpdateInstDeptVars {
  token: string;
  id: number;
  inst_id: string;
  input: {
    dept_desc: string;
    dept_short_desc: string;
  };
  user_details: userDetails;
}

//Branch
export interface AddInstBranchData {
  AddInstBranch: boolean;
}

export interface AddInstBranchVars {
  token: string;
  input: {
    inst_id: string;
    branch_desc: string;
    branch_short_desc: string;
    dept_id: string;
  };
  user_details: userDetails;
}

export interface UpdateInstBranchData {
  UpdateInstBranch: boolean;
}

export interface UpdateInstBranchVars {
  token: string;
  id: number;
  inst_id: string;
  user_details: userDetails;
  input: {
    branch_desc: string;
    branch_short_desc: string;
  };
}
export interface BranchList {
  id: number;
  branch_desc: string;
  branch_short_desc: string;
  branch_idx: number;
  inst_id: number;
  dept_id: number;
}

export interface BranchListEdges {
  node: BranchList;
  cursor: string;
}
export interface BranchListData {
  GetMstInstBranches: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: BranchListEdges[];
  };
}
export enum MsgMstInstBranchOrderField {
  BRANCH_DESC = "BRANCH_DESC",
  BRANCH_IDX = "BRANCH_IDX",
}

export enum BranchQueryType {
  BRANCH_BY_INST_ID = "BRANCH_BY_INST_ID",
  BRANCH_BY_DEPT_ID = "BRANCH_BY_DEPT_ID",
}

export interface BranchListVars {
  id: string | number;
  token: string;
  query_type: BranchQueryType;
  after: string | null;
  first: number | null;
  name: string;
  orderBy: {
    direction: Direction;
    field: MsgMstInstBranchOrderField;
  };
}

//Class
export interface AddInstClassData {
  AddInstClass: boolean;
}
export interface AddInstClassVars {
  input: {
    class_desc: string;
    class_short_desc: string;
    inst_id: string;
    branch_id: string;
  };
  token: string;
  user_details: userDetails;
}

export interface ClassList {
  id: number;
  class_desc: string;
  class_short_desc: string;
  class_idx: number;
  inst_id: number;
  branch_id: number;
}

export interface ClassListEdges {
  node: ClassList;
  cursor: string;
}

export interface ClassListData {
  GetMstInstClasses: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: ClassListEdges[];
  };
}

export enum MsgMstInstClassOrderField {
  CLASS_DESC = "CLASS_DESC",
  CLASS_IDX = "CLASS_IDX",
}

export enum ClassQueryType {
  CLASS_BY_INST_ID = "CLASS_BY_INST_ID",
  CLASS_BY_BRANCH_ID = "CLASS_BY_BRANCH_ID",
}

export interface ClassListVars {
  id: string | number;
  token: string;
  query_type: ClassQueryType;
  after: string | null;
  first: number | null;
  name: string;
  orderBy: {
    direction: Direction;
    field: MsgMstInstClassOrderField;
  };
}

export interface UpdateInstClassData {
  UpdateInstClass: boolean;
}

export interface UpdateInstClassVars {
  token: string;
  id: number;
  inst_id: string;
  input: {
    class_desc: string;
    class_short_desc: string;
  };
  user_details: userDetails;
}

//Semesters

export interface AddInstSemesterData {
  AddInstSemester: boolean;
}

export interface AddInstSemesterVars {
  token: string;
  input: {
    inst_id: string;
    sem_desc: string;
    sem_short_desc: string;
    class_id: string;
  };
  user_details: userDetails;
}

export interface UpdateInstSemesterData {
  UpdateInstSemester: boolean;
}

export interface UpdateInstSemesterVars {
  token: string;
  id: number;
  inst_id: string;
  user_details: userDetails;
  input: {
    sem_desc: string;
    sem_short_desc: string;
  };
}
export interface SemesterList {
  id: number;
  sem_desc: string;
  sem_short_desc: string;
  sem_idx: number;
  inst_id: number;
  class_id: number;
}

export interface SemesterListEdges {
  node: SemesterList;
  cursor: string;
}
export interface SemesterListData {
  GetMstInstSemesters: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: SemesterListEdges[];
  };
}
export enum MsgMstInstSemesterOrderField {
  SEM_DESC = "SEM_DESC",
  SEM_IDX = "SEM_IDX",
}

export enum SemesterQueryType {
  SEMESTER_BY_INST_ID = "SEMESTER_BY_INST_ID",
  SEMESTER_BY_CLASS_ID = "SEMESTER_BY_CLASS_ID",
}

export interface InstIdVars {
  inst_id: string;
  token: string;
}

export interface SemesterListVars {
  id: string | number;
  token: string;
  query_type: SemesterQueryType;
  after: string | null;
  first: number | null;
  name: string;
  orderBy: {
    direction: Direction;
    field: MsgMstInstSemesterOrderField;
  };
}
//Sections
export interface AddInstSectionData {
  AddInstSection: boolean;
}

export interface AddInstSectionVars {
  token: string;
  input: {
    inst_id: string;
    section_desc: string;
    section_short_desc: String;
    semester_id: string;
  };
  user_details: userDetails;
}

export interface UpdateInstSectionData {
  UpdateInstSection: boolean;
}

export interface UpdateInstSectionVars {
  token: string;
  id: number;
  inst_id: string;
  user_details: userDetails;
  input: {
    section_desc: string;
    section_short_desc: String;
  };
}
export interface SectionList {
  id: number;
  section_desc: string;
  section_short_desc: string;
  section_idx: number;
  inst_id: number;
  semester_id: number;
}

export interface SectionListEdges {
  node: SectionList;
  cursor: string;
}
export interface SectionListData {
  GetMstInstSections: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: SectionListEdges[];
  };
}
export enum MsgMstInstSectionOrderField {
  SECTION_DESC = "SECTION_DESC",
  SECTION_IDX = "SECTION_IDX",
}

export enum SectionQueryType {
  SECTION_BY_INST_ID = "SECTION_BY_INST_ID",
  SECTION_BY_SEMESTER_ID = "SECTION_BY_SEMESTER_ID",
}

export interface SectionListVars {
  id: string | number;
  token: string;
  query_type: SectionQueryType;
  after: string | null;
  first: number | null;
  name: string;
  orderBy: {
    direction: Direction;
    field: MsgMstInstSectionOrderField;
  };
}

export interface GetNotAllocatedIdsData {
  GetNotAllocatedIds: {
    dept_id: number;
    branch_id: number;
    class_id: number;
    semester_id: number;
    section_id: number;
  };
}
export enum TableType {
  TABLE_NONE = "TABLE_NONE",
  DEPARTMENT = "DEPARTMENT",
  BRANCH = "BRANCH",
  CLASS = "CLASS",
  SEMESTER = "SEMESTER",
  SECTION = "SECTION",
}
export interface GetNotAllocatedIdsVars {
  token: string;
  inst_id: string;
  table: TableType;
  table_id: 0;
}

export interface InstTreeVars {
  inst_id: string | number;
  token: string;
  id: string | number;
  query_type: InstTreeQueryType;
}

export interface SwConfigStudentDataEntry {
  id: number;
  data_field_name: string;
  data_field_type: string;
  data_used_in_table: string;
  data_field_label: string;
  data_field_enable: boolean;
  data_field_index_order: number;
  data_field_is_list_data: boolean;
  data_field_list_data_key: string;
  data_field_default_used: string;
  inst_id: number;
  data_field_block_number: number;
  data_type?: string;
  data_focus?: boolean;
  maxLength?: number;
}
export interface GetSwConfigStudentDataEntryByInstIdData {
  GetSwConfigStudentDataEntryByInstId: SwConfigStudentDataEntry[];
}
export interface GetSwConfigStudentDataEntryByInstIdVars {
  token: string;
  inst_id: string;
}

export interface ListFinancialYears {
  id: number;
  fin_yr: string;
  fin_is_curr_yr: string;
  fin_st_date: string;
  fin_end_date: string;
}

export interface GetMsgInstTemplatesNode {
  id: number;
  msg_tpl_desc: string;
  inst_module: string;
}
export interface templateEdges {
  node: GetMsgInstTemplatesNode;
}
interface TemplateDetails {
  totalCount: number;
  pageInfo: PageInfo;
  edges: templateEdges[];
}
export interface GetMsgInstTemplatesData {
  GetMsgInstTemplates: TemplateDetails;
}
export interface TemplateOrder {
  direction: Direction;
  field: string;
}
export interface SwMsgTemplates {
  id: number;
  msg_tpl_idx: number;
  msg_tpl_desc: string;
  inst_module: string;
  inst_type: string;
}
export interface EduateTemplateEdges {
  node: SwMsgTemplates;
}
interface GetSwMsgTemplates {
  totalCount: number;
  pageInfo: PageInfo;
  edges: EduateTemplateEdges[];
}
export interface GetSwMsgTemplatesData {
  GetSwMsgTemplates: GetSwMsgTemplates;
}
export interface GetSwMsgTemplatesVars {
  name: string;
  token: string;
  orderBy: TemplateOrder;
  first: number | null;
  after: string | null;
}
export interface GetInstMsgTemplatesVars {
  name: string;
  token: string;
  orderBy: TemplateOrder;
  first: number | null;
  after: string | null;
  inst_id: string | number;
}
export interface GetTemplateByIdData {
  node: SwMsgTemplates;
}
export interface GetInstTemplateByIdData {
  node: GetMsgInstTemplatesNode;
}
export interface GetTemplateByIdVars {
  id: number;
  token: string;
}

export interface GetSwMsgTags {
  id: number;
  tag_name: string;
  tag_details: string;
}

export interface GetSwMsgTagsVars {
  token: string;
}
export interface GetSwMsgTagsData {
  GetSwMsgTags: GetSwMsgTags[];
}

export interface ChannelList {
  id: number;
  channel_name: string;
  channel_desc: string;
  channel_topic: string;
  channel_is_student: string;
  channel_chat_enabled: string;
  channel_type: string;
  channel_active: boolean;
  channel_icon_id: string;
  created_user_type: string;
  inst_id: string;
  created_user_id: number;
}

export interface ChannelListData {
  GetChannelsByInstId: ChannelList[];
}
interface ChannelListInput {
  query_type: ChannelQueryTypeByInstId;
  channel_id: number;
}
export interface ChannelListVars {
  token: string;
  inst_id: string;
  input: ChannelListInput;
}

export interface ChannelDataByIdData {
  node: ChannelList;
}
export interface MessageDataByIdData {
  node: MessageNode;
}
export interface ChannelDataByIdVars {
  token: string;
  id: number;
}
export interface nodes {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
}
export interface CreatedByNamesData {
  nodes: nodes[];
}
export interface CreatedByNamesDataVars {
  ids: (number | undefined)[];
  token: string;
}

export interface ChannelUsersTotalCount {
  GetStaffUsersCountByChannelId: number;
  GetStudentsCountByChannelId: number;
}
export interface MessageNode {
  id: number;
  msg_content: string;
  msg_date: string;
  created_at: string;
  sent_user_id: number;
  msg_media_content: string;
  msg_header: string;
  url: string;
  msg_channel_id: number;
}
export interface MessageEdge {
  node: MessageNode;
  cursor: string;
}
export interface GetChannelMessages {
  totalCount: number;
  pageInfo: PageInfo;
  edges: MessageEdge[];
}
export interface GetChannelMessagesByData {
  GetChannelMessages: GetChannelMessages;
}
export interface GetChannelMessagesByVars {
  token: string;
  input: {
    query_type: MsgChannelType;
    channel_id: number;
  };
  inst_id: string;
  after: string | null;
  sortBy: string;
  direction: string;
  last: number;
  mediaContent: string;
}
export interface EduateDetails {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
}
export interface GetEduateDetailsByAuthIdByData {
  GetEduateDetailsByAuthId: EduateDetails;
}
export interface GetEduateDetailsByAuthIdByVars {
  token: string;
  auth_id: number;
}
export interface Node {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  emp_first_name: string;
  emp_middle_name: string;
  emp_last_name: string;
}

export interface GetMessageData {
  nodes: Node[];
}

export interface GetMessageDataVariables {
  ids: number[];
  token: string;
}

export interface BranchListVarsByInstId {
  token: string;
  inst_id: string | number;
  branch_id?: number;
}

export interface UpdateInstTimeZoneAndBdayMsgTimeData {
  UpdateInstTimeZoneAndBdayMsgTime: boolean;
}

export interface UpdateInstTimeZoneAndBdayMsgTimeVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  input: {
    inst_time_zone: string;
    bd_msg_hours: number;
    bd_msg_mins: number;
  };
}
