import React from "react";
import { InstitutionConfigurationTypes } from "../utils/Enum.types";

import { convertMB2Bytes } from "../utils/constants";
import useSwConfigData from "./useSwConfighData";
import { GlobalPageConfigData } from "../utils/Query.Types";

const useFileSizeDetails = () => {
  const { configData } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
  ]);

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let fileSize = 0;
    let mediaSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            fileSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            mediaSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      fileSize,
      mediaSize,
    };
  };
  const { fileSize, mediaSize } = filterDataByConfigKey(
    configData.data?.GetSwConfigVariables!
  );

  return {
    maxFileSize: { fileSize, mediaSize },
    maxFileSizeInBits: {
      fileSizeInBits: fileSize * convertMB2Bytes,
      mediaSize: mediaSize * convertMB2Bytes,
    },
    ComponentWarning: {
      fileSizeComponent: (
        <span>
          (Max File Size: {fileSize}MB. {"\n"}
          Only Jpeg, Pdf allowed)
        </span>
      ),
      mediaSizeComponent: (
        <span>
          (Max File Size: {mediaSize}MB. {"\n"}
          Only Jpeg, jpg and png allowed)
        </span>
      ),
    },
  };
};

export default useFileSizeDetails;
