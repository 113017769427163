import { gql } from "@apollo/client";
export const GetChannelMessages = gql`
  query GetChannelMessages(
    $token: String!
    $inst_id: ID!
    $input: ChannelMsgQueryData!
    $before: Cursor
    $first: Int
    $last: Int
    $direction: OrderDirection!
    $sortBy: ChannelMessageOrderField
    $messageContent: String
  ) {
    GetChannelMessages(
      token: $token
      inst_id: $inst_id
      input: $input
      before: $before
      first: $first
      last: $last
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ msgContentContainsFold: $messageContent }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          msg_date
          msg_expiry_date
          msg_header
          msg_content
          msg_media_type
          msg_media_content
          created_at
          sent_user_type
          sent_user_id
          msg_channel_id
        }
        cursor
      }
    }
  }
`;
export const GetMessageDataById = gql`
  query GetMessageDataById($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on MsgAuthEduate {
        first_name
        middle_name
        last_name
      }
      ... on MsgEmpMaster {
        emp_name
      }
      ... on MsgMstStudent {
        std_name
      }
    }
  }
`;
export const GetMessageById = gql`
  query GetMessageById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on ChannelMessage {
        id
        msg_date
        msg_expiry_date
        msg_header
        msg_content
        msg_media_type
        msg_media_content
        created_at
        sent_user_type
        sent_user_id
        msg_channel_id
      }
    }
  }
`;

export const GetShortStdList = gql`
  query GetShortStdList(
    $token: String!
    $acd_yr_id: ID!
    $inst_id: ID!
    $channel_id: ID!
  ) {
    GetShortStdList(
      token: $token
      acd_yr_id: $acd_yr_id
      inst_id: $inst_id
      channel_id: $channel_id
    ) {
      id
      std_name
      std_adm_no
      std_profile_filename
      total_count
    }
  }
`;
