import React from "react";
import "./Label.scss";

interface Props {
  variant?: "LabelPrimary" | "AccountingLabel" | "LabelBold" | "present-day";
  children: any;
  className?: string;
  onClick?: any;
  htmlFor?: string;
}

export const Label = (props: Props) => {
  const { variant, children, className, ...rest } = props;

  return (
    <label className={`Label ${variant}`} {...rest}>
      {children}
    </label>
  );
};
