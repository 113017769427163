import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import useMasterTableJson, {
  TableColumn,
} from "../../../Masters/json/useTableJson";

import Edit from "../../../../../images/EditProfile.svg";
import Delete from "../../../../../images/Delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  MsgMstInstSemesterOrderField,
  SemesterListData,
  SemesterListEdges,
  SemesterListVars,
  SemesterQueryType,
} from "../../../../../utils/Query.Types";
import useToken from "../../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { Direction } from "../../../../../utils/Enum.types";
import { InstDetailsProps } from "../Department/List";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { reOrderProcess } from "../../../../../utils/UtilFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GetMstInstSemesters } from "../../../../../queries/institution/semester/query";
interface SemListProps extends InstDetailsProps {
  localItemsState: {
    localItems: SemesterListEdges[];
    setLocalItems: React.Dispatch<React.SetStateAction<SemesterListEdges[]>>;
  };
}
const List = ({
  handleEdit,
  handleDelete,
  localItemsState,
  reOrder,
}: SemListProps) => {
  const { Institutions } = useMasterTableJson();
  const navigate = useNavigate();

  const { token } = useToken();
  const { localItems, setLocalItems } = localItemsState;

  // eslint-disable-next-line
  const [items, setItems] = useState<SemesterListEdges[]>([]);
  const { USE_SECTION_KEY } = useInstitutionConfiguration();
  const { classId } = useParams();
  const { semesterLabel } = useInstLabels();
  const [GetSemesters, { data, loading }] = useLazyQuery<
    SemesterListData,
    SemesterListVars
  >(GetMstInstSemesters, {
    variables: {
      id: classId!,
      token,
      after: null,
      first: null,
      name: EMPTY_STRING,
      query_type: SemesterQueryType.SEMESTER_BY_CLASS_ID,
      orderBy: {
        direction: Direction.ASC,
        field: MsgMstInstSemesterOrderField.SEM_IDX,
      },
    },
  });

  useEffect(() => {
    if (token) {
      GetSemesters();
    }
  }, [token, GetSemesters]);

  useEffect(() => {
    if (data && !loading) {
      setLocalItems(
        data.GetMstInstSemesters.edges.filter(({ node }) => node != null)
      );
    }
    // eslint-disable-next-line
  }, [data, loading]);
  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: SemesterListEdges[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setItems(temp);
      return temp;
    });
  };

  return (
    <>
      <Title variant="subtitle1">List of {semesterLabel}</Title>
      <div className="inst-config__masters--tableblock">
        <TableContainer className="inst-config__masters--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {reOrder ? <TableCell></TableCell> : null}
                {Institutions.InstDetails.filter(
                  ({ headerName }: TableColumn) =>
                    !(headerName === "Actions" && reOrder)
                ).map((th: TableColumn, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TableCell className={th.cellClassName}>
                        {th.headerName}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </TableHead>

            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {localItems.map(({ node: response }, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                        isDragDisabled={!reOrder}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                {reOrder ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="inst-config__masters--table--slno"
                                  >
                                    =
                                  </TableCell>
                                ) : null}
                                <TableCell
                                  id="td-center"
                                  {...draggableProvided.dragHandleProps}
                                  className="inst-config__masters--table--slno"
                                >
                                  {index + 1}
                                </TableCell>

                                {USE_SECTION_KEY ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    onClick={async () => {
                                      navigate(`${response.id}/section`);
                                    }}
                                  >
                                    {response.sem_desc}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response.sem_desc}
                                  </TableCell>
                                )}
                                {reOrder === false ? (
                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                    className="inst-config__masters--table--actions"
                                  >
                                    <>
                                      <img
                                        src={Edit}
                                        alt="/"
                                        onClick={() => {
                                          handleEdit(response.id);
                                        }}
                                      />
                                      <img
                                        src={Delete}
                                        alt="/"
                                        onClick={() => {
                                          handleDelete(response.id);
                                        }}
                                      />
                                    </>
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </React.Fragment>
                          );
                        }}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default List;
