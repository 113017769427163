import { gql } from "@apollo/client";

export const GetCustomerByNode = gql`
  query GetCustomerByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MsgMstCustomer {
        cust_name
        cust_address
        cust_place
        cust_state
        cust_pin
        cust_contact_person
        cust_phone
        cust_email
        cust_mobile
        cust_url
        cust_banner_1
        cust_banner_2
        cust_logo_filename
      }
    }
  }
`;
