import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import useToken from "./useToken";
import { AppContext } from "../context/context";
import { GetMstInstModules } from "../queries/customerModules/query";
import {
  GetMstInstModulesData,
  GetMstInstModulesVars,
} from "../utils/Query.Types";

export enum InstModuleQueryType {
  ALL_INST_MODULES = "ALL_INST_MODULES",
  // ACTIVE_INST_MODULES = "ACTIVE_INST_MODULES",
}
export interface EduateModuleDetails {
  id: number;
  Name: string;
}
export interface InstModuleDetails {
  id: number;
  is_module_enabled: boolean;
  eduate_module_details: EduateModuleDetails;
}
export interface InstModules {
  id: number;
  lic_type: string;
  lic_active: boolean;
  lic_exp_grace_period: number;
  lic_enabled_date: string;
  lic_start_date: string;
  lic_end_date: string;
  inst_module_details: InstModuleDetails[];
}

const useInstModules = () => {
  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [GetInstModuleData, { data, loading, error }] = useLazyQuery<
    GetMstInstModulesData,
    GetMstInstModulesVars
  >(GetMstInstModules, {
    variables: {
      token,
      inst_id: InstId! || state.InstId.toString(),
      query_type: InstModuleQueryType.ALL_INST_MODULES,
    },
  });
  useEffect(() => {
    if ((InstId || state.InstId) && token) {
      GetInstModuleData();
    }
  }, [InstId, token, GetInstModuleData, state.InstId]);
  return {
    InstModuleData: { data, loading, error },
  };
};

export default useInstModules;
