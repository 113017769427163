import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../../../images/Avatar.svg";
import EditProfile from "../../../../../images/EditProfile.svg";

// import useStudentDatabyId from "../../../../customhooks/useStudentDatabyId";

import More from "../../../../../images/More.svg";

import { AppContext } from "../../../../../context/context";
import useStudentDatabyId from "../../../../../customhooks/useStudentDataById";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { EMPTY_STRING, emptyMessageType } from "../../../../../utils/constants";
import { getDownloadUrl } from "../../../../../utils/DownloadFile";
import { Title } from "../../../../../stories/Title/Title";
import useSwConfigData from "../../../../../customhooks/useSwConfighData";
import {
  InstitutionConfigurationTypes,
  Operation,
} from "../../../../../utils/Enum.types";
import useFileSizeDetails from "../../../../../customhooks/useFileUploadConstraints";
import { handleUploadAndDownloadFile } from "../../../../../utils/Upload";
import { UpdateStudentById } from "../../../../../queries/students/mutations";
import { useMutation } from "@apollo/client";
import { msgType } from "../../../../../utils/Types";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { GetStudentDetails } from "../../../../../queries/students/list";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import MessageModal from "../../../../../utils/MessageModal";

const StudentDetails = () => {
  const [imageString, setImageString] = useState("");
  const { studentFormData, studentData } = useStudentDatabyId();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { instId, studentId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { USE_BRANCH_KEY, USE_CLASS_KEY, USE_SECTION_KEY } =
    useInstitutionConfiguration();
  const { configData } = useSwConfigData(
    InstitutionConfigurationTypes.CAN_STUDENT_UPDATE_PROFILE_PHOTO
  );
  const { InstFormData } = useInstDetails();
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [updateStudent] = useMutation(UpdateStudentById, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const { maxFileSizeInBits } = useFileSizeDetails();
  const filename = `${InstFormData.inst_name}/students/${
    state.studentId ? state.studentId : studentId
  }/std_profile_pic/std_profile_pic`;
  const canStudentUpdatePic =
    configData.data && configData.data.GetSwConfigVariables.length
      ? configData.data.GetSwConfigVariables[0].config_boolean_value
      : false;
  const { branchLabel, classLabel, sectionLabel } = useInstLabels();
  const uploadFile = (filename: string, file: File) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          handleUploadAndDownloadFile(file, filename, setProgress, false)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  const updateForm = async (filename: string, file: File) => {
    try {
      const uploadResult = await uploadFile(filename, file);
      if (uploadResult) {
        updateStudent({
          variables: {
            id: state.studentId,
            token,
            inst_id: instId!,
            user_details,
            input: {
              std_profile_filename: filename,
            },
          },
          refetchQueries: [
            {
              query: GetStudentDetails,
              variables: {
                token,
                ids: [state.studentId],
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            getDownloadUrl(
              studentFormData.std_profile_filename,
              setImageString,
              false
            );
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleImagePreview = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > maxFileSizeInBits.mediaSize) {
        alert("huge file");
        return;
      }
      let image_as_files = e.target.files[0];
      const filetype = image_as_files?.name.split(".").pop();
      const fileNameWithExtension = `${filename}.${filetype}`;
      await updateForm(fileNameWithExtension, image_as_files);
    }
  };
  useEffect(() => {
    if (studentFormData.std_profile_filename !== EMPTY_STRING) {
      // eslint-disable-next-line
      const ers = getDownloadUrl(
        studentFormData.std_profile_filename,
        setImageString,
        false
      );
    }
  }, [studentFormData.std_profile_filename, studentData.data]);

  return (
    <>
      <div className="std-dashboard__title">
        <Title variant="subtitle1">My Details</Title>
        <img src={More} alt="/" />
      </div>
      <div className="std-dashboard__std-details">
        <label
          className={`std-dashboard__std-details${
            canStudentUpdatePic ? "--profile" : "--profile-noedit"
          }`}
        >
          <img
            src={imageString ? imageString : Avatar}
            alt="/"
            className="std-dashboard__std-details--image"
          />
          <img
            src={EditProfile}
            alt="/"
            className="std-dashboard__std-details--edit"
          />
          <input
            type="file"
            className="std-dashboard__std-details--file"
            onChange={handleImagePreview}
          />
        </label>
        <div className="std-dashboard__std-details--data">
          <div className="std-dashboard__std-details--data--flex">
            <span>Name : </span>
            <b>{studentFormData.std_name}</b>
          </div>
          <div className="std-dashboard__std-details--data--flex">
            <span>Adm No. : </span>
            <b>{studentFormData.std_adm_no} </b>
          </div>

          {USE_BRANCH_KEY && (
            <div className="std-dashboard__std-details--data--flex">
              <span>{branchLabel} : </span>
              <b>{studentFormData.branch} </b>
            </div>
          )}
          {USE_CLASS_KEY && (
            <div className="std-dashboard__std-details--data--flex">
              <span>{classLabel} : </span>
              <b>{studentFormData.class} </b>
            </div>
          )}
          {USE_SECTION_KEY && (
            <div className="std-dashboard__std-details--data--flex">
              <span>{sectionLabel} : </span>
              <b>{studentFormData.section} </b>
            </div>
          )}
          <div className="std-dashboard__std-details--data--flex">
            <span>Parent Name : </span>
            <b>{studentFormData.parent_name}</b>
          </div>
          <div className="std-dashboard__std-details--data--flex">
            <span>Mobile No. </span>
            <b>{studentFormData.std_mobile}</b>
          </div>
        </div>
      </div>

      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default StudentDetails;
