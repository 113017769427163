import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";
import useToken from "./useToken";
import { GetMstInstTree } from "../queries/institution/queries/byId";
import { InstIdVars } from "../utils/Query.Types";
enum InstTreeQueryType {
  TREE_BY_INST_ID = "TREE_BY_INST_ID",
  TREE_BY_DEPT_ID = "TREE_BY_DEPT_ID",
  TREE_BY_BRANCH_ID = "TREE_BY_BRANCH_ID",
  TREE_BY_CLASS_ID = "TREE_BY_CLASS_ID",
  TREE_BY_SEMESTER_ID = "TREE_BY_SEMESTER_ID",
  TREE_BY_SECTION_ID = "TREE_BY_SECTION_ID",
  NONE = "NONE",
}
interface AcdLevel5Tree {
  level_5: string;
  level_5_name: string;
  level_5_desc: string;
  total_std_count: number;
  level_5_id: number;
  sub_level_total_count: number;
}
interface AcdLevel4Tree {
  level_4: string;
  level_4_name: string;
  level_4_desc: string;
  level_4_id: number;
  total_std_count: number;
  level_5_details: AcdLevel5Tree[];
  sub_level_total_count: number;
}
interface AcdLevel3Tree {
  level_3: string;
  level_3_name: string;
  level_3_desc: string;
  level_3_id: number;
  total_std_count: number;
  level_4_details: AcdLevel4Tree[];

  sub_level_total_count: number;
}
interface AcdLevel2Tree {
  level_2: string;
  level_2_name: string;
  level_2_desc: string;
  level_2_id: number;
  total_std_count: number;
  level_3_details: AcdLevel3Tree[];
  sub_level_total_count: number;
}
export interface AcdLevel1Tree {
  level_1: string;
  level_1_name: string;
  level_1_desc: string;
  level_1_id: number;
  total_std_count: number;
  level_2_details: AcdLevel2Tree[];
  sub_level_total_count: number;
}
interface GetMstInstTreeData {
  GetMstInstTree: AcdLevel1Tree[];
}
interface GetMstInstTreeByQueryTypeVars extends InstIdVars {
  id: number;
  query_type: InstTreeQueryType;
}

const useMstInstTree = (
  deptId: number,
  branchId: number,
  classId: number,
  semId: number,
  sectionId: number
) => {
  const { instId } = useParams();
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const [id, setId] = useState(0);
  const [query_type, setQueryType] = useState<InstTreeQueryType>(
    InstTreeQueryType.NONE
  );
  useEffect(() => {
    if (sectionId) {
      setId(sectionId);
      setQueryType(InstTreeQueryType.TREE_BY_SECTION_ID);
    } else if (semId) {
      setId(semId);
      setQueryType(InstTreeQueryType.TREE_BY_SEMESTER_ID);
    } else if (classId) {
      setId(classId);
      setQueryType(InstTreeQueryType.TREE_BY_CLASS_ID);
    } else if (branchId) {
      setId(branchId);
      setQueryType(InstTreeQueryType.TREE_BY_BRANCH_ID);
    } else if (deptId) {
      setId(deptId);
      setQueryType(InstTreeQueryType.TREE_BY_DEPT_ID);
    } else {
      setId(0);
      setQueryType(InstTreeQueryType.TREE_BY_INST_ID);
    }
  }, [sectionId, semId, classId, branchId, deptId]);

  const [GetInstTree, { data, loading }] = useLazyQuery<
    GetMstInstTreeData,
    GetMstInstTreeByQueryTypeVars
  >(GetMstInstTree, {
    variables: {
      inst_id: instId ? instId : state.InstId.toString(),
      id,
      token,
      query_type,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (token && instId && query_type !== InstTreeQueryType.NONE) {
      GetInstTree();
    }
  }, [token, state.InstId, GetInstTree, instId, id, query_type]);
  return {
    data,
    loading,
  };
};

export default useMstInstTree;
