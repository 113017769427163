import { gql } from "@apollo/client";
export const UpdateInstBranch = gql`
  mutation UpdateInstBranch(
    $input: UpdateMsgMstInstBranchInput!
    $id: ID!
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstBranch(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstBranchById = gql`
  mutation DeleteInstBranchById(
    $token: String!
    $branch_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstBranchById(
      token: $token
      id: $branch_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ReOrderInstBranch = gql`
  mutation ReOrderInstBranch(
    $token: String!
    $input: [ReOrderMsgMstInstBranchInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstBranch(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
