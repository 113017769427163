import { gql } from "@apollo/client";

export const GetCustomers = gql`
  query GetCustomers(
    $name: String
    $first: Int
    $after: Cursor
    $sortBy: MsgMstCustomerOrderField!
    $direction: OrderDirection!
    $token: String!
  ) {
    GetCustomers(
      token: $token
      first: $first
      after: $after
      orderBy: { direction: $direction, field: $sortBy }
      where: { or: [{ custNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          cust_name
          cust_place
          cust_contact_person
          cust_mobile
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;
