import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import Navbar from "../../../Layouts/Navbar";
import { Title } from "../../../../stories/Title/Title";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DatagridStyles";
import useMasterTableJson from "../json/useTableJson";
import Edit from "../../../../images/EditProfile.svg";
import Eye from "../../../../images/Eye.svg";
import Delete from "../../../../images/Delete.svg";

import { useNavigate, useParams } from "react-router-dom";
import {
  AddModalStyles,
  EditModalCustomStyles,
  previewModalStyles,
} from "../../../styles/ModalStyles";
import ReactModal from "react-modal";
import {
  ROWS_PER_PAGE,
  emptyMessageType,
  licenseTypes,
  payloadTypes,
} from "../../../../utils/constants";
import AddInstitution from "../Institution/Registration/Index";
import {
  GetInstitutionsByCustIdData,
  GetInstitutionsVars,
  InstitutionEdges,
} from "../../../../utils/Query.Types";
import { GetInstsByCustId } from "../../../../queries/institution/queries/byId";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import useToken from "../../../../customhooks/useToken";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import EditIndex from "./EditIndex";
import { AppContext } from "../../../../context/context";
import Preview from "./Preview/Index";
import { TableHeaderProps } from "../../../../utils/Types";
import Sidebar from "../../../Layouts/Sidebar";
import { DeleteInstById } from "../../../../queries/institution/mutations/update";
import LoadingModal from "../../../Modals/LoadingModal";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import DeleteModal from "../../../Modals/DeleteModal";
import MessageModal from "../../../../utils/MessageModal";

const Index = () => {
  const classes = useDataGridStyles();
  const { dispatch } = useContext(AppContext);
  const [addModal, setAddModal] = useState(false);
  const { Institutions } = useMasterTableJson();
  const navigate = useNavigate();
  const { custId } = useParams();

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteInstId, setDeleteInstId] = useState(0);

  const [searchData, setSearchData] = useState("");
  const { user_details } = useLoggedInUserDetails();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [previewModal, setPreviewModal] = useState(false);

  const [institutions, setInstitutions] = useState<InstitutionEdges[]>([]);

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { token } = useToken();
  const [message, setMessage] = useState(emptyMessageType);

  const [deleteInst, { loading: deletionLoading }] = useMutation(
    DeleteInstById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [GetInsts, { data, loading, fetchMore }] = useLazyQuery<
    GetInstitutionsByCustIdData,
    GetInstitutionsVars
  >(GetInstsByCustId, {
    variables: {
      customer_id: Number(custId),
      name: searchData,
      first: ROWS_PER_PAGE,
      after: null,
      orderBy: { field: SortBy.INST_NAME, direction: Direction.ASC },
      token,
    },
    fetchPolicy: "network-only",
  });
  const dynamicHeaders: TableHeaderProps[] = Institutions.Table_Headers.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "inst_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <img
            src={Eye}
            alt=""
            onClick={() => setPreviewModal(!previewModal)}
          />
          &nbsp;
          <img
            src={Edit}
            alt=""
            onClick={() => handleEdit(Number(params.row.instId))}
          />
          <img
            src={Delete}
            alt=""
            onClick={() => {
              setDeleteInstId(Number(params.row.instId));
              setDeleteModal(true);
            }}
          />
        </>
      ),
    },
  ];
  const handleDelete = (id: number) => {
    setDeleteModal(false);
    deleteInst({
      variables: {
        token,
        id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstsByCustId,
          variables: {
            customer_id: Number(custId),
            name: searchData,
            first: ROWS_PER_PAGE,
            after: null,
            orderBy: { field: SortBy.INST_NAME, direction: Direction.ASC },
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteInstById) {
        setMessage({
          flag: true,
          message: "Institution deleted successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleEdit = (id: number) => {
    dispatch({ type: payloadTypes.SET_INST_ID, payload: { InstId: id } });

    setEditModal(!editModal);
  };

  const handleClick = (params: GridCellParams) => {
    if (params.field === "inst_name") {
      navigate(`/${params.row.instId}/chat/userrights`);
    }
  };
  useEffect(() => {
    if (token) {
      GetInsts();
    }
  }, [token, GetInsts, addModal]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetInstsByCustId.edges;
                const pageInfo = fetchMoreResult.GetInstsByCustId.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetInstsByCustId.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetInstsByCustId: {
                    edges: [...institutions, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetInstsByCustId.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetInstsByCustId.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setInstitutions(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            instId: node.id,
            inst_name: node.inst_name,
            inst_addr: node.inst_place,
            inst_c_person: node.inst_contact_person,
            inst_mobile: node.inst_mobile,
          }))
        );
      } else {
        setInstitutions(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            instId: node.id,
            inst_name: node.inst_name,
            inst_addr: node.inst_place,
            inst_c_person: node.inst_contact_person,
            inst_mobile: node.inst_mobile,
          }))
        );
      }
      setEndCursor(data.GetInstsByCustId.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Navbar navType={licenseTypes.INSTITUTION} />
      <div className="cust-list">
        <Sidebar />
        <div className="cust-list__frame">
          <Title>Institutions</Title>
          <div className="cust-list__select row g-0">
            <div className="col-2">
              <Input
                placeholder="Search..."
                onChange={(e) => setSearchData(e.target.value)}
                id="search"
              />
            </div>
            <div className="col"></div>
            <div className="col-2">
              <Button
                mode="addnew"
                className="cust-list__select--button"
                onClick={() => setAddModal(!addModal)}
              />
            </div>
          </div>
          <div className={`cust-list__tableblock ${classes.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              onCellClick={handleClick}
              disableRowSelectionOnClick
              rowHeight={TABLE_ROW_HEIGHT}
            />
          </div>
        </div>
      </div>
      <ReactModal style={AddModalStyles} isOpen={addModal} ariaHideApp={false}>
        <AddInstitution setModal={setAddModal} />
      </ReactModal>

      <ReactModal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={editModal}
        style={EditModalCustomStyles}
      >
        <EditIndex setModal={setEditModal} />
      </ReactModal>
      <ReactModal
        ariaHideApp={false}
        isOpen={previewModal}
        style={previewModalStyles}
      >
        <Preview setModal={setPreviewModal} />
      </ReactModal>

      <LoadingModal flag={deletionLoading} />

      <DeleteModal
        id={deleteInstId}
        setModalFlag={setDeleteModal}
        modalFlag={deleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Index;
