import React, { useContext, useMemo } from "react";
import useMstInstTree from "../../../../customhooks/useMstInstTree";
import { AppContext } from "../../Masters/Components/MasterDropDown/context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../customhooks/useInstLabels";
import { TableHeaders } from "../../../../utils/Enum.types";
import { EMPTY_STRING } from "../../../../utils/constants";
import { TreeType } from "./Index";
import View from "../../../../images/EyeWhite.svg";
import { useNavigate, useParams } from "react-router-dom";
import { toStandardCase } from "../../../../utils/UtilFunctions";
export interface AssignComponentProp {
  id: number;
}
const TreeView = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { instId } = useParams();
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const { data } = useMstInstTree(
    state.deptId,
    state.branchId,
    state.classId,
    state.semId,
    state.sectionId
  );

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  let last_level = useMemo(() => {
    let final = 0;
    if (USE_DEPARTMENT_KEY) {
      final++;
    }
    if (USE_BRANCH_KEY) {
      final++;
    }
    if (USE_CLASS_KEY) {
      final++;
    }
    if (USE_SEMESTER_KEY) {
      final++;
    }
    if (USE_SECTION_KEY) {
      final++;
    }
    return final + 1;
  }, [
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  ]);
  let idsSet = new Set();
  const modifiedArrayForTable: TreeType[] = data
    ? data.GetMstInstTree.flatMap((level1) => {
        if (level1.level_2_details && level1.level_2_details.length > 0) {
          return level1.level_2_details.flatMap((level2) => {
            if (level2.level_3_details && level2.level_3_details.length > 0) {
              return level2.level_3_details.flatMap((level3) => {
                if (
                  level3.level_4_details &&
                  level3.level_4_details.length > 0
                ) {
                  return level3.level_4_details.flatMap((level4) => {
                    if (
                      level4.level_5_details &&
                      level4.level_5_details.length > 0
                    ) {
                      return level4.level_5_details.flatMap((level5) => {
                        let result = {
                          level1_name: idsSet.has(level1.level_1_id)
                            ? EMPTY_STRING
                            : level1.level_1_desc,
                          level1_id: level1.level_1_id,
                          level1_rowspan: level1.sub_level_total_count
                            ? level1.sub_level_total_count
                            : 1,
                          level1_count: level1.total_std_count,

                          level2_name: idsSet.has(level2.level_2_id)
                            ? EMPTY_STRING
                            : level2.level_2_desc,
                          level2_rowspan: level2.sub_level_total_count
                            ? level2.sub_level_total_count
                            : 1,
                          level2_id: level2.level_2_id,
                          level2_count: level2.total_std_count,

                          level3_name: idsSet.has(level3.level_3_id)
                            ? EMPTY_STRING
                            : level3.level_3_desc,
                          level3_span: level3.sub_level_total_count
                            ? level3.sub_level_total_count
                            : 1,
                          level3_id: level3.level_3_id,
                          level3_count: level3.total_std_count,

                          level4_name: idsSet.has(level4.level_4_id)
                            ? EMPTY_STRING
                            : level4.level_4_desc,
                          level4_span: level4.sub_level_total_count
                            ? level4.sub_level_total_count
                            : 1,
                          level4_id: level4.level_4_id,
                          level4_count: level4.total_std_count,

                          level5_name: level5.level_5_desc,
                          level5_id: level5.level_5_id,
                          level5_count: level5.total_std_count,
                          filterThis: false,
                        };
                        idsSet = new Set([
                          level1.level_1_id,
                          level2.level_2_id,
                          level3.level_3_id,
                          level4.level_4_id,
                          level5.level_5_id,
                          ...idsSet,
                        ]);
                        return result;
                      });
                    } else {
                      let result = {
                        level1_name: idsSet.has(level1.level_1_id)
                          ? EMPTY_STRING
                          : level1.level_1_desc,
                        level1_rowspan: level1.sub_level_total_count
                          ? level1.sub_level_total_count
                          : 1,
                        level2_name: idsSet.has(level2.level_2_id)
                          ? EMPTY_STRING
                          : level2.level_2_desc,
                        level2_rowspan: level2.sub_level_total_count
                          ? level2.sub_level_total_count
                          : 1,
                        level3_name: idsSet.has(level3.level_3_id)
                          ? EMPTY_STRING
                          : level3.level_3_desc,
                        level3_span: level3.sub_level_total_count
                          ? level3.sub_level_total_count
                          : 1,
                        level4_name: idsSet.has(level4.level_4_id)
                          ? EMPTY_STRING
                          : level4.level_4_desc,
                        level4_span: level4.sub_level_total_count,
                        level5_name: EMPTY_STRING,
                        level1_id: level1.level_1_id,
                        level2_id: level2.level_2_id,
                        level3_id: level3.level_3_id,
                        level4_id: level4.level_4_id,
                        level5_id: 0,
                        level1_count: level1.total_std_count,
                        level2_count: level2.total_std_count,
                        level3_count: level3.total_std_count,
                        level4_count: level4.total_std_count,
                        level5_count: 0,
                        filterThis: !(last_level <= 5),
                      };
                      idsSet = new Set([
                        level1.level_1_id,
                        level2.level_2_id,
                        level3.level_3_id,
                        level4.level_4_id,
                        ...idsSet,
                      ]);
                      return result;
                    }
                  });
                } else {
                  let result = {
                    level1_name: idsSet.has(level1.level_1_id)
                      ? EMPTY_STRING
                      : level1.level_1_desc,
                    level1_rowspan: level1.sub_level_total_count
                      ? level1.sub_level_total_count
                      : 1,
                    level2_name: idsSet.has(level2.level_2_id)
                      ? EMPTY_STRING
                      : level2.level_2_desc,
                    level2_rowspan: level2.sub_level_total_count
                      ? level2.sub_level_total_count
                      : 1,
                    level3_name: idsSet.has(level3.level_3_id)
                      ? EMPTY_STRING
                      : level3.level_3_desc,
                    level3_span: level3.sub_level_total_count
                      ? level3.sub_level_total_count
                      : 1,
                    level4_name: EMPTY_STRING,
                    level4_span: 1,
                    level5_name: EMPTY_STRING,
                    level1_id: level1.level_1_id,
                    level2_id: level2.level_2_id,
                    level3_id: level3.level_3_id,
                    level4_id: 0,
                    level5_id: 0,

                    level1_count: level1.total_std_count,
                    level2_count: level2.total_std_count,
                    level3_count: level3.total_std_count,
                    level4_count: 0,
                    level5_count: 0,

                    filterThis: !(last_level <= 4) || !(last_level <= 5),
                  };

                  idsSet = new Set([
                    level1.level_1_id,
                    level2.level_2_id,
                    level3.level_3_id,
                    ...idsSet,
                  ]);
                  return result;
                }
              });
            } else {
              let result = {
                level1_name: idsSet.has(level1.level_1_id)
                  ? EMPTY_STRING
                  : level1.level_1_desc,
                level1_rowspan: level1.sub_level_total_count
                  ? level1.sub_level_total_count
                  : 1,
                level2_name: idsSet.has(level2.level_2_id)
                  ? EMPTY_STRING
                  : level2.level_2_desc,
                level2_rowspan: level2.sub_level_total_count
                  ? level2.sub_level_total_count
                  : 1,
                level3_name: EMPTY_STRING,
                level3_span: 1,
                level4_name: EMPTY_STRING,
                level4_span: 1,
                level5_name: EMPTY_STRING,
                level1_id: level1.level_1_id,
                level2_id: level2.level_2_id,
                level3_id: 0,
                level4_id: 0,
                level5_id: 0,
                level1_count: level1.total_std_count,
                level2_count: level2.total_std_count,
                level3_count: 0,
                level4_count: 0,
                level5_count: 0,
                filterThis:
                  !(last_level <= 3) ||
                  !(last_level <= 4) ||
                  !(last_level <= 5),
              };

              idsSet = new Set([
                level1.level_1_id,
                level2.level_2_id,
                ...idsSet,
              ]);
              return result;
            }
          });
        } else {
          let result = {
            level1_name: idsSet.has(level1.level_1_id)
              ? EMPTY_STRING
              : level1.level_1_desc,
            level1_rowspan: level1.sub_level_total_count,
            level2_name: EMPTY_STRING,
            level2_rowspan: 0,
            level3_name: EMPTY_STRING,
            level3_span: 0,
            level4_name: EMPTY_STRING,
            level4_span: 0,
            level5_name: EMPTY_STRING,
            level1_id: level1.level_1_id,
            level2_id: 0,
            level3_id: 0,
            level4_id: 0,
            level5_id: 0,
            level1_count: level1.total_std_count,
            level2_count: 0,
            level3_count: 0,
            level4_count: 0,
            level5_count: 0,
            filterThis:
              !(last_level <= 2) ||
              !(last_level <= 3) ||
              !(last_level <= 4) ||
              !(last_level <= 5),
          };
          idsSet = new Set([level1.level_1_id, ...idsSet]);
          return result;
        }
      })
    : [];
  const AssignComponent = ({ id }: AssignComponentProp) => (
    <button
      className="chat-home__table--view-button"
      onClick={() => {
        navigate(`/${instId}/chat/informationcenter/${id}/channel`);
      }}
    >
      View <img src={View} alt="" />
    </button>
  );
  const filteredTreeFromEmptyMasterDetails = modifiedArrayForTable.filter(
    ({ filterThis }) => !filterThis
  );

  return (
    <div className="chat-home__tableblock">
      <TableContainer className="chat-home__table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {USE_DEPARTMENT_KEY ? (
                <TableCell>{departmentLabel}</TableCell>
              ) : null}
              {USE_BRANCH_KEY ? <TableCell>{branchLabel}</TableCell> : null}
              {USE_CLASS_KEY ? <TableCell>{classLabel}</TableCell> : null}
              {USE_SEMESTER_KEY ? <TableCell>{semesterLabel}</TableCell> : null}
              {USE_SECTION_KEY ? <TableCell>{sectionLabel}</TableCell> : null}
              <TableCell>Total Count</TableCell>
              <TableCell>{toStandardCase(TableHeaders.ACTION)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTreeFromEmptyMasterDetails.map((data, i) => {
              if (data) {
                return (
                  <TableRow key={i}>
                    {data.level1_name !== EMPTY_STRING ? (
                      <TableCell rowSpan={data.level1_rowspan}>
                        {data.level1_name}
                      </TableCell>
                    ) : null}
                    {data &&
                    (data.level2_name || data.level2_name !== EMPTY_STRING) ? (
                      <TableCell rowSpan={data.level2_rowspan}>
                        {data.level2_name}
                      </TableCell>
                    ) : null}
                    {data && data.level3_name !== EMPTY_STRING ? (
                      <TableCell rowSpan={data.level3_span}>
                        {data.level3_name}
                      </TableCell>
                    ) : null}
                    {data && data.level4_name !== EMPTY_STRING ? (
                      <TableCell rowSpan={data.level4_span}>
                        {data.level4_name}
                      </TableCell>
                    ) : null}
                    {data.level5_name !== EMPTY_STRING ? (
                      <TableCell>{data.level5_name}</TableCell>
                    ) : null}
                    <TableCell
                      id="td-center"
                      className="chat-home__table--actions"
                    >
                      {last_level - 1 === 1 && data.level1_name
                        ? data.level1_count
                        : null}
                      {last_level - 1 === 2 && data.level2_name
                        ? data.level2_count
                        : null}
                      {last_level - 1 === 3 && data.level3_name
                        ? data.level3_count
                        : null}
                      {last_level - 1 === 4 && data.level4_name
                        ? data.level4_count
                        : null}
                      {last_level - 1 === 5 && data.level5_name
                        ? data.level5_count
                        : null}
                    </TableCell>
                    <TableCell
                      id="td-center"
                      className="chat-home__table--actions"
                    >
                      {last_level - 1 === 1 && data.level1_name && (
                        <AssignComponent id={data.level1_id} />
                      )}
                      {last_level - 1 === 2 && data.level2_name && (
                        <AssignComponent id={data.level2_id} />
                      )}
                      {last_level - 1 === 3 && data.level3_name && (
                        <AssignComponent id={data.level3_id} />
                      )}
                      {last_level - 1 === 4 && data.level4_name && (
                        <AssignComponent id={data.level4_id} />
                      )}
                      {last_level - 1 === 5 && data.level5_name && (
                        <AssignComponent id={data.level5_id} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TreeView;
