import { gql } from "@apollo/client";

export const AddInst = gql`
  mutation AddInst(
    $token: String!
    $input: CreateMsgMstInstInput!
    $user_details: SubscribedUser!
  ) {
    AddInst(token: $token, input: $input, user_details: $user_details) {
      id
    }
  }
`;

export const AddMstInstLogo = gql`
  mutation AddMstInstLogo(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $inst_logo_filename: String!
  ) {
    AddMstInstLogo(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      inst_logo_filename: $inst_logo_filename
    )
  }
`;
