import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
} from "../../../styles/DatagridStyles";
import useInstLabels from "../../../../customhooks/useInstLabels";
export interface TableColumn {
  headerName: string;
  cellClassName: string;
  field: string;
  headerAlign: string;
  align?: string;
  flex?: number;
  renderCell?: (params: GridRenderCellParams) => React.ReactNode;
  renderHeader?: any;
  hide?: boolean;
}
export interface TableJson {
  Customers: {
    Table_Headers: TableColumn[];
  };
  StudentsBulkPic: {
    Table_Headers: TableColumn[];
  };
  Institutions: {
    Table_Headers: TableColumn[];
    Academics: TableColumn[];
    InstDetails: TableColumn[];
  };
  Students: {
    Table_Headers: TableColumn[];
  };
  LoginUsers: {
    Table_Headers: TableColumn[];
  };
  EnableUserLogin: {
    Table_Headers: TableColumn[];
  };
  AssignUserRights: {
    Table_Headers: TableColumn[];
  };
  StudentsListForFee: {
    Table_Headers: TableColumn[];
  };
}
const useMasterTableJson = (): TableJson => {
  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();
  const tableJson = {
    StudentsBulkPic: {
      Table_Headers: [
        {
          field: "std_adm_no",
          headerName: "Adm No.",
          headerAlign: HEADER_TEXT_ALIGN,
          cellClassName: "td-adm-no",
        },
        {
          field: "std_name",
          headerName: "Student Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          field: "dept_desc",
          headerName: departmentLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "branch_desc",
          headerName: branchLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "class_desc",
          headerName: classLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "sem_desc",
          headerName: semesterLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "section_desc",
          headerName: sectionLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    Customers: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name td-onClick",
          field: "cust_name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Place",
          cellClassName: "td-place",
          field: "cust_addr",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Contact Person",
          cellClassName: "td-name",
          field: "cust_c_person",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "cust_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        // {
        //   headerName: "Actions",
        //   cellClassName: "td-actions",
        //   field: "cust_actions",
        //   headerAlign: HEADER_TEXT_ALIGN,
        //   align: "",
        // },
      ],
    },

    Institutions: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-onClick td-name",
          field: "inst_name",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Place",
          cellClassName: "td-place",
          field: "inst_addr",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Contact Person",
          cellClassName: "td-name",
          field: "inst_c_person",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "inst_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
        },
        // {
        //   headerName: "Actions",
        //   className: "td-actions",
        //   field: "inst_actions",
        //   headerAlign: HEADER_TEXT_ALIGN,
        //   align: "",
        // },
      ],
      Academics: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },

        {
          headerName: "Year",
          cellClassName: "td-year",
          field: "acd_year",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Start Date",
          cellClassName: "td-date",
          field: "acd_s_date",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "End Date",
          cellClassName: "td-date",
          field: "acd_e_date",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
          flex: 1,
        },
      ],
      InstDetails: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Description",
          cellClassName: "td-onClick",
          field: "dept_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
        {
          headerName: "Actions",
          cellClassName: "td-onClick",
          field: "Action",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
      ],
      Categories: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Description",
          cellClassName: "",
          field: "cat_desc",
          headerAlign: HEADER_TEXT_ALIGN,
          align: "",
          flex: 1,
        },
      ],
    },
    Students: {
      Table_Headers: [
        {
          field: "std_adm_no",
          headerName: "Adm No.",
          headerAlign: HEADER_TEXT_ALIGN,
          cellClassName: "td-adm-no",
        },
        {
          field: "std_name",
          headerName: "Student Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          field: "parent_name",
          headerName: "Parent Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "dept_desc",
          headerName: departmentLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "branch_desc",
          headerName: branchLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "class_desc",
          headerName: classLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "sem_desc",
          headerName: semesterLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "section_desc",
          headerName: sectionLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "std_mobile",
          headerName: "Mobile No.",
          cellClassName: "td-mobile",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "std_email",
          headerName: "Email",
          cellClassName: "td-email",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },

    LoginUsers: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "user_name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "user_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "user_email",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    EnableUserLogin: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "emp_name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          headerName: "Emp Type",
          cellClassName: "td-mobile",
          field: "emp_type",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          headerName: "Mobile",
          cellClassName: "td-mobile",
          field: "emp_mobile",
          headerAlign: HEADER_TEXT_ALIGN,
        },

        {
          headerName: "Email",
          cellClassName: "td-email",
          field: "emp_email",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
    AssignUserRights: {
      Table_Headers: [
        {
          headerName: "Sl",
          cellClassName: "td-sl-no",
          field: "id",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
        },
        {
          headerName: "Name",
          cellClassName: "td-name",
          field: "user_name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
      ],
    },
    StudentsListForFee: {
      Table_Headers: [
        {
          field: "id",
          headerName: "Sl",
          headerAlign: HEADER_TEXT_ALIGN,
          align: SLNO_TEXT_ALIGN,
          cellClassName: "td-sl-no",
        },
        {
          field: "std_adm_no",
          headerName: "Adm No.",
          headerAlign: HEADER_TEXT_ALIGN,
          cellClassName: "td-adm-no",
        },
        {
          field: "std_name",
          headerName: "Student Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
          flex: 1,
        },
        {
          field: "parent_name",
          headerName: "Parent Name",
          cellClassName: "td-name",
          headerAlign: HEADER_TEXT_ALIGN,
        },

        {
          field: "branch_desc",
          headerName: branchLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "class_desc",
          headerName: classLabel,
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "cat_desc",
          headerName: "Category",
          cellClassName: "td-desc",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "std_mobile",
          headerName: "Mobile No.",
          cellClassName: "td-mobile",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "std_demand",
          headerName: "Demand",
          cellClassName: "td-amount",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "std_paid",
          headerName: "Paid ",
          cellClassName: "td-amount font-green",
          headerAlign: HEADER_TEXT_ALIGN,
        },
        {
          field: "std_balance",
          headerName: "Balance ",
          cellClassName: "td-amount font-red",
          headerAlign: HEADER_TEXT_ALIGN,
        },
      ],
    },
  };
  return tableJson;
};

export default useMasterTableJson;
