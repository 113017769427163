import { gql } from "@apollo/client";
export const UpdateInstSection = gql`
  mutation UpdateInstSection(
    $input: UpdateMsgMstInstSectionInput!
    $id: ID!
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateInstSection(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstSectionById = gql`
  mutation DeleteInstSectionById(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstSectionById(
      token: $token
      id: $id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ReOrderInstSection = gql`
  mutation ReOrderInstSection(
    $token: String!
    $input: [ReOrderMsgMstInstSectionInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstSection(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const UpdateStdSectionByIds = gql`
  mutation UpdateStdSectionByIds(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $section_id: ID!
    $student_ids: [ID!]!
  ) {
    UpdateStdSectionByIds(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      section_id: $section_id
      student_ids: $student_ids
    )
  }
`;
