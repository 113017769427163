import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { AppContext } from "../context/context";
import { GetMstInstWithoutToken } from "../queries/institution/queries/byId";
import { InstitutionNode } from "../utils/Query.Types";
interface InstFormData {
  inst_name: string;
  inst_short_name: string;
  inst_address: string;
  inst_pin: string;
  inst_place: string;
  inst_phone: string;
  inst_state: string;
  inst_email: string;
  inst_mobile: string;
  cust_name: string;
}

const useInstDetailsWithoutToken = () => {
  const { instId } = useParams();
  const { state } = useContext(AppContext);

  const [InstFormData, setInstFormData] = useState<InstFormData>({
    inst_name: "",
    inst_short_name: "",
    inst_address: "",
    inst_pin: "",
    inst_place: "",
    inst_phone: "",
    inst_state: "",
    inst_email: "",
    inst_mobile: "",
    cust_name: "",
  });

  const [GetInstDetails, { data, loading, error }] = useLazyQuery<
    { GetMstInstWithoutToken: InstitutionNode },
    { inst_id: string }
  >(GetMstInstWithoutToken, {
    variables: {
      inst_id: instId ? instId : state.InstId.toString(),
    },
  });
  useEffect(() => {
    if (instId || state.InstId) {
      GetInstDetails().then(({ data }) => {
        if (data && data.GetMstInstWithoutToken) {
          const {
            inst_name,
            inst_short_name,
            inst_address,
            inst_pin,
            inst_place,
            inst_phone,
            inst_state,
            inst_email,
            inst_mobile,
            cust_name,
          } = data.GetMstInstWithoutToken;
          setInstFormData({
            inst_name,
            inst_short_name,
            inst_address,
            inst_pin,
            inst_place,
            inst_phone,
            inst_state,
            inst_email,
            inst_mobile,
            cust_name,
          });
        }
      });
    }
  }, [instId, state.InstId, GetInstDetails]);

  return {
    InstDetails: { data, error, loading },
    InstFormData,
  };
};

export default useInstDetailsWithoutToken;
