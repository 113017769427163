import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "../styles/TooltipStyles";
import { handleSignOut } from "./Sidebar";
import {
  ParsedToken,
  getIdToken,
  getIdTokenResult,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../firebase";
import { useLazyQuery } from "@apollo/client";
import {
  GetAuthDetailsByToken,
  ValidateAuthUser,
} from "../../queries/DetailsByToken";
import {
  CheckEmpResetPwdStatusVars,
  CheckResetEmpPwdStatusData,
  CheckResetStdPwdStatusData,
  CheckStdResetPwdStatusVars,
  GetAuthDetailsByTokenData,
  GetAuthDetailsByTokenVars,
  GetEduateDetailsByAuthIdData,
  GetEduateDetailsByAuthIdVars,
  GetEmployeeByAuthIdData,
  GetEmployeeByAuthIdVars,
  GetStudentDetailsByAuthIdData,
  GetStudentDetailsByAuthIdVars,
} from "../../utils/Query.Types";
import { GetStudentDetailsByAuthId } from "../../queries/students/list/byId";
import { GetEmployeeByAuthId } from "../../queries/employees/list/byId";
import { CheckEmpResetPwdStatus } from "../../queries/employees/list";
import { GetEduateDetailsByAuthId } from "../../queries/eduate/list/byId";
import { CheckStdResetPwdStatus } from "../../queries/students/list";
import { UserType } from "../../utils/Enum.types";
import { AppContext } from "../../context/context";
import { payloadTypes } from "../../context/reducer";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ActiveAcdYrStyles } from "../styles/ModalStyles";
import ResetPassword from "../Modules/ResetPassword";
interface Props {
  children: React.ReactNode;
}

const DashBoard: React.FC<Props> = ({ children }) => {
  const tooltipClasses = useStyles();

  const { dispatch, state } = useContext(AppContext);

  const navigate = useNavigate();

  const [passwordModal, setPaswordModal] = useState(false);
  const [passwordMesg, setPasswordMesg] = useState("");
  // eslint-disable-next-line
  const [claims, setClaims] = useState<ParsedToken>({});

  const [GetDetailsByIdToken] = useLazyQuery<
    GetAuthDetailsByTokenData,
    GetAuthDetailsByTokenVars
  >(GetAuthDetailsByToken);

  const [isUserValid] = useLazyQuery(ValidateAuthUser);
  const [studentDetails] = useLazyQuery<
    GetStudentDetailsByAuthIdData,
    GetStudentDetailsByAuthIdVars
  >(GetStudentDetailsByAuthId);
  const [GetStaffDetailsByAuthId] = useLazyQuery<
    GetEmployeeByAuthIdData,
    GetEmployeeByAuthIdVars
  >(GetEmployeeByAuthId);
  const [GetEmpPwdStatus] = useLazyQuery<
    CheckResetEmpPwdStatusData,
    CheckEmpResetPwdStatusVars
  >(CheckEmpResetPwdStatus);

  const [eduateDetails] = useLazyQuery<
    GetEduateDetailsByAuthIdData,
    GetEduateDetailsByAuthIdVars
  >(GetEduateDetailsByAuthId);

  const [GetStdPwdStatus] = useLazyQuery<
    CheckResetStdPwdStatusData,
    CheckStdResetPwdStatusVars
  >(CheckStdResetPwdStatus);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdToken(user).then((token) => {
          GetDetailsByIdToken({
            variables: { token },
          }).then(({ data }) => {
            if (data) {
              const { auth_group_type } = data.GetAuthDetailsByToken;

              if (auth_group_type === UserType.EMPLOYEE) {
                dispatch({
                  type: payloadTypes.SET_INST_ID,
                  payload: {
                    InstId: data.GetAuthDetailsByToken.inst_id,
                  },
                });
              }

              switch (auth_group_type) {
                case UserType.STUDENT:
                  studentDetails({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data: Stddata }) => {
                    if (Stddata) {
                      dispatch({
                        type: payloadTypes.SET_STUDENT_ID,
                        payload: {
                          studentId: Stddata.GetStudentDetailsByAuthId.id,
                        },
                      });

                      isUserValid({
                        variables: {
                          token,
                          inst_id: Stddata.GetStudentDetailsByAuthId.inst_id,
                          input: {
                            user_type: UserType.STUDENT,
                            id: Stddata.GetStudentDetailsByAuthId.id,
                          },
                        },
                      }).then(({ data, error }) => {
                        if ((data && !data.ValidateAuthUser) || error) {
                          handleSignOut();
                        }
                        if (Stddata) {
                          GetStdPwdStatus({
                            variables: {
                              token,
                              inst_id:
                                Stddata.GetStudentDetailsByAuthId.inst_id.toString(),
                              student_id: Stddata.GetStudentDetailsByAuthId.id,
                            },
                          }).then(({ data: PwdStatusData }) => {
                            if (
                              PwdStatusData &&
                              PwdStatusData.CheckStdResetPwdStatus.status
                            ) {
                              setPaswordModal(true);
                              setPasswordMesg(
                                PwdStatusData?.CheckStdResetPwdStatus.message
                              );
                            } else {
                              window.location.pathname === "/" &&
                                Stddata.GetStudentDetailsByAuthId.inst_id &&
                                navigate(
                                  `/${Stddata.GetStudentDetailsByAuthId.inst_id}/student`
                                );
                            }
                          });
                        }
                      });
                    }
                  });

                  break;
                case UserType.EMPLOYEE:
                  GetStaffDetailsByAuthId({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data: EmpData }) => {
                    if (EmpData) {
                      dispatch({
                        type: payloadTypes.SET_EMP_LOGIN_ID,
                        payload: {
                          empLoginId: EmpData.GetEmployeeByAuthId.id,
                        },
                      });

                      isUserValid({
                        variables: {
                          token,
                          inst_id: EmpData.GetEmployeeByAuthId.inst_id,
                          input: {
                            user_type: UserType.EMPLOYEE,
                            id: EmpData.GetEmployeeByAuthId.id,
                          },
                        },
                      }).then(({ data }) => {
                        if (data && !data?.ValidateAuthUser) {
                          // handleSignOut();
                        }
                        if (EmpData) {
                          GetEmpPwdStatus({
                            variables: {
                              token,
                              inst_id:
                                EmpData.GetEmployeeByAuthId.inst_id.toString(),
                              msg_emp_id: EmpData.GetEmployeeByAuthId.id,
                            },
                          }).then(({ data: PwdStatusData }) => {
                            if (
                              PwdStatusData &&
                              PwdStatusData.CheckEmpResetPwdStatus.status
                            ) {
                              setPaswordModal(true);
                              setPasswordMesg(
                                PwdStatusData.CheckEmpResetPwdStatus.message
                              );
                            }
                          });
                        }
                      });
                      window.location.pathname === "/" &&
                        state.InstId &&
                        navigate(`/${state.InstId}/chat/informationcenter`);
                    }
                  });
                  break;

                case UserType.EDUATE:
                  eduateDetails({
                    variables: {
                      token,
                      auth_id: data.GetAuthDetailsByToken.id,
                    },
                  }).then(({ data }) => {
                    if (data) {
                      dispatch({
                        type: payloadTypes.SET_EDUATE_ID,
                        payload: {
                          eduateId: data.GetEduateDetailsByAuthId.id,
                        },
                      });

                      window.location.pathname === "/" &&
                        navigate("/customer/");
                    }
                  });
                  break;
                default:
                  break;
              }
            }
          });
        });
        getIdTokenResult(user).then((IdTokenResult) => {
          const { claims } = IdTokenResult;
          setClaims(claims);
        });
      }
    });
  }, [
    isUserValid,
    GetDetailsByIdToken,
    studentDetails,
    GetStaffDetailsByAuthId,
    dispatch,
    navigate,
    state.custId,
    state.InstId,
    state.subUserType,
    GetEmpPwdStatus,
    GetStdPwdStatus,
    eduateDetails,
  ]);
  return (
    <>
      <div className="container-fluid g-0 h-100">
        <div className="row g-0 h-100 ">
          <section className="col g-0 h-100">
            <div className="main">{children}</div>
          </section>
        </div>
        <footer className="container-fluid fixed-bottom">
          <div className="row g-0">
            <div className="col footer__lost-login">
              <span>
                {/* User Last Login:
              {toStandardDate(
                new Date(
                  auth.currentUser?.metadata.lastSignInTime!
                ).toLocaleString()
              )} */}
              </span>
            </div>

            <div className="col-6 footer_version">
              <Tooltip
                title="Active Finacial Year"
                placement="top"
                classes={{
                  tooltip: tooltipClasses.customTooltipGrey,
                  arrow: tooltipClasses.customArrowGrey,
                }}
              >
                <span>
                  {/* {(state.ActiveFinYr && state.ActiveFinYr.fin_yr) ||
                  CURRENT_YEAR} */}
                </span>
              </Tooltip>
              <span>{/* {toStandardDate(TODAY_DATE)}  */}</span>
              <span>{/* {time} */}</span>
              <span>
                Version <b onClick={() => handleSignOut()}>1.0.0</b>
              </span>
            </div>
          </div>
        </footer>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={passwordModal}
        style={ActiveAcdYrStyles}
        ariaHideApp={false}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <ResetPassword
              setPasswordModal={setPaswordModal}
              passwordMesg={passwordMesg}
            />
          </div>
          <div className="modal-flex__image"></div>
        </div>
      </Modal>
    </>
  );
};

export default DashBoard;
