import { makeStyles } from "@mui/styles";

export const AddModalStyles = {
  content: {
    margin: "auto",
    width: "80%",
    height: "80%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const InstitutionCustomStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const templateViewStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "35%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    padding: 10,
  },
}));

export const DisclaimerModalStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "60%",
    borderRadius: "10px",
    padding: "50px 20px 10px 20px",
    background: "rgba(0,0,0,0) !important",
    // boxShadow:
    //   "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "transparent !important",
    backdropFilter: "blur(1px)",
  },
};
export const LogOutModalStyles = {
  content: {
    borderRadius: "15px",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginLeft: "76.5%",
    width: "20%",
    height: "fit-content",
    marginTop: "10px",
    padding: "10px",
  },
  overlay: {
    background: "none",
    backdropFilter: "blur(0px)",
    zIndex: 1000,
  },
};
export const EditModalCustomStyles = {
  content: {
    margin: "auto",
    width: "45%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const ActiveAcdYrStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const LoadingModalStyles = {
  content: {
    margin: "auto",
    width: "30%",
    height: "fit-content",
    boxshadow: "none",
    border: "none",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    backdropFilter: "blur(1px)",
  },
};
export const LoadingStyles = {
  content: {
    margin: "auto",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "10px",
    padding: "0px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const MessageModalStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "80%",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const CopyModalStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "80%",
    borderRadius: "10px",
    padding: 0,
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
export const ModalCustomStyles = {
  content: {
    margin: "auto",
    marginTop: 75,
    width: "80%",
    height: "70%",

    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const StudentPreviewModalStyle = {
  content: {
    margin: "auto",
    marginTop: 75,
    width: "80%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const previewModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 50,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const ErrorModalStyles = {
  content: {
    margin: "auto",
    width: "25%",
    height: "25%",
    borderRadius: "10px",
    background: "rgb(255,255,255)",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
    backdropFilter: "blur(1px)",
  },
};

export const WaiveOffModalStyles = {
  content: {
    margin: "auto",
    width: "25%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const StudentModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 50,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const PrintModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 10,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1400,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const DeleteLedgerModalStyles = {
  content: {
    margin: "auto",
    width: "65%",
    height: "75%",
    borderRadius: "10px",
    marginTop: "75px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const AboutChannelModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    margin: "135px auto auto auto ",
    width: "40%",
    height: "65%",
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1000,
    background: "none",
    backdropFilter: "blur(0px)",
  },
};

export const AddLedgerModalStyles = {
  content: {
    margin: "auto",
    width: "40%",
    height: "fit-content",
    borderRadius: "10px",
    padding: "10px 20px 10px 20px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
  },
  overlay: {
    zIndex: 1000,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};

export const SectionAllocationModalStyles = {
  content: {
    borderRadius: "10px",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)",
    filter: "drop-shadow(8px -3px 4px rgba(0, 0, 0, 0.25))",
    marginTop: 75,
    marginLeft: 50,
    padding: "10px 20px 10px 20px",
  },
  overlay: {
    zIndex: 1300,
    background: "rgba(0, 0, 0, 0.24)",
    backdropFilter: "blur(1px)",
  },
};
