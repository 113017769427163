import React, { useEffect, useState } from "react";
import { Title } from "../../../../stories/Title/Title";
import Close from "../../../../images/Close.svg";
import { Button } from "../../../../stories/Button/Button";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";

import { SelectChangeEvent } from "@mui/material";
import { Form, Formik } from "formik";
import { CustomerFormDataTypes } from "../../../../utils/FormTypes";
import { customer_validation } from "../../../../utils/validationRules";
import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { msgType } from "../../../../utils/Types";
import MessageModal from "../../../../utils/MessageModal";
import { AddCustomer } from "../../../../queries/customers/mutations/new";
import useToken from "../../../../customhooks/useToken";
import {
  handleFormEvent,
  removeMoreSpace,
} from "../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import { GetCustomers } from "../../../../queries/customers/query/list";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import {
  AddCustomerData,
  AddCustomerVars,
  GetCustomerByNodeData,
  NodeVars,
  UpdateCustomerData,
  UpdateCustomerVars,
} from "../../../../utils/Query.Types";
import { UpdateCustomer } from "../../../../queries/customers/mutations/update";
import { GetCustomerByNode } from "../../../../queries/customers/query/byId";
import LoadingModal from "../../../Modals/LoadingModal";
const { Masters_Form } = require("../json/form.json");
export interface formLabelProps {
  labelName: string;
  inputName: string;
  autoFocus?: boolean;
  required?: boolean;
  dataType?: string;
  maxLength?: number;
}

interface Props {
  type: Operation;
  modalFlag: boolean;
  customerId: number;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<Operation>>;
}

const Add = ({ type, setModalFlag, modalFlag, customerId, setType }: Props) => {
  const { user_details } = useLoggedInUserDetails();

  let filename = "";
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { token } = useToken();

  const [GetCustomerDetail, { data }] = useLazyQuery<
    GetCustomerByNodeData,
    NodeVars
  >(GetCustomerByNode, {
    variables: {
      token,
      id: customerId,
    },
  });

  const [createCustomer, { loading: creationLoading }] = useMutation<
    AddCustomerData,
    AddCustomerVars
  >(AddCustomer, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [UpdateCustomerByid, { loading: updationLoading }] = useMutation<
    UpdateCustomerData,
    UpdateCustomerVars
  >(UpdateCustomer, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const [formData, setFormData] = useState<CustomerFormDataTypes>({
    cust_name: "",
    cust_address: "",
    cust_place: "",
    cust_state: "",
    cust_pin: "",
    cust_contact_person: "",
    cust_phone: "",
    cust_email: "",
    cust_mobile: "",
    cust_url: "",
    cust_banner_1: "",
    cust_banner_2: "",
    cust_logo_filename: "",
  });

  const handleClear = () => {
    setFormData({
      cust_name: "",
      cust_address: "",
      cust_place: "",
      cust_state: "",
      cust_pin: "",
      cust_contact_person: "",
      cust_phone: "",
      cust_email: "",
      cust_mobile: "",
      cust_url: "",
      cust_banner_1: "",
      cust_banner_2: "",
      cust_logo_filename: "",
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
      setType(Operation.CREATE);
      setModalFlag(false);
    }

    setMessage({
      message: "",
      flag: false,
      operation: Operation.NONE,
    });
  };
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string | number>
  ) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value.toString(),
    }));
  };
  useEffect(() => {
    if (type === Operation.UPDATE) {
      GetCustomerDetail().then(({ data }) => {
        if (data && data.node) {
          setFormData({
            ...data.node,
          });
        }
      });
    }
  }, [type, GetCustomerDetail, data, modalFlag]);

  const HandleRegister = () => {
    createCustomer({
      variables: {
        token,
        input: {
          cust_name: removeMoreSpace(formData.cust_name),
          cust_address: removeMoreSpace(formData.cust_address),
          cust_place: removeMoreSpace(formData.cust_place),
          cust_state: removeMoreSpace(formData.cust_state),
          cust_pin: formData.cust_pin,
          cust_contact_person: removeMoreSpace(formData.cust_contact_person),
          cust_phone: formData.cust_phone,
          cust_email: removeMoreSpace(formData.cust_email),
          cust_mobile: formData.cust_mobile,
          cust_banner_1: removeMoreSpace(formData.cust_banner_1),
          cust_banner_2: removeMoreSpace(formData.cust_banner_2),
          cust_url: formData.cust_url,
          cust_logo_filename: filename,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetCustomers,
          variables: {
            token,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: EMPTY_STRING,
            after: null,
            sortBy: SortBy.CUST_NAME,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Customer Created Successfully",
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };

  const HandleUpdate = () => {
    UpdateCustomerByid({
      variables: {
        id: customerId,
        token,
        input: {
          cust_name: removeMoreSpace(formData.cust_name),
          cust_address: removeMoreSpace(formData.cust_address),
          cust_place: removeMoreSpace(formData.cust_place),
          cust_state: removeMoreSpace(formData.cust_state),
          cust_pin: removeMoreSpace(formData.cust_pin),
          cust_contact_person: removeMoreSpace(formData.cust_contact_person),
          cust_phone: formData.cust_phone,
          cust_email: formData.cust_email,
          cust_mobile: formData.cust_mobile,
          cust_banner_1: removeMoreSpace(formData.cust_banner_1),
          cust_banner_2: removeMoreSpace(formData.cust_banner_2),
          cust_url: formData.cust_url,
          cust_logo_filename: filename,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetCustomers,
          variables: {
            token,
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: "",
            after: null,
            sortBy: SortBy.CUST_NAME,
          },
        },
        {
          query: GetCustomerByNode,
          variables: { token, id: customerId },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Customer Updated Successfully",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={customer_validation}
        onSubmit={type === Operation.CREATE ? HandleRegister : HandleUpdate}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form className="cust-add">
              <div className="cust-add__title">
                <Title>Add Customer</Title>
                <img src={Close} alt="" onClick={() => setModalFlag(false)} />
              </div>
              <div className="cust-add__form row g-0">
                <div className="col">
                  <div className="cust-add__form--header">
                    <h2>Personal Details</h2>
                  </div>
                  {Masters_Form.Customer.PersonalDetails.map(
                    (
                      {
                        labelName,
                        inputName,
                        autoFocus,
                        required,
                      }: formLabelProps,
                      index: number
                    ) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="label-grid">
                            <Label>{labelName}</Label>
                            <Input
                              required={required}
                              autoFocus={autoFocus}
                              name={inputName}
                              value={formData[inputName]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                meta.handleChange(e);
                                handleValueChange(e);
                              }}
                              error={meta.errors[inputName]}
                              onKeyDown={handleFormEvent}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                  )}
                  <br />
                  <div className="cust-add__form--header">
                    <h2>Other Details</h2>
                  </div>
                  {Masters_Form.Customer.OtherDetails.map(
                    (
                      {
                        labelName,
                        inputName,
                        autoFocus,
                        required,
                      }: formLabelProps,
                      index: number
                    ) => (
                      <React.Fragment key={index}>
                        <div className="label-grid">
                          <Label>{labelName}</Label>
                          <Input
                            required={required}
                            autoFocus={autoFocus}
                            value={formData[inputName]}
                            name={inputName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            onKeyDown={handleFormEvent}
                          />
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
                <div className="col">
                  <div className="cust-add__form--header">
                    <h2>Location Details</h2>
                  </div>
                  {Masters_Form.Customer.LocationDetails.map(
                    (
                      {
                        labelName,
                        inputName,
                        autoFocus,
                        required,
                      }: formLabelProps,
                      index: number
                    ) => (
                      <React.Fragment key={index}>
                        <div className="label-grid">
                          <Label>{labelName}</Label>
                          <Input
                            required={required}
                            autoFocus={autoFocus}
                            value={formData[inputName]}
                            name={inputName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            onKeyDown={handleFormEvent}
                          />
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
              <Button mode="save" type="submit" />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  setModalFlag(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>

      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />

      <LoadingModal flag={creationLoading || updationLoading} />
    </>
  );
};

export default Add;
