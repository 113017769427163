import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import { Actions } from "./types";

export type InitialStateType = {
  searchData: string;
  deptId: number;
  branchId: number;
  classId: number;
  semId: number;
  sectionId: number;
};

export const initialState: InitialStateType = {
  searchData: "",
  deptId: 0,
  branchId: 0,
  classId: 0,
  semId: 0,
  sectionId: 0,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});
interface Props {
  children: React.ReactNode;
}
const AppProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ dispatch, state }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
