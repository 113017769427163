import { gql } from "@apollo/client";

export const GetMstInstBranches = gql`
  query GetMstInstBranches(
    $token: String!
    $id: ID!
    $after: Cursor
    $query_type: BranchQueryType!
    $first: Int
    $name: String
    $orderBy: MsgMstInstBranchOrder
  ) {
    GetMstInstBranches(
      token: $token
      id: $id
      first: $first
      after: $after
      query_type: $query_type
      orderBy: $orderBy
      where: { or: [{ branchDescContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          branch_desc
          branch_short_desc
          branch_idx
          inst_id
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const BranchIdByName = gql`
  query GetBranchIdByName(
    $inst_id: ID!
    $dept_id: ID!
    $branch_name: String!
    $token: String!
  ) {
    GetBranchIdByName(
      token: $token
      inst_id: $inst_id
      dept_id: $dept_id
      branch_name: $branch_name
    )
  }
`;
