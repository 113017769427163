import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { IObjectKeys } from "../utils/FormTypes";
import useToken from "./useToken";
import { AppContext } from "../context/context";
import {
  CommonNodeVars,
  EmpData,
} from "../components/Modules/Chat/UserRights/Types";
import { MsgEmpMaster } from "../queries/userrights";

interface EmpDetailsForm extends IObjectKeys {
  emp_name: string;
  emp_sex: string;
  emp_dob: string;
  emp_mobile: string;
  emp_email: string;
  emp_status: string;
  can_send_feeds: boolean;
  can_send_announcement: boolean;

  is_global_user: boolean;
}

const useEmpDetailsById = (use_login: boolean) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [employeeFormData, setEmployeeFormData] = useState<EmpDetailsForm>({
    emp_name: "",
    emp_sex: "",
    emp_dob: "",
    emp_mobile: "",
    emp_email: "",
    emp_status: "",
    can_send_announcement: false,
    can_send_feeds: false,
    is_global_user: false,
  });

  const [GetEmpDetails, EmpData] = useLazyQuery<EmpData, CommonNodeVars>(
    MsgEmpMaster,
    {
      variables: {
        id: use_login ? state.empLoginId : state.employeeId,
        token,
      },
      fetchPolicy: "network-only",
    }
  );

  const handleClear = () => {
    setEmployeeFormData({
      emp_name: "",
      emp_sex: "",
      emp_dob: "",
      emp_mobile: "",
      emp_email: "",
      emp_status: "",
      can_send_announcement: false,
      can_send_feeds: false,
      is_global_user: false,
    });
  };

  useEffect(() => {
    if ((state.employeeId || state.empLoginId) && token) {
      GetEmpDetails().then(({ data }) => {
        if (data) {
          const {
            emp_name,
            emp_status,
            emp_dob,
            emp_email,
            emp_mobile,
            emp_sex,
            can_send_announcement,
            can_send_feeds,
            is_global_user,
          } = data.node;

          setEmployeeFormData({
            emp_name: emp_name,
            emp_sex,
            emp_dob,
            emp_email,
            emp_mobile,
            emp_status,
            can_send_announcement,
            can_send_feeds,
            is_global_user,
          });
        }
      });
    } else {
      handleClear();
    }

    // eslint-disable-next-line
  }, [state.employeeId, state.empLoginId, GetEmpDetails, token]);
  return { empolyeeData: EmpData, employeeFormData };
};

export default useEmpDetailsById;
