import { gql } from "@apollo/client";
export const UpdateCustomer = gql`
  mutation UpdateCustomer(
    $id: ID!
    $input: UpdateMsgMstCustomerInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    UpdateCustomer(
      token: $token
      id: $id
      input: $input
      user_details: $user_details
    )
  }
`;

export const DeleteCustomer = gql`
  mutation DeleteCustomer(
    $customer_id: ID!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    DeleteCustomer(
      token: $token
      customer_id: $customer_id
      user_details: $user_details
    )
  }
`;
