import { gql } from "@apollo/client";

export const AddInstBranch = gql`
  mutation AddInstBranch(
    $input: CreateMsgMstInstBranchInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstBranch(token: $token, input: $input, user_details: $user_details)
  }
`;
