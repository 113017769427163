import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Steps from "./Steps";
import BasicDetails from "./BasicDetails";
import LicenseDetails from "./LicenseDetails";
import AcademicYear from "../Registration/Academics/Index";
import Configuration from "./Configuration";
import Close from "../../../../../images/Close.svg";
import { Operation } from "../../../../../utils/Enum.types";

interface ModalProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index = ({ setModal }: ModalProps) => {
  const [step, setStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);

  const [basicDetails, setBasicDetails] = useState(true);
  const [licenseDetails, setLicenseDetails] = useState(false);
  const [academicYear, setAcademicYear] = useState(false);
  const [configuration, setConfiguration] = useState(false);
  useEffect(() => {
    switch (step) {
      case 1:
        setBasicDetails(true);
        setLicenseDetails(false);
        setAcademicYear(false);
        setConfiguration(false);
        break;
      case 2:
        setBasicDetails(false);
        setLicenseDetails(false);
        setAcademicYear(true);
        setConfiguration(false);
        break;
      case 3:
        setBasicDetails(false);
        setLicenseDetails(true);
        setAcademicYear(false);
        setConfiguration(false);
        break;
      case 4:
        setBasicDetails(false);
        setLicenseDetails(false);
        setAcademicYear(false);
        setConfiguration(true);

        break;
      default:
        break;
    }
  }, [step]);
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <div className="inst-details__form--title">
        <Title>Add Institution</Title>
        <img src={Close} alt="" onClick={() => setModal(false)} />
      </div>
      <Steps step={activeStep + 1} />
      {step === 1 && basicDetails && (
        <BasicDetails
          operation={Operation.CREATE}
          onNext={handleNext}
          onBack={handleBack}
          setModal={() => setModal(false)}
        />
      )}
      {step === 2 && academicYear && (
        <AcademicYear
          operation={Operation.CREATE}
          onNext={handleNext}
          onBack={handleBack}
          setModal={() => setModal(false)}
        />
      )}
      {step === 3 && licenseDetails && (
        <LicenseDetails
          operation={Operation.CREATE}
          onNext={handleNext}
          onBack={handleBack}
          setModal={() => setModal(false)}
        />
      )}
      {step === 4 && configuration && (
        <Configuration
          operation={Operation.CREATE}
          onNext={handleNext}
          onBack={handleBack}
          setModal={() => setModal(false)}
        />
      )}
    </>
  );
};

export default Index;
