import React, { useEffect, useState } from "react";
import Logo from "../../../../images/EduateLogo.svg";
import { useParams } from "react-router-dom";
import ArrowLeft from "../../../../images/DownArrow.svg";
import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import { Title } from "../../../../stories/Title/Title";
import {
  DataGridPro,
  GridColDef,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DatagridStyles";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import { Direction, SortBy } from "../../../../utils/Enum.types";
import PayOnline from "../../../../images/PayOnline.svg";
import ReactModal from "react-modal";
import { CopyModalStyles } from "../../../styles/ModalStyles";
import DisClaimer from "./DisClaimer";
import Input from "../../../../stories/Input/Input";
import { useLazyQuery } from "@apollo/client";
import { GetStdDemandForPayment } from "../../../../queries/students/list";
import {
  GetStdDemandList,
  GetStdDemandListEdges,
} from "../../Masters/Student/Dashboard/FeeDetails";
import useInstLabels from "../../../../customhooks/useInstLabels";
import { StdDemandQueryType } from "../../../../utils/QueryEnums";
import { GetEncodedValueData, GetEncodedValueVars } from "./FetchDetails";
import { GetEncodedValue } from "../../../../queries/payments/query";
import useInstDetailsWithoutToken from "../../../../customhooks/useInstDetailsWithoutToken";
import useMstInstLogoWithoutToken from "../../../../customhooks/useMstInstLogoWithoutToken";

export interface GetStdDemandForPaymentData {
  GetStdDemandForPayment: GetStdDemandList;
}

export interface GetStdDemandForPaymentVars {
  encoded_str: string;
  query_type: StdDemandQueryType;
  inst_id: string;
  after: string | null;
  first: number;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
  name: string;
}

interface Props {
  by: StdDemandQueryType;
}
const StudentDetails = ({ by }: Props) => {
  const { InstFormData } = useInstDetailsWithoutToken();
  const { InstLogo } = useMstInstLogoWithoutToken();
  const { instId, encryptString } = useParams();
  const tableClasses = useDataGridStyles();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [students, setStudents] = useState<GetStdDemandListEdges[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [disclaimer, setDisclaimer] = useState(false);
  const { classLabel } = useInstLabels();

  const [encodedValue, setEncodedValue] = useState("");

  const [mobileNo, setMobileNo] = useState("");

  const [plainValue, setPlainValue] = useState("");
  const [stdDemandId, setStdDemanId] = useState(0);
  const [GetEncode] = useLazyQuery<GetEncodedValueData, GetEncodedValueVars>(
    GetEncodedValue
  );
  const [GetStdDemand, { data, loading, fetchMore }] = useLazyQuery<
    GetStdDemandForPaymentData,
    GetStdDemandForPaymentVars
  >(GetStdDemandForPayment, {
    variables: {
      inst_id: instId!,
      encoded_str: encodedValue,
      query_type: by,
      after: null,
      first: ROWS_PER_PAGE,
      name: EMPTY_STRING,
      orderBy: {
        direction: Direction.ASC,

        field: SortBy.STD_NAME,
      },
    },
  });

  useEffect(() => {
    const scrollTable = document.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    )[0] as Element;
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
      const scrollTop = target.scrollTop;
      const scrollHeight = target.scrollHeight;
      const clientHeight = target.clientHeight;
      if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
        if (hasNextPage && !loading) {
          fetchMore({
            variables: {
              first: ROWS_PER_PAGE,
              after: endCursor,
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prevResult;

              const newEdges = fetchMoreResult.GetStdDemandForPayment.edges;
              const pageInfo = fetchMoreResult.GetStdDemandForPayment.pageInfo;
              setEndCursor(pageInfo.endCursor);
              setHasNextPage(pageInfo.hasNextPage);

              const duplicateCheck =
                prevResult.GetStdDemandForPayment.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

              if (duplicateCheck.length > 0) return prevResult;

              return {
                GetStdDemandForPayment: {
                  edges: [...students, ...newEdges],
                  pageInfo,
                  totalCount: data
                    ? data.GetStdDemandForPayment.totalCount!
                    : 0,
                },
              };
            },
          });
        }
      }
    };
    if (scrollTable && rows.length)
      scrollTable.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollTable) scrollTable.removeEventListener("scroll", handleScroll);
    };
  }, [rows]);

  useEffect(() => {
    if (instId && encodedValue && by) GetStdDemand();
  }, [GetStdDemand, instId, encodedValue, by]);

  useEffect(() => {
    if (encryptString && encodedValue === EMPTY_STRING) {
      setEncodedValue(encryptString);
    }
  }, [encryptString, encodedValue]);
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStdDemandForPayment.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            student_id: node.std_id,
            std_name: node.std_name,
            std_roll_no: node.std_roll_no,
            std_father_name: node.std_father_name,
            std_mother_name: node.std_mother_name,
            std_place: node.std_place,
            class_desc: node.class_desc,
            mobile_no: node.mobile_no,
            fee_total_demand: node.fee_total_demand,
            fee_total_paid: node.fee_total_paid,
            fee_balance: node.fee_balance,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            student_id: node.std_id,
            std_name: node.std_name,
            std_roll_no: node.std_roll_no,
            std_father_name: node.std_father_name,
            std_mother_name: node.std_mother_name,
            std_place: node.std_place,
            class_desc: node.class_desc,
            mobile_no: node.mobile_no,
            fee_total_demand: node.fee_total_demand,
            fee_total_paid: node.fee_total_paid,
            fee_balance: node.fee_balance,
          }))
        );
      }
      setEndCursor(data.GetStdDemandForPayment.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    {
      field: "student_id",
      headerName: "Student Id",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "",
    },
    {
      field: "std_name",
      headerName: "Name",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "",
      flex: 1,
    },
    {
      field: "std_father_name",
      headerName: "Father Name",
      cellClassName: "",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "std_mother_name",
      headerName: "Mother Name",
      cellClassName: "",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "std_roll_no",
      headerName: "Roll No",
      cellClassName: "",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "std_place",
      headerName: "Place",
      cellClassName: "",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "class_desc",
      headerName: classLabel,
      cellClassName: "",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "mobile_no",
      headerName: "Mobile No",
      cellClassName: "",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "fee_total_demand",
      headerName: "Demand",
      cellClassName: "td-amount",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "fee_total_paid",
      headerName: "Paid",
      cellClassName: "td-amount",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "fee_balance",
      headerName: "Balance",
      cellClassName: "td-amount",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "actions",
      headerName: "Actions",
      cellClassName: "td-status",
      headerAlign: HEADER_TEXT_ALIGN,
      renderCell: (params) => (
        <>
          <button
            className="std-fee-details__block--tableblock--pay-fee"
            onClick={() => {
              setStdDemanId(params.row.stdId);
              setDisclaimer(!disclaimer);
            }}
          >
            {" "}
            Pay Fee <img src={PayOnline} alt="" />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="std-fee-details">
        <div className="std-fee-details__block">
          <div className="std-fee-details__block--header">
            <div className="std-fee-details__block--header--l">
              {InstLogo.defaultLogo ? (
                <img src={InstLogo.defaultLogo} alt="/" />
              ) : null}{" "}
              <div className="std-fee-details__block--header--inst-details">
                <span>{InstFormData.cust_name}</span>
                <b>{InstFormData.inst_name}</b>
                <span>
                  {`${InstFormData.inst_address} ${InstFormData.inst_place} ${InstFormData.inst_state} ${InstFormData.inst_pin}`}
                </span>
              </div>
            </div>
            <div className="std-fee-details__block--header--r">
              <span>SimpliFied By</span>
              <img src={Logo} alt="" />
              <b>My-Eduate</b>
            </div>
          </div>
          <div className="std-fee-details__block--data">
            <a href={`/${instId}/chat/fee/fetchdetails`}>
              <img src={ArrowLeft} alt="" />
              Go Back
            </a>
            <div className="std-fee-details__block--frame">
              <Label> Your Mobile No.</Label>
              <Input
                onChange={(e) => {
                  if (by === StdDemandQueryType.BY_MOBILE_NO) {
                    if (
                      Number(e.target.value) >= 0 &&
                      e.target.value.length <= 10
                    ) {
                      setMobileNo(e.target.value);
                    }
                  } else {
                    setPlainValue(e.target.value);
                  }
                }}
              />
              <Button
                mode="change-number"
                onClick={() => {
                  GetEncode({
                    variables: {
                      inst_id: instId!,
                      str_to_encode:
                        by === StdDemandQueryType.BY_MOBILE_NO
                          ? mobileNo
                          : plainValue,
                    },
                  }).then(({ data }) => {
                    if (data && data.GetEncodedValue.length) {
                      setEncodedValue(data.GetEncodedValue);
                    }
                  });
                }}
              />
            </div>
            <div className="std-fee-details__block--flex">
              <Title variant="subtitle1">Students List</Title>
              <div className="std-fee-details__block--flex--total">
                <span>Total Students List :</span>
                <b>{data ? data.GetStdDemandForPayment.totalCount : 0}</b>
              </div>
            </div>
            <div
              className={`std-fee-details__block--tableblock ${tableClasses.root}`}
            >
              <DataGridPro
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick
                rowHeight={TABLE_ROW_HEIGHT}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={disclaimer}
        ariaHideApp={false}
        style={CopyModalStyles}
      >
        <DisClaimer setModalFlag={setDisclaimer} stdDemandId={stdDemandId} />
      </ReactModal>
    </>
  );
};

export default StudentDetails;
