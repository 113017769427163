import { gql } from "@apollo/client";

export const ClassByNode = gql`
  query ClassDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstClass {
        id
        inst_id
        class_desc
        class_short_desc
      }
    }
  }
`;
