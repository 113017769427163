import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";

import { GetMstInstLogoWithoutToken } from "../queries/institution/queries/byId";
import { getDownloadUrl } from "../utils/DownloadFile";
export interface GetMstInstLogoWithoutTokenData {
  GetMstInstLogoWithoutToken: string;
}
export interface GetMstInstLogoWithoutTokenVars {
  inst_id: string | number;
}

const useMstInstLogoWithoutToken = () => {
  const { instId } = useParams();
  const { state } = useContext(AppContext);
  const [imageString, setImageString] = useState("");

  const [GetLogoData, { data, loading }] = useLazyQuery<
    GetMstInstLogoWithoutTokenData,
    GetMstInstLogoWithoutTokenVars
  >(GetMstInstLogoWithoutToken, {
    variables: { inst_id: state.InstId || instId! },
  });
  useEffect(() => {
    if (data && !loading) {
      const filename = data.GetMstInstLogoWithoutToken;
      if (filename) {
        // eslint-disable-next-line
        const ers = getDownloadUrl(filename, setImageString, false);
      }
    }
  }, [data, loading]);
  useEffect(() => {
    if (instId) {
      GetLogoData();
    }
  }, [instId, GetLogoData]);
  return { InstLogo: { data, loading, defaultLogo: imageString } };
};

export default useMstInstLogoWithoutToken;
