import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "../styles/TooltipStyles";
import Teal from "../../images/Teal.svg";
import Green from "../../images/Green.svg";
import Pink from "../../images/Pink.svg";
import Amber from "../../images/Amber.svg";
import Blue from "../../images/SkyBlue.svg";
import Fuchsia from "../../images/Fuchsia.svg";
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const ThemeMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
      borderRadius: "10px",
    },
  },
};
const Skins = () => {
  const classes = useStyles();
  const [theme, setTheme] = useState(
    localStorage.getItem("selectedTheme") || ""
  );

  const colorOption = [
    {
      label: "Teal",
      value: "teal",
      image: `${Teal}`,
      tooltip: classes.customTooltipTeal,
      arrow: classes.customArrowTeal,
    },

    {
      label: "Pink",
      value: "pink",
      image: `${Pink}`,
      tooltip: classes.customTooltipPink,
      arrow: classes.customArrowPink,
    },

    {
      label: "Green",
      value: "Green",
      image: `${Green}`,
      tooltip: classes.customTooltipGreen,
      arrow: classes.customArrowGreen,
    },
    {
      label: "Fuchsia",
      value: "fuchsia",
      image: `${Fuchsia}`,
      tooltip: classes.customTooltipFuchsia,
      arrow: classes.customArrowFuchsia,
    },

    {
      label: "SkyBlue",
      value: "sky",
      image: `${Blue}`,
      tooltip: classes.customTooltipBlue,
      arrow: classes.customArrowBlue,
    },
    {
      label: "Amber",
      value: "amber",
      image: `${Amber}`,
      tooltip: classes.customTooltipAmber,
      arrow: classes.customArrowAmber,
    },
  ];
  const setThemeAndStore = (selectedTheme: string) => {
    localStorage.setItem("selectedTheme", selectedTheme);
    document.documentElement.className = selectedTheme;
    setTheme(selectedTheme);
  };
  useEffect(() => {
    const storedTheme = localStorage.getItem("selectedTheme");
    if (storedTheme) {
      document.documentElement.className = storedTheme;
      setTheme(storedTheme);
    }
  }, []);
  return (
    <>
      <Select
        onChange={(e: SelectChangeEvent) => setThemeAndStore(e.target.value)}
        className="theme-option"
        value={theme}
        MenuProps={ThemeMenuProps}
      >
        {colorOption.map((option, index: React.Key) => (
          <MenuItem key={index} value={option.value} className="theme-menu">
            <ListItemText>
              <Tooltip
                title={option.label}
                arrow
                placement="bottom"
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
                className="tooltip-hide"
              >
                <img src={option.image} alt="/" className="theme-image" />
              </Tooltip>
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default Skins;
