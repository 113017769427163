import { TEXT_FONT_FAMILY } from "./TooltipStyles";
import { makeStyles } from "@mui/styles";
import { CSSProperties } from "react";
import { NUMBER_FONT_FAMILY } from "../components/styles/DatagridStyles";
const commonHeaderStyles: CSSProperties = {
  position: "sticky",
  backgroundColor: "var(--level-8)",
  padding: "0px !important",
  color: "white !important",
  textAlign: "center",
  fontFamily: TEXT_FONT_FAMILY,
  borderRight: "1px solid var(--border-color) !important",
  height: "25px",
  fontSize: "14px !important",
  fontStyle: "normal",
  zIndex: 0,
  top: 0,
};

const stickyColumnStyles: CSSProperties = {
  position: "sticky",
  zIndex: 0,
  backgroundColor: "var(--level-1)",
  borderRight: "1px solid var(--border-color) !important",
  padding: "5px 3px !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px !important",
};
const commonColumnStyles: CSSProperties = {
  padding: "5px 3px !important",
  zIndex: 0,
  backgroundColor: "var(--level-1)",
  borderRight: "1px solid var(--border-color) !important",
  fontFamily: TEXT_FONT_FAMILY,
  fontSize: "14px important",
};
const columnWithNumberFont: CSSProperties = {
  fontFamily: NUMBER_FONT_FAMILY,
};
export const ImportStudentDataTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    minWidth: 160,
    maxWidth: 160,
  },

  // stickyHeaderCheckbox: {
  //   ...commonHeaderStyles,
  //   zIndex: 998,
  //   minWidth: 80,
  //   maxWidth: 80,
  //   left: 0,
  // },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },

  stickyHeaderAdmNo: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },
  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 240,
  },

  stickyHeaderStatus: {
    ...commonHeaderStyles,
    zIndex: 998,
    overflow: "hidden",
    right: 0,
    maxWidth: "160px",
    minWidth: "160px",
  },

  // stickyColumnCheckbox: {
  //   ...stickyColumnStyles,
  //   left: 0,
  //   zIndex: 0,
  //   minWidth: 80,
  //   maxWidth: 80,
  // },
  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },

  stickyColumnAdmno: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnName: {
    ...stickyColumnStyles,
    left: 240,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
  },
  stickyColumnStatus: {
    ...stickyColumnStyles,
    right: 0,
    zIndex: 0,
    backgroundColor: "red",
    maxWidth: "160px",
    minWidth: "160px",
  },
});

export const ImportMessageTableStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "calc(100% - 10px)",
    tableLayout: "fixed",
  },

  stickyHeader: {
    ...commonHeaderStyles,
    minWidth: 160,
    maxWidth: 160,
  },

  // stickyHeaderCheckbox: {
  //   ...commonHeaderStyles,
  //   zIndex: 998,
  //   minWidth: 80,
  //   maxWidth: 80,
  //   left: 0,
  // },
  stickyHeaderSl: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 80,
    maxWidth: 80,
    left: 0,
  },


  stickyHeaderName: {
    ...commonHeaderStyles,
    zIndex: 998,
    minWidth: 160,
    maxWidth: 160,
    left: 80,
  },




  stickyColumnSl: {
    ...stickyColumnStyles,
    ...columnWithNumberFont,
    left: 0,
    minWidth: 80,
    maxWidth: 80,
  },


  stickyColumnName: {
    ...stickyColumnStyles,
    left: 80,
    minWidth: 160,
    maxWidth: 160,
  },

  stickyColumn: {
    ...commonColumnStyles,
    minWidth: 160,
    maxWidth: 160,
  },

});
