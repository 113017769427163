import { gql } from "@apollo/client";

export const GetChannelStudentIndividual = gql`
  query GetChannelStudentIndividual(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
  ) {
    GetChannelStudentIndividual(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
    ) {
      id
    }
  }
`;

export const GetChannelSubscribed = gql`
  query GetChannelSubscribed(
    $token: String!
    $inst_id: ID!
    $input: SubscribedUser!
  ) {
    GetChannelSubscribed(token: $token, inst_id: $inst_id, input: $input) {
      id
      channel_name
      channel_desc
      channel_topic
      channel_chat_enabled
      channel_type
      channel_active
      inst_id
    }
  }
`;

export const GetChannels = gql`
  query GetChannels(
    $token: String!
    $inst_id: ID!
    $channel_type: String!
    $entry_level: String!
    $entry_id: ID!
  ) {
    GetChannels(
      token: $token
      inst_id: $inst_id
      channel_type: $channel_type
      entry_level: $entry_level
      entry_id: $entry_id
    ) {
      id
      channel_name
      channel_desc
      channel_topic
      channel_chat_enabled
      channel_type
      channel_active
      inst_id
    }
  }
`;
