import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { WaiveOffModalStyles } from "../../../../../styles/ModalStyles";
import { Title } from "../../../../../../stories/Title/Title";
import Close from "../../../../../../images/Close.svg";
import BrowseFile from "../../../../../../images/BrowseFiles.svg";
import Image from "../../../../../../images/DownloadImage.svg";

import useFileSizeDetails from "../../../../../../customhooks/useFileUploadConstraints";
import { Button } from "../../../../../../stories/Button/Button";
import { useMutation } from "@apollo/client";
import { UpdateStudentById } from "../../../../../../queries/students/mutations";
import { Operation, imageFileType } from "../../../../../../utils/Enum.types";
import useToken from "../../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../../../customhooks/useLoggedInUserDetails";
import useInstDetails from "../../../../../../customhooks/useInstDetails";
import { GetStudentDetails } from "../../../../../../queries/students/list";
import { msgType } from "../../../../../../utils/Types";
import useStudentDatabyId from "../../../../../../customhooks/useStudentDataById";
import { handleUploadAndDownloadFile } from "../../../../../../utils/Upload";
import { AppContext } from "../../../../../../context/context";
import LoadingModal from "../../../../../Modals/LoadingModal";
import { getDownloadUrl } from "../../../../../../utils/DownloadFile";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  handleClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<msgType>>;
}
const ProfilePicUpload = ({ modal, handleClose, setMessage }: Props) => {
  const { ComponentWarning, maxFileSizeInBits } = useFileSizeDetails();
  const { token } = useToken();
  const { instId, studentId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { InstFormData } = useInstDetails();
  const [file, setFile] = useState<File | null>(null);
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  const [uploadLoading, setUploading] = useState(false);
  const { state } = useContext(AppContext);
  const { studentFormData } = useStudentDatabyId();

  const [imageString, setImageString] = useState("");

  const filetype = file ? file.name.split(".").pop() : "";

  const filename = `${InstFormData.inst_name}/students/${
    state.studentId ? state.studentId : studentId
  }/std_profile_pic/std_profile_pic`;
  const fileNameWithExtension = `${filename}.${filetype}`;

  const [updateStudent, { loading: updateLoading }] = useMutation(
    UpdateStudentById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  useEffect(() => {
    if (studentFormData.std_profile_filename) {
      getDownloadUrl(
        studentFormData.std_profile_filename,
        setImageString,
        false
      );
    } else {
      setImageString("");
    }
  }, [studentFormData.std_profile_filename]);

  useEffect(() => {
    if (file) {
      // eslint-disable-next-line
      Object.values(imageFileType).map((d) => {
        if (d === filetype) {
          setImageString(URL.createObjectURL(file));
        }
      });
    } else {
      setImageString("");
    }

    // eslint-disable-next-line
  }, [file]);

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > maxFileSizeInBits.mediaSize) {
        alert("huge file");
        setFile(null);
        return;
      }
      let image_as_files = e.target.files[0];
      setFile(image_as_files);
    }
  };

  const uploadFile = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          handleUploadAndDownloadFile(
            file,
            fileNameWithExtension,
            setProgress,
            false
          )
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateForm = async () => {
    setUploading(true);
    if (file === null) {
      alert("File not Found");
      return;
    }
    try {
      const uploadResult = await uploadFile();
      if (uploadResult) {
        setUploading(false);
        updateStudent({
          variables: {
            id: state.studentId,
            token,
            inst_id: instId!,
            user_details,
            input: {
              std_profile_filename: fileNameWithExtension,
            },
          },
          refetchQueries: [
            {
              query: GetStudentDetails,
              variables: {
                token,
                ids: [state.studentId],
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Student Details Updated Successfully",
              flag: true,
              operation: Operation.UPDATE,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={modal}
      style={WaiveOffModalStyles}
    >
      <div className="profile-pic">
        {uploadLoading || updateLoading ? (
          <LoadingModal flag={uploadLoading || updateLoading} />
        ) : (
          <>
            <div className="profile-pic__title">
              <Title>Update Profile Pic</Title>
              <Title variant="subtitle1">({studentFormData.std_name})</Title>
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={handleClose}
              />
            </div>

            <div className="profile-pic__preview">
              <img src={imageString ? imageString : Image} alt="/" />
            </div>
            <div className="profile-pic__warning">
              {ComponentWarning.mediaSizeComponent}
            </div>
            <div className="profile-pic__btns">
              <label className="profile-pic__browse">
                <img src={BrowseFile} alt="/" className="browse-icon" />
                Browse
                <input
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleImagePreview(e)
                  }
                  className="profile-pic__upload-btn--input"
                  accept={`${".jpg, .jpeg, .png"}`}
                />
              </label>
              <Button mode="save" onClick={updateForm} />
              <Button mode="cancel" onClick={handleClose} />
            </div>
          </>
        )}
      </div>
    </ReactModal>
  );
};

export default ProfilePicUpload;
