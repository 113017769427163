import { makeStyles } from "@mui/styles";

export const sectionAllocationStyles = makeStyles(() => ({
  drawer: {
    width: "25%",
  },
  drawerPaper: {
    top: "110px !important",
    bottom: "0px !important",
    width: "60%",
    borderRadius: "6px 0px",
    height: "calc(100% - 110px) !important",
    zIndex: "100 !important",
  },
}));
