import { gql } from "@apollo/client";

export const AddStudent = gql`
  mutation AddStudent(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateMsgMstStudentInput!
  ) {
    AddStudent(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      input: $input
    ) {
      id
    }
  }
`;

export const UpdateStdResetPwdDate = gql`
  mutation UpdateStdResetPwdDate(
    $token: String!
    $inst_id: ID!
    $student_id: ID!
    $user_details: SubscribedUser!
  ) {
    UpdateStdResetPwdDate(
      token: $token
      inst_id: $inst_id
      student_id: $student_id
      user_details: $user_details
    )
  }
`;

export const UpdateStudentById = gql`
  mutation UpdateStudentById(
    $token: String!
    $id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $input: UpdateMsgMstStudentInput!
  ) {
    UpdateStudentById(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      id
      std_profile_filename
    }
  }
`;

export const DeleteStudents = gql`
  mutation DeleteStudents(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $student_ids: [ID!]!
  ) {
    DeleteStudents(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      student_ids: $student_ids
    )
  }
`;

export const UpdateStudentLoginStatus = gql`
  mutation UpdateStudentLoginStatus(
    $token: String!
    $student_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $login_status: Boolean!
  ) {
    UpdateStudentLoginStatus(
      token: $token
      student_id: $student_id
      login_status: $login_status
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
