import React from "react";
import Navbar from "../../../Layouts/Navbar";
import { licenseTypes } from "../../../../utils/constants";
import { Title } from "../../../../stories/Title/Title";
import Sidebar from "../../../Layouts/Sidebar";
import { InstDetailType } from "../../../../utils/Enum.types";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import Department from "./Department/Index";
import Branch from "./Branch/Index";
import Class from "./Class/Index";
import useInstLabels from "../../../../customhooks/useInstLabels";
import Semester from "./Semester/Index";
import Section from "./Section/Index";
interface Props {
  instType: InstDetailType;
}
const Index = ({ instType }: Props) => {
  const {
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_DEPARTMENT_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();

  const {
    branchLabel,
    classLabel,
    departmentLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  const renderComponent = () => {
    switch (instType) {
      case InstDetailType.DEPT:
        return USE_DEPARTMENT_KEY ? (
          <Department />
        ) : (
          <b className="nodata">{departmentLabel} not enabled</b>
        );
      case InstDetailType.BRANCH:
        return USE_BRANCH_KEY ? (
          <Branch />
        ) : (
          <b className="nodata">{branchLabel} not enabled</b>
        );
      case InstDetailType.CLASS:
        return USE_CLASS_KEY ? (
          <Class />
        ) : (
          <b className="nodata">{classLabel} not enabled</b>
        );
      case InstDetailType.SEMESTER:
        return USE_SEMESTER_KEY ? (
          <Semester />
        ) : (
          <b className="nodata">{semesterLabel} not enabled</b>
        );
      case InstDetailType.SECTION:
        return USE_SECTION_KEY ? (
          <Section />
        ) : (
          <b className="nodata">{sectionLabel} not enabled</b>
        );
      default:
        return <b className="nodata">Configurations not enabled</b>;
    }
  };
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="inst-config">
        <Sidebar />
        <div className="inst-config__frame">
          <Title>Master Configurations</Title>
          {renderComponent()}
        </div>
      </div>
    </>
  );
};

export default Index;
