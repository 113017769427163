import { gql } from "@apollo/client";

export const UpdateMstInstModuleDetails = gql`
  mutation UpdateMstInstModuleDetails(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateInstModuleDetails!
  ) {
    UpdateMstInstModuleDetails(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    )
  }
`;
