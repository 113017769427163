import React, { useEffect, useState } from "react";
import Close from "../../../../../images/Close.svg";
import Avatar from "../../../../../images/Avatar.svg";
import useStudentMembersCount, {
  GetShortStdListResult,
} from "../../CustomHooks/useStudentMembersCount";
import axios from "axios";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import { EMPTY_STRING } from "../../../../../utils/constants";

interface Props {
  isMembersListChecked: boolean;
}

interface studentUrls extends GetShortStdListResult {
  url: string;
}
const MemberCount = ({ isMembersListChecked }: Props) => {
  const { data } = useStudentMembersCount();
  const [downLoadUrl, setDownloadUrl] = useState<studentUrls[]>([]);

  const { InstFormData } = useInstDetails();
  const getDownloadUrl = (id: number, mediaContent: string) => {
    const downloadBaseUrl = `https://hnlm1fiypb.execute-api.ap-south-1.amazonaws.com/downloadObjectFromESandeshBucket?file_name=${mediaContent}&access_type=${process.env.React_App_access_type}`;
    axios
      .post(downloadBaseUrl, null, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded; charset=UTF-8;application/json",
        },
      })
      .then((response) => {
        if (response.data !== null)
          axios.get(response.data, { responseType: "blob" }).then(() => {
            setDownloadUrl((prevDownloadUrl) =>
              prevDownloadUrl.map((messageData) => {
                if (id === messageData.id) {
                  return { ...messageData, url: response.data };
                } else return messageData;
              })
            );
          });
      })
      .catch((err) => {
        setDownloadUrl((prevDownloadUrl) =>
          prevDownloadUrl.map((messageData) => {
            if (id === messageData.id) {
              return { ...messageData, url: EMPTY_STRING };
            } else return messageData;
          })
        );
      });
  };

  useEffect(() => {
    if (data) {
      data.GetShortStdList.forEach((res) => {
        getDownloadUrl(
          res.id,
          `${InstFormData.inst_name}/students/${res.id}/std_profile_pic/std_profile_pic`
        );
      });
    }
  }, [data, InstFormData.inst_name]);

  if (isMembersListChecked) {
    return (
      <>
        <div className="chat-channel__data-block--checked-total">
          <div>
            <label htmlFor="members-list" id="members-list-label">
              <img src={Close} alt="" /> &nbsp;
            </label>
            <span>Members List</span>
          </div>
          <span>
            {data && data.GetShortStdList.length
              ? data.GetShortStdList[0].total_count
              : 0}
          </span>
        </div>
      </>
    );
  } else {
    return (
      <div className="chat-channel__data-block--total">
        <div className="chat-channel__data-block--total--images">
          {/* {Array.from({ length: 4 }, (_value, index) => (
            <img key={index} src={Avatar} alt="" />
          ))} */}
          {data
            ? data.GetShortStdList.map((res, index) => {
                const foundPic = downLoadUrl.find(
                  (pic_res) => pic_res.id === res.id
                );

                const foundUrl = foundPic ? foundPic.url : Avatar;

                return <img key={index} src={foundUrl} alt="" />;
              })
            : null}
        </div>
        <label htmlFor="members-list" id="members-list-label">
          <span id="toggle">
            {data && data.GetShortStdList.length
              ? data.GetShortStdList[0].total_count
              : 0}{" "}
            Members
          </span>
        </label>
      </div>
    );
  }
};

export default MemberCount;
