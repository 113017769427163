import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import useToken from "./useToken";

import { GetInstConfigNames } from "../queries/configuration/query";

interface GetInstConfigNamesData {
  GetInstConfigNames: {
    entry_level: string;
    entry_id: number;
    dept_desc: string;
    dept_id: number;
    branch_desc: string;
    branch_id: number;
    class_desc: string;
    class_id: number;
    semester_desc: string;
    semester_id: number;
    section_desc: string;
    section_id: number;
  };
}
interface GetInstConfigNamesVars {
  token: string;
  entry_level: string;
  entry_id: string | number;
}

const useInstConfigByEntryId = (
  entry_id: string | number,
  entry_level: string
) => {
  const { token } = useToken();

  const [GetInstDetails, { data, loading, error }] = useLazyQuery<
    GetInstConfigNamesData,
    GetInstConfigNamesVars
  >(GetInstConfigNames, {
    variables: {
      token,
      entry_id,
      entry_level,
    },
  });
  useEffect(() => {
    if (token && entry_level) {
      GetInstDetails();
    }
  }, [token, entry_level, GetInstDetails, data]);

  return { InstConfigDetails: { data, loading, error } };
};

export default useInstConfigByEntryId;
