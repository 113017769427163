import { gql } from "@apollo/client";

export const GetSemesterNamesByClassIds = gql`
  query GetSemesterNamesByClassIds($class_id: [ID!]!, $token: String!) {
    GetSemesterNamesByClassIds(token: $token, class_id: $class_id) {
      id
      sem_desc
      class_id
    }
  }
`;
