import React from "react";
import Excel from "../../../images/Excel.svg";
import Success from "../../../images/PositiveConfirmation.svg";
import Error from "../../../images/MarksEntryNotCompleted.svg";
import Loading from "../../../images/Loading.gif";

interface ExcelLoadingProps {
  total: number;
  success: number;
  errors: number;
  loading: boolean;
}

const ExcelLoading: React.FC<ExcelLoadingProps> = ({
  total,
  success,
  errors,
  loading,
}) => {
  return (
    <>
      <div className="excel-loading">
        <div className="excel-loading__top">
          <div className="excel-loading__top--flex">
            <img src={Excel} alt="Excel" />
            <b>Importing</b>
          </div>
          <div className="excel-loading__top--details">
            <span className="excel-loading__top--text">Total files</span>
            <div className="excel-loading__top--total">{`${
              success + errors
            }/${total}`}</div>
          </div>
        </div>

        <div className="excel-loading__bottom">
          <div className="excel-loading__bottom--details">
            <div className="excel-loading__bottom--flex">
              <div className="excel-loading__bottom--flex--f">
                <span>{success}</span>
                <img src={Success} alt="Success" />
                <b>Success</b>
              </div>
              <div className="excel-loading__bottom--flex--f">
                <span>{errors}</span>
                <img src={Error} alt="Error" />
                <b>Error</b>
              </div>
            </div>
            {loading && (
              <div className="excel-loading__bottom--loading">
                <img src={Loading} alt="Loading" />
              </div>
            )}
          </div>
          <progress value={((success + errors) / total) * 100} max="100" />
        </div>
      </div>
    </>
  );
};

export default ExcelLoading;
