import { ReactNode, useState } from "react";

import Close from "../../../../images/Close.svg";
import { ModalCustomStyles } from "../../../styles/ModalStyles";
import InstBasicData from "./Registration/BasicDetails";
import { Operation, PageFor } from "../../../../utils/Enum.types";
import AcademicYearList from "../Institution/Registration/Academics/List";
import { Title } from "../../../../stories/Title/Title";
import { Button } from "../../../../stories/Button/Button";
import LicenseDetails from "./Registration/LicenseDetails";
import EduateGlobalConfig from "../Eduate/EduateGlobalConfig";
import ReactModal from "react-modal";
interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CustomModalProps {
  isOpen: boolean;
  contentComponent: ReactNode;
}
const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  contentComponent,
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={ModalCustomStyles}
    >
      {contentComponent}
    </ReactModal>
  );
};
const EditIndex = ({ setModal }: Props) => {
  const [instBasicDetails, setInstBasicDetails] = useState(false);
  const [editAcdYr, setEditAcdYr] = useState(false);
  const [editModuleConfig, setEditModuleConfig] = useState(false);
  const [editBasicConfig, setEditBasicConfig] = useState(false);
  return (
    <>
      <div className="inst-edit">
        <div className="inst-edit__title">
          <Title>What Do You like to Edit?</Title>
          <img src={Close} alt="/" onClick={() => setModal(false)} />
        </div>
      </div>
      <div className="inst-edit__menus">
        <Button onClick={() => setInstBasicDetails(!instBasicDetails)}>
          Basic Details
        </Button>
        <Button onClick={() => setEditAcdYr(!editAcdYr)}>Academic Year</Button>
        <Button onClick={() => setEditModuleConfig(!editModuleConfig)}>
          Module Configuration
        </Button>
        <Button onClick={() => setEditBasicConfig(!editBasicConfig)}>
          Basic Configuration
        </Button>
      </div>
      <Button mode="cancel" onClick={() => setModal(false)} />
      <CustomModal
        isOpen={instBasicDetails}
        contentComponent={
          <InstBasicData
            operation={Operation.UPDATE}
            onNext={() => {}}
            setModal={setInstBasicDetails}
          />
        }
      />
      <CustomModal
        isOpen={editAcdYr}
        contentComponent={
          <>
            {/* <AcdYr pageType={PageFor.MODAL} setModal={setEditAcdYr} /> */}
            <AcademicYearList
              handleEdit={() => {}}
              onlyTable={true}
              setModal={setEditAcdYr}
              pageType={PageFor.MODAL}
            />
          </>
        }
      />
      <CustomModal
        isOpen={editModuleConfig}
        contentComponent={
          <>
            <LicenseDetails
              onNext={() => {}}
              operation={Operation.UPDATE}
              setModal={setEditModuleConfig}
              onBack={() => {}}
            />
          </>
        }
      />
      <CustomModal
        isOpen={editBasicConfig}
        contentComponent={
          <>
            <EduateGlobalConfig
              pageType={PageFor.MODAL}
              setModal={setEditBasicConfig}
              operation={Operation.UPDATE}
            />
          </>
        }
      />
    </>
  );
};

export default EditIndex;
