import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { BasicDetailsProps } from "./BasicDetails";
import { Button } from "../../../../../stories/Button/Button";
import { Title } from "../../../../../stories/Title/Title";
import Compare from "../../../../../images/Compare.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  timeDifference,
  toInputStandardDate,
  toIsoDate,
  toStandardCase,
} from "../../../../../utils/UtilFunctions";
import { ModuleType } from "../../../../../utils/FormTypes";
import { UpdateMstInstModuleDetails } from "../../../../../queries/customerModules/query/mutations/update";
import { TableHeaderProps, msgType } from "../../../../../utils/Types";
import { Operation } from "../../../../../utils/Enum.types";
import { emptyMessageType } from "../../../../../utils/constants";
import MessageModal from "../../../../../utils/MessageModal";
import {
  UpdateMstInstModuleDetailsData,
  UpdateMstInstModuleDetailsVars,
} from "../../../../../utils/Query.Types";
import useInstModules, {
  InstModuleDetails,
  InstModuleQueryType,
} from "../../../../../customhooks/useInstModules";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { GetMstInstModules } from "../../../../../queries/customerModules/query";
import { InstModuleById } from "../../../../../queries/customerModules/query/byId";
import Close from "../../../../../images/Close.svg";
import { AntSwitch } from "../../../../../pages/Switch";

const { EduateModules } = require("../../json/table.json");
const LicenseDetails = ({
  operation,
  onNext,
  onBack,
  setModal,
}: BasicDetailsProps) => {
  const [formData, setFormData] = useState<ModuleType>({
    id: 0,
    lic_type: "",
    lic_active: false,
    lic_exp_grace_period: 0,
    lic_enabled_date: "",
    lic_start_date: "",
    lic_end_date: "",
  });

  const { token } = useToken();
  const { InstId } = useParams();
  const { state } = useContext(AppContext);

  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const [inst_module_details, setInstModuleDetails] = useState<
    InstModuleDetails[]
  >([]);
  const { InstModuleData } = useInstModules();

  const { user_details } = useLoggedInUserDetails();
  const [UpdateModule] = useMutation<
    UpdateMstInstModuleDetailsData,
    UpdateMstInstModuleDetailsVars
  >(UpdateMstInstModuleDetails, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  const handleClear = () => {
    setFormData({
      id: 0,
      lic_type: "",
      lic_active: false,
      lic_exp_grace_period: 0,
      lic_enabled_date: "",
      lic_start_date: "",
      lic_end_date: "",
    });
  };

  const handleClose = () => {
    if (message.operation !== Operation.NONE && message.flag) {
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const handleSwitchToggle = (index: number) => {
    setInstModuleDetails((prevState) => {
      const updatedModuleDetails = prevState.map((module, idx) => {
        if (idx === index) {
          return {
            ...module,
            is_module_enabled: !module.is_module_enabled,
          };
        }
        return module;
      });
      return updatedModuleDetails;
    });
  };

  useEffect(() => {
    if (InstModuleData.data && !InstModuleData.loading) {
      const {
        id,
        lic_type,
        lic_active,
        lic_exp_grace_period,
        lic_enabled_date,
        lic_start_date,
        lic_end_date,
        inst_module_details,
      } = InstModuleData.data.GetMstInstModules;
      setFormData({
        lic_active,
        lic_enabled_date: toInputStandardDate(lic_enabled_date),
        lic_end_date: toInputStandardDate(lic_end_date),
        lic_exp_grace_period,
        lic_start_date: toInputStandardDate(lic_start_date),
        lic_type,
        id,
      });
      setInstModuleDetails(inst_module_details);
    }
  }, [InstModuleData.data, InstModuleData.loading]);
  const HandleSubmit = () => {
    UpdateModule({
      variables: {
        token,
        inst_id: InstId! || state.InstId.toString(),
        user_details,
        input: {
          module_master_id: formData.id,

          update_module_master: {
            lic_type: formData.lic_type,
            lic_active: true,
            lic_exp_grace_period: formData.lic_exp_grace_period,
            lic_enabled_date: toIsoDate(Date.now().toString()),
            lic_start_date: toIsoDate(formData.lic_start_date),
            lic_end_date: toIsoDate(formData.lic_end_date),
          },
          module_details: inst_module_details.map((module) => ({
            is_module_enabled: module.is_module_enabled,
            eduate_module_id: module.eduate_module_details.id,
          })),
        },
      },
      refetchQueries: [
        {
          query: GetMstInstModules,
          variables: {
            token,
            inst_id: InstId || state.InstId,
            query_type: InstModuleQueryType.ALL_INST_MODULES,
          },
        },
        {
          query: InstModuleById,
          variables: {
            id: formData.id,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateMstInstModuleDetails) {
        onNext();
      }
    });
  };

  return (
    <>
      <div
        className={
          operation === Operation.UPDATE
            ? "inst-details__update"
            : "inst-details"
        }
      >
        <div className="inst-details__form--title">
          <Title variant={operation === Operation.UPDATE ? "h6" : "subtitle1"}>
            License Details
          </Title>
          {operation === Operation.UPDATE && (
            <img src={Close} alt="/" onClick={() => setModal(false)} />
          )}
        </div>

        <div className=" row g-0 justify-content-center">
          <div className="col-4">
            <div className="inst-details__license">
              <div className="inst-details__license--flex">
                <TextField
                  label="Start Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  className="inst-details__license--s-date"
                  value={toInputStandardDate(formData.lic_start_date)}
                  onChange={(e) => {
                    setFormData((prevValues) => ({
                      ...prevValues,
                      lic_start_date: e.target.value,
                    }));
                  }}
                />
                <img src={Compare} alt="" />
                <TextField
                  label="End Date"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  className="inst-details__license--e-date"
                  value={toInputStandardDate(formData.lic_end_date)}
                  onChange={(e) => {
                    setFormData((prevValues) => ({
                      ...prevValues,
                      lic_end_date: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="inst-details__license--flex">
                <TextField
                  label="Grace Period"
                  InputLabelProps={{ shrink: true }}
                  className="inst-details__license--g-period"
                  type="number"
                  onChange={(e) => {
                    setFormData((prevValues) => ({
                      ...prevValues,
                      lic_exp_grace_period: Number(e.target.value),
                    }));
                  }}
                  value={formData.lic_exp_grace_period}
                />
              </div>
              <div className="inst-details__license--total">
                {formData.lic_start_date !== "" &&
                formData.lic_end_date !== "" ? (
                  <>
                    <b>
                      {timeDifference(
                        formData.lic_start_date,
                        formData.lic_end_date
                      )}
                    </b>
                    &nbsp;
                    <span>Days left to Expire</span>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="inst-details__license--tableblock">
          <TableContainer className="inst-details__license--table">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {EduateModules.EduateModuleConfig.Table_Headers.map(
                    (th: TableHeaderProps, index: React.Key) => {
                      return (
                        <TableCell key={index} className={th.className}>
                          {th.headerName}
                        </TableCell>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {inst_module_details.map((response, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className="inst-details__license--table--slno"
                        id="td-center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {toStandardCase(response.eduate_module_details.Name)}
                      </TableCell>
                      <TableCell className="inst-details__license--table--actions">
                        {formData.lic_type}{" "}
                      </TableCell>

                      <TableCell
                        id="td-center"
                        className="inst-details__license--table--actions"
                      >
                        <AntSwitch
                          checked={response.is_module_enabled}
                          onClick={() => handleSwitchToggle(index)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Button
          mode={operation === Operation.CREATE ? "save-continue" : "save"}
          onClick={HandleSubmit}
        />
        <Button mode="previous" onClick={onBack} />
        <Button mode="cancel" onClick={() => setModal(false)} />
        <MessageModal
          handleClose={handleClose}
          modalFlag={message.flag}
          operation={message.operation}
          value={message.message}
        />
      </div>
    </>
  );
};

export default LicenseDetails;
