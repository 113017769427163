import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { GetServerDateAndTimeWithoutToken } from "../queries/byTime";
import { useParams } from "react-router-dom";
interface ServerDateTimeData {
  GetServerDateAndTimeWithoutToken: string;
}
interface ServerDateTimeVars {
  inst_id: string;
}

const useServerDateandTimeWithoutToken = () => {
  const [serverDate, setServerDate] = useState("");
  const { instId } = useParams();
  const [GetDate, { data, loading }] = useLazyQuery<
    ServerDateTimeData,
    ServerDateTimeVars
  >(GetServerDateAndTimeWithoutToken, {
    variables: {
      inst_id: instId!,
    },
  });
  useEffect(() => {
    GetDate().then(({ data }) => {
      if (data && data.GetServerDateAndTimeWithoutToken) {
        setServerDate(data.GetServerDateAndTimeWithoutToken);
      }
    });
  }, [data, GetDate, instId]);
  return {
    serverDate,
    data,
    loading,
  };
};
export default useServerDateandTimeWithoutToken;
