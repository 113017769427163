import { Label } from "../../../stories/Label/Label";
import { Input } from "../../../stories/Input/Input";
import { useField } from "formik";
import { handleFormEvent } from "../../../utils/UtilFunctions";

const Index = ({ LabelName, values, className, variant, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="label-grid">
        <Label variant={variant}>{LabelName}</Label>
        <Input
          {...field}
          {...props}
          value={values}
          error={!props.disabled && meta.error}
          className={className}
          onKeyDown={(e) => {
            if (props.onKeyDown) {
              props.onKeyDown(e);
            } else {
              handleFormEvent(e);
            }
          }}
        />
      </div>
    </>
  );
};

export default Index;
