import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { UserType } from "../utils/Enum.types";
import useToken from "./useToken";
import { useParams } from "react-router-dom";
import useInstitutionConfiguration from "./useInstitutionConfiguration";
import { GetChannelSubscribed } from "../queries/chat/queries";

interface GetChannelSubscribedDetails {
  id: number;
  channel_name: string;
  channel_desc: string;
  channel_topic: string;
  channel_is_student: boolean;
  channel_chat_enabled: boolean;
  channel_type: string;
  channel_active: boolean;
  channel_icon_id: number;
}
interface GetChannelSubscribedData {
  GetChannelSubscribed: GetChannelSubscribedDetails[];
}
interface GetChannelSubscribedVars {
  token: string;
  input: { user_type: string; id: number };
  inst_id: number | string;
}

const useChannelDetails = () => {
  const { state } = useContext(AppContext);
  const [user_type, setUserType] = useState("");
  const { token } = useToken();
  const { instId } = useParams();
  const { entry_level } = useInstitutionConfiguration();

  const [id, setId] = useState(0);
  const [ChannelListids, { data, loading, error }] = useLazyQuery<
    GetChannelSubscribedData,
    GetChannelSubscribedVars
  >(GetChannelSubscribed, {
    variables: {
      token,
      input: {
        user_type,
        id,
      },
      inst_id: instId!,
    },
  });

  useEffect(() => {
    if (state.claims!.STUDENT) {
      setUserType(UserType.STUDENT);
      setId(state.studentId);
    } else if (state.claims?.EMPLOYEE) {
      setUserType(UserType.EMPLOYEE);
      setId(state.empLoginId);
    }
  }, [state]);

  useEffect(() => {
    if (user_type && id) {
      ChannelListids();
    }
  }, [user_type, ChannelListids, id, token, entry_level]);
  return {
    channelDetails: {
      data,
      loading,
      error,
    },
  };
};

export default useChannelDetails;
