import { gql } from "@apollo/client";

export const AddInstAcdYr = gql`
  mutation AddInstAcdYr(
    $input: CreateMsgMstInstAcdYrInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstAcdYr(token: $token, input: $input, user_details: $user_details) {
      inst_id
      acd_yr
      acd_st_date
      acd_end_date
    }
  }
`;
