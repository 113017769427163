import { GridAlignment } from "@mui/x-data-grid";
import useMasterTableJson from "../../../json/useTableJson";
import { GridColDef } from "@mui/x-data-grid";
import { GridValidRowModel } from "@mui/x-data-grid";
import Edit from "../../../../../../images/EditProfile.svg";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  useDataGridStyles,
} from "../../../../../styles/DatagridStyles";
import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import useAcademicYears from "../../../../../../customhooks/useAcademicYears";
import { toStandardDate } from "../../../../../../utils/UtilFunctions";
import { TableHeaderProps } from "../../../../../../utils/Types";
import { Title } from "../../../../../../stories/Title/Title";
import Close from "../../../../../../images/Close.svg";
import { Button } from "../../../../../../stories/Button/Button";
import { PageFor } from "../../../../../../utils/Enum.types";
interface Props {
  handleEdit: (id: number) => void;
  onlyTable: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  pageType: PageFor;
}
const List = ({ handleEdit, onlyTable, setModal, pageType }: Props) => {
  const { Institutions } = useMasterTableJson();
  const classes = useDataGridStyles();
  const {
    academicYearResponse: { data },
  } = useAcademicYears();

  const dynamicHeaders: TableHeaderProps[] = Institutions.Academics.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "cust_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <img
              src={Edit}
              alt=""
              onClick={() => handleEdit(Number(params.row.acd_id))}
            />
          </>
        );
      },
    },
  ];
  const rows: GridValidRowModel[] = data
    ? data.GetAcdYrsByInstId.map((res, index) => ({
        id: index + 1,
        acd_id: res.id,
        acd_year: res.acd_yr,
        acd_s_date: toStandardDate(res.acd_st_date),
        acd_e_date: toStandardDate(res.acd_end_date),
        active_yr: res.acd_is_curr_yr,
      }))
    : [];

  return (
    <>
      {pageType === PageFor.MODAL && (
        <div className="inst-edit__title">
          <Title>Academic Year</Title>
          <img src={Close} alt="" onClick={() => setModal(false)} />
        </div>
      )}

      <div className={`inst-details__academics--list ${classes.root} `}>
        <DataGridPro
          columns={columns.filter(
            (inst) =>
              (inst.field !== "cust_actions" && onlyTable) ||
              onlyTable === false
          )}
          rows={rows}
          disableRowSelectionOnClick
          getRowClassName={(params) => {
            return `${params.row.active_yr ? "row-green" : ""}`;
          }}
        />
      </div>
      {pageType === PageFor.MODAL && (
        <Button mode="cancel" onClick={() => setModal(false)} />
      )}
    </>
  );
};

export default List;
