import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/context";

import useToken from "./useToken";

import useInstitutionConfiguration from "./useInstitutionConfiguration";
import { IObjectKeys } from "../utils/FormTypes";
import { GetStudentDetails } from "../queries/students/list";
import { AcademicYear } from "../utils/Types";
import {
  BranchesList,
  ClassList,
  DepartmentList,
  SectionList,
  SemesterList,
} from "../components/Modules/Masters/Student/Types";
import useInstDetails from "./useInstDetails";

export interface parentData extends IObjectKeys {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  parent_address: string;
  parent_place: string;
  parent_state: string;
  parent_pin: string;
  parent_email: string;
  parent_mobile: string;
  parent_occup: string;
  parent_qualification: string;
  parent_aadhar: string;
  parent_type: string;
}
export interface Parents {
  relationship: string;
  parent: parentData;
}

export interface StudentDetails {
  id: number;
  std_name: string;
  std_reg_no: string;
  std_adm_no: string;
  acd_yr: AcademicYear;
  parent_name: string;
  std_studying: boolean;
  std_sex: string;
  std_doa: string;
  std_fresher: boolean;
  std_mobile: string;
  std_status: string;
  std_email: string;

  std_dob: string;
  std_profile_filename: string;
  class: ClassList;
  branch: BranchesList;
  dept: DepartmentList;
  semester: SemesterList;

  section: SectionList;
  std_sts_no?: string;
}
export interface StdPassedOutDetails {
  branch_details: BranchesList;
  class_details: ClassList;
  branch_id: number;
  class_id: number;
  std_name: string;
  std_passout_yr: string;
  std_mobile: string;
  std_email: string;
  inst_id: string | number;
}
export interface StudentData {
  nodes: StudentDetails[];
}
export interface StudentDetailsvars {
  token: string;
  ids: number[] | number;
}
export interface StudentPaasedOutData {
  node: StdPassedOutDetails;
}
export interface StudentPassedOutVars {
  token: string;
  id: number;
}
export interface Istudentform extends IObjectKeys {
  std_name: string;
  reg_number: string;
  branch: string;
  parent_name: string;
  class: string;
  dept: string;
  semester: string;
  section: string;
  std_adm_no: string;
  acd_yr: string;
  std_dob: string;
  std_doa: string;
  std_mobile: string;
  std_profile_filename: string;
  fresher: boolean;
  std_email: string;
}

export interface IstudentPassedOutform extends IObjectKeys {
  std_name: string;
  branch: string;
  class: string;
}

const useStudentDatabyId = () => {
  const { state } = useContext(AppContext);
  const { token } = useToken();
  const { studentId } = useParams();

  const { InstDetails } = useInstDetails();

  const { USE_SECTION_KEY, USE_SEMESTER_KEY, USE_CLASS_KEY } =
    useInstitutionConfiguration();

  const [studentEntryId, setStudentEntryId] = useState(0);

  const [studentFormData, setStudentFormData] = useState<Istudentform>({
    branch: "",
    class: "",
    dept: "",
    parent_name: "",
    reg_number: "",
    section: "",
    semester: "",
    std_name: "",
    acd_yr: "",
    std_dob: "",
    std_sex: "",
    std_mobile: "",
    std_adm_no: "",
    std_doa: "",
    std_profile_filename: "",
    std_status: "",
    fresher: false,
    std_email: "",
  });

  const [GetStudentDetailsByIds, GetStudentDetailsByIdsQuery] = useLazyQuery<
    StudentData,
    StudentDetailsvars
  >(GetStudentDetails, {
    //Shiva: in Student Reservation when we try to edit and click cancel the formData will get cleared when we click on edit again the formData won't appear because the api wont be called again since the id is same while calling the api.
    // fetchPolicy: "network-only",
  });

  const handleClear = () => {
    setStudentFormData({
      branch: "",
      category: "",
      class: "",
      dept: "",
      reg_number: "",
      section: "",
      semester: "",
      std_name: "",
      acd_yr: "",
      std_dob: "",
      std_mobile: "",
      std_adm_no: "",
      std_doa: "",
      std_profile_filename: "",
      std_status: "",
      fresher: false,
      parent_name: "",
      std_email: "",
    });
  };
  useEffect(
    () => {
      if (!state.studentId || !studentId) {
        handleClear();
      }
    },
    // eslint-disable-next-line

    [state.studentId, studentId]
  );
  useEffect(() => {
    if ((state.studentId || studentId) && token) {
      GetStudentDetailsByIds({
        variables: {
          token,
          ids: [state.studentId ? state.studentId : Number(studentId)],
        },
      }).then(({ data }) => {
        if (data && data.nodes) {
          var studentProfilePic = "";

          if (InstDetails.data) {
            studentProfilePic = `${
              InstDetails.data?.nodes[0]?.inst_name
            }/students/${
              state.studentId ? state.studentId : studentId
            }/std_profile_pic/std_profile_pic`;
          }

          const {
            branch,
            class: c,
            dept,
            std_name,
            section,
            std_adm_no,
            semester,
            std_reg_no,
            parent_name,
            acd_yr,
            std_dob,
            std_doa,
            std_mobile,
            std_status,
            std_fresher,
            std_email,
          } = data.nodes[0];
          setStudentFormData({
            branch: branch.branch_desc,
            class: c.class_desc,
            dept: dept.dept_desc,
            reg_number: std_reg_no,
            section: section.section_desc,
            semester: semester.sem_desc,
            std_name,
            std_adm_no,
            acd_yr: acd_yr.acd_yr,
            std_dob: std_dob,
            std_doa: std_doa,
            std_mobile: std_mobile,
            std_profile_filename: studentProfilePic,
            std_status: std_status,
            fresher: std_fresher,
            parent_name,
            std_email,
          });

          setStudentEntryId(
            USE_SECTION_KEY
              ? section.id
              : USE_SEMESTER_KEY
              ? semester.id
              : USE_CLASS_KEY
              ? c.id
              : 0
          );
        }
      });
    }
  }, [
    state.studentId,
    studentId,
    GetStudentDetailsByIds,
    token,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
    GetStudentDetailsByIdsQuery.data,
    InstDetails.data,
  ]);

  return {
    studentData: GetStudentDetailsByIdsQuery,
    studentFormData,
    studentEntryId,
  };
};

export default useStudentDatabyId;
