import { gql } from "@apollo/client";
export const AddMsgEmpMaster = gql`
  mutation AddMsgEmpMaster(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: CreateMsgEmpMasterInput!
  ) {
    AddMsgEmpMaster(
      token: $token
      inst_id: $inst_id
      input: $input
      user_details: $user_details
    ) {
      id
    }
  }
`;
export const UpdateMsgEmpMaster = gql`
  mutation UpdateMsgEmpMaster(
    $token: String!
    $id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $input: UpdateMsgEmpMasterInput!
  ) {
    UpdateMsgEmpMaster(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    ) {
      id
    }
  }
`;
export const ResetEmpPassword = gql`
  mutation ResetEmpPassword(
    $token: String!
    $msg_emp_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $password: String!
  ) {
    ResetEmpPassword(
      token: $token
      msg_emp_id: $msg_emp_id
      inst_id: $inst_id
      user_details: $user_details
      password: $password
    )
  }
`;
export const UpdateEmpResetPwdDate = gql`
  mutation UpdateEmpResetPwdDate(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
  ) {
    UpdateEmpResetPwdDate(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
    )
  }
`;

export const EnableEmpLoginStatusWithNewEmail = gql`
  mutation EnableEmpLoginStatusWithNewEmail(
    $token: String!
    $pr_emp_id: ID!
    $login_data: EmpLoginData!
    $user_details: SubscribedUser!
    $inst_id: ID!
  ) {
    EnableEmpLoginStatusWithNewEmail(
      token: $token
      pr_emp_id: $pr_emp_id
      login_data: $login_data
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
export const UpdateEmpLoginStatus = gql`
  mutation UpdateEmpLoginStatus(
    $token: String!
    $msg_emp_id: ID!
    $user_details: SubscribedUser!
    $inst_id: ID!
    $login_status: Boolean!
  ) {
    UpdateEmpLoginStatus(
      token: $token
      msg_emp_id: $msg_emp_id
      login_status: $login_status
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const AddUserRights = gql`
  mutation AddUserRights(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $is_inst_sysadmin: Boolean!
    $input: [CreateUserRightsDetailsInput!]!
  ) {
    AddUserRights(
      token: $token
      inst_id: $inst_id
      user_details: $user_details
      pr_emp_id: $pr_emp_id
      input: $input
      is_inst_sysadmin: $is_inst_sysadmin
    )
  }
`;

export const AddInstUserAccess = gql`
  mutation AddInstUserAccess(
    $token: String!
    $inst_ids: [ID!]!
    $pr_emp_id: ID!
    $user_details: SubscribedUser!
  ) {
    AddInstUserAccess(
      token: $token
      inst_ids: $inst_ids
      pr_emp_id: $pr_emp_id
      user_details: $user_details
    )
  }
`;

export const UpdateUserRightsTheme = gql`
  mutation UpdateUserRightsTheme(
    $token: String!
    $inst_id: ID!
    $user_details: SubscribedUser!
    $pr_emp_id: ID!
    $theme: String!
  ) {
    UpdateUserRightsTheme(
      token: $token
      inst_id: $inst_id
      user_details: $user_details

      pr_emp_id: $pr_emp_id
      theme: $theme
    )
  }
`;
