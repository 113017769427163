// import { handleSelectEvent } from "../../utils/UtilFunctions";
import "./select.scss";

export type Props = {
  // Component label.
  label?: string;
  autoFocus?: boolean;
  // Is checkbox disabled?
  disabled?: boolean;

  // Description beneath the select.
  description?: string;

  // Placeholder text for the form control. Set to a blank string to create a non-floating placeholder label.
  placeholder?: string;

  // Makes the Select have a visual box.
  box?: boolean;
  name?: string;
  // One or more <option> or <optgroup> elements.
  children?: any;

  // Props for the root element. By default, additional props spread to the native select element.
  rootProps?: Object;
  defaultValue?: string;
  // A className for the root element.
  className?: string;
  size?: string;
  onChange?: any;
  onKeyDown?: any;
  required?: any;
  value?: any;
  kindOfCustomeRef?: React.LegacyRef<HTMLSelectElement>;
};

export const Select = ({
  disabled,
  children,
  onChange,
  name,
  size,
  autoFocus,
  required,
  defaultValue,
  value,
  onKeyDown,
  className,
  kindOfCustomeRef,
}: Props) => {
  return (
    <select
      disabled={disabled}
      onChange={onChange}
      name={name}
      className={`select  ${size}  ${required ? "required_error" : ""} `}
      required={required}
      defaultValue={defaultValue}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      value={value}
      ref={kindOfCustomeRef}
    >
      {children}
    </select>
  );
};
