import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../../../../images/EduateLogo.svg";
import ArrowLeft from "../../../../images/DownArrow.svg";
import { Title } from "../../../../stories/Title/Title";
import TotalFee from "../../../../images/Total_Fee.svg";
import Paid from "../../../../images/TotalPaid.svg";
import FeeBalance from "../../../../images/Fee_Balance.svg";
import { Label } from "../../../../stories/Label/Label";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import PaymentProcess from "../../../../images/Payment_Process.svg";
import loading from "../../../../images/Loading.gif";
import Success from "../../../../images/Successfull_Transaction_Gif.gif";
import Failed from "../../../../images/Unsuccessfull_Transaction_Gif.gif";
import { TextField } from "@mui/material";
import useInstDetailsWithoutToken from "../../../../customhooks/useInstDetailsWithoutToken";
import useMstInstLogoWithoutToken from "../../../../customhooks/useMstInstLogoWithoutToken";
import useStdDetailsWithoutTokenByDemanId from "../../../../customhooks/useStdDetailsWithoutTokenByDemanId";
import { useMutation } from "@apollo/client";
import { AcctMakeIciciOnlinePayment } from "../../../../queries/payments/mutations/new";
import { toIsoDate } from "../../../../utils/UtilFunctions";
import { EMPTY_STRING, emptyMessageType } from "../../../../utils/constants";
import { Operation, Result } from "../../../../utils/Enum.types";
import useServerDateandTimeWithoutToken from "../../../../customhooks/useServerDateandTime";
import MessageModal from "../../../../utils/MessageModal";
import { useTransactionDetailsById } from "../../../../customhooks/useTransactionDetailsById";

export interface AcctMakeIciciOnlinePaymentData {
  AcctMakeIciciOnlinePayment: {
    encrypted_mandatory_fields: string;
    encrypted_optional_fields: string;
    encrypted_return_url: string;
    encrypted_sub_merchant_id: string;
    encrypted_transaction_amount: string;
    encrypted_payment_mode: string;
    encrypted_reference_no: string;
  };
}
export interface AcctMakeIciciOnlinePaymentVars {
  inst_id: string;
  acct_std_demand_id: string;
  fee_paid: string;
  payment_date: string;
  payment_mode: string;
}

interface Props {
  PaymentResult: Result;
}
const Payment = ({ PaymentResult }: Props) => {
  const { InstFormData } = useInstDetailsWithoutToken();
  const { InstLogo } = useMstInstLogoWithoutToken();
  const { instId, stdDemandId, transactionId } = useParams();
  const [proceeding, setProceeding] = useState(false);
  const [printReceipt, setPrintReceipt] = useState(false);
  const [message, setMessage] = useState(emptyMessageType);

  const { serverDate } = useServerDateandTimeWithoutToken();

  const [amount, setAmount] = useState("");
  const { studentFormData } = useStdDetailsWithoutTokenByDemanId();
  const { data } = useTransactionDetailsById();

  const [MakePayment] = useMutation<
    AcctMakeIciciOnlinePaymentData,
    AcctMakeIciciOnlinePaymentVars
  >(AcctMakeIciciOnlinePayment);

  const handlePayment = () => {
    if (serverDate === EMPTY_STRING) {
      setMessage({
        flag: true,
        message: "Date does not exist",
        operation: Operation.NONE,
      });
      return;
    }
    if (serverDate)
      MakePayment({
        variables: {
          acct_std_demand_id: stdDemandId!,
          fee_paid: amount,
          inst_id: instId!,
          payment_date: toIsoDate(serverDate),
          payment_mode: "9",
        },
      }).then(({ data }) => {
        if (data && data.AcctMakeIciciOnlinePayment) {
          const {
            encrypted_mandatory_fields,
            encrypted_payment_mode,
            encrypted_reference_no,
            encrypted_return_url,
            encrypted_sub_merchant_id,
            encrypted_transaction_amount,
          } = data.AcctMakeIciciOnlinePayment;

          if (
            encrypted_mandatory_fields &&
            encrypted_payment_mode &&
            encrypted_reference_no &&
            encrypted_return_url &&
            encrypted_sub_merchant_id &&
            encrypted_transaction_amount
          ) {
            setProceeding(!proceeding);
            window.location.href = `https://eazypayuat.icicibank.com/EazyPG?merchantid=600343&mandatory%20fields=${encrypted_mandatory_fields}&optional%20fields=&returnurl=${encrypted_return_url}&Reference%20No=${encrypted_reference_no}&submerchantid=${encrypted_sub_merchant_id}&transaction%20amount=${encrypted_transaction_amount}&paymode=${encrypted_payment_mode}`;
          }
        }
      });
  };

  useEffect(() => {
    if (studentFormData.fee_balance) {
      setAmount(studentFormData.fee_balance.toString());
    }
  }, [studentFormData]);
  return (
    <>
   
      <div className="std-fee-details">
        <div className="std-fee-details__block">
          <div className="std-fee-details__block--header">
            <div className="std-fee-details__block--header--l">
              {InstLogo.defaultLogo ? (
                <img src={InstLogo.defaultLogo} alt="/" />
              ) : null}{" "}
              <div className="std-fee-details__block--header--inst-details">
                <span>{InstFormData.cust_name}</span>
                <b>{InstFormData.inst_name}</b>
                <span>
                  {`${InstFormData.inst_address} ${InstFormData.inst_place} ${InstFormData.inst_state} ${InstFormData.inst_pin}`}
                </span>
              </div>
            </div>
            <div className="std-fee-details__block--header--r">
              <span>SimpliFied By</span>
              <img src={Logo} alt="" />
              <b>My-Eduate</b>
            </div>
          </div>
          <div className="std-fee-details__block--data">
            <a
              href={
                PaymentResult === Result.SUCCESS
                  ? `/${instId}/chat/fee/fetchdetails/paymentprocess`
                  : PaymentResult === Result.FAILURE
                  ? `/${instId}/chat/fee/fetchdetails/paymentprocess`
                  : `/${instId}/chat/fee/fetchdetails`
              }
            >
              <img src={ArrowLeft} alt="" />
              Go Back
            </a>
            {PaymentResult === Result.SUCCESS ? (
              <>
                <div className="std-fee-details__success">
                  <div className="std-fee-details__success--data">
                    <div className="std-fee-details__success--gif">
                      <img src={Success} alt="" />
                    </div>
                    <b className="font-green">
                      {data && transactionId ? data.node.total_amount : 0}
                    </b>
                    <span>Transaction Success</span>
                  </div>

                  <Button
                    mode="print"
                    onClick={() => setPrintReceipt(!printReceipt)}
                  >
                    &nbsp;Receipt
                  </Button>
                  <Button mode="back" />
                </div>
              </>
            ) : PaymentResult === Result.FAILURE ? (
              <>
                <div className="std-fee-details__success">
                  <div className="std-fee-details__success--data">
                    <div className="std-fee-details__success--gif">
                      <img src={Failed} alt="" />
                    </div>
                    <b className="font-red">
                      {" "}
                      {data && transactionId ? data.node.total_amount : 0}
                    </b>
                    <span>Transaction Failed</span>
                  </div>
                  <Button mode="back" />
                </div>
              </>
            ) : (
              <>
                <div className="std-fee-details__std-details">
                  <div className="student-preview__details row g-0">
                    <div className="col">
                      <TextField
                        className="student-preview__details--textfield"
                        label="Student ID"
                        InputLabelProps={{ shrink: true }}
                        value={studentFormData.std_id}
                        disabled
                      />
                      <TextField
                        className="student-preview__details--textfield"
                        label="Roll Number"
                        InputLabelProps={{ shrink: true }}
                        value={studentFormData.std_roll_no}
                        disabled
                      />
                    </div>
                    <div className="col">
                      <TextField
                        className="student-preview__details--textfield"
                        label="Name"
                        InputLabelProps={{ shrink: true }}
                        value={studentFormData.std_name}
                        disabled
                      />
                      <TextField
                        className="student-preview__details--textfield"
                        label="Father Name"
                        InputLabelProps={{ shrink: true }}
                        value={studentFormData.std_father_name}
                        disabled
                      />
                    </div>
                    <div className="col">
                      <TextField
                        className="student-preview__details--textfield"
                        label="Class Description"
                        InputLabelProps={{ shrink: true }}
                        value={studentFormData.class_desc}
                        disabled
                      />
                      <TextField
                        className="student-preview__details--textfield"
                        label="Mother Name"
                        InputLabelProps={{ shrink: true }}
                        value={studentFormData.std_mother_name}
                        disabled
                      />
                    </div>
                  </div>
                  <Title variant="subtitle1">Payment Process</Title>
                  <div className="std-fee-details__std-details--cards">
                    <div className="std-fee-details__std-details--card">
                      <div>
                        <img src={TotalFee} alt="" />
                        <span>Total Fee</span>
                      </div>
                      <b className="font-blue">
                        {studentFormData.fee_total_demand}
                      </b>
                    </div>
                    <div className="std-fee-details__std-details--card">
                      <div>
                        <img src={Paid} alt="" />
                        <span>Total Paid</span>
                      </div>
                      <b className="font-green">
                        {studentFormData.fee_total_paid}
                      </b>
                    </div>
                    <div className="std-fee-details__std-details--card">
                      <div>
                        <img src={FeeBalance} alt="" />
                        <span>Total Balance</span>
                      </div>
                      <b className="font-red">{studentFormData.fee_balance}</b>
                    </div>
                  </div>
                  {!proceeding ? (
                    <div className="std-fee-details__std-details--frame">
                      <Label>Enter Amount to Pay</Label>
                      <Input
                        value={amount}
                        onChange={(e) => {
                          if (Number(e.target.value) >= 0) {
                            setAmount(e.target.value);
                          }
                        }}
                      />
                      <Button mode="proceed-to-pay" onClick={handlePayment} />
                    </div>
                  ) : (
                    <>
                      <div className="std-fee-details__std-details--proceed">
                        <Title variant="subtitle1">
                          Proceeding for Payment
                        </Title>
                        <Label>Total Amount Paying is</Label>
                        <b>{amount}</b>
                        <img src={PaymentProcess} alt="" />
                        <br />
                        <img
                          src={loading}
                          alt="loading"
                          className="loading-gif"
                        />{" "}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <ReactModal isOpen={!printReceipt} style={StudentPreviewModalStyle} ariaHideApp={false}>
        <FeeReceipt/>
      </ReactModal> */}
      <MessageModal
        handleClose={() => setMessage(emptyMessageType)}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default Payment;
