import * as Yup from "yup";
export const TelNos_Regex = /^[^-\s][\d\s,/,-]*$/;
export const noSpaceAndNoFirst_Number_Regex = /^[^-\s0-9][a-zA-Z0-9_\s-]*$/;
export const Number_Regex = /^\d+$/;
export const OnlyAlphabets =
  /^[^-\s0-9~@#$^*()_+-=/[\]{}|\\,.?: -][a-zA-Z\s]*$/;
export const Url_Regex =
  /(^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w[a-zA-Z-_%@?]+)*([^\w[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$|^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$)/;
export const AllowSpecial_Regex =
  /^[^-\s][a-zA-Z0-9\s~@#$^*()_+=/[\]{}|\\,.?: -]*$/;

export const noSpace_Regex_includes_dot =
  /^[^-\s][a-zA-Z0-9_.~@#$^*()_+'/&=\s-]*$/;

export const customer_validation = Yup.object({
  cust_name: Yup.string()
    .required("Name is required")
    .max(100, "Only 100 characters allowed"),
  cust_phone: Yup.string().matches(TelNos_Regex),
  cust_contact_person: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  cust_mobile: Yup.string().min(10, "Invalid Mobile").matches(Number_Regex),
  cust_email: Yup.string().email(),
  cust_address: Yup.string().max(200),
  cust_url: Yup.string().matches(Url_Regex),
  cust_pin: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
});

export const inst_validation = Yup.object({
  inst_phone: Yup.string().matches(TelNos_Regex),
  inst_name: Yup.string()
    .required("Name is required")
    .max(100, "Only 100 characters allowed"),
  inst_contact_person: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  inst_mobile: Yup.string().min(10, "Invalid Mobile").matches(Number_Regex),
  inst_short_name: Yup.string()
    .max(10, "Only 10 characters allowed")
    .matches(noSpaceAndNoFirst_Number_Regex),
  inst_email: Yup.string().email(),
  inst_address: Yup.string().max(200),
  inst_place: Yup.string().matches(noSpaceAndNoFirst_Number_Regex),
  inst_state: Yup.string(),
  inst_url: Yup.string().matches(Url_Regex),

  inst_pin: Yup.string().max(6, "Invalid pin").matches(Number_Regex),
});

export const academic_validation = Yup.object({
  AcdYear: Yup.string().matches(AllowSpecial_Regex),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date can't be before start date"),
});

export const departmentValidation = Yup.object({
  dept_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
});

export const branchValidation = Yup.object({
  branch_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
});

export const classValidation = Yup.object({
  class_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
});

export const semValidation = Yup.object({
  sem_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
});
export const sectionValidation = Yup.object({
  section_desc: Yup.string()
    .required("Description is required")
    .matches(noSpace_Regex_includes_dot),
});
export const _genderRegex = { mask: /^(male|female)$/i };
export const _isPhone = {
  mask: /^[0-9]{10}$/,
};

export const loginUserValidation = Yup.object({
  emp_name: Yup.string()
    .required("Firstname is required")
    .matches(OnlyAlphabets),
  emp_mobile: Yup.string()
    .min(10, "Invalid mobile")
    .matches(Number_Regex)
    .required("Mobile number is required"),
  emp_email: Yup.string().email().required("Email is required"),
});

export const studentValidation = Yup.object({
  std_name: Yup.string()
    .required("Student Name is required")
    .matches(OnlyAlphabets),
  dob: Yup.string().required("Date of birth is required"),
});
