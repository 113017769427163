import { gql } from "@apollo/client";

export const updateInstAcademicYear = gql`
  mutation UpadteAcademicYear(
    $id: ID!
    $token: String!
    $acd_yr: String!
    $acd_st_date: Time!
    $acd_end_date: Time!
    $acd_is_curr_yr: Boolean!
    $user_details: SubscribedUser!
  ) {
    UpdateInstAcdYr(
      token: $token
      id: $id
      input: {
        acd_yr: $acd_yr
        acd_st_date: $acd_st_date
        acd_end_date: $acd_end_date
        acd_is_curr_yr: $acd_is_curr_yr
      }
      user_details: $user_details
    ) {
      inst_id
      acd_yr
      acd_st_date
      acd_end_date
      acd_is_curr_yr
    }
  }
`;
