import React, { useEffect, useState } from "react";
import Navbar from "../../../Layouts/Navbar";
import { Title } from "../../../../stories/Title/Title";
import { emptyMessageType, licenseTypes } from "../../../../utils/constants";
import Input from "../../../../stories/Input/Input";
import { Button } from "../../../../stories/Button/Button";
import {
  DataGridPro,
  GridAlignment,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DatagridStyles";
import useMasterTableJson from "../json/useTableJson";
import Edit from "../../../../images/EditProfile.svg";
import { useNavigate } from "react-router-dom";
import { AddModalStyles } from "../../../styles/ModalStyles";
import ReactModal from "react-modal";
import Add from "./Add";

import { Direction, Operation, SortBy } from "../../../../utils/Enum.types";
import { GetCustomers } from "../../../../queries/customers/query/list";
import {
  CutomerListEdge,
  GetCustomersData,
  GetCustomersVars,
} from "../../../../utils/Query.Types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ROWS_PER_PAGE } from "../../../../utils/constants";
import useToken from "../../../../customhooks/useToken";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import Sidebar from "../../../Layouts/Sidebar";
import Delete from "../../../../images/Delete.svg";
import { DeleteCustomer } from "../../../../queries/customers/mutations/update";
import { msgType } from "../../../../utils/Types";
import DeleteModal from "../../../Modals/DeleteModal";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import MessageModal from "../../../../utils/MessageModal";
import LoadingModal from "../../../Modals/LoadingModal";
interface TableHeaderProps {
  headerName: string;
  className: string;
  field: string;
  headerAlign: GridAlignment;
  align: GridAlignment;
}
const Index = () => {
  const classes = useDataGridStyles();
  const [addModal, setAddModal] = useState(false);
  const { Customers } = useMasterTableJson();
  const [sortBy] = useState(SortBy.CUST_NAME);
  const [direction] = useState(Direction.ASC);
  const [customerId, setCustomerId] = useState(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [customers, setCustomers] = useState<CutomerListEdge[]>([]);

  const [deleteCustId, setDeletCustId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const { token } = useToken();
  const [type, setType] = useState(Operation.CREATE);
  const [searchData, setSearchData] = useState("");

  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const { user_details } = useLoggedInUserDetails();

  const [deleteCust, { loading: deletionLoading }] = useMutation(
    DeleteCustomer,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const [GetCustomerData, { data, loading, fetchMore }] = useLazyQuery<
    GetCustomersData,
    GetCustomersVars
  >(GetCustomers, {
    variables: {
      first: ROWS_PER_PAGE,
      after: null,
      sortBy,
      direction,
      token,
      name: searchData,
    },
  });

  const navigate = useNavigate();

  const handleDelete = (id: number) => {
    setDeleteModal(false);
    deleteCust({
      variables: {
        token,
        customer_id: id,
        user_details,
      },
      refetchQueries: [
        {
          query: GetCustomers,
          variables: {
            first: ROWS_PER_PAGE,
            after: null,
            sortBy,
            direction,
            token,
            name: searchData,
          },
        },
      ],
    }).then(({ data }) => {
      if (data && data.DeleteCustomer) {
        setMessage({
          flag: true,
          message: "Customer deleted successfully",
          operation: Operation.CREATE,
        });
      }
    });
  };
  const dynamicHeaders: TableHeaderProps[] = Customers.Table_Headers.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );
  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "cust_actions",
      headerName: "Actions",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      sortable: false,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <img
              src={Edit}
              alt=""
              onClick={() => handleEdit(Number(params.row.custId))}
            />
            <img
              src={Delete}
              alt=""
              onClick={() => {
                setDeletCustId(Number(params.row.custId));
                setDeleteModal(true);
              }}
            />
          </>
        );
      },
    },
  ];
  const handleEdit = (id: number) => {
    setCustomerId(id);
    setType(Operation.UPDATE);
    setAddModal(!addModal);
  };

  useEffect(() => {
    if (token) {
      GetCustomerData();
    }
  }, [token, GetCustomerData]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetCustomers.edges;
                const pageInfo = fetchMoreResult.GetCustomers.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetCustomers.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetCustomers: {
                    edges: [...customers, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetCustomers.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetCustomers.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setCustomers(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            custId: node.id,
            cust_name: node.cust_name,
            cust_place: node.cust_place,
            cust_contact_person: node.cust_contact_person,
            cust_mobile: node.cust_mobile,
          }))
        );
      } else {
        setCustomers(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            custId: node.id,
            cust_name: node.cust_name,
            cust_place: node.cust_place,
            cust_contact_person: node.cust_contact_person,
            cust_mobile: node.cust_mobile,
          }))
        );
      }
      setEndCursor(data.GetCustomers.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  const handleClick = (params: GridCellParams) => {
    if (params.field === "cust_name") {
      const custID = params.row.custId;
      navigate(`/${custID}/institution`);
    }
  };
  const handleClose = () => {
    setMessage(emptyMessageType);
  };
  return (
    <>
      <Navbar navType={licenseTypes.CUSTOMER} />
      <div className="cust-list">
        <Sidebar />
        <div className="cust-list__frame">
          <Title>Customer</Title>

          <div className="cust-list__select row g-0">
            <div className="col-2">
              <Input
                placeholder="Search..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchData(e.target.value);
                }}
              />
            </div>
            <div className="col"></div>
            <div className="col-2">
              <Button
                mode="addnew"
                className="cust-list__select--button"
                onClick={() => setAddModal(!addModal)}
              />
            </div>
          </div>
          <div className={`cust-list__tableblock ${classes.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              onCellClick={handleClick}
              disableRowSelectionOnClick
              disableChildrenSorting
              rowHeight={TABLE_ROW_HEIGHT}
            />
          </div>
        </div>
      </div>
      <ReactModal style={AddModalStyles} isOpen={addModal} ariaHideApp={false}>
        <Add
          setModalFlag={setAddModal}
          type={type}
          customerId={customerId}
          modalFlag={addModal}
          setType={setType}
        />
      </ReactModal>

      <DeleteModal
        id={deleteCustId}
        setModalFlag={setDeleteModal}
        modalFlag={deleteModal}
        handleDelete={handleDelete}
      />

      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />

      <LoadingModal flag={deletionLoading} />
    </>
  );
};

export default Index;
