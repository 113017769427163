import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { Label } from "../../../../../stories/Label/Label";
import Input from "../../../../../stories/Input/Input";
import { Button } from "../../../../../stories/Button/Button";
import Close from "../../../../../images/Close.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Direction, Operation } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Types";
import { EMPTY_STRING, emptyMessageType } from "../../../../../utils/constants";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import {
  handleFormEvent,
  removeMoreSpace,
} from "../../../../../utils/UtilFunctions";
import { semesterType } from "../../../../../utils/FormTypes";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { Form, Formik } from "formik";
import { semValidation } from "../../../../../utils/validationRules";
import LoadingModal from "../../../../Modals/LoadingModal";
import {
  AddInstSemesterData,
  AddInstSemesterVars,
  MsgMstInstSemesterOrderField,
  NodeVars,
  SemesterList,
  SemesterQueryType,
  UpdateInstSemesterData,
  UpdateInstSemesterVars,
} from "../../../../../utils/Query.Types";
import MessageModal from "../../../../../utils/MessageModal";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { SemesterByNode } from "../../../../../queries/institution/semester/query/byNode";
import { UpdateInstSemester } from "../../../../../queries/institution/semester/mutations/update";
import { AddInstSemester } from "../../../../../queries/institution/semester/mutations/new";
import { GetMstInstSemesters } from "../../../../../queries/institution/semester/query";
interface ModalProps {
  operation: Operation;
  id: number;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleEdit: (id: number) => void;
}
const Add = ({ id, operation, setModal, handleEdit }: ModalProps) => {
  const [message, setMessage] = useState<msgType>(emptyMessageType);

  const [formData, setFormData] = useState<semesterType>({
    sem_desc: "",
    sem_short_desc: "",
  });
  const { token } = useToken();

  const { classId, instId } = useParams();

  const { user_details } = useLoggedInUserDetails();

  const { semesterLabel } = useInstLabels();
  const [GetSemByNode] = useLazyQuery<
    {
      node: SemesterList;
    },
    NodeVars
  >(SemesterByNode);
  const [updateSem, { loading: updationLoading }] = useMutation<
    UpdateInstSemesterData,
    UpdateInstSemesterVars
  >(UpdateInstSemester, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [NewSem, { loading: creationLoading }] = useMutation<
    AddInstSemesterData,
    AddInstSemesterVars
  >(AddInstSemester, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });

  useEffect(() => {
    if (id && operation === Operation.UPDATE && token) {
      GetSemByNode({
        variables: {
          id,
          token,
        },
      }).then(({ data }) => {
        if (data && data.node) {
          setFormData({
            sem_desc: data.node.sem_desc,
            sem_short_desc: data.node.sem_short_desc,
          });
        }
      });
    } else {
      setFormData({
        sem_desc: "",
        sem_short_desc: "",
      });
    }
  }, [id, GetSemByNode, token, operation]);
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const HandleSubmit = async () => {
    await NewSem({
      variables: {
        token,
        input: {
          inst_id: instId!,
          sem_desc: removeMoreSpace(formData.sem_desc),
          sem_short_desc: removeMoreSpace(formData.sem_short_desc),
          class_id: classId!,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstSemesters,
          variables: {
            id: classId,
            token,
            query_type: SemesterQueryType.SEMESTER_BY_CLASS_ID,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstSemesterOrderField.SEM_IDX,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `${semesterLabel} Added successfully`,
          flag: true,
          operation: Operation.CREATE,
        });
      }
    });
  };
  const handleClear = () => {
    setFormData({
      sem_desc: "",
      sem_short_desc: "",
    });
  };

  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      if (message.operation === Operation.UPDATE) {
        handleEdit(0);
      }
      setModal(false);
      handleClear();
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  const HandleEdit = async () => {
    await updateSem({
      variables: {
        token,
        id: id,
        inst_id: instId!,
        input: {
          sem_desc: removeMoreSpace(formData.sem_desc),
          sem_short_desc: removeMoreSpace(formData.sem_short_desc),
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstSemesters,
          variables: {
            id: classId,
            token,
            query_type: SemesterQueryType.SEMESTER_BY_CLASS_ID,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstSemesterOrderField.SEM_IDX,
            },
          },
        },
        {
          query: SemesterByNode,
          variables: { id: id, token },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `${semesterLabel} Updated successfully`,
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });
  };

  return (
    <>
      <div className="inst-config__masters--title">
        <Title>
          {operation === Operation.CREATE ? "Add" : "Update"} {semesterLabel}
        </Title>
        <img src={Close} alt="" onClick={() => setModal(false)} />
      </div>
      <Formik
        initialValues={formData}
        validationSchema={semValidation}
        onSubmit={operation === Operation.UPDATE ? HandleEdit : HandleSubmit}
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form>
              <div className="label-grid">
                <Label>Description</Label>
                <Input
                  name="sem_desc"
                  value={formData.sem_desc}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    meta.handleChange(e);
                    handleValueChange(e);
                  }}
                  onKeyDown={handleFormEvent}
                  required
                  autoFocus
                  error={meta.errors.sem_desc}
                />
                <Label>Short Description</Label>
                <Input
                  name="sem_short_desc"
                  value={formData.sem_short_desc}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    meta.handleChange(e);
                    handleValueChange(e);
                  }}
                  onKeyDown={handleFormEvent}
                  required
                />
              </div>
              <Button mode="save" type="submit" />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  handleClear();
                  setModal(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
      <LoadingModal flag={creationLoading || updationLoading} />
    </>
  );
};

export default Add;
