import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "./useToken";
import { AppContext } from "../context/context";
import { DatesOptionType } from "../utils/Types";
import { GetAcdYrActiveByInstId } from "../queries/institution/academicyear/queries";
import { getDatesBetween } from "../utils/UtilFunctions";

export interface AcdActiveVars {
  token: string;
  inst_id: string;
}
export interface AcdActiveDetails {
  id: number;
  acd_yr: string;
  acd_st_date: string;
  acd_end_date: string;
  acd_is_curr_yr: boolean;
}
export interface AcdActiveData {
  GetAcdYrActiveByInstId: AcdActiveDetails;
}
const useActiveAcademicYear = () => {
  const { instId } = useParams();
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const [dateOptions, setDateOptions] = useState<DatesOptionType[]>([]);
  const [GetAcdYear, { data, loading, error }] = useLazyQuery<
    AcdActiveData,
    AcdActiveVars
  >(GetAcdYrActiveByInstId, {
    variables: {
      token,
      inst_id: instId! ?? state.InstId,
    },
  });

  useEffect(() => {
    if (token && (instId || state.InstId)) {
      GetAcdYear().then(({ data }) => {
        if (data) {
          setDateOptions(
            getDatesBetween(
              data.GetAcdYrActiveByInstId.acd_st_date,
              data.GetAcdYrActiveByInstId.acd_end_date
            )
          );
        }
      });
    }
  }, [token, GetAcdYear, instId, state.InstId, data]);
  return { activeAcademicYearData: { data, loading, error, dateOptions } };
};

export default useActiveAcademicYear;
