import { useLazyQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Direction,
  SortBy,
  StudentReportType,
  studentQueryTypes,
} from "../utils/Enum.types";
import useToken from "./useToken";

import { EMPTY_STRING } from "../utils/constants";
import { AppContext } from "../context/context";
import { GetStudents } from "../queries/students/list";
import useActiveAcademicYear from "./useActiveAcademicYear";

export interface MstStudentMiscData {
  id: number;
  lib_book_count: number;
  student_id: number;
  availed_transport: boolean;
  availed_hostel: boolean;
}
export interface StudentNode {
  id: number;
  std_name: string;
  parent_name: string;
  std_status: string;
  std_sex: string;
  std_adm_no: string;
  std_dob: string;
  std_email: string;
  std_mobile: string;
  is_login_created: boolean;
  std_profile_filename: string;
  inst_id: number;
  dept_id: number;
  branch_id: number;
  class_id: number;
  semester_id: number;
  section_id: number;
  acd_yr_id: number;
  dept: {
    id: number;
    dept_desc: string;
  };
  branch: {
    id: number;
    branch_desc: string;
  };
  class: {
    id: number;
    class_desc: string;
  };
  semester: {
    id: number;
    sem_desc: string;
  };
  section: {
    id: number;
    section_desc: number;
  };
  misc_data: {
    mobile_app_count: number;
  };
  isChecked?: boolean;
}

export interface StudentPageInfo {
  endCursor: string;
  hasNextPage: boolean;
}
export interface StudentEdges {
  node: StudentNode;
}
export interface GetStudentsList {
  edges: StudentEdges[];
  pageInfo: StudentPageInfo;
  totalCount: number;
}
export interface input {
  std_query_type: string;
  ids: number[];
  str_data?: string[];
  int_data?: number[];
  float_data?: number[];
}
export interface MstStudentOrder {
  field?: string;
  direction: Direction;
}
export interface vars {
  name: string;
  after: string | null;
  acd_yr_id: number;
  orderBy: MstStudentOrder[];
  first: number | null;
  token: string;
  input: input;
}
export interface GetStudentsData {
  GetStudents: GetStudentsList;
}

const useStudentsbyNewApi = (
  departmentId: number,
  branchId: number,
  classId: number,
  semesterId: number,
  sectionId: number,
  name: string,
  rowsPerPage: number | null,
  queryType: StudentReportType,
  channelId: number,
  adm_nums: string[]
) => {
  const { token } = useToken();
  const { state } = useContext(AppContext);
  const { instId } = useParams();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const [std_query_type, setstd_query_type] = useState("");

  const [ids, setId] = useState<number[]>([]);

  const setGeneralQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.BY_DEPT_ID);
    } else {
      setId([Number(instId)]);
      setstd_query_type(studentQueryTypes.BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, instId]);

  const setSectionAllocationQueryType = useCallback(() => {
    // else if (sectionId) {
    //   setId([sectionId]);
    //   setstd_query_type(studentQueryTypes.section_NOT);
    // }
    if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_DEPT_ID);
    } else {
      setId([Number(instId)]);
      setstd_query_type(studentQueryTypes.SECTION_NOT_ALLOC_BY_INST_ID);
    }
  }, [semesterId, classId, branchId, departmentId, instId]);
  const setEligibleQueryType = useCallback(() => {
    if (sectionId) {
      setId([sectionId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_ACTIVE_BY_SECTION_ID);
    } else if (semesterId) {
      setId([semesterId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_ACTIVE_BY_SEMESTER_ID);
    } else if (classId) {
      setId([classId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_ACTIVE_BY_CLASS_ID);
    } else if (branchId) {
      setId([branchId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_ACTIVE_BY_BRANCH_ID);
    } else if (departmentId) {
      setId([departmentId]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_ACTIVE_BY_DEPT_ID);
    } else {
      setId([Number(instId)]);
      setstd_query_type(studentQueryTypes.STATUS_EQ_ACTIVE_BY_INST_ID);
    }
  }, [sectionId, semesterId, classId, branchId, departmentId, instId]);
  //Global States
  useEffect(() => {
    switch (queryType) {
      case StudentReportType.GENERAL:
        setGeneralQueryType();
        break;

      case StudentReportType.SECTION_ALLOCATION:
        setSectionAllocationQueryType();
        break;

      case StudentReportType.ELIGIBLE:
        setEligibleQueryType();
        break;

      case StudentReportType.STUDENTS_BY_CHANNEL:
        if (channelId && channelId !== 0) {
          setstd_query_type(studentQueryTypes.BY_CHANNEL_ID);
          setId([channelId!]);
        }
        break;
      case StudentReportType.STDS_BY_ADMISSION_NUMS:
        if (adm_nums.length) {
          setId([Number(instId)]);
          setstd_query_type(studentQueryTypes.STDS_BY_ADMISSION_NUMS);
        }
        break;
      default:
        break;
    }
  }, [
    instId,
    departmentId,
    branchId,
    classId,
    semesterId,
    sectionId,
    queryType,
    channelId,
    setGeneralQueryType,
    setSectionAllocationQueryType,
    adm_nums.length,
  ]);
  const [GetStudentsData, { data, loading, error, fetchMore }] = useLazyQuery<
    GetStudentsData,
    vars
  >(GetStudents, {
    variables: {
      after: null,
      first: rowsPerPage === null ? null : rowsPerPage,
      acd_yr_id: activeAcademicYearData.data?.GetAcdYrActiveByInstId.id!,
      name,
      token,
      orderBy: [
        {
          direction: Direction.ASC,
          field: SortBy.STD_NAME,
        },
      ],
      input: {
        ids,
        std_query_type,
        str_data: adm_nums,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      ids[0] > 0 &&
      std_query_type !== EMPTY_STRING &&
      activeAcademicYearData.data
    ) {
      GetStudentsData();
    }
  }, [
    GetStudentsData,
    state.ActiveFinYr,
    ids,
    std_query_type,
    activeAcademicYearData.data,
    // rowsPerPage,
    adm_nums.length,
    name,
  ]);
  return { StudentsData: { data, loading, error, fetchMore } };
};

export default useStudentsbyNewApi;
