import React, { useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactModal from "react-modal";
import { ActiveAcdYrStyles } from "../../../../styles/ModalStyles";
import Add from "./Add";
import TreeView from "../Treeview";
import List from "./List";
import { Breadcrumbs } from "@mui/material";
import { Direction, Operation } from "../../../../../utils/Enum.types";
import DeleteModal from "../../../../Modals/DeleteModal";
import useToken from "../../../../../customhooks/useToken";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DeleteInstDeptById,
  ReOrderInstDept,
} from "../../../../../queries/institution/department/mutations/update";
import { EMPTY_STRING, emptyMessageType } from "../../../../../utils/constants";
import { msgType } from "../../../../../utils/Types";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { GetMstInstDepts } from "../../../../../queries/institution/department/query";
import {
  DepartmentListDataByInstId,
  DepartmentListEdges,
  DepartmentListVarsByInstId,
  MsgMstInstDeptOrderField,
} from "../../../../../utils/Query.Types";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import useInstLabels from "../../../../../customhooks/useInstLabels";

const Department = () => {
  const navigate = useNavigate();

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reOrder, setReOrder] = useState(false);

  const [deptId, setDepartmentId] = useState(0);

  const [operation, setOperation] = useState(Operation.CREATE);
  const [localItems, setLocalItems] = useState<DepartmentListEdges[]>([]);

  const { instId } = useParams();
  const { token } = useToken();

  const [message, setMessage] = useState<msgType>(emptyMessageType);
  const { user_details } = useLoggedInUserDetails();

  const { departmentLabel } = useInstLabels();
  const [GetDepartments] = useLazyQuery<
    DepartmentListDataByInstId,
    DepartmentListVarsByInstId
  >(GetMstInstDepts);

  const [ReOrderDepartment, { loading: reorderLoading }] = useMutation(
    ReOrderInstDept,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [DeleteDepartment, { loading: DeleteLoading }] = useMutation(
    DeleteInstDeptById,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const hanldeEdit = (id: number) => {
    setDepartmentId(id);

    if (id > 0) {
      setOperation(Operation.UPDATE);
    } else {
      setOperation(Operation.CREATE);
    }
    setAddModal(!addModal);
  };

  const hanldeDelete = (id: number) => {
    setDepartmentId(id);
    setDeleteModal(!deleteModal);
  };

  const HandleDelete = async (dept_id: number) => {
    setDeleteModal(!deleteModal);
    await DeleteDepartment({
      variables: {
        token,
        dept_id,
        inst_id: instId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstDepts,
          variables: {
            inst_id: instId!,
            token,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstDeptOrderField.DEPT_IDX,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: `${departmentLabel} Deleted successfully`,
          flag: true,
          operation: Operation.DELETE,
        });
      }
    });
    setOperation(Operation.CREATE);
  };

  const HandleSaveDragAndDrop = () => {
    // eslint-disable-next-line
    ReOrderDepartment({
      variables: {
        token,
        input: localItems?.map(({ node: res }, index) => ({
          id: res.id,
          dept_idx: index + 1,
        })),
        inst_id: instId,
        user_details,
      },
      refetchQueries: [
        {
          query: GetMstInstDepts,
          variables: {
            inst_id: instId!,
            token,
            after: null,
            first: null,
            name: EMPTY_STRING,
            orderBy: {
              direction: Direction.ASC,
              field: MsgMstInstDeptOrderField.DEPT_IDX,
            },
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        setMessage({
          message: "Re-Order Successful",
          flag: true,
          operation: Operation.UPDATE,
        });
      }
    });

    setReOrder(!reOrder);
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  return (
    <>
      <div className="inst-config__masters">
        <Title>{departmentLabel}</Title>
        <Breadcrumbs aria-label="breadcrumb" className="inst-breadcrumbs">
          <NavLink to="/"></NavLink>
        </Breadcrumbs>

        <div className="inst-config__masters--datablock row g-0">
          <div className="col inst-config__masters--datablock--left">
            <List
              handleEdit={hanldeEdit}
              handleDelete={hanldeDelete}
              reOrder={reOrder}
              localItemsState={{ localItems, setLocalItems }}
            />
          </div>
          <div className="col inst-config__masters--datablock--right">
            <TreeView />
          </div>
        </div>

        {reOrder ? (
          <Button mode="save" onClick={HandleSaveDragAndDrop} />
        ) : (
          <>
            <Button
              mode="addnew"
              onClick={() => {
                setDepartmentId(0);
                setOperation(Operation.CREATE);
                setAddModal(!addModal);
              }}
            />
            <Button mode="re-order" onClick={() => setReOrder(!reOrder)} />
          </>
        )}

        <Button
          mode="back"
          onClick={() => {
            if (reOrder) {
              GetDepartments({
                variables: {
                  inst_id: instId!,
                  token,
                  after: null,
                  first: null,
                  name: EMPTY_STRING,
                  orderBy: {
                    direction: Direction.ASC,
                    field: MsgMstInstDeptOrderField.DEPT_IDX,
                  },
                },
              }).then(({ data }) => {
                if (data && data.GetMstInstDepts) {
                  setLocalItems(
                    data.GetMstInstDepts.edges.filter(
                      ({ node: dept }) => dept != null
                    )
                  );
                }
              });
              setReOrder(false);
            } else navigate(-1);
          }}
        />
      </div>
      <ReactModal
        isOpen={addModal}
        ariaHideApp={false}
        style={ActiveAcdYrStyles}
      >
        <Add
          setModal={setAddModal}
          operation={operation}
          id={deptId}
          handleEdit={hanldeEdit}
        />
      </ReactModal>
      <DeleteModal
        modalFlag={deleteModal}
        setModalFlag={setDeleteModal}
        handleDelete={HandleDelete}
        id={deptId}
      />
      <MessageModal
        handleClose={handleClose}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />

      <LoadingModal flag={reorderLoading || DeleteLoading} />
    </>
  );
};

export default Department;
