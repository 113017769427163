import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GetShortStdList } from "../../../../queries/chat/feeds/list";
import useToken from "../../../../customhooks/useToken";
import useActiveAcademicYear from "../../../../customhooks/useActiveAcademicYear";
import useChannels from "./useChannels";
import { ChannelQueryType } from "../../../../utils/Enum.types";
import { useParams } from "react-router-dom";

export interface GetShortStdListResult {
  id: number;
  std_name: string;
  std_adm_no: string;
  std_profile_filename: string;
  total_count: number;
}
interface GetShortStdListData {
  GetShortStdList: GetShortStdListResult[];
}

interface GetShortStdListVars {
  token: string;
  acd_yr_id: number;
  inst_id: string;
  channel_id: number;
}
const useStudentMembersCount = () => {
  const { token } = useToken();
  const { activeAcademicYearData } = useActiveAcademicYear();
  const { instId } = useParams();
  const { data: channelData } = useChannels(ChannelQueryType.ENTRYLEVEL);
  const channel_id =
    channelData && channelData.GetChannels.length
      ? channelData.GetChannels[0].id
      : 0;
  const [GetCounts, { data, loading }] = useLazyQuery<
    GetShortStdListData,
    GetShortStdListVars
  >(GetShortStdList, {
    variables: {
      acd_yr_id: activeAcademicYearData.data
        ? activeAcademicYearData.data.GetAcdYrActiveByInstId.id
        : 0,
      channel_id,
      inst_id: instId!,
      token,
    },
  });

  useEffect(() => {
    if (token && activeAcademicYearData.data) GetCounts();
  }, [
    GetCounts,
    token,
    activeAcademicYearData.data,
    channel_id,
    instId,
    token,
  ]);
  return { data, loading };
};

export default useStudentMembersCount;
