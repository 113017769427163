import React, { useContext, useEffect, useState } from "react";
import {
  FetchStudentStatusClassName,
  getModifiedScrollHeight,
  handleFormEvent,
  handleMUISelectEvent,
  isOptionEqualToValue,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { Autocomplete, Drawer, TextField } from "@mui/material";
import Input from "../../../../../stories/Input/Input";
import {
  StudentReportType,
  Operation,
  ColumnVisibilityFor,
  DEFAULT_TIME,
  DeviceType,
} from "../../../../../utils/Enum.types";
import {
  EMPTY_STRING,
  payloadTypes,
  ROWS_PER_PAGE,
} from "../../../../../utils/constants";

import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import useStudentTableJson from "../../../../../customhooks/useStudentTableJson";
import MobileDeviceModal from "./DeviceModal";
import MobileDevice from "../../../../../images/Installed_Devices.svg";
import MessageModal from "../../../../../utils/MessageModal";
import StudentTotalCount from "../Components/StudentsTotalCount";
import { Keys } from "../../../../../utils/Enum.keys";
import useStudentsbyNewApi, {
  StudentEdges,
} from "../../../../../customhooks/useStudentByNodeApi";
import { responseType, TableHeaderProps } from "../../../../../utils/Types";

import { AppContext } from "../../../../../context/context";
import {
  ListAutoCompleteStyles,
  ListAutoCompleteTextStyles,
} from "../../../../../styles/AutocompleteListStyles";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { msgType } from "../../../../../utils/FormTypes";
import useDropdownData from "../../../../../customhooks/useDropDown";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import { allocateTeachers } from "../../../../../styles/DrawerStyles";

const DeviceDetails = () => {
  const classes = ListAutoCompleteStyles();
  const textClasses = ListAutoCompleteTextStyles();
  const dataClasses = useDataGridStyles();

  const { dispatch } = useContext(AppContext);




  const [departmentSelected, setDepartmentSelected] =
    useState<responseType | null>(null);

  const [branchSelected, setBranchSelected] = useState<responseType | null>(
    null
  );
  const [classSelected, setClassSelected] = useState<responseType | null>(null);
  const [semesterSelected, setSemesterSelected] = useState<responseType | null>(
    null
  );
  const [sectionSelected, setSectionSelected] = useState<responseType | null>(
    null
  );
  const [categorySelected, setCategorySelected] = useState<responseType | null>(
    null
  );
  const [statusSelected, setStatusSelected] = useState<string>("");
  const drawerClasses = allocateTeachers();

  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [searchData, setSearchData] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [students, setStudents] = useState<StudentEdges[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const { Student } = useStudentTableJson({
    jsonFor: ColumnVisibilityFor.FOR_ADMISSIONS,
  });
  const [deviceNo, setDeviceNo] = useState(0);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const {
    departmentLabel,
    branchLabel,
    classLabel,
    semesterLabel,
    sectionLabel,
  } = useInstLabels();

  const [messageModal, setMessageModal] = useState(false);

  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { StudentsData } = useStudentsbyNewApi(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0,
    sectionSelected ? sectionSelected.value : 0,
    searchData,
    ROWS_PER_PAGE,
    StudentReportType.GENERAL,
    0,
    []
  );

  const { data, fetchMore, loading } = StudentsData;

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CATEGORY_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useDropdownData(
    departmentSelected ? departmentSelected.value : 0,
    branchSelected ? branchSelected.value : 0,
    classSelected ? classSelected.value : 0,
    semesterSelected ? semesterSelected.value : 0
  );

  const clear = () => {
    setDepartmentSelected(null);
    setBranchSelected(null);
    setClassSelected(null);
    setSemesterSelected(null);
    setSectionSelected(null);
    setCategorySelected(null);
    setSearchData("");
    setStatusSelected(EMPTY_STRING);
  };

  const handleClose = () => {
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };

  const dynamicHeaders: TableHeaderProps[] = Student.Table_Headers?.map(
    (header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    })
  );

  const columns: GridColDef[] = [
    ...dynamicHeaders,
    {
      field: "status",
      headerName: "No Of Devices Installed",
      headerAlign: HEADER_TEXT_ALIGN,
      cellClassName: "td-status",
      align: SLNO_TEXT_ALIGN,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div
            className={`std-device-details__tableblock--cell ${FetchStudentStatusClassName(
              params.row.status
            )}`}
          >
            {params.row.status >= 1 ? (
              <>
                {params.row.status} <img src={MobileDevice} alt="/" />
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,

      is_login_created: false,
      std_roll_no: false,
      dept: false,
      branch: false,
      class: false,
      sem: false,
      sec: false,
    });
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStudents.edges;

      const updateStudents = (newData: any[]) => {
        if (endCursor) {
          return newData.map((newStudent) => {
            const existingStudent = students.find(
              (student) => student.node.id === newStudent.node.id
            );
            return existingStudent
              ? { ...newStudent, node: { ...newStudent.node } }
              : newStudent;
          });
        }
        return newData;
      };

      const formatStudentRow = (node: any, index: number) => ({
        id: index + 1,
        std_adm_no: node.std_adm_no,
        std_reg_no: node.std_reg_no,
        std_name: node.std_name,
        std_father_name: node.std_father_name,
        std_mobile: node.std_mobile,
        status: node.misc_data.mobile_app_count,
        std_id: node.id,
        std_mother_name: node.std_mother_name,
        std_sex: node.std_sex,
        std_roll_no: node.std_roll_no,
        std_doa:
          toStandardDate(node.std_doa) === toStandardDate(DEFAULT_TIME)
            ? EMPTY_STRING
            : toStandardDate(node.std_doa),
        std_dob:
          toStandardDate(node.std_dob) === toStandardDate(DEFAULT_TIME)
            ? EMPTY_STRING
            : toStandardDate(node.std_dob),
        std_email: node.std_email,
        is_login_created: "",
        dept: node.dept.dept_desc,
        branch: node.branch.branch_desc,
        class: node.class.class_desc,
        sem: node.semester.sem_desc,
        sec: node.section.section_desc,
        std_type: node.std_fresher ? "Fresher" : "Old Student",
      });

      const filteredRows = (data: any[], condition: (node: any) => boolean) =>
        data
          .filter(({ node }) => condition(node))
          .map(({ node }, index) => formatStudentRow(node, index));

      const updatedData = updateStudents(newData);

      setStudents(updatedData);

      if (statusSelected === DeviceType.Installed) {
        setRows(
          filteredRows(
            updatedData,
            (node) => node.misc_data.mobile_app_count > 0
          )
        );
      } else if (statusSelected === DeviceType.Not_Installed) {
        setRows(
          filteredRows(
            updatedData,
            (node) => node.misc_data.mobile_app_count === 0
          )
        );
      } else {
        setRows(
          updatedData.map(({ node }, index) => formatStudentRow(node, index))
        );
      }

      setEndCursor(data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [rowsPerPage, loading, data, statusSelected]);

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStudents.edges;
                const pageInfo = fetchMoreResult.GetStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        // eslint-disable-next-line
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );

  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.DEVICE_DETAILS
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.DEVICE_DETAILS,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <div className="user-rights__login-user">
        <Title variant="subtitle1">
          Students Device Details
        </Title>
        <form className="user-rights__login-user--select row g-0">
          <div className="col-1">
            <Input
              id="search"
              type="text"
              placeholder="Search "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchData(e.target.value);
              }}
              value={searchData}
              onKeyDown={handleFormEvent}
            />
          </div>

          {USE_DEPARTMENT_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={departmentDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, departmentSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && departmentSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setDepartmentSelected(null);
                  }
                }}
                openOnFocus
                value={departmentSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDepartmentSelected(newValue);
                    setHasNextPage(true);
                  } else {
                    setDepartmentSelected(null);
                  }
                  setBranchSelected(null);
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={departmentLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_BRANCH_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={branchDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, branchSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && branchSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setBranchSelected(null);
                  }
                }}
                openOnFocus
                value={branchSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setBranchSelected(newValue);
                  } else {
                    setBranchSelected(null);
                  }
                  setClassSelected(null);
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={branchLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_CLASS_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={classDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, classSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && classSelected) {
                    handleMUISelectEvent(e);
                  }

                  if (e.key === Keys.BACKSPACE) {
                    setClassSelected(null);
                  }
                }}
                openOnFocus
                value={classSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setClassSelected(newValue);
                  } else {
                    setClassSelected(null);
                  }
                  setSemesterSelected(null);
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={classLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                    fullWidth
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SEMESTER_KEY ? (
            <div className="col-1">
              <Autocomplete
                classes={classes}
                options={semesterDropDown}
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, semesterSelected)
                }
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && semesterSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSemesterSelected(null);
                  }
                }}
                openOnFocus
                value={semesterSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSemesterSelected(newValue);
                  } else {
                    setSemesterSelected(null);
                  }
                  setSectionSelected(null);
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={semesterLabel}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}
          {USE_SECTION_KEY ? (
            <div className="col-1">
              <Autocomplete
                isOptionEqualToValue={(option) =>
                  isOptionEqualToValue(option, sectionSelected)
                }
                classes={classes}
                options={sectionDropDown}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER && sectionSelected) {
                    handleMUISelectEvent(e);
                  }
                  if (e.key === Keys.BACKSPACE) {
                    setSectionSelected(null);
                  }
                }}
                openOnFocus
                value={sectionSelected}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setSectionSelected(newValue);
                  } else {
                    setSectionSelected(null);
                  }
                  setCategorySelected(null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={sectionLabel}
                    InputLabelProps={{ shrink: true }}
                    classes={{ root: textClasses.formControlRoot }}
                  />
                )}
              />
            </div>
          ) : null}

          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={Object.keys(DeviceType)}
              openOnFocus
              value={statusSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setStatusSelected(newValue);
                } else {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  setStatusSelected(EMPTY_STRING);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  InputLabelProps={{ shrink: true }}
                  classes={{ root: textClasses.formControlRoot }}
                  fullWidth
                />
              )}
            />
          </div>
          <div className="col"></div>

          <div className="col-2 flex-end">
            <>
              <Button
                mode="clear"
                onClick={(e: React.FormEvent) => {
                  e.preventDefault();
                  clear();
                }}
                className="student-clear-button"
              />
            </>
          </div>
        </form>
        <div className={`user-rights__login-user--tableblock ${dataClasses.root}`}>
        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={TABLE_ROW_HEIGHT}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          onCellClick={(params) => {
            if (params.field === "std_name") {
              setDeviceNo(params.row.status);
              dispatch({
                type: payloadTypes.SET_STUDENT_ID,
                payload: {
                  studentId: params.row.std_id,
                },
              });
              setMessageModal(!messageModal);
            }
          }}
          disableRowSelectionOnClick
          hideFooter
          slotProps={{
            columnsPanel: { disableHideAllButton: true },
          }}
        />
      </div>


    
        <div className=" flex-end">
          <StudentTotalCount totalCount={data?.GetStudents.totalCount!} />
        </div>
      </div>

   

      {/* MessageModal */}

      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="right"
        open={messageModal}
        onClose={() => setMessageModal(!messageModal)}
      >
        <MobileDeviceModal
          setMessageModal={setMessageModal}
          deviceNo={deviceNo}
        />
      </Drawer>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
    </>
  );
};

export default DeviceDetails;
