import { useContext, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { transactionStatusOptions } from "../../../../../utils/constants";
import TransactionTable from "./TransactionTable";
import {
  listAutoCompleteStyles,
  listAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { Title } from "../../../../../stories/Title/Title";
import { Button } from "../../../../../stories/Button/Button";
import { optionsType } from "../../../../../utils/Types";
import { AppContext } from "../../../../../context/context";
import { payloadTypes } from "../../../../../context/reducer";
import { AcctOnlinePaymentQueryType } from "../../../../../utils/QueryEnums";
import Close from "../../../../../images/Close.svg";
import useStudentDetailsByStdDemandId from "../../../../../customhooks/useStudentDetailsByStdDemandId";
export const tableJson = [
  {
    t_date: "10-10-2023",

    uref_no: "231457856",
    ref_no: "3ghftrdgko90",
    mid: "32568",
    s_mid: "0874",
    p_mode: "Net Bancking",
    amount: 10000,
    code: "E000",
    message:
      "Received successful confirmation in real time for the transaction. Settlement process isinitiated for the transaction",
    v_no: "Vou123",
    status: "Success",
  },
  {
    t_date: "10-10-2023",

    uref_no: "231457856",
    ref_no: "3ghftrdgko90",
    mid: "32568",
    s_mid: "9087",
    p_mode: "Net Bancking",
    amount: 10000,
    code: "E001",

    message: "Transaction Failed",
    v_no: "Vou123",
    status: "Failed",
  },
];

interface QueryOptionsType extends optionsType {
  generalValue: string;
}
export const All: QueryOptionsType = {
  label: "All",
  value: AcctOnlinePaymentQueryType.ON_PY_RECORDS,
  generalValue: AcctOnlinePaymentQueryType.ON_PY_RECORDS,
};

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const StudentTransactionIndex = ({ setModal }: Props) => {
  const classes = listAutoCompleteStyles();
  const textClasses = listAutoCompleteTextStyles();
  const { dispatch } = useContext(AppContext);

  const [queryType, setQueryType] = useState<optionsType>({
    ...All,
    value: All.generalValue,
  });
  const [transactionDate, setTransactionDate] = useState("");

  const { studentFormData } = useStudentDetailsByStdDemandId();
  const [statusCode, setStatusCode] = useState("");
  const [searchString, setSearchString] = useState("");
  const [count, setCount] = useState(0);

  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_STD_DEMAND_ID,
      payload: { stdDemandId: 0 },
    });
    setSearchString("");
    setQueryType(All);
    setStatusCode("");
    setTransactionDate("");
  };

  return (
    <>
      <div className="online-transaction__title">
        <Title>Online Transaction Details</Title>
        <img src={Close} alt="" onClick={()=>setModal(false)}/>
      </div>
      <div className="online-transaction">
        <div className="online-transaction__details row g-0">
          <div className="col">
            <TextField
              className="online-transaction__details--textfield"
              label="Roll No."
              value={studentFormData.std_roll_no}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Father Name"
              value={studentFormData.std_father_name}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Total Demand"
              value={studentFormData.fee_total_demand}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="online-transaction__details--textfield"
              label="Student Name"
              value={studentFormData.std_name}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Class Description"
              value={studentFormData.class_desc}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Total Balance"
              value={studentFormData.fee_balance}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              className="online-transaction__details--textfield"
              label="Father Name"
              value={studentFormData.std_father_name}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Father Name"
              value={studentFormData.std_mother_name}
              InputLabelProps={{ shrink: true }}
              disabled
            />
            <TextField
              className="online-transaction__details--textfield"
              label="Paid"
              value={studentFormData.fee_total_paid}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </div>
        </div>
        <div className="online-transaction__filters row g-0">
          <div className="col-2">
            <TextField
              className="online-transaction__details--textfield"
              label="Unique Reference No / Voucher No"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="col-2">
            <Autocomplete
              classes={classes}
              options={transactionStatusOptions}
              openOnFocus
              forcePopupIcon
              disableClearable
              onChange={(e, newValue) => {
                if (newValue) {
                  setQueryType({
                    label: newValue.label,
                    value: newValue.value,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  classes={{ root: textClasses.formControlRoot }}
                  label="Transaction Status"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
          <div className="col"></div>
          <div className="col-2">
            <TextField
              className="online-transaction__filters--textfield"
              label="Date"
              type="date"
              onChange={(e) => {
                setTransactionDate(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <TransactionTable
          showLogDataBtn={false}
          queryType={queryType}
          statusCode={statusCode}
          transactionDateContainsFold={transactionDate}
          searchString={searchString}
          setCount={setCount}
        />
        <div className="row g-0">
          <div className="col">
            <Button mode="clear" onClick={handleClear} />
            <Button mode="cancel" onClick={() => setModal(false)} />
          </div>
          <div className="col-4">
            <div className="online-transaction__total-count">
              Total Transactions Done : <b>{count}</b>
            </div>
          </div>
        </div>
      </div>

      {/* print-modal */}
    </>
  );
};

export default StudentTransactionIndex;
