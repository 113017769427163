import { gql } from "@apollo/client";
export const UpdateInstDept = gql`
  mutation UpdateInstDept(
    $id: ID!
    $token: String!
    $inst_id: ID!
    $input: UpdateMsgMstInstDeptInput!
    $user_details: SubscribedUser!
  ) {
    UpdateInstDept(
      token: $token
      id: $id
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const DeleteInstDeptById = gql`
  mutation DeleteInstDeptById(
    $token: String!
    $dept_id: ID!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    DeleteInstDeptById(
      token: $token
      id: $dept_id
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;

export const ReOrderInstDept = gql`
  mutation ReOrderInstDept(
    $token: String!
    $input: [ReOrderMstInstDeptInput!]!
    $inst_id: ID!
    $user_details: SubscribedUser!
  ) {
    ReOrderInstDept(
      token: $token
      input: $input
      inst_id: $inst_id
      user_details: $user_details
    )
  }
`;
