import React, { useContext, useEffect, useState } from "react";

import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import ReactModal from "react-modal";

import Navbar from "../../../../Layouts/Navbar";
import {
  A2_CELL,
  A3_CELL,
  A4_CELL,
  ACC_HEADER_FONT,
  ADDRESS_ALIGNMENT,
  ADDRESS_FONT,
  BLOB_TYPE,
  BORDER_DATA,
  C4_CELL,
  DOWNLOAD,
  EDUATE_IMG_FORMAT,
  ELEMENT,
  EMPTY_STRING,
  FILE_NAME_CSS,
  FILE_NAME_FONT,
  FIN_YEAR_FONT,
  FIRST_CELL,
  FIRST_INDEX,
  FROZEN_CELLS,
  HEADER_ALIGNMENT,
  HEADER_ALIGNMENT_CENTER,
  HEADER_ALIGNMENT_LEFT,
  HEADER_ALIGNMENT_RIGHT,
  HEADER_CSS,
  ROWS_PER_PAGE,
  StdFeeDetailHeaders,
  TABLE_HEADER_CSS,
  licenseTypes,
} from "../../../../../utils/constants";
import Sidebar from "../../../../Layouts/Sidebar";
import { Title } from "../../../../../stories/Title/Title";
import Input from "../../../../../stories/Input/Input";
import {
  getHeaderRowStyle,
  getModifiedScrollHeight,
  handleFormEvent,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../../styles/DatagridStyles";
import useToken from "../../../../../customhooks/useToken";
import { useNavigate, useParams } from "react-router-dom";
import {
  ColumnVisibilityFor,
  Direction,
  ExcelAlignment,
  ExcelPageHeader,
  SortBy,
} from "../../../../../utils/Enum.types";
import { Button } from "../../../../../stories/Button/Button";
import StudentTotalCount from "../Components/StudentsTotalCount";
import { useLazyQuery } from "@apollo/client";
import { GetStdDemand } from "../../../../../queries/students/list";
import { PageInfo } from "../../../Chat/Feeds/Types";
import PayOnline from "../../../../../images/PayOnline.svg";
import Copy from "../../../../../images/Copy_Link_White.svg";
import Eduate from "../../../../../images/EduateLogo.svg";

import { AppContext } from "../../../../../context/context";
import StudentTransactionIndex from "./StudentTransactionIndex";
import { payloadTypes } from "../../../../../context/reducer";
import AllTransactionsIndex from "./AllTransactionsIndex";
import { CopyModalStyles, StudentModalStyles } from "../../../../styles/ModalStyles";
import CopyLink from "../../../Chat/FeeDetails/CopyLink";

import ExcelJS from "exceljs";
import { Cell } from "exceljs";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import useInstLogoDetails from "../../../../../customhooks/useInstLogoDetails";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import useServerDateandTime from "../../../../../customhooks/useServerDateandTimeWithoutToken";

export interface GetStdDemandVars {
  token: string;
  inst_id: string;
  after: string | null;
  first: number;
  orderBy: {
    direction: Direction;
    field: SortBy;
  };
  name: string;
}

export interface GetStdDemandData {
  GetStdDemand: GetStdDemandList;
}

export interface GetStdDemandList {
  totalCount: number;
  pageInfo: PageInfo;
  edges: GetStdDemandListEdges[];
}

export interface GetStdDemandListEdges {
  node: MsgAcctStdDemand;
  cursor: string;
}

export interface MsgAcctStdDemand {
  id: string;
  std_name: string;
  std_id: string;
  std_roll_no: string;
  std_father_name: string;
  std_mother_name: string;
  std_place: string;
  class_desc: string;
  mobile_no: string;
  fee_total_demand: number;
  fee_total_paid: number;
  fee_balance: number;
  inst_id: string;
}
const FeeDetails = () => {
  const tableClasses = useDataGridStyles();
const navigate=useNavigate()
  const [searchData, setSearchData] = useState("");
  const [students, setStudents] = useState<GetStdDemandListEdges[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number | null>(ROWS_PER_PAGE);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [copyLinkModal, setCopyLinkModal] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [perStudentOnlineTransactions, setPerStudentOnlineTransactions] =
    useState(false);
  const [allTransactionsModal, setAllTransactionsModal] = useState(false);

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [pdfFlag, setPdfFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);

  const { token } = useToken();
  const { instId } = useParams();

  const { InstLogo } = useInstLogoDetails();
  const { InstFormData } = useInstDetails();

  const { serverDate } = useServerDateandTime();

  const { state, dispatch } = useContext(AppContext);
  //   const { } = useAct

  const [GetStdDemandList, { data, fetchMore, loading }] = useLazyQuery<
    GetStdDemandData,
    GetStdDemandVars
  >(GetStdDemand, {
    variables: {
      token,
      after: null,
      first: ROWS_PER_PAGE,
      inst_id: instId!,
      name: searchData,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.STD_NAME,
      },
    },
  });

  const handleClear = () => {
    setSearchData("");
  };

  useEffect(() => {
    if (token) {
      GetStdDemandList();
    }
  }, [token, instId, GetStdDemandList, searchData, searchData]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    {
      field: "student_id",
      headerName: "Student Id",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-adm-no",
    },
    {
      field: "std_roll_no",
      headerName: "Roll No",
      cellClassName: "td-adm-no",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "std_name",
      headerName: "Name",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "",
      flex: 1,
    },
    {
      field: "std_father_name",
      headerName: "Father Name",
      cellClassName: "td-name",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "std_mother_name",
      headerName: "Mother Name",
      cellClassName: "td-name",
      headerAlign: HEADER_TEXT_ALIGN,
    },

    {
      field: "std_place",
      headerName: "Place",
      cellClassName: "td-desc",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "class_desc",
      headerName: "Class Description",
      cellClassName: "td-desc",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "mobile_no",
      headerName: "Mobile No",
      cellClassName: "td-mobile",
      headerAlign: HEADER_TEXT_ALIGN,
    },
    {
      field: "fee_total_demand",
      headerName: "Demand",
      cellClassName: "td-amount",
      headerAlign: HEADER_TEXT_ALIGN,
      align:"right"
    },
    {
      field: "fee_total_paid",
      headerName: "Paid",
      cellClassName: "td-amount",
      headerAlign: HEADER_TEXT_ALIGN,
      align:"right"
    },
    {
      field: "fee_balance",
      headerName: "Balance",
      cellClassName: "td-amount",
      headerAlign: HEADER_TEXT_ALIGN,
      align:"right"
    },
  ];

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStdDemand.edges;
                const pageInfo = fetchMoreResult.GetStdDemand.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStdDemand.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStdDemand: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStdDemand.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },
    // eslint-disable-next-line
    [rows]
  );
  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStdDemand.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);
        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            student_id: node.std_id,
            std_name: node.std_name,
            std_roll_no: node.std_roll_no,
            std_father_name: node.std_father_name,
            std_mother_name: node.std_mother_name,
            std_place: node.std_place,
            class_desc: node.class_desc,
            mobile_no: node.mobile_no,
            fee_total_demand: node.fee_total_demand,
            fee_total_paid: node.fee_total_paid,
            fee_balance: node.fee_balance,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            student_id: node.std_id,
            std_name: node.std_name,
            std_father_name: node.std_father_name,
            std_mother_name: node.std_mother_name,
            std_roll_no: node.std_roll_no,

            std_place: node.std_place,
            class_desc: node.class_desc,
            mobile_no: node.mobile_no,
            fee_total_demand: node.fee_total_demand,
            fee_total_paid: node.fee_total_paid,
            fee_balance: node.fee_balance,
          }))
        );
      }
      setEndCursor(data.GetStdDemand.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);

  const handleCellClick = (params: GridCellParams) => {
    if (params.field === "std_name") {
      dispatch({
        type: payloadTypes.SET_STD_DEMAND_ID,
        payload: {
          stdDemandId: params.row.stdId,
        },
      });
      setPerStudentOnlineTransactions(!perStudentOnlineTransactions);
    }
  };
  const fieldCol = [
    { title: "Sl", field: "Slno" },
    { title: "Student Id", field: "StudentId" },
    { title: "Name", field: "Name" },
    { title: "Father Name", field: "FatherName" },
    { title: "Mother Name", field: "MotherName" },
    { title: "Roll No", field: "RollNo" },
    { title: "Place", field: "Place" },
    { title: "Class Description", field: "ClassDescription" },
    { title: "Mobile No", field: "MobileNumber" },
    { title: "Demand", field: "Demand" },
    { title: "Paid", field: "Paid" },
    { title: "Balance", field: "Balance" },
  ];
  const downloadPdf = () => {
    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };
    fetch(InstLogo.defaultLogo)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          fetch(Eduate)
            .then((response) => {
              return response.blob();
            })
            .then((blob2) => {
              getBase64(blob2, (result2) => {
                const pageWidth = 210; // A4 width in mm
                const pageHeight = 297; // A4 height in mm

                const doc = new jsPDF("portrait", "mm", [
                  pageWidth,
                  pageHeight,
                ]);
                doc.setFont("Helvetica", "bold");
                let i = 0;
                var totalPages = doc.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                  doc.setFontSize(15);
                  const startY = 10 + (i - 1) * 20;
                  doc.setFillColor(255, 255, 255);
                  doc.rect(
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight(),
                    "F"
                  );

                  doc.setTextColor(0, 0, 0);
                  doc.text(`${InstFormData.inst_name}`, pageWidth / 2, startY, {
                    align: "center",
                  });

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(11);
                  doc.text(
                    `${InstFormData.inst_address}`,
                    pageWidth / 2,
                    startY + 7,
                    { align: "center" }
                  );

                  doc.setFont("Times New Roman", "normal");
                  doc.setFontSize(11);
                  doc.text(
                    `${InstFormData.inst_place},${InstFormData.inst_pin}`,
                    pageWidth / 2,
                    startY + 12,
                    { align: "center" }
                  );
                  doc.setFontSize(12);
                  doc.text(
                    `FY:${
                      state.ActiveFinYr
                        ? state.ActiveFinYr.fin_yr
                        : EMPTY_STRING
                    }`,
                    15,
                    startY + 21
                  );
                  doc.setFontSize(12);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(`Parents List`, pageWidth / 2, startY + 21, {
                    align: "center",
                  });
                  doc.setFontSize(12);
                  doc.text(
                    `As on date:${toStandardDate(serverDate)}`,
                    155,
                    startY + 21
                  );
                  doc.addImage(result, "JPEG", 15, 5, 21, 21);
                  // if (configLogo) {
                  doc.addImage(result2, "JPEG", 250, 5, 20, 20);
                  // }
                }
                autoTable(doc, {
                  startY: 33,
                  bodyStyles: { valign: "top" },
                  theme: "striped",
                  columns: fieldCol.map((col) => ({
                    ...col,
                    dataKey: col.field,
                    styles: { fontSize: 18 },
                  })),
                  body: data
                    ? data.GetStdDemand.edges.map(({ node }, index) => ({
                        Sl: index + 1,
                        StudentId: node.std_id,
                        Name: node.std_name,
                        FatherName: node.std_father_name,
                        MotherName: node.std_mother_name,
                        RollNo: node.std_roll_no,
                        Place: node.std_roll_no,
                        ClassDescription: node.class_desc,
                        MobileNumber: node.mobile_no,
                        Demand: node.fee_total_demand,
                        Paid: node.fee_total_paid,
                        Balance: node.fee_balance,
                      }))
                    : [],
                  showFoot: "lastPage",
                  showHead: "everyPage",
                  useCss: true,
                  didDrawPage: function (data) {
                    // Footer
                    let str =
                      "" +
                      doc.getCurrentPageInfo().pageNumber +
                      "of" +
                      doc.getNumberOfPages();
                    doc.setFontSize(10);

                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    doc.text(str, data.settings.margin.left, pageHeight - 10);
                  },
                });

                doc.save(
                  `${InstFormData.inst_name} ${
                    state.ActiveFinYr ? state.ActiveFinYr.fin_yr : EMPTY_STRING
                  } `
                );
              });
            });
        });
      });
    setPdfFlag(false);
    setRowsPerPage(ROWS_PER_PAGE);
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Student Fee Details");
    const feeDetailsList = data
      ? data.GetStdDemand.edges.map(({ node }, index) => ({
          Sl: index + 1,
          StudentId: node.std_id,
          Name: node.std_name,
          FatherName: node.std_father_name,
          MotherName: node.std_mother_name,
          RollNo: node.std_roll_no,
          Place: node.std_roll_no,
          ClassDescription: node.class_desc,
          MobileNumber: node.mobile_no,
          Demand: node.fee_total_demand,
          Paid: node.fee_total_paid,
          Balance: node.fee_balance,
        }))
      : [];

    const dataRows = feeDetailsList
      ? feeDetailsList?.map((item) => [
          item.Sl,
          item.StudentId,
          item.Name,
          item.FatherName,
          item.MotherName,
          item.RollNo,
          item.Place,
          item.ClassDescription,
          item.MobileNumber,
          item.Demand,
          item.Paid,
          item.Balance,
        ])
      : [];

    worksheet.views = FROZEN_CELLS;
    const headerStyle = getHeaderRowStyle();
    worksheet.getRow(1).height = 31;
    worksheet.getRow(2).height = 20;
    worksheet.getRow(3).height = 20;
    worksheet.getRow(4).height = 22;
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 25;

    const getBase64 = (file: any, cb: (a: string) => void) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        cb(reader.result?.toString()!);
      };
    };

    fetch(Eduate)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        getBase64(blob, (result) => {
          const imageV = workbook.addImage({
            base64: result,
            extension: EDUATE_IMG_FORMAT,
          });
          worksheet.addImage(imageV, "L1:L3");

          workbook.xlsx.writeBuffer().then(() => {
            fetch(InstLogo.defaultLogo)
              .then((response) => {
                return response.blob();
              })
              .then((blob) => {
                getBase64(blob, (result) => {
                  const imageB = workbook.addImage({
                    base64: result,
                    extension: EDUATE_IMG_FORMAT,
                  });

                  worksheet.addImage(imageB, "A1:A3");
                  worksheet.mergeCells(1, 1, 1, dataRows[0].length);
                  const mergedCell: Cell = worksheet.getCell(FIRST_CELL);
                  mergedCell.value = InstFormData.inst_name;
                  mergedCell.fill = HEADER_CSS;
                  mergedCell.font = headerStyle[0].font;
                  mergedCell.alignment = HEADER_ALIGNMENT;
                  const mergedAddress: Cell = worksheet.getCell(A2_CELL);
                  mergedAddress.value = InstFormData.inst_address;
                  mergedAddress.fill = HEADER_CSS;
                  mergedAddress.font = ADDRESS_FONT;
                  mergedAddress.alignment = HEADER_ALIGNMENT;
                  worksheet.mergeCells("A2:L2");
                  const mergedPlace: Cell = worksheet.getCell(A3_CELL);
                  mergedPlace.value =
                    InstFormData.inst_place + "-" + InstFormData.inst_pin;
                  mergedPlace.fill = HEADER_CSS;
                  mergedPlace.font = ADDRESS_FONT;
                  mergedPlace.alignment = ADDRESS_ALIGNMENT;
                  worksheet.mergeCells("A3:L3");

                  const mergedHeader: Cell = worksheet.getCell(C4_CELL);
                  mergedHeader.value = "Student FeeDetail List";
                  mergedHeader.fill = FILE_NAME_CSS;
                  mergedHeader.font = FILE_NAME_FONT;
                  mergedHeader.alignment = HEADER_ALIGNMENT_CENTER;
                  worksheet.mergeCells("C4:E4");
                  const mergedDate: Cell = worksheet.getCell("F4");
                  mergedDate.value =
                    ExcelPageHeader.DATE + toStandardDate(serverDate);
                  mergedDate.fill = FILE_NAME_CSS;
                  mergedDate.font = FILE_NAME_FONT;
                  mergedDate.alignment = HEADER_ALIGNMENT_RIGHT;
                  worksheet.mergeCells("F4:L4");
                  const mergedYear: Cell = worksheet.getCell(A4_CELL);
                  mergedYear.value = state.ActiveFinYr
                    ? ExcelPageHeader.YEAR + state.ActiveFinYr
                    : ExcelPageHeader.YEAR;
                  mergedYear.fill = FILE_NAME_CSS;
                  mergedYear.font = FIN_YEAR_FONT;
                  mergedYear.alignment = HEADER_ALIGNMENT_LEFT;
                  worksheet.mergeCells("A4:B4");
                  let Char = FIRST_INDEX;

                  for (let i = 0; i < StdFeeDetailHeaders.length; i++) {
                    Char = String.fromCharCode(Char.charCodeAt(0) + 1);

                    const rowData: Cell = worksheet.getCell(Char + 5);
                    rowData.value = StdFeeDetailHeaders[i];
                    rowData.fill = TABLE_HEADER_CSS;
                    rowData.border = BORDER_DATA;
                    rowData.font = ACC_HEADER_FONT;
                    rowData.alignment = {
                      horizontal: ExcelAlignment.CENTER,
                    };
                  }
                  dataRows!.forEach((rowData) => {
                    const row = worksheet.addRow(rowData);
                    row.eachCell({ includeEmpty: true }, (cell) => {
                      cell.alignment = { horizontal: ExcelAlignment.LEFT };
                    });
                  });

                  workbook.xlsx.writeBuffer().then((buffer: ArrayBuffer) => {
                    const blob = new Blob([buffer], {
                      type: BLOB_TYPE,
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement(ELEMENT);
                    link.href = url;
                    link.setAttribute(DOWNLOAD, "Student Fee Details");
                    document.body.appendChild(link);
                    link.click();
                  });
                  setExcelFlag(false);
                  setRowsPerPage(ROWS_PER_PAGE);
                });
              });
          });
        });
      });
  };

  useEffect(() => {
    if (excelFlag && rowsPerPage === null && !loading) downloadExcel();
    if (pdfFlag && rowsPerPage === null && !loading) downloadPdf();
    // eslint-disable-next-line
  }, [excelFlag, pdfFlag, rowsPerPage, loading]);
  

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      std_type: false,
      std_mother_name: false,
      std_sex: false,
      std_doa: false,
      std_dob: false,
      std_roll_no: false,
      dept: false,
      branch: false,
      class: false,
      sem: false,
      sec: false,
      status: false,
      category: false,
    });

    useEffect(() => {
      const savedVisibilityModel = localStorage.getItem(
        ColumnVisibilityFor.FEE_DETAILS
      );
      if (savedVisibilityModel) {
        setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
      }
    }, []);
  
    useEffect(() => {
      localStorage.setItem(
        ColumnVisibilityFor.FEE_DETAILS,
        JSON.stringify(columnVisibilityModel)
      );
    }, [columnVisibilityModel]);
  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="student-list">
        <Sidebar />
        <div className="student-list__frame">
          <Title>Student Fee Details</Title>

          <div className="student-list__filters row g-0">
            <div className="col-2">
              <Input
                id="search"
                type="text"
                placeholder="Search "
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchData(e.target.value);
                }}
                value={searchData}
                onKeyDown={handleFormEvent}
              />{" "}
            </div>
            <div className="col"></div>
            <div className="col-1">
              <Button
                mode="clear"
                className="student-list__button"
                onClick={handleClear}
              />
            </div>
          </div>
        
          <div className={`student-list__tableblock ${tableClasses.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              disableRowSelectionOnClick
              onCellClick={handleCellClick}
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </div>
          <div className="row g-0">
            <div className="col">
            <Button
            mode="pdf"
            onClick={() => {
              setRowsPerPage(null);
              setPdfFlag(true);
            }}
          />
          <Button
            mode="download-excel"
            onClick={() => {
              setRowsPerPage(null);
              setExcelFlag(true);
            }}
          />
          <Button onClick={() => setCopyLinkModal(!copyLinkModal)}>
            <img src={Copy} alt="/" /> Copy Link for Payment
          </Button>
          <Button
            onClick={() => setAllTransactionsModal(!allTransactionsModal)}
          >
            <img src={PayOnline} alt="/" /> All Transaction History
          </Button>
          <Button mode="back" onClick={()=>navigate(-1)}/>
            </div>
            <div className="col-3 flex-end">
            <StudentTotalCount
            totalCount={data ? data.GetStdDemand.totalCount : 0}
          />
            </div>
          </div>
    
        </div>
      </div>

      <ReactModal isOpen={perStudentOnlineTransactions} ariaHideApp={false} style={StudentModalStyles}>
        <StudentTransactionIndex setModal={setPerStudentOnlineTransactions} />
      </ReactModal>
      <ReactModal isOpen={allTransactionsModal} ariaHideApp={false} style={StudentModalStyles}>
        <AllTransactionsIndex setModal={setAllTransactionsModal} />
      </ReactModal>
      <ReactModal
        isOpen={copyLinkModal}
        ariaHideApp={false}
        style={CopyModalStyles}
      >
        <CopyLink setModalFlag={setCopyLinkModal} />
      </ReactModal>
    </>
  );
};

export default FeeDetails;
