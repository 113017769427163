import { gql } from "@apollo/client";

export const DepartmentByNode = gql`
  query DepartmentDetailsByNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstDept {
        id
        inst_id
        dept_desc
        dept_short_desc
      }
    }
  }
`;
