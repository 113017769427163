import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Direction, InstitutionDetails } from "../utils/Enum.types";

import { GetNotAllocatedIds } from "../queries/institution/common/index";
import useInstitutionConfiguration from "./useInstitutionConfiguration";
import useToken from "./useToken";
import { responseType } from "../utils/Types";
import { GetMstInstDepts } from "../queries/institution/department/query";
import {
  BranchListData,
  BranchListVars,
  BranchQueryType,
  ClassListData,
  ClassListVars,
  ClassQueryType,
  DepartmentListDataByInstId,
  DepartmentListVarsByInstId,
  MsgMstInstBranchOrderField,
  MsgMstInstClassOrderField,
  MsgMstInstDeptOrderField,
  MsgMstInstSectionOrderField,
  MsgMstInstSemesterOrderField,
  SectionListData,
  SectionListVars,
  SectionQueryType,
  SemesterListData,
  SemesterListVars,
  SemesterQueryType,
} from "../utils/Query.Types";
import { EMPTY_STRING } from "../utils/constants";
import { GetMstInstBranches } from "../queries/institution/branch/query";
import { GetMstInstClasses } from "../queries/institution/class/query";
import { GetMstInstSemesters } from "../queries/institution/semester/query";
import { GetMstInstSections } from "../queries/institution/sections/query";
export interface NotAllocatedIdsDetails {
  dept_id: number;
  branch_id: number;
  class_id: number;
  semester_id: number;
  section_id: number;
}
export interface NotAllocatedIdsData {
  GetNotAllocatedIds: NotAllocatedIdsDetails;
}
export interface GetNotAllocatedIdsVars {
  token: string;
  inst_id: string;
  table: string;
  table_id: number;
}
const useDropdownData = (
  selectedDeptId: number,
  selectedbranchId: number,
  selectedClassId: number,
  selectedSemester: number,
  selectedSection?: number
) => {
  const { instId } = useParams();
  const { token } = useToken();
  const [departmentId, setDeptId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [classId, setClassId] = useState(0);
  const [semesterId, setSemesterId] = useState(0);
  const [sectionId, setSectionId] = useState(0);
  const [departmentDropDown, setDepartmentDropDown] = useState<responseType[]>(
    []
  );
  const [branchDropDown, setBranchDropDown] = useState<responseType[]>([]);
  const [classDropDown, setClassDropDown] = useState<responseType[]>([]);
  const [semesterDropDown, setSemesterDropDown] = useState<responseType[]>([]);
  const [sectionDropDown, setSectionDropDown] = useState<responseType[]>([]);
  const [DepartmentsByInstId] = useLazyQuery<
    DepartmentListDataByInstId,
    DepartmentListVarsByInstId
  >(GetMstInstDepts, {
    variables: {
      inst_id: instId!,
      after: null,
      first: null,
      name: EMPTY_STRING,
      orderBy: {
        direction: Direction.ASC,
        field: MsgMstInstDeptOrderField.DEPT_IDX,
      },
      token,
    },
  });
  const [GetBranchesByDept] = useLazyQuery<BranchListData, BranchListVars>(
    GetMstInstBranches
  );
  const [GetClassesByBranch] = useLazyQuery<ClassListData, ClassListVars>(
    GetMstInstClasses
  );

  const [GetSemesterByClass] = useLazyQuery<SemesterListData, SemesterListVars>(
    GetMstInstSemesters
  );
  const [GetSectionBySemester] = useLazyQuery<SectionListData, SectionListVars>(
    GetMstInstSections
  );
  const [GetNotallocted, { data: notAllocatedIdData }] = useLazyQuery<
    NotAllocatedIdsData,
    GetNotAllocatedIdsVars
  >(GetNotAllocatedIds);
  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
  } = useInstitutionConfiguration();

  useEffect(() => {
    if (selectedDeptId >= 0) {
      setDeptId(selectedDeptId);
    }
    if (selectedbranchId >= 0) {
      setBranchId(selectedbranchId);
    }
    if (selectedClassId >= 0) {
      setClassId(selectedClassId);
    }
    if (selectedSemester >= 0) {
      setSemesterId(selectedSemester);
    }
  }, [selectedDeptId, selectedbranchId, selectedClassId, selectedSemester]);

  const GetBranches = (dept_id: number) => {
    if (selectedDeptId === 0 && USE_DEPARTMENT_KEY) {
      setBranchDropDown([]);
      setClassDropDown([]);
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (dept_id) {
      GetBranchesByDept({
        variables: {
          //sometimes the departmentId remains 0 because the useEffect does not re-run so we will always have the value
          token,
          id: dept_id,
          after: null,
          first: null,
          name: EMPTY_STRING,
          orderBy: {
            direction: Direction.ASC,
            field: MsgMstInstBranchOrderField.BRANCH_IDX,
          },
          query_type: BranchQueryType.BRANCH_BY_DEPT_ID,
        },
      }).then(({ data }) => {
        if (data && data.GetMstInstBranches) {
          const filteredBranch = data?.GetMstInstBranches.edges
            .filter(({ node: { id } }) => id !== null)
            .map(({ node: branch }) => ({
              label: branch?.branch_desc,
              value: Number(branch.id),
            }));
          setBranchDropDown(filteredBranch);
        }
      });
    }
  };
  const GetClasses = (branch_id: number) => {
    if (selectedbranchId === 0 && USE_BRANCH_KEY) {
      setClassDropDown([]);
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (branch_id) {
      GetClassesByBranch({
        variables: {
          id: branch_id,
          after: null,
          first: null,
          name: EMPTY_STRING,
          orderBy: {
            direction: Direction.ASC,
            field: MsgMstInstClassOrderField.CLASS_IDX,
          },
          query_type: ClassQueryType.CLASS_BY_BRANCH_ID,
          token,
        },
      }).then(({ data }) => {
        if (data && data.GetMstInstClasses) {
          const filteredClass = data?.GetMstInstClasses.edges
            ?.filter(({ node: { id } }) => id !== null)
            .map(({ node: branch }) => ({
              label: branch.class_desc,
              value: branch.id,
            }));

          setClassDropDown(filteredClass);
        }
      });
    }
  };
  const GetSemester = (class_id: number) => {
    if (selectedClassId === 0 && USE_CLASS_KEY) {
      setSemesterDropDown([]);
      setSectionDropDown([]);
    } else if (class_id) {
      GetSemesterByClass({
        variables: {
          id: class_id!,
          token,
          after: null,
          first: null,
          name: EMPTY_STRING,
          orderBy: {
            direction: Direction.ASC,
            field: MsgMstInstSemesterOrderField.SEM_IDX,
          },
          query_type: SemesterQueryType.SEMESTER_BY_CLASS_ID,
        },
      }).then(({ data }) => {
        if (data && data.GetMstInstSemesters) {
          const filteredSemester = data.GetMstInstSemesters.edges
            .filter(({ node: { id } }) => id !== null)
            .map(({ node: sem }) => ({ label: sem.sem_desc, value: sem.id }));
          setSemesterDropDown(filteredSemester);
        }
      });
    }
  };
  const GetSections = (sem_id: number) => {
    if (selectedSemester === 0 && USE_SEMESTER_KEY) {
      setSectionDropDown([]);
    } else if (sem_id) {
      GetSectionBySemester({
        variables: {
          id: sem_id,
          token,
          after: null,
          first: null,
          name: EMPTY_STRING,
          orderBy: {
            direction: Direction.ASC,
            field: MsgMstInstSectionOrderField.SECTION_IDX,
          },
          query_type: SectionQueryType.SECTION_BY_SEMESTER_ID,
        },
      }).then(({ data }) => {
        if (data && data.GetMstInstSections) {
          const filteredSection = data.GetMstInstSections.edges
            .filter(({ node: { id } }) => id !== null)
            .map(({ node: section }) => ({
              label: section.section_desc,
              value: section.id,
            }));
          setSectionDropDown(filteredSection);
        }
      });
    } else {
      setSectionDropDown([]);
    }
  };

  useEffect(() => {
    // dept false
    if (token) {
      if (departmentId === 0) {
        if (USE_DEPARTMENT_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: instId!,
              table: InstitutionDetails.TABLE_NONE,
              table_id: 0,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              // setting notallocated deptid
              if (data.GetNotAllocatedIds.dept_id) {
                setDeptId(data.GetNotAllocatedIds.dept_id);
                GetBranches(data.GetNotAllocatedIds.dept_id);
              }
            }
          });
        } else {
          DepartmentsByInstId().then(({ data }) => {
            if (data && data.GetMstInstDepts) {
              const filteredDepartment = data.GetMstInstDepts.edges
                .filter(({ node }) => node !== null)
                .map(({ node: dept }) => ({
                  label: dept.dept_desc,
                  value: dept.id,
                }));
              setDepartmentDropDown(filteredDepartment);
            }
          });
        }
      }

      // branch false
      if (branchId === 0 && departmentId !== 0) {
        if (USE_BRANCH_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: instId!,
              table: InstitutionDetails.DEPARTMENT,
              table_id: departmentId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetNotAllocatedIds.branch_id) {
                setBranchId(data.GetNotAllocatedIds.branch_id);
                GetClasses(data.GetNotAllocatedIds.branch_id);
              }
            }
          });
        } else {
          GetBranches(departmentId);
        }
      } else {
        GetBranches(departmentId);
      }

      // class false
      if (classId === 0 && branchId !== 0) {
        if (USE_CLASS_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: instId!,
              table: InstitutionDetails.BRANCH,
              table_id: branchId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetNotAllocatedIds.class_id) {
                setClassId(data.GetNotAllocatedIds.class_id);
                GetSemester(data.GetNotAllocatedIds.class_id);
              }
            }
          });
        }
        // true class
        else {
          GetClasses(branchId);
          setSemesterDropDown([]);
        }
      } else {
        GetClasses(branchId);
      }
      if (semesterId === 0 && classId !== 0) {
        // //semseter false
        if (USE_SEMESTER_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: instId!,
              table: InstitutionDetails.CLASS,
              table_id: classId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              if (data.GetNotAllocatedIds.semester_id) {
                setSemesterId(data.GetNotAllocatedIds.semester_id);
                GetSections(data.GetNotAllocatedIds.semester_id);
              }
            }
          });
        }
        // true semester
        else {
          GetSemester(classId);
          setSectionDropDown([]);
        }
      } else {
        GetSemester(classId);
      }
      //section
      if (
        semesterId !== 0 &&
        departmentId !== 0 &&
        branchId !== 0 &&
        classId !== 0
      ) {
        if (USE_SECTION_KEY === false) {
          GetNotallocted({
            variables: {
              inst_id: instId!,
              table: InstitutionDetails.SEMESTER,
              table_id: semesterId,
              token,
            },
          }).then(({ data }) => {
            if (data) {
              setSectionId(data.GetNotAllocatedIds.section_id);
            }
          });
        }

        // true section
        else {
          setSectionId(selectedSection!);
          GetSections(semesterId);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    instId,
    token,
    selectedDeptId,
    selectedbranchId,
    selectedSemester,
    selectedClassId,
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SEMESTER_KEY,
    USE_SECTION_KEY,
    DepartmentsByInstId,
    GetBranchesByDept,
    GetClassesByBranch,
    GetNotallocted,
    GetSectionBySemester,
    GetSemesterByClass,
    branchId,
    classId,
    departmentId,
    semesterId,
    selectedSection,
    sectionId,
    notAllocatedIdData,
  ]);

  return {
    departmentDropDown,
    branchDropDown,
    classDropDown,
    semesterDropDown,
    sectionDropDown,
    // we are not sendng the depatmentId because, it's already notallocated depending on instId
    // this will return not allocated ids if the flg of any master configuration is disabled, else it will return the same id which we are passing thorugh the props.
    masterIds: { branchId, classId, semesterId, sectionId },
  };
};

export default useDropdownData;
