import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment, Tab, Tabs } from "@mui/material";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  getIdToken,
  getIdTokenResult,
} from "firebase/auth";
import { AppContext } from "../../context/context";
import React, { useContext, useEffect, useRef, useState } from "react";
import EduateLogo from "../../images/E-Sandesh.svg";
import { Button } from "../../stories/Button/Button";
import { payloadTypes } from "../../context/reducer";
import Visibility from "@mui/icons-material/Visibility";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Label } from "../../stories/Label/Label";
import { TabPanel, a11yProps } from "../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Keys } from "../../utils/Enum.keys";
import { useLazyQuery } from "@apollo/client";
import { GetAuthDetailsByToken } from "../../queries/DetailsByToken";
import {
  GetAuthDetailsByTokenData,
  GetAuthDetailsByTokenVars,
} from "../../utils/Query.Types";
import { ValidateMobileNumber } from "../../queries/institution/queries/byId";

const Login = () => {
  const auth = getAuth();

  const { dispatch, state } = useContext(AppContext);
  const [verificationCode, setVerificationCode] = useState("");
  const passwordRef = useRef<HTMLInputElement>(null);
  const saveRef = useRef<HTMLButtonElement>(null);
  const mobileRef = useRef<HTMLButtonElement>(null);
  const loginRef = useRef<HTMLButtonElement>(null);
  const [requestOTP, setRequestOTP] = useState(false);

  const [mobFlag, setMobFlag] = useState(false);

  const [GetAuthDetails] = useLazyQuery<
    GetAuthDetailsByTokenData,
    GetAuthDetailsByTokenVars
  >(GetAuthDetailsByToken);
  const [confirmationResult, setConfirmationResult] = useState<any | null>(
    null
  );

  const [MobileNoStatusFlag] = useLazyQuery(ValidateMobileNumber);

  const [value, setValue] = useState(0);
  const [mobileNo, setMobileNo] = useState("");

  const [authCred, setAuthCred] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedAuthCred = { ...authCred };

    if (/^\S+@\S+\.\S+$/.test(value)) {
      updatedAuthCred.email = value;
    } else {
      updatedAuthCred[name as keyof typeof authCred] = value;
    }
    setAuthCred(updatedAuthCred);
  };
  const handleSubmit = () => {
    signInWithEmailAndPassword(auth, authCred.email, authCred.password)
      .then((userCredential) => {
        getIdToken(userCredential.user).then((token) => {
          GetAuthDetails({
            variables: {
              token,
            },
          }).then(({ data, error }) => {
            if (data && data.GetAuthDetailsByToken) {
              dispatch({
                type: payloadTypes.SET_USER,
                payload: { user: userCredential.user },
              });

              getIdTokenResult(userCredential.user).then((IdTokenResult) => {
                const { claims } = IdTokenResult;

                console.log(claims, "Claims");

                dispatch({
                  type: payloadTypes.SET_CLAIMS,
                  payload: { claims },
                });
              });
            }
            if (error) {
              alert(error.message);
              return;
            }
          });
        });
      })
      .catch((error) => {
        // Customize user-friendly error messages
        let errorMessage = "Error signing in. Please try again.";

        if (error.code === "auth/user-not-found") {
          errorMessage =
            "User not found. Please check your email and try again.";
        } else if (error.code === "auth/wrong-password") {
          errorMessage = "Incorrect password. Please try again.";
        } else if (error.code === "auth/too-many-requests") {
          errorMessage =
            "Too many unsuccessful attempts. Please try again later.";
        }
        alert(errorMessage);
        console.error("Error signing in:", error);
      });
  };

  useEffect(() => {
    if (mobFlag) {
      MobileNoStatusFlag({
        variables: {
          mobile_number: `+${mobileNo}`,
        },
      }).then(({ data }) => {
        if (data && data.ValidateMobileNumber === true) {
          setupReCap();
          setRequestOTP(!requestOTP);
        } else {
          alert("Mobile Number has not been registered");
        }
      });
    } // eslint-disable-next-line
  }, [mobFlag, MobileNoStatusFlag, mobileNo]);

  const setupReCap = () => {
    const applicationVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        callback: () => {
          sendOtp();
        },
        defaultCountry: "IN",
      }
    );

    signInWithPhoneNumber(auth, `+${mobileNo}`, applicationVerifier)
      .then((confirmResult) => {
        setRequestOTP(!requestOTP);
        setConfirmationResult(confirmResult);
      })
      .catch((error) => {
        console.error("Error requesting OTP:", error);
        let errorMessage = "Error requesting OTP. Please try again.";
        if (error.code === "auth/invalid-phone-number") {
          errorMessage = "Invalid phone number. Please enter a valid one.";
        } else if (error.code === "auth/quota-exceeded") {
          errorMessage = "Quota exceeded. Please try again later.";
        }

        alert(errorMessage);
      });
  };

  const sendOtp = () => {
    if (confirmationResult && verificationCode) {
      confirmationResult
        .confirm(verificationCode)
        .then((userCredential: any) => {
          dispatch({
            type: payloadTypes.SET_USER,
            payload: { user: userCredential.user },
          });
        })
        .catch((error: any) => {
          console.error("Error confirming OTP:", error);

          let errorMessage = "Error confirming OTP. Please try again.";

          if (error.code === "auth/invalid-verification-code") {
            errorMessage =
              "Invalid verification code. Please enter a valid one.";
          } else if (error.code === "auth/session-expired") {
            errorMessage = "Session expired. Please request a new OTP.";
          }
          alert(errorMessage);
        });
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const theme = useTheme();

  const handleNumberChange = (mobileNo: string) => {
    setMobileNo(mobileNo);
  };

  return (
    <>
      <div className="login">
        <div className="login__left-block">
          <div className="login__left-block--container">
            <div className="login__left-block--container--image"></div>
            {/* <div className="login__left-block--container--curve"></div> */}
          </div>
        </div>

        <div className="login__right-block">
          <div className="login__right-block--container">
            <div className="login__right-block--container--data">
              <div className="login__right-block--container--data--title">
                <img className="logo" src={EduateLogo} alt="logo" />
                <h4>Sandesh</h4>
              </div>
              <b>Login to Your Account</b>
              <div className="login__block">
                <div className="login__block--tabs">
                  <Tabs value={value} onChange={handleTabChange}>
                    <Tab label="Email" value={0} {...a11yProps(0)} />

                    <Tab label="Mobile No." value={1} {...a11yProps(1)} />
                  </Tabs>
                </div>
                <div className="login__block--panel">
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Label> Email</Label>
                    <TextField
                      required
                      id="email"
                      name="email"
                      className="loginfield"
                      autoFocus
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          passwordRef.current?.focus();
                        }
                      }}
                    />
                    <Label>Password *</Label>
                    <TextField
                      required
                      value={authCred.password}
                      className="loginfield"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      inputRef={passwordRef!}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          saveRef.current?.focus();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      onClick={() => {
                        if (verificationCode) {
                          sendOtp();
                        } else handleSubmit();
                      }}
                      buttonref={saveRef!}
                      mode="sign-in"
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Label>Mobile No.</Label>
                    <PhoneInput
                      country={"in"}
                      value={mobileNo}
                      onKeyDown={(e) => {
                        if (e.key === Keys.ENTER) {
                          e.preventDefault();
                          mobileRef.current?.focus();
                        }
                      }}
                      onChange={handleNumberChange}
                      inputProps={{
                        required: true,
                      }}
                    />

                    {!requestOTP ? (
                      <>
                        <div id="recaptcha-container"></div>
                        <br />
                        <Button
                          onClick={() => {
                            setMobFlag(!mobFlag);
                          }}
                          buttonref={mobileRef!}
                          mode="otp"
                        />
                      </>
                    ) : (
                      <>
                        <Label>Enter OTP</Label>
                        <TextField
                          required
                          name="Otp"
                          value={verificationCode}
                          className="loginfield"
                          onChange={(e) => setVerificationCode(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === Keys.ENTER) {
                              e.preventDefault();
                              loginRef.current?.focus();
                            }
                          }}
                        />
                        <Button
                          onClick={() => {
                            if (verificationCode) {
                              sendOtp();
                            } else handleSubmit();
                          }}
                          buttonref={loginRef!}
                          mode="sign-in"
                        />
                      </>
                    )}
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
