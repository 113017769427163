import { gql } from "@apollo/client";

export const AddInstSection = gql`
  mutation AddInstSection(
    $input: CreateMsgMstInstSectionInput!
    $token: String!
    $user_details: SubscribedUser!
  ) {
    AddInstSection(token: $token, input: $input, user_details: $user_details)
  }
`;
