import { gql } from "@apollo/client";

export const GetSwConfigReferenceDataByKey = gql`
  query GetSwConfigReferenceDataByKey($token: String!, $config_key: String!) {
    GetSwConfigReferenceDataByKey(token: $token, config_key: $config_key) {
      id
      list_item_key
      list_item_no_of_items
      list_item_list
    }
  }
`;

export const GetSwConfigReferenceData = gql`
  query GetSwConfigReferenceData($token: String!) {
    GetSwConfigReferenceData(token: $token) {
      id
      list_item_key
      list_item_no_of_items
      list_item_list
    }
  }
`;

export const GetSwEmpConfigVariables = gql`
  query GetSwEmpConfigVariables(
    $token: String!
    $inst_id: ID!
    $pr_emp_id: ID!
    $input: EmpConfigQuery!
  ) {
    GetSwEmpConfigVariables(
      token: $token
      inst_id: $inst_id
      pr_emp_id: $pr_emp_id
      input: $input
    ) {
      id
      config_key
      config_form_label_caption
      config_key_used_for_module
      config_key_data_storage_type
      config_depends_on_primary_list_key
      config_depends_on_secondary_list_key
      config_primary_list_key
      config_secondary_list_key
      config_boolean_value
      config_integer_value
      config_double_value
      config_string_value
      config_string_value_2
      config_key_show_index
      inst_id
      config_secondary_list_caption
    }
  }
`;

export const GetInstConfigNames = gql`
  query GetInstConfigNames(
    $token: String!
    $entry_level: String!
    $entry_id: ID!
  ) {
    GetInstConfigNames(
      token: $token
      entry_level: $entry_level
      entry_id: $entry_id
    ) {
      entry_level
      entry_id
      dept_desc
      dept_id
      branch_desc
      branch_id
      class_desc
      class_id
      semester_desc
      semester_id
      section_desc
      section_id
    }
  }
`;
