import React, { useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import useMasterTableJson, {
  TableColumn,
} from "../../../Masters/json/useTableJson";

import { useDataGridStyles } from "../../../../styles/DatagridStyles";
import Edit from "../../../../../images/EditProfile.svg";
import Delete from "../../../../../images/Delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  BranchListData,
  BranchListEdges,
  BranchListVars,
  BranchQueryType,
  MsgMstInstBranchOrderField,
} from "../../../../../utils/Query.Types";
import useToken from "../../../../../customhooks/useToken";
import { EMPTY_STRING } from "../../../../../utils/constants";
import { Direction } from "../../../../../utils/Enum.types";
import { GetMstInstBranches } from "../../../../../queries/institution/branch/query";
import { InstDetailsProps } from "../Department/List";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
import useInstLabels from "../../../../../customhooks/useInstLabels";
import { GetClassNamesByBranchIds } from "../../../../../queries/institution/class/query/byId";
import { GetSemesterNamesByClassIds } from "../../../../../queries/institution/semester/query/byId";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { reOrderProcess } from "../../../../../utils/UtilFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
interface BranchListProps extends InstDetailsProps {
  localItemsState: {
    localItems: BranchListEdges[];
    setLocalItems: React.Dispatch<React.SetStateAction<BranchListEdges[]>>;
  };
}
const List = ({
  handleEdit,
  handleDelete,
  localItemsState,
  reOrder,
}: BranchListProps) => {
  const { Institutions } = useMasterTableJson();
  const navigate = useNavigate();
  const classes = useDataGridStyles();

  const { deptId } = useParams();
  const { token } = useToken();
  const { localItems, setLocalItems } = localItemsState;

  // eslint-disable-next-line
  const [items, setItems] = useState<BranchListEdges[]>([]);
  const { USE_CLASS_KEY, USE_SECTION_KEY, USE_SEMESTER_KEY } =
    useInstitutionConfiguration();

  const { branchLabel } = useInstLabels();
  const [GetBranches, { data, loading }] = useLazyQuery<
    BranchListData,
    BranchListVars
  >(GetMstInstBranches, {
    variables: {
      id: deptId!,
      token,
      after: null,
      first: null,
      name: EMPTY_STRING,
      query_type: BranchQueryType.BRANCH_BY_DEPT_ID,
      orderBy: {
        direction: Direction.ASC,
        field: MsgMstInstBranchOrderField.BRANCH_IDX,
      },
    },
  });

  const [ClassData] = useLazyQuery(GetClassNamesByBranchIds);
  const [SemesterData] = useLazyQuery(GetSemesterNamesByClassIds);

  useEffect(() => {
    if (token) {
      GetBranches();
    }
  }, [token, GetBranches]);

  useEffect(() => {
    if (data && !loading)
      setLocalItems(
        data.GetMstInstBranches.edges.filter(({ node: dept }) => dept != null)
      );
    // eslint-disable-next-line
  }, [data, loading]);
  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    setLocalItems((prev: BranchListEdges[]) => {
      const temp = [...prev];
      reOrderProcess(result, temp);
      setItems(temp);
      return temp;
    });
  };

  return (
    <>
      <Title variant="subtitle1">List of {branchLabel}</Title>
      <div className="inst-config__masters--tableblock">
        <TableContainer className="inst-config__masters--table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {reOrder ? <TableCell></TableCell> : null}
                {Institutions.InstDetails.filter(
                  ({ headerName }: TableColumn) =>
                    !(headerName === "Actions" && reOrder)
                ).map((th: TableColumn, index: React.Key) => {
                  return (
                    <React.Fragment key={index}>
                      <TableCell className={th.cellClassName}>
                        {th.headerName}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            </TableHead>

            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="droppable" direction="vertical">
                {(droppableProvided: DroppableProvided) => (
                  <TableBody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    {localItems.map(({ node: response }, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                        isDragDisabled={!reOrder}
                      >
                        {(
                          draggableProvided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => {
                          return (
                            <React.Fragment key={index}>
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                {reOrder ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    id="td-center"
                                    className="inst-config__masters--table--slno"
                                  >
                                    =
                                  </TableCell>
                                ) : null}
                                <TableCell
                                  id="td-center"
                                  {...draggableProvided.dragHandleProps}
                                  className="inst-config__masters--table--slno"
                                >
                                  {index + 1}
                                </TableCell>

                                {USE_CLASS_KEY ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    onClick={() =>
                                      navigate(`${response.id}/class`)
                                    }
                                    className="inst-config__masters--table--name"
                                  >
                                    {response?.branch_desc}
                                  </TableCell>
                                ) : USE_SEMESTER_KEY ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    onClick={async () => {
                                      const classResult = await ClassData({
                                        variables: {
                                          token,
                                          branch_id: [response.id!],
                                        },
                                      });
                                      navigate(
                                        `${response.id}/class/${classResult.data.GetClassNamesByBranchIds[0].id}/semester`
                                      );
                                    }}
                                  >
                                    {response?.branch_desc}
                                  </TableCell>
                                ) : USE_SECTION_KEY ? (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                    onClick={async () => {
                                      const classResult = await ClassData({
                                        variables: {
                                          branch_id: [response.id!],
                                          token,
                                        },
                                      });
                                      const semesterResult = await SemesterData(
                                        {
                                          variables: {
                                            token,
                                            class_id: [
                                              classResult.data
                                                .GetClassNamesByBranchIds[0]
                                                ?.id,
                                            ],
                                          },
                                        }
                                      );
                                      navigate(
                                        `${response.id}/class/${classResult.data.GetClassNamesByBranchIds[0].id}/semester/${semesterResult.data.GetSemesterNamesByClassIds[0].id}/section`
                                      );
                                    }}
                                  >
                                    {response.branch_desc}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    {response.branch_desc}
                                  </TableCell>
                                )}
                                {reOrder === false ? (
                                  <TableCell
                                    id="td-center"
                                    {...draggableProvided.dragHandleProps}
                                    className="inst-config__masters--table--actions"
                                  >
                                    <>
                                      <img
                                        src={Edit}
                                        alt="/"
                                        onClick={() => {
                                          handleEdit(response.id);
                                        }}
                                      />
                                      <img
                                        src={Delete}
                                        alt="/"
                                        onClick={() => {
                                          handleDelete(response.id);
                                        }}
                                      />
                                    </>
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </React.Fragment>
                          );
                        }}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default List;
