import React, {  useState } from "react";
import EduateLogo from "../../../../images/EduateLogo.svg";
import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { Label } from "../../../../stories/Label/Label";
import { useTheme } from "@mui/material/styles";
import Input from "../../../../stories/Input/Input";

import Fetch from "../../../../images/Fetch.svg";
import useInstDetails from "../../../../customhooks/useInstDetails";

import { useNavigate, useParams } from "react-router-dom";
import useMstInstLogoWithoutToken from "../../../../customhooks/useMstInstLogoWithoutToken";
import {
  GetEncodedValueData,
  GetEncodedValueVars,
} from "../../Chat/FeeDetails/FetchDetails";
import { GetEncodedValue } from "../../../../queries/payments/query";
import { useLazyQuery } from "@apollo/client";

const OnlinePayment = () => {
  const { instId } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { InstLogo } = useMstInstLogoWithoutToken();
  const { InstFormData } = useInstDetails();
  const [encodeValue, setEncodeValue] = useState("");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setEncodeValue("");
    setValue(newValue);
  };

  const [GetEncode] = useLazyQuery<GetEncodedValueData, GetEncodedValueVars>(
    GetEncodedValue
  );
  return (
    <>
      <div className="mobile-login">
        <div className="mobile-login__inst-details">
          <div className="mobile-login__inst-details--logo">
            {InstLogo.defaultLogo ? (
              <img src={InstLogo.defaultLogo} alt="/" />
            ) : null}{" "}
          </div>
          <div className="mobile-login__inst-details--cust-name">
            {InstFormData.cust_name} 
          </div>
          <div className="mobile-login__inst-details--inst-name">
            {InstFormData.inst_name}
          </div>
          <div className="mobile-login__inst-details--inst-addr">
            {InstFormData.inst_address +
              InstFormData.inst_place +
              InstFormData.inst_pin}
          </div>
        </div>

        <div className="mobile-login__main">
          <div className="mobile-login__main--block">
            <div className="mobile-login__main--block--tabs">
              <Tabs value={value} onChange={handleTabChange}>
                <Tab label="Mobile No." value={0} {...a11yProps(0)} />
                <Tab label="Std ID" value={1} {...a11yProps(1)} />
                <Tab label="Std Roll No." value={2} {...a11yProps(2)} />
              </Tabs>
            </div>
            <div className="mobile-login__main--block--tabpanel">
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Label>Mobile No.</Label>
                <Input
                  onChange={(e) => {
                    if (
                      Number(e.target.value) >= 0 &&
                      e.target.value.length <= 10
                    ) {
                      setEncodeValue(e.target.value);
                    }
                  }}
                  type="number"
                />
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                <Label>Enter Student ID</Label>
                <Input
                  onChange={(e) => {
                    setEncodeValue(e.target.value);
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Label> Enter Student Roll No.</Label>
                <Input
                  onChange={(e) => {
                    setEncodeValue(e.target.value);
                  }}
                />
              </TabPanel>
              <br />

              <a
                onClick={() => {
                  GetEncode({
                    variables: {
                      inst_id: instId!,
                      str_to_encode: encodeValue,
                    },
                  }).then(({ data }) => {
                    if (data && data.GetEncodedValue.length) {
                      switch (value) {
                        case 0:
                          navigate(
                            `/${instId}/mobileapp/fee/fetchdetails/${data.GetEncodedValue}/stdlist/mobile`
                          );
                          break;
                        case 1:
                          navigate(
                            `/${instId}/mobileapp/fee/fetchdetails/${data.GetEncodedValue}/stdlist/studentid`
                          );
                          break;
                        case 2:
                          navigate(
                            `/${instId}/mobileapp/fee/fetchdetails/${data.GetEncodedValue}/stdlist/rollno`
                          );
                          break;
                        default:
                          break;
                      }
                    }
                  });
                }}
                target="blank"
                className="fee-fetch-details__block--fetch-details"
              >
                <img src={Fetch} alt="" />
                Fetch Details
              </a>
            </div>
          </div>
          <div className="mobile-login__footer">
            <span>Simplified By</span>
            <img src={EduateLogo} alt="" />
            <b>My-Eduate</b>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnlinePayment;
