import { useContext } from "react";
import { AppContext } from "../context/context";
import { UserType } from "../utils/Enum.types";
import { EMPTY_STRING } from "../utils/constants";

const useLoggedInUserDetails = () => {
  const { state } = useContext(AppContext);

  let user = EMPTY_STRING;
  let userId = 0;

  if (state.claims) {
    if (state.claims.EMPLOYEE) {
      user = UserType.EMPLOYEE;
      userId = state.empLoginId;
    } else if (state.claims.STUDENT) {
      user = UserType.STUDENT;
      userId = state.studentId;
    } else if (state.claims.EDUATE) {
      user = UserType.EDUATE;
      userId = state.eduateId;
    } else if (state.claims.OWNER) {
      user = UserType.OWNER;
      userId = state.custId;
    } else if (state.claims.PARENT) {
      user = UserType.PARENT;
      userId = state.parentId;
    } else if (state.claims.USER) {
      user = UserType.USER;
    }
  }

  return {
    user_details: {
      user_type: user,
      id: userId,
    },
  };
};

export default useLoggedInUserDetails;
