import { gql } from "@apollo/client";

export const ValidateStudentDataForImport = gql`
  query ValidateStudentDataForImport(
    $token: String!
    $inst_id: ID!
    $input: ValidateStdData!
  ) {
    ValidateStudentDataForImport(
      token: $token
      inst_id: $inst_id
      input: $input
    )
  }
`;
