import { gql } from "@apollo/client";
export const ClassesByBranchIds = gql`
  query GetClassesForScanByBranchIds($branch_ids: [ID!]!, $token: String!) {
    GetClassesByBranchIds(token: $token, branch_ids: $branch_ids) {
      id
      class_desc
      class_idx
      class_total_adm_count
      class_total_Res_adm_count
    }
  }
`;

export const ClassIdByName = gql`
  query GetClassIdByName(
    $token: String!
    $inst_id: ID!
    $branch_id: ID!
    $class_name: String!
  ) {
    GetClassIdByName(
      token: $token
      inst_id: $inst_id
      branch_id: $branch_id
      class_name: $class_name
    )
  }
`;
