import { gql } from "@apollo/client";

export const GetClassNamesByBranchIds = gql`
  query GetClassNamesByBranchIds($branch_id: [ID!]!, $token: String!) {
    GetClassNamesByBranchIds(token: $token, branch_id: $branch_id) {
      id
      branch_id
      class_desc
    }
  }
`;
