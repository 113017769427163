import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { GetSwConfigVariables } from "../queries/institution/configuration/query/SoftwareConfig";
import {
  ChatConfigKeys,
  GetSwConfigVariablesVars,
  InstDetails,
  InstitutionConfigurationTypes,
  SwConfigQueryType,
} from "../utils/Enum.types";
import useToken from "./useToken";

import { AppContext } from "../context/context";
import { GetSwConfigVariablesData } from "../utils/Query.Types";

export type ConfigKey =
  | InstitutionConfigurationTypes
  | InstDetails
  | ChatConfigKeys;

const useSwConfigData = (configKey: ConfigKey | ConfigKey[]) => {
  const { token } = useToken();
  const { instId } = useParams();
  const { state } = useContext(AppContext);
  const [GetConfigDetails, { data, loading, error }] = useLazyQuery<
    GetSwConfigVariablesData,
    GetSwConfigVariablesVars
  >(GetSwConfigVariables, {
    variables: {
      token,
      inst_id: instId! || state.InstId,
      input: {
        config_query_type: SwConfigQueryType.BY_CONFIG_KEY,
        str_value: configKey,
        int_value: 0,
      },
    },
  });

  useEffect(() => {
    if (configKey && token && (Number(instId) || state.InstId)) {
      GetConfigDetails();
    } // eslint-disable-next-line
  }, [GetConfigDetails, token, instId, state.InstId]);

  return { configData: { data, loading, error } };
};

export default useSwConfigData;
