import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Layouts/Navbar";
import {
  EMPTY_STRING,
  licenseTypes,
  ROWS_PER_PAGE,
} from "../../../../utils/constants";
import Sidebar from "../../../Layouts/Sidebar";
import { Title } from "../../../../stories/Title/Title";
import {
  HEADER_TEXT_ALIGN,
  SLNO_TEXT_ALIGN,
  TABLE_ROW_HEIGHT,
  useDataGridStyles,
} from "../../../styles/DatagridStyles";
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { TableHeaderProps } from "../../../../utils/Types";
import useMasterTableJson, { TableColumn } from "../json/useTableJson";
import useInstitutionConfiguration from "../../../../customhooks/useInstitutionConfiguration";
import useStudentsbyNewApi, {
  StudentEdges,
} from "../../../../customhooks/useStudentByNodeApi";
import { ColumnVisibilityFor, StudentReportType } from "../../../../utils/Enum.types";
import Avatar from "../../../../images/Avatar.svg";
import PicPreview from "./PicPreview";
import { Button } from "../../../../stories/Button/Button";
import useInstDetails from "../../../../customhooks/useInstDetails";
import { handleUploadAndDownloadFile } from "../../../../utils/Upload";
import { useMutation } from "@apollo/client";
import { UpdateStudentById } from "../../../../queries/students/mutations";
import useToken from "../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../customhooks/useLoggedInUserDetails";
import { GetStudentDetails } from "../../../../queries/students/list";
import LoadingModal from "../../../Modals/LoadingModal";
import { getModifiedScrollHeight } from "../../../../utils/UtilFunctions";
import Upload from "../../../../images/Upload.svg"
export interface previewStudentType {
  adm_no: string;
  image: File | null;
  std_name: string;
  flag: boolean;
}
const BulkProfilePicUpload = () => {
  const tableClasses = useDataGridStyles();
  const { StudentsBulkPic } = useMasterTableJson();
  const { instId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { token } = useToken();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const [stateChange, setStateChange] = useState(false);
  const [fetchingProgress, setFetchingProgress] = useState(false);

  const [uploadingProgress, setUploadingProgress] = useState(false);

  const [students, setStudents] = useState<StudentEdges[]>([]);

  const { InstDetails } = useInstDetails();
  const [progress, setProgress] = useState(0);
  const [updateStudent, { loading: updateLoading }] =
    useMutation(UpdateStudentById);
  const [previewStudent, setPreviewStudent] = useState<previewStudentType>({
    adm_no: "",
    image: null,
    std_name: "",
    flag: false,
  });

  const [endCursor, setEndCursor] = useState<string | null>(null);

  const [adm_numbers, setAdmNumbers] = useState<Map<string, File>>(new Map());
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {
    StudentsData: { data, loading, fetchMore, error },
  } = useStudentsbyNewApi(
    0,
    0,
    0,
    0,
    0,
    EMPTY_STRING,
    ROWS_PER_PAGE,
    StudentReportType.STDS_BY_ADMISSION_NUMS,
    0,
    Array.from(adm_numbers.keys())
  );

  useEffect(
    () => {
      const scrollTable = document.getElementsByClassName(
        "MuiDataGrid-virtualScroller"
      )[0] as Element;
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        const scrollTop = target.scrollTop;
        const scrollHeight = target.scrollHeight;
        const clientHeight = target.clientHeight;
        if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
          if (hasNextPage && !loading) {
            fetchMore({
              variables: {
                first: ROWS_PER_PAGE,
                after: endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;

                const newEdges = fetchMoreResult.GetStudents.edges;
                const pageInfo = fetchMoreResult.GetStudents.pageInfo;
                setEndCursor(pageInfo.endCursor);
                setHasNextPage(pageInfo.hasNextPage);

                const duplicateCheck = prevResult.GetStudents.edges.filter(
                  ({ node: { id } }) =>
                    newEdges.findIndex(
                      ({ node: { id: newId } }) => newId === id
                    ) !== -1
                );

                if (duplicateCheck.length > 0) return prevResult;

                return {
                  GetStudents: {
                    edges: [...students, ...newEdges],
                    pageInfo,
                    totalCount: data ? data.GetStudents.totalCount! : 0,
                  },
                };
              },
            });
          }
        }
      };
      if (scrollTable && rows.length)
        scrollTable.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollTable)
          scrollTable.removeEventListener("scroll", handleScroll);
      };
    },

    // eslint-disable-next-line
    [rows]
  );
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFetchingProgress(true);

    if (event.target.files) {
      const { files } = event.target;
      const tempAdmNumbers = Object.values(files).reduce((acc, element) => {
        const adm_number: string = (element as unknown as File).name.split(
          "."
        )[0];

        acc.set(adm_number, element);
        return acc;
      }, new Map() as Map<string, File>); // Handle selected files

      setAdmNumbers(tempAdmNumbers);
    }

    if (rows.length !== 0 && rows.length > 0) {
      setFetchingProgress(false);
    }
  };

  const {
    USE_DEPARTMENT_KEY,
    USE_BRANCH_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();

  const enabledMasters = (ele: TableColumn) => {
    if (ele.field === "dept_desc") return USE_DEPARTMENT_KEY;
    else if (ele.field === "branch_desc") return USE_BRANCH_KEY;
    else if (ele.field === "class_desc") {
      return USE_CLASS_KEY;
    } else if (ele.field === "sem_desc") return USE_SEMESTER_KEY;
    else if (ele.field === "section_desc") return USE_SECTION_KEY;
    else return true;
  };

  const dynamicHeaders: TableHeaderProps[] =
    StudentsBulkPic.Table_Headers.filter(enabledMasters).map((header) => ({
      headerName: header.headerName,
      className: header.cellClassName,
      field: header.field,
      headerAlign: header.headerAlign as GridAlignment,
      align: header.align as GridAlignment,
      flex: header.flex,
    }));
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Sl",
      headerAlign: HEADER_TEXT_ALIGN,
      align: SLNO_TEXT_ALIGN,
      cellClassName: "td-sl-no",
    },
    ...dynamicHeaders,
    {
      field: "std_profile_pic",
      headerName: "Profile Pic",
      cellClassName: "td-center",
      headerAlign: HEADER_TEXT_ALIGN,
      renderCell: (params) => {
        const foundStudent = adm_numbers.get(params.row.std_adm_no);
        if (foundStudent) {
          const imageUrl = URL.createObjectURL(foundStudent);
          return (
            <img
              src={imageUrl}
              alt={params.row.std_adm_no}
              onClick={() => {
                setPreviewStudent({
                  adm_no: params.row.std_adm_no,
                  flag: true,
                  image: foundStudent,
                  std_name: params.row.std_name,
                });
              }}
            />
          );
        } else {
          return <img src={Avatar} alt={params.row.std_adm_no} />;
        }
      },
    },
  ];

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetStudents.edges;

      if (endCursor) {
        const updatedNewData = newData.map((newRow) => {
          const filteredStudent = rows.find(
            (row) => row.custId && row.custId === newRow.node.id
          );
          if (filteredStudent) {
            return {
              ...newRow,
              node: {
                ...newRow.node,
              },
            };
          }
          return newRow;
        });
        setStudents(updatedNewData);

        setRows(
          updatedNewData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_name: node.std_name,
            parent_name: node.parent_name,
            dept_desc: node.dept.dept_desc,
            branch_desc: node.branch.branch_desc,
            class_desc: node.class.class_desc,
            sem_desc: node.semester.sem_desc,
            section_desc: node.section.section_desc,
            std_status: node.std_status,
          }))
        );
      } else {
        setStudents(newData);
        setRows(
          newData.map(({ node }, index) => ({
            id: index + 1,
            stdId: node.id,
            std_adm_no: node.std_adm_no,
            std_name: node.std_name,
            parent_name: node.parent_name,
            dept_desc: node.dept.dept_desc,
            branch_desc: node.branch.branch_desc,
            class_desc: node.class.class_desc,
            sem_desc: node.semester.sem_desc,
            section_desc: node.section.section_desc,
            std_status: node.std_status,
          }))
        );
      }
      setEndCursor(data.GetStudents.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading, adm_numbers]);

  const changePic = (adm_no: string, image: File) => {
    const adm_nums = adm_numbers;
    adm_nums.set(adm_no, image);
    setAdmNumbers(adm_nums);
    setStateChange(!stateChange);
  };

  const uploadFiles = () => {
    setUploadingProgress(true);
    rows.forEach(async (item) => {
      const file = adm_numbers.get(item.std_adm_no);
      const file_type = file
        ? file.name.split(".")[file.name.split(".").length - 1]
        : EMPTY_STRING;
      const file_name = `${InstDetails.data?.nodes[0]?.inst_name}/students/${item.stdId}/std_profile_pic/std_profile_pic.${file_type}`;
      if (file_type && file) {
        await handleUploadAndDownloadFile(
          file,
          file_name,
          setProgress,
          false
        ).then((res) => {
          if (res) {
            updateStudent({
              variables: {
                id: item.stdId,
                token,
                inst_id: instId!,
                user_details,
                input: {
                  std_profile_filename: file_name,
                },
              },
              refetchQueries: [
                {
                  query: GetStudentDetails,
                  variables: {
                    token,
                    ids: [item.stdId],
                  },
                },
              ],
            }).then(({ data }) => {
              if (data && data.UpdateStudentById) {
                if (data.UpdateStudentById.id === rows[rows.length - 1].stdId) {
                  setUploadingProgress(false);
                }
              }
            });
          }
        });
      }
    });
    // setUploadingProgress(false);
  };

  useEffect(() => {
    if (rows.length > 0 && rows.length === adm_numbers.size) {
      setFetchingProgress(false);
    }
  }, [adm_numbers, rows]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
  React.useState<GridColumnVisibilityModel>({

    std_roll_no: false,
    dept: false,
    branch:false,
    class:false,
    sem: USE_SEMESTER_KEY!,
    sec: USE_SECTION_KEY!,
    status: false,
    category: false,
  });
  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem(
      ColumnVisibilityFor.BULK_PHOTO_UPLOAD
    );
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      ColumnVisibilityFor.BULK_PHOTO_UPLOAD,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="bulk_pic_upload">
        <Sidebar />
        <div className="bulk_pic_upload__frame">
          <Title>Bulk Profile Pic Upload</Title>
          <div className="bulk_pic_upload__file">
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={handleFileChange}
              multiple
            />

            <button type="button" onClick={handleFileClick}>
              <img src={Upload} alt="" />
              Select Folder
            </button>

            {adm_numbers.size
              ? `${adm_numbers.size} image${
                  adm_numbers.size > 1 ? "s" : ""
                } selected`
              : null}
          </div>
          <div className={`bulk_pic_upload__tableblock ${tableClasses.root}`}>
            <DataGridPro
              columns={columns}
              rows={rows}
              onCellClick={() => {}}
              disableRowSelectionOnClick
              rowHeight={TABLE_ROW_HEIGHT}
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              slotProps={{
                columnsPanel: { disableHideAllButton: true },
              }}
            />
          </div>
          <Button mode="save" onClick={uploadFiles} />
        </div>
      </div>
      <PicPreview
        details={previewStudent}
        setPreviewStudent={setPreviewStudent}
        changePic={changePic}
      />
      <LoadingModal flag={uploadingProgress || fetchingProgress} />
    </>
  );
};

export default BulkProfilePicUpload;
