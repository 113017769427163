import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Direction, SortBy } from "../../../../../utils/Enum.types";
import {
  E000,
  EMPTY_STRING,
  ONLINE_TRANSACTION_ROWS_PER_PAGE,
} from "../../../../../utils/constants";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  convertIsoToIst,
  formatter,
  getModifiedScrollHeight,
  toStandardDate,
} from "../../../../../utils/UtilFunctions";

import { PageInfo } from "../../../Chat/Feeds/Types";
import useAcctTableJson from "../../json/useAcctTableJson";
import useToken from "../../../../../customhooks/useToken";
import { GetAcctOnlineICICIPayments } from "../../../../../queries/payments/query";
import { TableHeaderPropsLabel, optionsType } from "../../../../../utils/Types";

export enum AcctOnlinePaymentQueryType {
  ON_PY_RECORDS = "ON_PY_RECORDS",
  ON_PY_SUCCESS_RECORDS = "ON_PY_SUCCESS_RECORDS",
  ON_PY_FAILED_RECORDS = "ON_PY_FAILED_RECORDS",
  ON_PY_IN_COMPLETE_RECORDS = "ON_PY_IN_COMPLETE_RECORDS",
}
export interface AcctOnlinePaymentsNode {
  id: number;
  uuid: string;
  response_code: string;
  unique_ref_number: string;
  service_tax_amount: string;
  processing_fee_amount: string;
  total_amount: string;
  transaction_amount: string;
  transaction_date: string;
  response_code_str: string;
  payment_mode: string;
  sub_merchant_id: string;
  reference_no: string;
  merchant_eazypay_id: string;
  mandatory_fields: string;
  optional_fields: string;
  voucher_no: string;
  voucher_master_id: number;
  created_at: string;
}
interface AcctOnlinePaymentsEdge {
  node: AcctOnlinePaymentsNode;
  cursor: string;
}
interface GetAcctOnlinePaymentsData {
  GetAcctOnlineICICIPayments: {
    totalCount: number;
    pageInfo: PageInfo;
    edges: AcctOnlinePaymentsEdge[];
  };
}
interface GetAcctOnlinePaymentsVars {
  token: string;
  inst_id: string;
  first: number | null;
  after: string | null;
  query_type: AcctOnlinePaymentQueryType;
  acct_std_demand_id: number;
  orderBy: { direction: Direction; field?: string };
  // responseCodeNEQ: string;
  transactionDateContainsFold: string;
  searchString: String;
}

interface Props {
  showLogDataBtn: boolean;
  queryType: optionsType;
  transactionDateContainsFold: string;
  statusCode: string;
  searchString: string;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}
const TransactionTable = ({
  showLogDataBtn,
  queryType,
  transactionDateContainsFold,
  statusCode,
  searchString,
  setCount,
}: Props) => {
  const { token } = useToken();
  const { instId } = useParams();
  const { state } = useContext(AppContext);
  const { Accounts_Table } = useAcctTableJson();
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [transactions, setTransactions] = useState<AcctOnlinePaymentsEdge[]>(
    []
  );

  const { format } = formatter;
  const [GetAcctPayments, { data, error, loading, fetchMore }] = useLazyQuery<
    GetAcctOnlinePaymentsData,
    GetAcctOnlinePaymentsVars
  >(GetAcctOnlineICICIPayments);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= getModifiedScrollHeight(scrollHeight)) {
      if (hasNextPage && !loading) {
        fetchMore({
          variables: {
            first: ONLINE_TRANSACTION_ROWS_PER_PAGE,
            after: endCursor,
          },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prevResult;

            const newEdges = fetchMoreResult.GetAcctOnlineICICIPayments.edges;
            const pageInfo =
              fetchMoreResult.GetAcctOnlineICICIPayments.pageInfo;

            setEndCursor(pageInfo.endCursor);
            setHasNextPage(pageInfo.hasNextPage);

            const duplicateCheck =
              prevResult.GetAcctOnlineICICIPayments.edges.filter(
                ({ node: { id } }) =>
                  newEdges.findIndex(
                    ({ node: { id: newId } }) => newId === id
                  ) !== -1
              );

            if (duplicateCheck.length > 0) return prevResult;

            return {
              GetAcctOnlineICICIPayments: {
                edges: [...transactions, ...newEdges],
                pageInfo,
                totalCount: data?.GetAcctOnlineICICIPayments.totalCount!,
              },
            };
          },
        });
      }
    }
  };
  useEffect(
    () => {
      if (token && instId) {
        GetAcctPayments({
          variables: {
            token,
            after: null,
            first: ONLINE_TRANSACTION_ROWS_PER_PAGE,
            inst_id: instId!,
            orderBy: {
              direction: Direction.ASC,
              field: SortBy.CREATED_AT,
            },
            query_type: queryType.value as AcctOnlinePaymentQueryType,
            acct_std_demand_id: state.stdDemandId,

            transactionDateContainsFold: transactionDateContainsFold
              ? toStandardDate(transactionDateContainsFold)
              : EMPTY_STRING,
            searchString,
          },
        }).then(({ data }) => {
          if (data && data.GetAcctOnlineICICIPayments) {
            setCount(data.GetAcctOnlineICICIPayments.totalCount);
          }
        });
      }
    },
    // eslint-disable-next-line
    [
      token,
      GetAcctPayments,
      instId,
      state.studentId,
      queryType,
      transactionDateContainsFold,
      searchString,
      statusCode,
      data,
      state.stdDemandId,
    ]
  );

  useEffect(() => {
    if (data && !loading) {
      const newData = data.GetAcctOnlineICICIPayments.edges.map((edge) => ({
        ...edge,
        node: {
          ...edge.node,
          isChecked: true, // set default value of isChecked to true
        },
      }));

      if (endCursor) {
        // If we have already fetched some data, we need to check if there
        // are any duplicates in the new data, and update their isChecked
        // property based on the existing data.
        // const filteredStudents = students.filter(
        //   (student) => !student.node.isChecked
        // );

        const updatedNewData = newData.map((newStudent) => {
          const filteredStudent = transactions.find(
            (transaction) => transaction.node.id === newStudent.node.id
          );
          if (filteredStudent) {
            return {
              ...newStudent,
              node: {
                ...newStudent.node,
                // isChecked: filteredStudent.node.isChecked,
              },
            };
          }
          return newStudent;
        });
        setTransactions(updatedNewData);
      } else {
        setTransactions(newData);
      }
      setEndCursor(data.GetAcctOnlineICICIPayments.pageInfo.endCursor);
    } // eslint-disable-next-line
  }, [data, loading]);
  return (
    <div className="online-transaction__tableblock">
      {error ? (
        <b className="nodata">{error.message}</b>
      ) : (
        <TableContainer
          className="online-transaction__table"
          onScroll={handleScroll}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Accounts_Table.OnlineTransactionDetails.Table_Headers.filter(
                  (th) =>
                    (th.labelName !== "Action" && showLogDataBtn === false) ||
                    showLogDataBtn
                ).map((th: TableHeaderPropsLabel, index: React.Key) => {
                  return <TableCell key={index}>{th.labelName}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && transactions.length
                ? transactions.map((edge, index) => {
                    const { istDate, istTime } = convertIsoToIst(
                      edge.node.created_at
                    );
                    return (
                      <TableRow key={index}>
                        <TableCell
                          className="online-transaction__table--slno"
                          id="td-center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell className="online-transaction__table--date">
                          {`${toStandardDate(istDate)} ${istTime}`}
                        </TableCell>
                        <TableCell className="online-transaction__table--date">
                          {edge.node.transaction_date
                            ? `${edge.node.transaction_date.split(" ")[0]} ${
                                edge.node.transaction_date.split(" ")[1]
                              }`
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell id="td-center">
                          {edge.node.unique_ref_number}
                        </TableCell>
                        <TableCell>{edge.node.uuid}</TableCell>
                        <TableCell>{edge.node.reference_no}</TableCell>
                        <TableCell>{edge.node.merchant_eazypay_id}</TableCell>
                        <TableCell>{edge.node.payment_mode}</TableCell>
                        <TableCell id="td-right">
                          {edge.node.transaction_amount
                            ? format(Number(edge.node.transaction_amount))
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell id="td-right">
                          {edge.node.total_amount
                            ? format(Number(edge.node.total_amount))
                            : EMPTY_STRING}
                        </TableCell>
                        <TableCell
                          className={
                            edge.node.response_code === E000
                              ? "font-green"
                              : "font-red"
                          }
                          id="td-center"
                        >
                          {edge.node.response_code}
                        </TableCell>
                        <TableCell className="online-transaction__table--message">
                          {edge.node.response_code_str}
                        </TableCell>
                        <TableCell
                          className={
                            edge.node.response_code === E000 &&
                            edge.node.voucher_master_id === 0
                              ? "online-transaction__table--no-voucher"
                              : edge.node.response_code === E000
                              ? "online-transaction__table--success"
                              : edge.node.response_code === EMPTY_STRING
                              ? "online-transaction__table--incomplete"
                              : "online-transaction__table--failure"
                          }
                          id="td-center"
                        >
                          {edge.node.response_code === E000 &&
                          edge.node.voucher_master_id === 0
                            ? "Success / Voucher not generated"
                            : edge.node.response_code === E000
                            ? "Success"
                            : edge.node.response_code === EMPTY_STRING
                            ? "Incomplete"
                            : "Failed"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* <Modal
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        isOpen={printModal}
        style={PrintModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <Print
              tableData={tableData}
              v_date={v_date}
              v_no={v_no}
              studentDetails={studentDetails}
            />
          </div>
          <div className="modal-flex__image">
            <img
              src={Close}
              alt="/"
              className="modal-close-icon"
              onClick={() => setPrintModal(!printModal)}
            />
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        shouldCloseOnOverlayClick={true}
        isOpen={logData}
        ariaHideApp={false}
        style={StudentModalStyles}
      >
        <div className="modal-flex h-100">
          <div className="modal-flex__data h-100">
            <LogData
              setModal={setLogData}
              transactionData={transactionData}
              handleViewVoucher={handleViewVoucher}
            />
          </div>
          <div className="modal-flex__image">
            <img src={Close} alt="" onClick={() => setLogData(!logData)} />
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default TransactionTable;
