import { gql } from "@apollo/client";
export const GetNotAllocatedIds = gql`
  query GetNotAllocatedIds(
    $token: String!
    $inst_id: ID!
    $table: TableType!
    $table_id: ID!
  ) {
    GetNotAllocatedIds(
      token: $token
      inst_id: $inst_id
      table: $table
      table_id: $table_id
    ) {
      dept_id
      branch_id
      class_id
      semester_id
      section_id
    }
  }
`;
