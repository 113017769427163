import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Title } from "../../../../../stories/Title/Title";
import Close from "../../../../../images/Close.svg";
import { Button } from "../../../../../stories/Button/Button";
import {
  EMPTY_STRING,
  formatOptions,
  formattingToolbarOptions,
} from "../../../../../utils/constants";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Label } from "../../../../../stories/Label/Label";
import {
  GetInstMsgTemplatesVars,
  GetMsgInstTemplatesData,
  GlobalPageConfigData,
  templateEdges,
} from "../../../../../utils/Query.Types";
import {
  ChannelQueryType,
  DEFAULT_TIME,
  Direction,
  InstitutionConfigurationTypes,
  MsgChannelType,
  Operation,
  SortBy,
} from "../../../../../utils/Enum.types";
import useSwConfigData from "../../../../../customhooks/useSwConfighData";
import {
  AddChannelmessage,
  AddIndividualStdMessageWithTags,
  UpdateChannelMessage,
} from "../../../../../queries/chat/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { msgType } from "../../../../../utils/Types";
import useToken from "../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/context";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import { GetChannelMessages } from "../../../../../queries/chat/feeds/list";
import { handleUploadAndDownloadFile } from "../../../../../utils/Upload";
import useChannels from "../../CustomHooks/useChannels";
import { toIsoDate } from "../../../../../utils/UtilFunctions";
import useIndividualStudentId from "../../CustomHooks/useIndividualStudentId";
import Template from "../../../../../images/TemplateMessages.svg";
import {
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { templateViewStyles } from "../../../../styles/ModalStyles";
import { GetMsgInstTemplates } from "../../../../../queries/Templates/query";
import { UpdateMessageType } from "./MessageList";
interface ModalProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  Channel: ChannelQueryType;
  setMessage: React.Dispatch<React.SetStateAction<msgType>>;
  selectedStudents?: { id: number; name: string }[];
  setSelectedStudents?: React.Dispatch<
    React.SetStateAction<{ id: number; name: string }[]>
  >;
  updateMessage?: UpdateMessageType;
}
const SendMessage = ({
  setModal,
  Channel,
  setMessage,
  selectedStudents,
  setSelectedStudents,
  updateMessage,
}: ModalProps) => {
  const drawerClasses = templateViewStyles();
  const studentIds = selectedStudents?.map((student) => student.id);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [channelMessage, setChannelMessage] = useState("");
  const { token } = useToken();
  const { state } = useContext(AppContext);

  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  const [templates, setTemplates] = useState<templateEdges[]>([]);
  const [templateId, setTemplateId] = useState(-898);
  const { user_details } = useLoggedInUserDetails();
  const { channelId: individualChannelId } = useIndividualStudentId(
    state.studentId
  );
  const { instId } = useParams();
  const { data } = useChannels(ChannelQueryType.ENTRYLEVEL);

  const [GetTemplates] = useLazyQuery<
    GetMsgInstTemplatesData,
    GetInstMsgTemplatesVars
  >(GetMsgInstTemplates, {
    variables: {
      token,
      inst_id: instId!,
      after: null,
      first: null,
      name: EMPTY_STRING,
      orderBy: {
        direction: Direction.ASC,
        field: SortBy.MSG_TPL_DESC,
      },
    },
  });

  const { InstFormData } = useInstDetails();
  // eslint-disable-next-line
  const [mediaContent, setMediaContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleQuillChange = (value: string) => {
    setChannelMessage(value);
  };

  const { configData: sizeLimits } = useSwConfigData([
    InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE,
    InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE,
  ]);

  const channelId =
    Channel === ChannelQueryType.INDIVIDUAL
      ? individualChannelId
      : data && data.GetChannels.length
      ? data.GetChannels[0].id
      : 0;

  let filename =
    selectedFiles.length > 1
      ? selectedFiles
          .map(
            (f) => `${InstFormData.inst_name}/channels/${channelId}/${f.name}`
          )
          .join(",")
      : selectedFiles.length === 1
      ? `${InstFormData.inst_name}/channels/${channelId}/${selectedFiles[0].name}`
      : "";

  const [AddChannelMsg, { loading }] = useMutation(AddChannelmessage, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  const [UpdateChannelMsg, { loading: UpdateLoading }] = useMutation(
    UpdateChannelMessage,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [AddIndividualStudentMessage, { loading: IndividualLoading }] =
    useMutation(AddIndividualStdMessageWithTags, {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    });
  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const filterDataByConfigKey = (data: GlobalPageConfigData[]) => {
    let imageSize = 0;
    let videoSize = 0;
    if (data) {
      data.forEach((item) => {
        switch (item.config_key) {
          case InstitutionConfigurationTypes.ATTACHMENT_FILE_SIZE:
            imageSize = item.config_integer_value;
            break;
          case InstitutionConfigurationTypes.ATTACHMENT_MEDIA_SIZE:
            videoSize = item.config_integer_value;
            break;

          default:
            break;
        }
      });
    }
    return {
      imageSize,
      videoSize,
    };
  };
  const { imageSize, videoSize } = filterDataByConfigKey(
    sizeLimits.data?.GetSwConfigVariables!
  );

  const handleSubmit = () => {
    let flag = true;

    if (channelMessage === EMPTY_STRING) {
      alert("Message content cannot be empty");
      return;
    }

    // Validate multiple files
    for (const f of selectedFiles) {
      if (f.type.includes("image/") && f.size > imageSize! * 1024 * 1024) {
        alert("Image file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type.includes("video/") && f.size > videoSize! * 1024 * 1024) {
        alert("Video file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (f.type === "application/pdf" && f.size > imageSize! * 1024 * 1024) {
        alert("PDF file size exceeds the maximum limit.");
        flag = false;
        break;
      }

      if (
        (f.type === "application/vnd.ms-excel" ||
          f.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
        f.size > imageSize! * 1024 * 1024
      ) {
        alert("Excel file size exceeds the maximum limit.");
        flag = false;
        break;
      }
    }

    if (flag) {
      let sendMsgPromise: Promise<any>;

      if (
        Channel === ChannelQueryType.INDIVIDUAL ||
        Channel === ChannelQueryType.MULTIPLE
      ) {
        // Send message to individual or multiple students
        sendMsgPromise = AddIndividualStudentMessage({
          variables: {
            token,
            inst_id: instId!,
            std_ids: selectedStudents ? studentIds : [state.studentId],
            input: {
              msg_content: channelMessage,
              msg_header: EMPTY_STRING,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetChannelMessages,
              variables: {
                token,
                inst_id: instId,
                input: {
                  query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                  channel_id: channelId,
                },
                direction: Direction.ASC,
                last: 5,
                after: null,
                sortBy: SortBy.CREATED_AT,
                mediaContent: EMPTY_STRING,
              },
            },
          ],
        });
      } else if (updateMessage) {
        // Update existing channel message
        sendMsgPromise = UpdateChannelMsg({
          variables: {
            token,
            input: {
              msg_content: channelMessage,
              msg_media_content: selectedFiles.length ? filename : "",
              msg_header: EMPTY_STRING,
            },
            user_details,
            msg_id: updateMessage.id,
          },
          refetchQueries: [
            {
              query: GetChannelMessages,
              variables: {
                token,
                inst_id: instId ? instId : state.InstId.toString(),
                input: {
                  query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                  channel_id: channelId,
                  start_date: toIsoDate(DEFAULT_TIME),
                  end_date: toIsoDate(DEFAULT_TIME),
                },
                direction: Direction.ASC,
                last: 10,
                after: null,
                sortBy: SortBy.CREATED_AT,
                messageContent: EMPTY_STRING,
              },
            },
          ],
        });
      } else {
        // Add new channel message
        sendMsgPromise = AddChannelMsg({
          variables: {
            token,
            input: {
              msg_content: channelMessage,
              msg_media_content: selectedFiles.length ? filename : "",
              msg_channel_id: channelId,
              inst_id: instId!,
              msg_header: EMPTY_STRING,
            },
            user_details,
          },
          refetchQueries: [
            {
              query: GetChannelMessages,
              variables: {
                token,
                inst_id: instId ? instId : state.InstId.toString(),
                input: {
                  query_type: MsgChannelType.MSGS_BY_CHANNEL_ID,
                  channel_id: channelId,
                  start_date: toIsoDate(DEFAULT_TIME),
                  end_date: toIsoDate(DEFAULT_TIME),
                },
                direction: Direction.ASC,
                last: 10,
                after: null,
                sortBy: SortBy.CREATED_AT,
                messageContent: EMPTY_STRING,
              },
            },
          ],
        });
      }

      // Once the message is sent, handle file upload if any files were selected
      sendMsgPromise.then(({ data }) => {
        if (data) {
          setChannelMessage(EMPTY_STRING);
          const uploadFilePromise = selectedFiles.length
            ? selectedFiles.length > 1
              ? Promise.all(
                  selectedFiles.map((f, index) =>
                    handleUploadAndDownloadFile(
                      f,
                      `${InstFormData.inst_name}/channels/${channelId}/${
                        data.AddChannelMessage.id
                      }_${index}.${
                        f.name.split(".")[f.name.split(".").length - 1]
                      }`,
                      setProgress,
                      false
                    )
                  )
                )
              : handleUploadAndDownloadFile(
                  selectedFiles[0],
                  `${InstFormData.inst_name}/channels/${channelId}/${
                    data.AddChannelMessage.id
                  }.${
                    selectedFiles[0].name.split(".")[
                      selectedFiles[0].name.split(".").length - 1
                    ]
                  }`,
                  setProgress,
                  false
                )
            : false;

          // Final handling of both message and file upload
          Promise.all([sendMsgPromise, uploadFilePromise])
            .then(([sendMsgData]) => {
              if (sendMsgData) {
                setChannelMessage(EMPTY_STRING);
                setSelectedFiles([]);
                setModal(false);
                setMessage({
                  flag: true,
                  message: "Message Sent",
                  operation: Operation.CREATE,
                });
              }
            })
            .catch((error) => {
              console.log("Error occurred:", error);
            });
        }
      });

      setSelectedStudents?.([]);
    }
  };

  useEffect(() => {
    if (token && instId) {
      GetTemplates().then(({ data }) => {
        if (data) {
          setTemplates(data.GetMsgInstTemplates.edges);
        }
      });
    }
  }, [GetTemplates, token, instId]);
  useEffect(() => {
    if (templateId > 0) {
      const res = templates.find(({ node: { id } }) => id === templateId);
      if (res) {
        setChannelMessage(res.node.msg_tpl_desc);
      }
    } // eslint-disable-next-line
  }, [templateId, templates]);
  useEffect(() => {
    if (updateMessage) {
      setChannelMessage(updateMessage.message);
    }
  }, [updateMessage]);
  const handleClear = () => {
    setChannelMessage("");
    setMediaContent("");
    setSelectedFiles([]);
  };

  if (channelId)
    return (
      <>
        <div className="send-message">
          <div className="send-message__title">
            <Title>Send a Message</Title>
            <img src={Close} alt="" onClick={() => setModal(false)} />
          </div>
          <div className="send-message__form">
            <div className="send-message__form--label-grid1">
              <Label>Message</Label>
              <ReactQuill
                value={channelMessage}
                onChange={handleQuillChange}
                modules={formattingToolbarOptions}
                formats={formatOptions}
                placeholder={`Message...`}
              />
            </div>
            {selectedStudents && selectedStudents.length > 0 ? null : (
              <div className="send-message__form--label-grid2">
                <Label>Attachments</Label>
                <div className="send-message__form--attachments">
                  <ul>
                    {selectedFiles.length
                      ? selectedFiles.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))
                      : null}
                  </ul>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileInputChange}
                  />
                  <div className="send-message__form--attachments--footer">
                    Please Upload File (Max File Size: 5MB. Only Jpeg, jpg and
                    png allowed)
                  </div>
                </div>
              </div>
            )}
          </div>
          <Button
            mode="send-message"
            onClick={handleSubmit}
            disabled={
              channelMessage === EMPTY_STRING ||
              loading ||
              IndividualLoading ||
              UpdateLoading
            }
          />
        {Channel === ChannelQueryType.ENTRYLEVEL ?null : (
              <Button onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                <img src={Template} alt="/" /> Add Template
              </Button>
            )}

          <Button mode="clear" onClick={handleClear} />
          <Button mode="cancel" onClick={() => setModal(false)} />
        </div>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(!isDrawerOpen)}
          className={drawerClasses.drawer}
          classes={{
            paper: drawerClasses.drawerPaper,
          }}
        >
          <div className="choose-message-template">
            <div className="choose-message-template__title">
              <Title>Choose Message Template</Title>
              <img
                src={Close}
                alt=""
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
            </div>

            <div className="choose-message-template__tableblock">
              <TableContainer className="choose-message-template__table">
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templates.map((template, index) => {
                      return (
                        <TableRow
                          key={template.node.id}
                          onClick={() => {
                            setTemplateId(template.node.id);
                            setIsDrawerOpen(!isDrawerOpen);
                          }}
                        >
                          <TableCell className="choose-message-templte__table--slno">
                            {index + 1}
                          </TableCell>
                          <TableCell>{template.node.msg_tpl_desc}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button
              mode="cancel"
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            />
          </div>
        </Drawer>
      </>
    );
  else return <b className="nodata"></b>;
};

export default SendMessage;
