import { gql } from "@apollo/client";

export const GetMsgInstTemplates = gql`
  query GetMsgInstTemplates(
    $name: String
    $token: String!
    $after: Cursor
    $first: Int
    $inst_id: ID!
    $orderBy: MsgMstInstTemplatesOrder
  ) {
    GetMsgInstTemplates(
      token: $token
      after: $after
      first: $first
      orderBy: $orderBy
      inst_id: $inst_id
      where: { or: [{ msgTplDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          msg_tpl_idx
          msg_tpl_desc
          inst_module
          inst_type
          inst_id
        }
      }
    }
  }
`;
export const GetSwMsgTags = gql`
  query GetSwMsgTags($token: String!) {
    GetSwMsgTags(token: $token) {
      id
      tag_name
      tag_details
    }
  }
`;
export const GetSwMsgTagsNode = gql`
  query GetSwMsgTagsNode($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MsgSwMsgTags {
        tag_name
        tag_details
      }
    }
  }
`;
export const studentInfo = gql`
  query studentInfo($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      id
      ... on MstStudent {
        first_name
        inst_id
        dept_id
        branch_id
        section_id
        semester_id
        class_id
        auth_id
      }
    }
  }
`;
export const GetTemplateById = gql`
  query GetTemplateById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on SwMsgTemplates {
        id
        msg_tpl_idx
        msg_tpl_desc
        inst_module
        inst_type
      }
    }
  }
`;
export const GetInstTemplateById = gql`
  query GetInstTemplateById($id: ID!, $token: String!) {
    node(id: $id, token: $token) {
      ... on MsgMstInstTemplates {
        id
        msg_tpl_idx
        msg_tpl_desc
        inst_module
        inst_type
        inst_id
      }
    }
  }
`;
export const GetSwMsgTemplates = gql`
  query GetSwMsgTemplates(
    $name: String
    $token: String!
    $after: Cursor
    $first: Int
    $orderBy: SwMsgTemplatesOrder
  ) {
    GetSwMsgTemplates(
      token: $token
      after: $after
      first: $first
      orderBy: $orderBy
      where: { or: [{ msgTplDescContainsFold: $name }] }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          msg_tpl_idx
          msg_tpl_desc
          inst_module
          inst_type
        }
      }
    }
  }
`;
