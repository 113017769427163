import { gql } from "@apollo/client";

export const GetEmployeeByAuthId = gql`
  query GetEmployeeByAuthId($token: String!, $auth_id: ID!) {
    GetEmployeeByAuthId(token: $token, auth_id: $auth_id) {
      id
      emp_name
      inst_id
    }
  }
`;
