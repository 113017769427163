import { gql } from "@apollo/client";

export const GetInstsByCustId = gql`
  query GetInstsByCustId(
    $token: String!
    $first: Int!
    $customer_id: ID!
    $after: Cursor
    $name: String
    $orderBy: MsgMstInstOrder
  ) {
    GetInstsByCustId(
      token: $token
      first: $first
      after: $after
      customer_id: $customer_id
      orderBy: $orderBy
      where: { or: [{ instNameContainsFold: $name }] }
    ) {
      edges {
        node {
          id
          inst_code
          inst_name
          inst_short_name
          inst_address
          inst_place
          inst_state
          inst_pin
          inst_contact_person
          inst_phone
          inst_email
          inst_mobile
        }
        cursor
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`;

export const InstDetailsByNodeId = gql`
  query InstDetailsByNodeId($ids: [ID!]!, $token: String!) {
    nodes(ids: $ids, token: $token) {
      id
      ... on MsgMstInst {
        id
        inst_code
        inst_name
        inst_short_name
        inst_address
        inst_place
        inst_state
        inst_pin
        inst_contact_person
        inst_phone
        inst_email
        inst_mobile
        inst_url
        inst_is_active
        inst_status
        inst_logo_filename
        inst_latitude
        inst_longitude
        inst_time_zone
        customer_id
        inst_time_zone
        bd_msg_hours
        bd_msg_mins
        customer_details {
          id
          cust_name
        }
        module_master_details {
          id
          lic_type
          lic_active
          lic_exp_grace_period
          lic_enabled_date
          lic_start_date
          lic_end_date
          inst_module_details {
            id
            is_module_enabled
            eduate_module_details {
              id
              Name
            }
          }
        }
      }
    }
  }
`;

export const GetAcdYrsByInstId = gql`
  query GetAcdYrsByInstId($inst_id: ID!, $token: String!) {
    GetAcdYrsByInstId(token: $token, inst_id: $inst_id) {
      id
      acd_yr
      acd_st_date
      acd_end_date
      acd_is_curr_yr
    }
  }
`;

export const GetMstInstLogos = gql`
  query GetMstInstLogos($token: String!, $inst_id: ID!) {
    GetMstInstLogos(token: $token, inst_id: $inst_id)
  }
`;
export const GetInstMasterData = gql`
  query GetInstMasterData($token: String!, $inst_id: ID!) {
    GetInstMasterData(token: $token, inst_id: $inst_id) {
      dept_details {
        id
        dept_desc
      }
      branch_details {
        id
        branch_desc
      }
      class_details {
        id
        class_desc
        class_short_desc
      }
      semester_details {
        id
        sem_desc
      }
      section_details {
        id
        section_desc
      }
    }
  }
`;
export const GetMstInstTree = gql`
  query GetMstInstTree(
    $token: String!
    $inst_id: ID!
    $id: ID!
    $query_type: InstTreeQueryType!
  ) {
    GetMstInstTree(
      token: $token
      inst_id: $inst_id
      id: $id
      query_type: $query_type
    ) {
      level_1
      level_1_name
      level_1_desc
      level_1_id
      total_std_count
      sub_level_total_count
      level_2_details {
        level_2
        level_2_name
        level_2_desc
        level_2_id
        total_std_count
        sub_level_total_count

        level_3_details {
          level_3
          level_3_name
          level_3_desc
          level_3_id
          total_std_count

          sub_level_total_count

          level_4_details {
            level_4
            level_4_name
            level_4_desc
            level_4_id
            total_std_count

            sub_level_total_count

            level_5_details {
              level_5
              level_5_name
              level_5_desc
              level_5_id
              total_std_count
            }
          }
        }
      }
    }
  }
`;

export const GetMstInstLogoWithoutToken = gql`
  query GetMstInstLogoWithoutToken($inst_id: ID!) {
    GetMstInstLogoWithoutToken(inst_id: $inst_id)
  }
`;

export const GetMstInstWithoutToken = gql`
  query GetMstInstWithoutToken($inst_id: ID!) {
    GetMstInstWithoutToken(inst_id: $inst_id) {
      id
      inst_code
      inst_name
      inst_short_name
      inst_address
      inst_place
      inst_state
      inst_pin
      inst_contact_person
      inst_phone
      inst_email
      inst_mobile
      inst_url
      inst_is_active
      inst_status
      inst_logo_filename
      inst_latitude
      inst_longitude
      inst_time_zone
      customer_id
      customer_details {
        id
        cust_name
      }
      module_master_details {
        id
        lic_type
        lic_active
        lic_exp_grace_period
        lic_enabled_date
        lic_start_date
        lic_end_date
        inst_module_details {
          id
          is_module_enabled
          eduate_module_details {
            id
            Name
          }
        }
      }
    }
  }
`;

export const ValidateMobileNumber = gql`
  query ValidateMobileNumber($mobile_number: String!) {
    ValidateMobileNumber(mobile_number: $mobile_number)
  }
`;
