import React, { useEffect, useState } from "react";
import useInstDetails from "../../../../customhooks/useInstDetails";
import { EMPTY_STRING, ROWS_PER_PAGE } from "../../../../utils/constants";
import EduateLogo from "../../../../images/EduateLogo.svg";
import Arrow from "../../../../images/ArrowRight.svg";
import { Label } from "../../../../stories/Label/Label";
import { Button } from "../../../../stories/Button/Button";
import Change from "../../../../images/TransferReceipt.svg";
import Input from "../../../../stories/Input/Input";
import DownArrow from "../../../../images/DownArrow.svg";
import Payfee from "../../../../images/PayOnline.svg";
import { Drawer } from "@mui/material";
import { disclaimerStyles } from "../../../../styles/DrawerStyles";
import DisClaimer from "./Disclaimer";
import useMstInstLogoWithoutToken from "../../../../customhooks/useMstInstLogoWithoutToken";
import { useLazyQuery } from "@apollo/client";
import {
  GetStdDemandForPaymentData,
  GetStdDemandForPaymentVars,
} from "../../Chat/FeeDetails/StudentDetails";
import { GetStdDemandForPayment } from "../../../../queries/students/list";
import { useParams } from "react-router-dom";
import { Direction, SortBy } from "../../../../utils/Enum.types";
import { StdDemandQueryType } from "../../../../utils/QueryEnums";
import { GetEncodedValue } from "../../../../queries/payments/query";
import {
  GetEncodedValueData,
  GetEncodedValueVars,
} from "../../Chat/FeeDetails/FetchDetails";

interface Props {
  by: StdDemandQueryType;
}
const StudentListForPayment = ({ by }: Props) => {
  const [disClaimer, setDisclaimer] = useState(false);
  const drawerClasses = disclaimerStyles();

  const { InstFormData } = useInstDetails();
  const { InstLogo } = useMstInstLogoWithoutToken();
  const { instId, encryptString } = useParams();
  const [encodedValue, setEncodedValue] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [plainValue, setPlainValue] = useState("");
  const [stdDemandId, setStdDemanId] = useState(0);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const [GetEncode] = useLazyQuery<GetEncodedValueData, GetEncodedValueVars>(
    GetEncodedValue
  );
  const [GetStdDemand, { data }] = useLazyQuery<
    GetStdDemandForPaymentData,
    GetStdDemandForPaymentVars
  >(GetStdDemandForPayment, {
    variables: {
      inst_id: instId!,
      encoded_str: encodedValue,
      query_type: by,
      after: null,
      first: ROWS_PER_PAGE,
      name: EMPTY_STRING,
      orderBy: {
        direction: Direction.ASC,

        field: SortBy.STD_NAME,
      },
    },
  });
  useEffect(() => {
    if (instId && encodedValue && by) GetStdDemand();
  }, [GetStdDemand, instId, encodedValue, by]);
  useEffect(() => {
    if (encryptString && encodedValue === EMPTY_STRING) {
      setEncodedValue(encryptString);
    }
  }, [encryptString, encodedValue]);

  return (
    <div className="mobile-login">
      <div className="mobile-login__inst-details">
        <div className="mobile-login__inst-details--logo">
          {InstLogo.defaultLogo ? (
            <img src={InstLogo.defaultLogo} alt="/" />
          ) : null}{" "}
        </div>
        <div className="mobile-login__inst-details--cust-name">
          {InstFormData.cust_name}
        </div>
        <div className="mobile-login__inst-details--inst-name">
          {InstFormData.inst_name}
        </div>
        <div className="mobile-login__inst-details--inst-addr">
          {InstFormData.inst_address +
            InstFormData.inst_place +
            InstFormData.inst_pin}
        </div>
      </div>
      <div className="mobile-login__back">
        <a href={`/${instId}/mobileapp/login`}>
          <img src={Arrow} alt="" /> Go Back
        </a>
      </div>

      <div className="mobile-login__std-list">
        <Label> Your Mobile No.</Label>
        <Input
          onChange={(e) => {
            if (by === StdDemandQueryType.BY_MOBILE_NO) {
              if (Number(e.target.value) >= 0 && e.target.value.length <= 10) {
                setMobileNo(e.target.value);
              }
            } else {
              setPlainValue(e.target.value);
            }
          }}
        />{" "}
        <Button
          className="storybook-button--change-number"
          onClick={() => {
            GetEncode({
              variables: {
                inst_id: instId!,
                str_to_encode:
                  by === StdDemandQueryType.BY_MOBILE_NO
                    ? mobileNo
                    : plainValue,
              },
            }).then(({ data }) => {
              if (data && data.GetEncodedValue.length) {
                setEncodedValue(data.GetEncodedValue);
              }
            });
          }}
        >
          <img src={Change} alt="" />
          Change Number
        </Button>
        <div className="mobile-login__std-list">
          <div className="mobile-login__std-list--title">
            <h5>Student List</h5>
            <div>
              <span>Total Students:</span>
              <b className="font-blue">2</b>
            </div>
          </div>
          <div className="mobile-login__std-list--acn">
            {data?.GetStdDemandForPayment.edges.map((res, index) => {
              const isExpanded = expandedIndex === index;

              return (
                <React.Fragment key={index}>
                  <h6 className="mobile-login__std-list--acn--title">
                    {index + 1}. {res.node.std_name}
                    <button
                      onClick={() => {
                        setStdDemanId(Number(res.node.id));
                        setDisclaimer(!disClaimer);
                      }}
                    >
                      <img src={Payfee} alt="/" />
                      Pay Fees
                    </button>
                  </h6>
                  {data
                    ? data.GetStdDemandForPayment.edges.map((res, index) => {
                        return (
                          <div className="mobile-login__std-list--acn--grid">
                            <ul>
                              <li>
                                <span>Roll No:</span>
                                <b>{res.node.std_roll_no}</b>
                              </li>
                              <li>
                                <span>Balance:</span>
                                <b className="font-red">
                                  {res.node.fee_balance}
                                </b>
                              </li>
                            </ul>

                            {isExpanded ? (
                              <img
                                src={DownArrow}
                                alt=""
                                onClick={() => handleToggle(index)}
                              />
                            ) : (
                              <img
                                src={Arrow}
                                alt=""
                                onClick={() => handleToggle(index)}
                              />
                            )}
                          </div>
                        );
                      })
                    : null}

                  {isExpanded ? (
                    <>
                      {data
                        ? data.GetStdDemandForPayment.edges.map(
                            (res, index) => {
                              return (
                                <div className="mobile-login__std-list--acn--grid">
                                  <ul>
                                    <li>
                                      <span>Father Name</span>
                                      <b>{res.node.std_father_name}</b>
                                    </li>
                                    <li>
                                      <span>Class</span>
                                      <b>{res.node.class_desc}</b>
                                    </li>

                                    <li>
                                      <span>Demand</span>
                                      <b className="font-blue">
                                        {res.node.fee_total_demand}
                                      </b>
                                    </li>

                                    <li>
                                      <span>Paid</span>
                                      <b>{res.node.fee_total_paid}</b>
                                    </li>
                                  </ul>
                                </div>
                              );
                            }
                          )
                        : null}
                    </>
                  ) : null}
                  <hr className="dashed" />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="mobile-login__footer">
          <span>Simplified By</span>
          <img src={EduateLogo} alt="" />
          <b>My-Eduate</b>
        </div>
      </div>
      <Drawer
        className={drawerClasses.drawer}
        classes={{
          paper: drawerClasses.drawerPaper,
        }}
        anchor="bottom"
        open={disClaimer}
        onClose={() => setDisclaimer(!disClaimer)}
      >
        <DisClaimer setModalFlag={setDisclaimer} stdDemandId={stdDemandId} />
      </Drawer>
    </div>
  );
};

export default StudentListForPayment;
