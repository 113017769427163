import { gql } from "@apollo/client";

export const GetMstInstActiveModules = gql`
  query GetMstInstActiveModules($token: String!, $inst_id: ID!) {
    GetMstInstActiveModules(token: $token, inst_id: $inst_id) {
      id
      is_module_enabled
      eduate_module_details {
        Name
        id
      }
    }
  }
`;

export const GetMstInstModules = gql`
  query GetMstInstModules(
    $token: String!
    $inst_id: ID!
    $query_type: InstModuleQueryType!
  ) {
    GetMstInstModules(
      token: $token
      inst_id: $inst_id
      query_type: $query_type
    ) {
      id
      lic_type
      lic_active
      lic_exp_grace_period
      lic_enabled_date
      lic_start_date
      lic_end_date
      inst_module_details {
        id
        is_module_enabled
        eduate_module_details {
          id
          Name
        }
      }
    }
  }
`;
