import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Button } from "../../../../../stories/Button/Button";
import { formLabelProps } from "../../Customer/Add";
import { Label } from "../../../../../stories/Label/Label";
import Input from "../../../../../stories/Input/Input";
import { useMutation } from "@apollo/client";

import { Title } from "../../../../../stories/Title/Title";
import { CreateMsgMstInstInput } from "../../../../../utils/FormTypes";
import { inst_validation } from "../../../../../utils/validationRules";
import { Direction, Operation, SortBy } from "../../../../../utils/Enum.types";
import { msgType } from "../../../../../utils/Types";
import { AddInst } from "../../../../../queries/institution/mutations/new";
import { UpdateInst } from "../../../../../queries/institution/mutations/update";
import { useParams } from "react-router-dom";
import useToken from "../../../../../customhooks/useToken";
import {
  handleFormEvent,
  removeMoreSpace,
} from "../../../../../utils/UtilFunctions";
import useLoggedInUserDetails from "../../../../../customhooks/useLoggedInUserDetails";
import {
  GetInstsByCustId,
  InstDetailsByNodeId,
} from "../../../../../queries/institution/queries/byId";
import { ROWS_PER_PAGE, payloadTypes } from "../../../../../utils/constants";
import { AppContext } from "../../../../../context/context";
import MessageModal from "../../../../../utils/MessageModal";
import LoadingModal from "../../../../Modals/LoadingModal";
import useInstDetails from "../../../../../customhooks/useInstDetails";
import Close from "../../../../../images/Close.svg";
const { Masters_Form } = require("../../json/form.json");

export interface BasicDetailsProps {
  operation: Operation;
  onNext: () => void;
  onBack?: () => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const BasicDetails = ({ operation, onNext, setModal }: BasicDetailsProps) => {
  const { custId } = useParams();
  const { token } = useToken();

  const { state, dispatch } = useContext(AppContext);
  const [message, setMessage] = useState<msgType>({
    message: "",
    flag: false,
    operation: Operation.NONE,
  });

  const { InstDetails } = useInstDetails();
  const { user_details } = useLoggedInUserDetails();
  const [formData, setFormData] = useState<CreateMsgMstInstInput>({
    inst_name: "",
    inst_short_name: "",
    inst_contact_person: "",
    inst_phone: "",
    inst_mobile: "",
    inst_email: "",
    inst_pin: "",
    inst_address: "",
    inst_latitude: 0,
    inst_longitude: 0,
    inst_place: "",
    inst_state: "",
    inst_url: "",
  });

  //mutations
  const [createInstituion, { loading: creationLoading }] = useMutation(
    AddInst,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );
  const [UpdateInstitution, { loading: updationLoading }] = useMutation(
    UpdateInst,
    {
      onError: (e) =>
        setMessage({
          flag: true,
          message: e.message,
          operation: Operation.NONE,
        }),
    }
  );

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (
      InstDetails.data &&
      !InstDetails.loading &&
      state.InstId &&
      operation === Operation.UPDATE
    ) {
      const {
        inst_name,
        inst_short_name,
        inst_address,
        inst_place,
        inst_state,
        inst_pin,
        inst_contact_person,
        inst_phone,
        inst_email,
        inst_mobile,
        inst_url,
        inst_latitude,
        inst_longitude,
      } = InstDetails.data.nodes[0];

      setFormData({
        inst_name,
        inst_short_name,
        inst_contact_person,
        inst_phone,
        inst_mobile,
        inst_email,
        inst_pin,
        inst_address,
        inst_latitude,
        inst_longitude,
        inst_place,
        inst_state,
        inst_url,
      });
    }
  }, [InstDetails.data, state.InstId, InstDetails.loading, operation]);
  const HandleRegister = async () => {
    await createInstituion({
      variables: {
        token,
        input: {
          customer_id: custId,
          inst_name: removeMoreSpace(formData.inst_name),
          inst_short_name: removeMoreSpace(formData.inst_short_name),
          inst_address: removeMoreSpace(formData.inst_address),
          inst_place: removeMoreSpace(formData.inst_place),
          inst_state: removeMoreSpace(formData.inst_state),
          inst_pin: formData.inst_pin,
          inst_contact_person: removeMoreSpace(formData.inst_contact_person),
          inst_phone: formData.inst_phone,
          inst_email: formData.inst_email,
          inst_mobile: formData.inst_mobile,

          inst_url: formData.inst_url,
          inst_latitude: formData.inst_latitude,
          inst_longitude: formData.inst_longitude,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstsByCustId,
          variables: {
            customer_id: Number(custId),
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: "",
            after: null,
            sortBy: SortBy.INST_NAME,
            token,
          },
        },
      ],
    }).then(({ data }) => {
      if (data) {
        const { id } = data.AddInst;
        handleClear();
        dispatch({
          type: payloadTypes.SET_INST_ID,
          payload: { InstId: id },
        });
        onNext();
      }
    });
  };
  const HandleUpdate = async () => {
    await UpdateInstitution({
      variables: {
        id: state.InstId,
        token,
        input: {
          inst_name: removeMoreSpace(formData.inst_name),
          inst_short_name: removeMoreSpace(formData.inst_short_name),
          inst_address: removeMoreSpace(formData.inst_address),
          inst_place: removeMoreSpace(formData.inst_place),
          inst_state: removeMoreSpace(formData.inst_state),
          inst_pin: formData.inst_pin,
          inst_contact_person: removeMoreSpace(formData.inst_contact_person),
          inst_phone: formData.inst_phone,
          inst_email: formData.inst_email,
          inst_mobile: formData.inst_mobile,
          inst_url: formData.inst_url,
        },
        user_details,
      },
      refetchQueries: [
        {
          query: GetInstsByCustId,
          variables: {
            customer_id: Number(custId),
            first: ROWS_PER_PAGE,
            direction: Direction.ASC,
            name: "",
            after: null,
            sortBy: SortBy.INST_NAME,
            token,
          },
        },
        {
          query: InstDetailsByNodeId,
          variables: { ids: [state.InstId], token },
        },
      ],
    }).then(({ data }) => {
      if (data && data.UpdateInst) {
        setMessage({
          flag: true,
          message: "Institution Updated Successfully",
          operation: Operation.UPDATE,
        });
      }
    });
  };

  const handleClear = () => {
    setFormData({
      id: 0,
      inst_code: "",
      inst_name: "",
      inst_short_name: "",
      inst_address: "",
      inst_place: "",
      inst_state: "",
      inst_pin: "",
      inst_contact_person: "",
      inst_phone: "",
      inst_email: "",
      inst_mobile: "",
      inst_url: "",
      inst_is_active: "",
      inst_status: "",
      inst_logo_filename: "",
      inst_latitude: 0,
      inst_longitude: 0,
      inst_time_zone: "",
    });
  };
  const handleClose = () => {
    if (message.flag && message.operation !== Operation.NONE) {
      handleClear();
      if (message.operation === Operation.UPDATE) {
        setModal(false);
      }
    }
    setMessage({
      flag: false,
      message: "",
      operation: Operation.NONE,
    });
  };
  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={inst_validation}
        onSubmit={
          operation === Operation.CREATE ? HandleRegister : HandleUpdate
        }
        enableReinitialize
      >
        {(meta) => {
          return (
            <Form
              className={
                operation === Operation.UPDATE
                  ? "inst-details__update"
                  : "inst-details"
              }
            >
              <div className="inst-details__form--title">
                <Title
                  variant={operation === Operation.CREATE ? "subtitle1" : "h6"}
                >
                  Basic Details
                </Title>

                {operation === Operation.UPDATE && (
                  <img src={Close} alt="" onClick={() => setModal(false)} />
                )}
              </div>
              <div className="inst-details__form row g-0">
                <div className="col">
                  <div className="inst-details__form--header">
                    <h2>Personal Details</h2>
                  </div>
                  {Masters_Form.Institution.PersonalDetails.map(
                    (
                      {
                        labelName,
                        inputName,
                        autoFocus,
                        required,
                        dataType,
                      }: formLabelProps,
                      index: number
                    ) => (
                      <React.Fragment key={index}>
                        <div className="label-grid">
                          <Label>{labelName}</Label>
                          <Input
                            required={required}
                            autoFocus={autoFocus}
                            type={dataType}
                            value={formData[inputName]}
                            name={inputName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            onKeyDown={handleFormEvent}
                          />
                        </div>
                      </React.Fragment>
                    )
                  )}
                  <br />
                  <div className="inst-details__form--header">
                    <h2>Other Details</h2>
                  </div>
                  {Masters_Form.Institution.OtherDetails.map(
                    (
                      {
                        labelName,
                        inputName,
                        autoFocus,
                        required,
                        dataType,
                      }: formLabelProps,
                      index: number
                    ) => (
                      <React.Fragment key={index}>
                        <div className="label-grid">
                          <Label>{labelName}</Label>
                          <Input
                            required={required}
                            autoFocus={autoFocus}
                            value={formData[inputName]}
                            type={dataType}
                            name={inputName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            onKeyDown={handleFormEvent}
                          />
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
                <div className="col">
                  <div className="inst-details__form--header">
                    <h2>Location Details</h2>
                  </div>
                  {Masters_Form.Institution.LocationDetails.map(
                    (
                      {
                        labelName,
                        inputName,
                        autoFocus,
                        required,
                        dataType,
                      }: formLabelProps,
                      index: number
                    ) => (
                      <React.Fragment key={index}>
                        <div className="label-grid">
                          <Label>{labelName}</Label>
                          <Input
                            required={required}
                            autoFocus={autoFocus}
                            value={formData[inputName]}
                            name={inputName}
                            type={dataType}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              meta.handleChange(e);
                              handleValueChange(e);
                            }}
                            onKeyDown={handleFormEvent}
                          />
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
              <Button
                mode={operation === Operation.CREATE ? "save-continue" : "save"}
                type="submit"
              />
              <Button mode="clear" type="button" onClick={handleClear} />
              <Button
                mode="cancel"
                type="button"
                onClick={() => {
                  handleClear();
                  setModal(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <MessageModal
        modalFlag={message.flag!}
        value={message.message!}
        handleClose={handleClose}
        operation={message.operation!}
      />
      <LoadingModal flag={creationLoading || updationLoading} />
    </>
  );
};

export default BasicDetails;
