import React, { useState } from "react";
import Close from "../../../../images/Close.svg";
import ImageBlue from "../../../../images/Copy_Link_Blue.svg";
import ImageWhite from "../../../../images/Copy_Link_White.svg";
import { useNavigate, useParams } from "react-router-dom";
import { emptyMessageType } from "../../../../utils/constants";
import MessageModal from "../../../../utils/MessageModal";
import { Operation } from "../../../../utils/Enum.types";
interface Props {
  setModalFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyLink = ({ setModalFlag }: Props) => {
  const { instId } = useParams();
  const [message, setMessage] = useState(emptyMessageType);
  return (
    <>
      <div className="copy-link">
        <div className="copy-link__header">
          <img src={Close} alt="" onClick={() => setModalFlag(false)} />
        </div>
        <div className="copy-link__data">
          <div className="copy-link__data--image">
            <img src={ImageBlue} alt="/" />
          </div>
          <h2 className="copy-link__data--title">Copy Link</h2>
          <div className="copy-link__data--text">
            <ul>
              <li>
                <p>
                  {" "}
                  Please integrate the provided link into your website to
                  seamlessly direct students to the payment options page. This
                  enables them to proceed with their payments by fetching
                  student information using either their mobile number or email.
                  This approach ensures a smooth and efficient transaction
                  process, allowing students to complete their transactions
                  hassle-free
                </p>
              </li>
            </ul>
          </div>

          <div className="copy-link__data--link">
            {/* <span>{`http://64.227.181.227:3000/${instId}/chat/fee/fetchdetails`}</span>{" "} */}
            <span>{`https://msg.myeduate.com/${instId}/chat/fee/fetchdetails`}</span>{" "}
          </div>
        </div>
        <div
          className="copy-link__footer"
          onClick={() => {
            // navigate(`/${instId}/chat/fee/fetchdetails`);
            navigator.clipboard.writeText(
              `https://msg.myeduate.com/${instId}/chat/fee/fetchdetails`
            );
            setMessage({
              flag: true,
              message: "Copied link!",
              operation: Operation.CREATE,
            });
          }}
        >
          <img src={ImageWhite} alt="" />
          <span> Copy-Link</span>
        </div>
      </div>
      <MessageModal
        handleClose={() => {
          setMessage({
            flag: false,
            message: "",
            operation: Operation.NONE,
          });
        }}
        modalFlag={message.flag}
        operation={message.operation}
        value={message.message}
      />
    </>
  );
};

export default CopyLink;
