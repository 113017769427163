import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { EMPTY_STRING, NOTALLOCATED } from "../utils/constants";
import { GetDeptIdByName as DeptIdByName } from "../queries/institution/department/query/index";
import useToken from "./useToken";
import { useEffect } from "react";
import { BranchIdByName } from "../queries/institution/branch/query";
import { ClassIdByName } from "../queries/institution/classes/byid";
import { SemIdByName } from "../queries/institution/semester/query";
import { SectionIdByName } from "../queries/institution/sections/query";

const useNotAlloactedIdFromInst = () => {
  const { instId } = useParams();
  const { token } = useToken();

  const [GetNotAllocatedDeptId, { data: NotAllocatedDeptId }] = useLazyQuery(
    DeptIdByName,
    {
      variables: {
        dept_name: NOTALLOCATED,
        inst_id: instId,
        token,
      },
    }
  );
  const [GetNotAllocatedBranchId, { data: NotAllocatedBranchId }] =
    useLazyQuery(BranchIdByName, {
      variables: {
        branch_name: NOTALLOCATED,
        dept_id: NotAllocatedDeptId?.GetDeptIdByName ?? EMPTY_STRING,
        inst_id: instId,
        token,
      },
    });
  const [GetNotAllocatedClassId, { data: NotAllocatedClassId }] = useLazyQuery(
    ClassIdByName,
    {
      variables: {
        class_name: NOTALLOCATED,
        branch_id: NotAllocatedBranchId?.GetBranchIdByName ?? EMPTY_STRING,
        inst_id: instId,
        token,
      },
    }
  );

  const [GetNotAllocatedSemesterId, { data: NotAllocatedSemesterId }] =
    useLazyQuery(SemIdByName, {
      variables: {
        semester_name: NOTALLOCATED,
        class_id: NotAllocatedClassId?.GetClassIdByName ?? EMPTY_STRING,
        inst_id: instId,
        token,
      },
    });
  const [GetNotAllocatedSectionId, { data: NotAllocatedSectionId }] =
    useLazyQuery(SectionIdByName, {
      variables: {
        section_name: NOTALLOCATED,
        semester_id:
          NotAllocatedSemesterId?.GetSemesterIdByName ?? EMPTY_STRING,
        inst_id: instId,
        token,
      },
    });

  useEffect(() => {
    if (token && instId) {
      GetNotAllocatedDeptId();
    }
  }, [token, instId, GetNotAllocatedDeptId, NotAllocatedDeptId]);

  useEffect(() => {
    if (token && instId) {
      GetNotAllocatedBranchId();
    }
  }, [token, instId, GetNotAllocatedBranchId]);

  useEffect(() => {
    if (token && instId) {
      GetNotAllocatedClassId();
    }
  }, [
    token,
    instId,
    GetNotAllocatedClassId,
    NotAllocatedBranchId,
    NOTALLOCATED,
  ]);

  useEffect(() => {
    if (token && instId) {
      GetNotAllocatedSemesterId();
    }
  }, [
    token,
    instId,
    GetNotAllocatedSemesterId,
    NotAllocatedClassId,
    NOTALLOCATED,
  ]);

  useEffect(() => {
    if (token && instId) {
      GetNotAllocatedSectionId();
    }
  }, [
    token,
    instId,
    GetNotAllocatedSectionId,
    NotAllocatedSemesterId,
    NOTALLOCATED,
  ]);

  return {
    NotAllocatedDeptId:
      NotAllocatedDeptId && NotAllocatedDeptId?.GetDeptIdByName,
    NotAllocatedBranchId:
      NotAllocatedBranchId && NotAllocatedBranchId?.GetBranchIdByName,
    NotAllocatedClassId:
      NotAllocatedClassId && NotAllocatedClassId?.GetClassIdByName,
    NotAllocatedSemesterId:
      NotAllocatedSemesterId && NotAllocatedSemesterId?.GetSemesterIdByName,
    NotAllocatedSectionId:
      NotAllocatedSectionId && NotAllocatedSectionId?.GetSectionIdByName,
  };
};

export default useNotAlloactedIdFromInst;
