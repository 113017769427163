import React from "react";
interface Props {
  totalCount: number;
}
const StudentTotalCount = ({ totalCount }: Props) => {
  return (
    <div className="student-total-count">
      Total Students : <b>{totalCount}</b>
    </div>
  );
};

export default StudentTotalCount;
