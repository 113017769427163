import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { msgType } from "../../../../../../utils/Types";
import useFileSizeDetails from "../../../../../../customhooks/useFileUploadConstraints";
import useToken from "../../../../../../customhooks/useToken";
import { useParams } from "react-router-dom";
import useLoggedInUserDetails from "../../../../../../customhooks/useLoggedInUserDetails";
import useInstDetails from "../../../../../../customhooks/useInstDetails";
import useStudentDatabyId from "../../../../../../customhooks/useStudentDataById";
import { useMutation } from "@apollo/client";
import { Operation, imageFileType } from "../../../../../../utils/Enum.types";
import { getDownloadUrl } from "../../../../../../utils/DownloadFile";
import { handleUploadAndDownloadFile } from "../../../../../../utils/Upload";
import { WaiveOffModalStyles } from "../../../../../styles/ModalStyles";
import LoadingModal from "../../../../../Modals/LoadingModal";
import { Title } from "../../../../../../stories/Title/Title";
import { Button } from "../../../../../../stories/Button/Button";
import Close from "../../../../../../images/Close.svg";
import Avatar from "../../../../../../images/Avatar.svg";
import BrowseFile from "../../../../../../images/BrowseFiles.svg";
import { AddMstInstLogo } from "../../../../../../queries/institution/mutations/new";
import { userDetails } from "../../../../Chat/Feeds/Types";
import { GetMstInstLogos } from "../../../../../../queries/institution/queries/byId";
import MessageModal from "../../../../../../utils/MessageModal";
import { emptyMessageType } from "../../../../../../utils/constants";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  handleClose: () => void;
  setMessage: React.Dispatch<React.SetStateAction<msgType>>;
  message: msgType;
}

interface AddMstInstLogoData {
  AddMstInstLogo: boolean;
}

interface AddMstInstLogoVars {
  token: string;
  inst_id: string;
  user_details: userDetails;
  inst_logo_filename: string;
}
const InstLogoUpload = ({
  modal,
  handleClose,
  setMessage,
  message,
  setModal,
}: Props) => {
  const { ComponentWarning, maxFileSizeInBits } = useFileSizeDetails();
  const { token } = useToken();
  const { instId } = useParams();
  const { user_details } = useLoggedInUserDetails();
  const { InstFormData } = useInstDetails();
  const [file, setFile] = useState<File | null>(null);
  // eslint-disable-next-line
  const [progress, setProgress] = useState(0);
  const [uploadLoading, setUploading] = useState(false);
  const { studentFormData } = useStudentDatabyId();

  const [imageString, setImageString] = useState("");

  const filetype = file?.name.split(".").pop();

  const filename = `${InstFormData.inst_name}/logos/logo`;
  const fileNameWithExtension = `${filename}.${filetype}`;

  const [AddInstLogo, { loading: updateLoading }] = useMutation<
    AddMstInstLogoData,
    AddMstInstLogoVars
  >(AddMstInstLogo, {
    onError: (e) =>
      setMessage({
        flag: true,
        message: e.message,
        operation: Operation.NONE,
      }),
  });
  useEffect(() => {
    if (studentFormData.std_profile_filename) {
      getDownloadUrl(
        studentFormData.std_profile_filename,
        setImageString,
        false
      );
    }
  }, [studentFormData.std_profile_filename]);

  useEffect(() => {
    if (file) {
      // eslint-disable-next-line
      Object.values(imageFileType).map((d) => {
        if (d === filetype) {
          setImageString(URL.createObjectURL(file));
        }
      });
    } // eslint-disable-next-line
  }, [file]);

  const handleImagePreview = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      if (e.target.files[0].size > maxFileSizeInBits.mediaSize) {
        alert("huge file");
        setFile(null);
        return;
      }
      let image_as_files = e.target.files[0];
      setFile(image_as_files);
    }
  };

  const uploadFile = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          handleUploadAndDownloadFile(
            file,
            fileNameWithExtension,
            setProgress,
            false
          )
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        } else {
          resolve(true);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateForm = async () => {
    setUploading(true);
    if (file === null) {
      alert("File not Found");
      return;
    }
    try {
      const uploadResult = await uploadFile();
      if (uploadResult) {
        setUploading(false);
        AddInstLogo({
          variables: {
            token,
            inst_id: instId!,
            user_details,
            inst_logo_filename: fileNameWithExtension,
          },
          refetchQueries: [
            {
              query: GetMstInstLogos,
              variables: {
                token,
                inst_id: instId,
              },
            },
          ],
        }).then(({ data }) => {
          if (data) {
            setMessage({
              message: "Institution Logo Updated Successfully",
              flag: true,
              operation: Operation.UPDATE,
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      isOpen={modal}
      style={WaiveOffModalStyles}
    >
      <div className="profile-pic">
        {uploadLoading || updateLoading ? (
          <LoadingModal flag={uploadLoading || updateLoading} />
        ) : (
          <>
            <div className="profile-pic__title">
              <Title>Institution Logo Upload</Title>
              <img
                src={Close}
                alt="/"
                className="modal-close-icon"
                onClick={handleClose}
              />
            </div>

            <div className="profile-pic__preview">
              <img src={imageString ? imageString : Avatar} alt="/" />
            </div>
            <div className="profile-pic__warning">
              {ComponentWarning.mediaSizeComponent}
            </div>
            <div className="profile-pic__btns">
              <label className="profile-pic__browse">
                <img src={BrowseFile} alt="/" className="browse-icon" />
                Browse
                <input
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleImagePreview(e)
                  }
                  className="profile-pic__upload-btn--input"
                  accept={`${".jpg, .jpeg, .png"}`}
                />
              </label>
              <Button mode="save" onClick={updateForm} />
              <Button mode="cancel" onClick={handleClose} />
            </div>
          </>
        )}
        <MessageModal
          handleClose={() => {
            if (message.operation !== Operation.NONE) {
              setMessage(emptyMessageType);
              setModal(false);
            }
          }}
          modalFlag={message.flag}
          operation={message.operation}
          value={message.message}
        />
      </div>
    </ReactModal>
  );
};

export default InstLogoUpload;
