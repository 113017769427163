import React, { ChangeEvent, FocusEvent, SyntheticEvent } from "react";
import "./textarea.scss";
// import { Requiredfield } from "./Input.stories";

export type Props<T extends HTMLElement> = {
  rawOnChange?: boolean;
  onChange?: (e: ChangeEvent<T>) => void;
  className?: string;
  name?: string;
  autoFocus?: boolean;
  onFocus?: (e: FocusEvent<T>) => void;
  onSelect?: (e: React.SyntheticEvent<T>) => void;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  size?: string;
  textAreaRef?: React.Ref<any>;
  required?: boolean;
  onBlur?: (e: SyntheticEvent<T, Event>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => any;
  value?: any;
  min?: any;
  max?: any;
  defaultValue?: any;
  maxLength?: any;
  error?: any;
  pattern?: string;
  checked?: boolean;
  rows: number;
  draggable?: boolean;
  resize?: "both" | "vertical" | "horizontal" | "none";
};

// eslint-disable-next-line
export function TextArea<T extends HTMLElement>({
  onBlur,
  placeholder,
  disabled,
  required,
  name,
  rows,
  onChange,
  autoFocus,
  size,
  defaultValue,
  textAreaRef,
  maxLength,
  onKeyDown,
  onSelect,
  onFocus,
  value,
  error,
  id,
  className,
}: Props<HTMLTextAreaElement>) {
  // eslint-disable-next-line
  error ? (placeholder = error) : (placeholder = placeholder);

  return (
    <>
      <textarea
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete="off"
        onChange={onChange}
        rows={rows}
        ref={textAreaRef!}
        name={name}
        required={required}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxLength}
        draggable={false}
        style={{ resize: "none" }}
        id={id}
        onBlur={onBlur}
        onSelect={onSelect}
        className={[
          `storybook-textarea  ${error ? "field_error" : ""} ${
            required ? "required_error" : ""
          } ${size} ${className}`,
        ].join(" ")}
      />
    </>
  );
}
export default TextArea;
