import Modal from "react-modal";
import { LoadingModalStyles } from "../styles/ModalStyles";
import loading from "../../images/Loading.gif";
interface Props {
  flag: boolean;
}
const LoadingModal = ({ flag }: Props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={flag}
      style={LoadingModalStyles}
      ariaHideApp={false}
    >
      <div className="loading-time">
        <span>Loading...</span>
        <br />
        <img src={loading} alt="loading" className="loading-gif" style={{height:80,width:80}}/>
      </div>
    </Modal>
  );
};

export default LoadingModal;
