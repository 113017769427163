import { gql } from "@apollo/client";

export const GetEduateDetailsByAuthId = gql`
  query GetEduateDetailsByAuthId($token: String!, $auth_id: ID!) {
    GetEduateDetailsByAuthId(token: $token, auth_id: $auth_id) {
      id
      first_name
      middle_name
      last_name
    }
  }
`;
