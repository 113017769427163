import React, { useState } from "react";
import Navbar from "../../../Layouts/Navbar";
import { licenseTypes } from "../../../../utils/constants";
import Sidebar from "../../../Layouts/Sidebar";
import { Title } from "../../../../stories/Title/Title";
import { Operation, PageFor } from "../../../../utils/Enum.types";

import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../../../styles/Tabs";
import { useTheme } from "@mui/material/styles";
import LoginUser from "./LoginUsers/Index";
import UserResetPassword from "./ResetPassword/Index";
import EnableUserLogin from "./EnableUserLogin/Index";
import DeviceDetails from "../../Masters/Student/DeviceDetails/DeviceDetails";
export interface ModalType {
  operation: Operation;
  id: number;
  flag: boolean;
}
const Index = () => {
  const [value, setValue] = useState(0);

  // eslint-disable-next-line
  const [modalFlag, setModalFlag] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const theme = useTheme();

  return (
    <>
      <Navbar navType={licenseTypes.CHANNEL} />
      <div className="user-rights">
        <Sidebar />
        <div className="user-rights__frame">
          <Title>User Rights</Title>
          <div className="user-rights__frame--data">
            <div className="user-rights__frame--tabs">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Create Staff" value={0} {...a11yProps(0)} />
                <Tab label="Reset User Password" value={1} {...a11yProps(1)} />
                <Tab label="Enable User Login" value={2} {...a11yProps(2)} />
                <Tab
                  label="Student Device Details"
                  value={3}
                  {...a11yProps(3)}
                />
              </Tabs>
            </div>
            <div className="user-rights__frame--tab-panel">
              <TabPanel value={value} index={0} dir={theme.direction}>
                <LoginUser
                  pageType={PageFor.GENERAL}
                  setModalFlag={setModalFlag}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <UserResetPassword />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <EnableUserLogin />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <DeviceDetails />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
