import React, { useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  listAutoCompleteStyles,
  listAutoCompleteTextStyles,
} from "../../../../styles/AutocompleteStyles";
import { Button } from "../../../../../stories/Button/Button";
import { AppContext } from "./context";
import { payloadTypes } from "./reducer";
import useMasterDetailsDropDown from "../../../../../customhooks/useMasterDetailsDropDown";
import { Keys } from "../../../../../utils/Enum.keys";
import useInstitutionConfiguration from "../../../../../customhooks/useInstitutionConfiguration";
interface Props {
  children: React.ReactNode;
}
const MasterDetailsDropDown: React.FC<Props> = ({ children }) => {
  const classes = listAutoCompleteStyles();
  const textClasses = listAutoCompleteTextStyles();
  const { dispatch, state } = useContext(AppContext);

  const {
    USE_BRANCH_KEY,
    USE_DEPARTMENT_KEY,
    USE_CLASS_KEY,
    USE_SECTION_KEY,
    USE_SEMESTER_KEY,
  } = useInstitutionConfiguration();
  const {
    branchDropDown,
    classDropDown,
    departmentDropDown,
    sectionDropDown,
    semesterDropDown,
  } = useMasterDetailsDropDown();

  const handleClear = () => {
    dispatch({
      type: payloadTypes.SET_DEPT_ID,
      payload: { deptId: 0 },
    });
    dispatch({
      type: payloadTypes.SET_BRANCH_ID,
      payload: { branchId: 0 },
    });
    dispatch({
      type: payloadTypes.SET_CLASS_ID,
      payload: { classId: 0 },
    });
    dispatch({
      type: payloadTypes.SET_SEMESTER_ID,
      payload: { semId: 0 },
    });
    dispatch({
      type: payloadTypes.SET_SECTION_ID,
      payload: { sectionId: 0 },
    });
    dispatch({
      type: payloadTypes.SET_SEARCH_DATA,
      payload: { searchData: "" },
    });
  };

  return (
    // <AppProvider>
    <>
      <div className="chat-home__select row g-0">
        {USE_DEPARTMENT_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={departmentDropDown}
              value={
                departmentDropDown.find(
                  ({ value }) => value === state.deptId
                ) ?? null
              }
              openOnFocus
              onChange={(e, newValue) => {
                if (newValue)
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: newValue.value },
                  });
                else {
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                }
              }}
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: textClasses.formControlRoot }}
                  label="Departments"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_BRANCH_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={branchDropDown}
              openOnFocus
              forcePopupIcon
              value={
                branchDropDown.find(({ value }) => value === state.branchId) ??
                null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: newValue.value },
                  });
                } else {
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: textClasses.formControlRoot }}
                  label="Branches"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_CLASS_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={classDropDown}
              value={
                classDropDown.find(({ value }) => value === state.classId) ??
                null
              }
              onChange={(e, newValue) => {
                if (newValue)
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: newValue.value },
                  });
                else {
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: 0 },
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: 0 },
                  });
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: textClasses.formControlRoot }}
                  label="Classes"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_SEMESTER_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={semesterDropDown}
              value={
                semesterDropDown.find(({ value }) => value === state.semId) ??
                null
              }
              onChange={(e, newValue) => {
                if (newValue)
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: newValue.value },
                  });
                else {
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: 0 },
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: 0 },
                  });
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: textClasses.formControlRoot }}
                  label="Semesters"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        ) : null}

        {USE_SECTION_KEY ? (
          <div className="col-1">
            <Autocomplete
              classes={classes}
              options={sectionDropDown}
              value={
                sectionDropDown.find(
                  ({ value }) => value === state.sectionId
                ) ?? null
              }
              onChange={(e, newValue) => {
                if (newValue)
                  dispatch({
                    type: payloadTypes.SET_SECTION_ID,
                    payload: { sectionId: newValue.value },
                  });
                else {
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_SECTION_ID,
                    payload: { sectionId: 0 },
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === Keys.BACKSPACE) {
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_SEMESTER_ID,
                    payload: { semId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_DEPT_ID,
                    payload: { deptId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_BRANCH_ID,
                    payload: { branchId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_CLASS_ID,
                    payload: { classId: 0 },
                  });
                  dispatch({
                    type: payloadTypes.SET_SECTION_ID,
                    payload: { sectionId: 0 },
                  });
                }
              }}
              openOnFocus
              forcePopupIcon
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: textClasses.formControlRoot }}
                  label="Sections"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </div>
        ) : null}

        <div className="col"></div>
        <div className="col-1 ">
          <Button
            mode="clear"
            className="chat-home__select--button"
            onClick={handleClear}
          />
        </div>
      </div>
      {children}
    </>
  );
};

export default MasterDetailsDropDown;
